import React, { useState, useEffect, useRef } from 'react';
import { Main, Loading, Axios, Modal, usePaginacion, useModal, Paginacion } from '../../../Helpers/importacionesComunes'
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import AbmIglesia from '../../../abms/Abm-iglesia';
import { Tabla } from '../componentes/Tabla'
import { Cabecera } from '../componentes/Cabecera'
import { esMayor, desglosarFecha, obtenerFechaDiamenosN } from '../../../Helpers/fechas'
import { SeleccionadorX } from '../../../componentes/SeleccionadorX';
import { Loader } from '../../../componentes/Loader';
import Logo from '../../../componentes/Logo';
import { Card, List, ListItem, Option, Select, Spinner, Typography } from '@material-tailwind/react';
import { Divider } from '@mui/material';

export default function Ingresos({ match, history }) {
    const [registros, setRegistros] = useState([])
    const [registrosAmostrar, setRegistrosAmostrar] = useState([])
    const [cargandoRegistros, setCargandoRegistros] = useState(true);
    const { esCelular, usuario } = useContextoGlobal();
    const [hayFiltrosActivos, setHayFiltrosActivos] = useState(false)
    const [huboError, setHuboError] = useState(false)
    const filtrosRef = useRef()
    const [fechaDesde, setFechaDesde] = useState(obtenerFechaDiamenosN(4).date)
    const [fechaHasta, setFechaHasta] = useState(new Date())
    const [regionSeleccionada, setRegionSeleccionada] = useState(null)

    const [regiones, setRegiones] = useState([])

    useEffect(() => {
        const buscarRegiones = async () => {
            try {
                setCargandoRegistros(true)
                const resultado = await Axios.get('/api/tablasgenerales/regionesrep')
                setRegiones(resultado.data.filter(item => item.id_region > 0))
                setCargandoRegistros(false)
            } catch (err) {
                alert(err)
                setCargandoRegistros(false)
            }
        }

        /* if(usuario.sede_admin){
             buscarRegiones()
         }
         */
        // Busco las regiones aunque sea un usuario regional para poder mapear entre usuario.id_region y id_rubro que es lo que sirve para buscar los ingresos
        buscarRegiones()

    }, [])

    useEffect(() => {
        /*if (usuario){
            buscarDatos({conLoading:true},fechaDesde,fechaHasta) 
        }*/
        if (usuario.sede_admin) {
            if (regionSeleccionada) {
                buscarDatos({ conLoading: true }, fechaDesde, fechaHasta)
            }
        } else {
            if (regiones.length > 0) {
                buscarDatos({ conLoading: true }, fechaDesde, fechaHasta)
            }
        }
    }, [usuario, regionSeleccionada, regiones]) // agregué usuario porque necesito que se dispare el evento cuando no sea vacío usuario, al principio es vacío por unos milisegundos...

    useEffect(() => {

        if (registrosAmostrar.length != registros.length) {
            setHayFiltrosActivos(true)
        } else {
            setHayFiltrosActivos(false)
        }

    }, [registrosAmostrar])

    useEffect(() => {

        if (esMayor(fechaDesde, fechaHasta)) {
            alert('El orden de las fechas es incorrecto')
        } else {
            //buscarDatos({conLoading:true},fechaDesde,fechaHasta,) 
            if (usuario.sede_admin) {
                if (regionSeleccionada) {
                    buscarDatos({ conLoading: true }, fechaDesde, fechaHasta)
                }
            } else {
                if (regiones.length > 0) {
                    buscarDatos({ conLoading: true }, fechaDesde, fechaHasta)
                }
            }
        }

    }, [fechaDesde, fechaHasta])

    const buscarDatos = async (params = {}) => {
        const { conLoading } = params // con loading se usa para la búsqueda inicial de registros
        // Cuando se modificó una iglesia queremos mantener el modal abierto y no mostramos el loading

        let url = buscarApi(usuario, fechaDesde, fechaHasta, regionSeleccionada, regiones);
        setHuboError(false)
        setRegistros([])
        conLoading && setCargandoRegistros(true)
        try {
            const { data } = await Axios.get(`${url}`)
            setRegistros(data)
            setCargandoRegistros(false)
        } catch (err) {
            console.log(err)
            setHuboError(true)
            setCargandoRegistros(false)
        }
    }

    const filtrarLista = (filterCallback) => {

        let vectorRegistrosOriginalFiltrado;

        vectorRegistrosOriginalFiltrado = registros.filter(filterCallback)

        filtrosRef.current = filterCallback

        setRegistrosAmostrar(vectorRegistrosOriginalFiltrado)

    }

    const handleChangeSelectRegion = (e) => {
        setRegionSeleccionada(e)
    }

    /*if (usuario.sede_admin){
        return <Main center><span>Esta vista está disponible solo para el perfil de secretaría regional</span></Main>
    }*/

    if (huboError) {
        return <Main center>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <span>Se produjo un error al cargar los datos para esta vista</span>
            </div>
        </Main>
    }

    if (cargandoRegistros || !usuario) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
            </div>
        </Main>
    };

    return (
        <Card className='w-full lg:!m-6 xl:!m-6'>
            <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4 w-full">
                <Typography variant="h5" className="w-full mb-2 flex justify-center lg:hidden xl:hidden">
                    Ingresos
                </Typography>
                <div className='mb-2'>
                    <Cabecera
                        fechaDesde={fechaDesde}
                        fechaHasta={fechaHasta}
                        setFechaDesde={setFechaDesde}
                        setFechaHasta={setFechaHasta}
                        filtrarLista={filtrarLista}   // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                        hayFiltrosActivos={hayFiltrosActivos}
                        usuario={usuario}
                        regiones={regiones}
                        handleChangeSelectRegion={handleChangeSelectRegion}
                        regionSeleccionada={regionSeleccionada}
                    />

                </div>

                {esCelular && <TablaMobile registrosAmostrar={registrosAmostrar} />}

                {!esCelular && <Tabla
                    registrosAmostrar={registrosAmostrar}
                />}
            </div>
        </Card>)
}

const convertirFormato = (numero) => {
    // Reemplaza la coma por un punto
    let numeroConPunto = numero.replace(/,/g, '.');
    // Reemplaza el punto (de los decimales) por una coma
    let numeroEnNuevoFormato = numeroConPunto.replace(/\.(?=[^.]*$)/, ',');
    return numeroEnNuevoFormato;
}

function TablaMobile({ registrosAmostrar }) {
    return <Card className='mt-2'>
        <List>
            {registrosAmostrar.map((item, index) =>
                <>
                    <ListItem key={index}>
                        <div className='f-col space-y-2'>
                            <p><span className='font-bold'>Fecha: </span>{item.fecha}</p>
                            <p><span className='font-bold'>Contribuyente: </span>{item.contribuyente}</p>
                            <p><span className='font-bold'>Titular: </span>{item.titular}</p>
                            <p><span className='font-bold'>Domicilio: </span>{item.domicilio}</p>
                            <p><span className='font-bold'>Provincia: </span>{item.provincia}</p>
                            <p><span className='font-bold'>Monto: </span>{convertirFormato(item.monto)}</p>
                            <p><span className='font-bold'>Comprobante: </span>{item.comprobante}</p>
                        </div>
                    </ListItem>
                    <Divider />
                </>
            )}
        </List>
    </Card>
}

function buscarApi(usuario, fechaDesde, fechaHasta, regionSeleccionada, regiones) {
    //    return `/api/tablasgenerales/interdepositos/${usuario.id_region}`;
    //url= `/api/tablasgenerales/ingresos/${usuario.id_region}/${seleccionIngresos.mes_d}/${seleccionIngresos.mes_h}/${seleccionIngresos.anio_d}/${seleccionIngresos.anio_h}/${seleccionIngresos.dia_d}/${seleccionIngresos.dia_h}`;
    const fd = desglosarFecha(fechaDesde)
    const fh = desglosarFecha(fechaHasta)
    //    return `/api/tablasgenerales/ingresos/2/${fd.mes}/${fh.mes}/${fd.anio}/${fh.anio}/${fd.dia}/${fh.dia}`;
    //    return `/api/tablasgenerales/ingresos/${usuario.id_region}/${fd.mes}/${fh.mes}/${fd.anio}/${fh.anio}/${fd.dia}/${fh.dia}`;

    if (usuario.sede_admin) {
        if (!regionSeleccionada) {
            alert('Falta seleccionar una región')
            return
        }
        return `/api/tablasgenerales/ingresos/${regionSeleccionada}/${fd.mes}/${fh.mes}/${fd.anio}/${fh.anio}/${fd.dia}/${fh.dia}`;
    } else {
        return `/api/tablasgenerales/ingresos/${mapearRegionConRubro(usuario.id_region, regiones)}/${fd.mes}/${fh.mes}/${fd.anio}/${fh.anio}/${fd.dia}/${fh.dia}`;
    }

}

const mapearRegionConRubro = (id_region, regiones) => {
    const datosRegion = regiones.filter(item => item.region_map == id_region)
    
    if (datosRegion.length > 0) {
        if (datosRegion[0]?.id_region) {
            return datosRegion[0]?.id_region
        } else {
            alert(`No se encontró una relación entre región y rubro para el id ${id_region} - Tabla de mapeo ${JSON.stringify(regiones)}`)
            return 0
        }
    } else {
        alert(`No se encontró una relación entre región y rubro para el id ${id_region} - Tabla de mapeo ${JSON.stringify(regiones)}`)
        return 0
    }
}