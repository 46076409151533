import React, {useState, useEffect} from 'react';
import {Main,Axios,Loading,usePaginacion,Paginacion} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose, faUser, faPlusSquare, faEdit } from '@fortawesome/free-regular-svg-icons';
import {faAngleRight,faAngleLeft} from '@fortawesome/free-solid-svg-icons';
import {hacerScroll,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import { Spinner } from '@material-tailwind/react';

export default function BusquedaEntesdependientes({texto,finalizarSeleccion,objetoModificacion}){

    const anchoPaginacion = 50;

    const [alumnosInactivos,setAlumnosInactivos]=useState([]);
    const [buscandoAlumnos,setBuscandoAlumnos]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [textoBusqueda,setTextoBusqueda]=useState('');
    

    useEffect(()=>{
       
        setBuscandoAlumnos(true)

        const buscarEntes = async ()=>{

           try{
                const {data}= await Axios.get('/api/tablasgenerales/listado/entes')
        
                setAlumnosInactivos(data)
                setBuscandoAlumnos(false)
                setTimeout(() => {
                    hacerfocoEnPrimerInput("texto-busqueda")
                }, 100);
            }catch(err){
                console.log(err.response.data)
                setBuscandoAlumnos(false)
                setHuboError(true)
            }
        }
        
        buscarEntes()
    },[])



    async function handleSubmit(e,alumno) {
        e.preventDefault();
        finalizarSeleccion(alumno.id_alumno,alumno.nombre,alumno.apellido,alumno.documento)
    }

    function limpiarFiltro(){
        setTextoBusqueda("")
        hacerfocoEnPrimerInput("texto-busqueda")
    }

    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        //e.preventDefault()
        setTextoBusqueda(e.target.value)
    }

    function seleccionarAlumno(e,item){
        finalizarSeleccion(item,objetoModificacion)
    }

    if (huboError){
        return <Main center>
                        <div className='flex flex-col justify-center items-center h-[calc(100vh-5rem)]'>
                            <span>Se produjo un error al cargar los datos para esta vista</span>
                        </div>
                </Main>
    }

    if (buscandoAlumnos){
        return <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-5rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Buscando registros...</span>
                    </div>
                </Main>
    };

    return(
        <>  
            <Listado alumnos={alumnosInactivos} 
                textoBusqueda={texto} 
                seleccionarAlumno={seleccionarAlumno}
            />
        </>

       

    )
}

function Listado({alumnos,textoBusqueda,seleccionarAlumno}){
    const {configuracion,paginar,inicializar:inicializarPaginacion} = usePaginacion()

    const [alumnosEncontrados,setAlumnosEncontrados]=useState([])

    useEffect(()=>{
        const vector_aux = alumnos.filter(
            item=>item.nombre.toUpperCase().includes(textoBusqueda.toUpperCase()))
    
            setAlumnosEncontrados(vector_aux)
    },[textoBusqueda])
  

    useEffect(()=>{
   
        inicializarPaginacion(null,alumnosEncontrados)

    },[alumnosEncontrados])

    return (
    <div className="">
       {/* <span className="color-63 text-small inline-block absolute right-35">{alumnosEncontrados.length} ministros encontrados</span> */}
        <div className="flex f-col">
            <Paginacion configuracion={configuracion} paginar={paginar}/>
        </div>
        {alumnosEncontrados
           .map((item,index)=>{return {...item,indice:index+1}})
           .filter((item,index)=>{
               return index>= configuracion.iIni && index<=configuracion.iFin
           })
           .map(item=>
            <div onClick={(e)=>{seleccionarAlumno(e,item)}} className="listado-al color-63" key={`alin-${item.id > 0 ? item.id * -1 : item.id}`}>
                {/* la funcion seleccionarAlumno hay que encerrarla para que no se ejecute sola  */}
                <FontAwesomeIcon className="mr-2" icon={faUser}/>
                <span>{item.nombre}</span>
            </div>
            )
        }
    </div>
    )
}

