import React, { useState, useEffect } from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import { Grid } from "@material-ui/core";
import { Spinner, Card, Typography, Input, IconButton, Tooltip, Dialog, DialogHeader, DialogBody, DialogFooter, Button, Select, Option, Tabs, TabsHeader, TabsBody, Tab, TabPanel, Textarea } from '@material-tailwind/react';
import { MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import ExportToExcelGeneric from '../componentes/exportExcelGeneric';
import { PiePagina, Paginacion, usePaginacion } from '../Helpers/importacionesComunes'
import Provincias from './Provincias';

const IPartidoModel = {
    nombre: '',
    id_provincia: 0,
    id_distrito: 0,
    id_region: 0
}


export default function Partidos({ usuario }) {

    const [partidosList, setPartidosList] = useState([])
    const [provinciasList, setProvinciasList] = useState([])
    const [regionesList, setRegionesList] = useState([])
    const [distritosList, setDistritosList] = useState([]);
    const [nameSearch, setNameSearch] = useState('')
    const [partidoId, setPartidoId,] = useState(0)
    const [buscandoDatos, setBuscandoDatos] = useState(false)
    const [buscandoDistritos, setBuscandoDistritos] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [partidoData, setPartidoData] = useState(IPartidoModel);
    const { configuracion, paginar, inicializar: inicializarPaginacion } = usePaginacion()

    useEffect(() => {
        obtenerArchivos();
        obtenerProvincias();
        obtenerRegiones();
    }, [])

    useEffect(() => {
        setPartidosList([...partidosList])
        inicializarPaginacion(null, partidosList)
    }, [partidosList])


    useEffect(() => {
        if (nameSearch.trim() === '') {
            setPartidosList(partidosList);
        } else {
            const listaFiltrada = partidosList.filter(item =>
                item.nombre.toLowerCase().includes(nameSearch.toLowerCase())
            );
            setPartidosList(listaFiltrada.length > 0 ? listaFiltrada : partidosList);
        }
    }, [nameSearch, partidosList]);


    const obtenerArchivos = async () => {
        setBuscandoDatos(true);
        try {
            const resultList = await Promise.all([Axios.get(`/api/tablasgenerales/partidos`)])
            setPartidosList(resultList[0].data.partidos ?? [])
            setBuscandoDatos(false);
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const obtenerProvincias = async () => {
        setBuscandoDatos(true);
        try {
            const resultList = await Promise.all([Axios.get(`/api/tablasgenerales/provincias`)])
            setProvinciasList(resultList[0].data ?? [])
        } catch (error) {
            console.log(error)
        }
    }

    const obtenerRegiones = async () => {
        setBuscandoDatos(true);
        try {
            const resultList = await Promise.all([Axios.get(`/api/tablasgenerales/regiones`)])
            setRegionesList(resultList[0].data ?? [])
        } catch (error) {
            console.log(error)
        }
    }

    const fetchDistritos = async (id_region) => {
        try {
            setBuscandoDistritos(true)
            const resultList = await Axios.get(`/api/tablasgenerales/distritos/${id_region}`);
            setDistritosList(resultList.data ?? []);
            setBuscandoDistritos(false)
        } catch (error) {
            console.log(error);
            setBuscandoDistritos(false)
            return [];
        }
    };

    useEffect(() => {
        const id_region = partidoData.id_region;
        if (id_region) fetchDistritos(id_region);
    }, [partidoData.id_region]);

    const handleOpen = () => { 
        setOpen(!open);
    }

    const verPartido = async (e) => {
        try {
            const partidoIdResponse = await Promise.all([Axios.get(`/api/tablasgenerales/partido/${e.id_partido}`)])
            const partidoIdSeleccionada = partidoIdResponse[0].data.recordsets[0][0];
            setPartidoId(partidoIdSeleccionada.id_partido);

            let data = {
                nombre: partidoIdSeleccionada.nombre,
                id_provincia: partidoIdSeleccionada.id_provincia,
                id_distrito: partidoIdSeleccionada.id_distrito,
                id_region: partidoIdSeleccionada.id_region
            }
            setPartidoData(data)

            handleOpen();
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const verCrearPartido = async (e) => {
        try {
            setPartidoId(0);
            handleOpen();
            setPartidoData(IPartidoModel)
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const crearPartido = async () => {
        try {
            await Promise.all([Axios.post(`/api/tablasgenerales/partido/`, partidoData)])
            Swal.fire({
                icon: 'alert',
                title: `<p>La Provincia ha sido creada con éxito</p>`,
            })
            handleOpen();
            obtenerArchivos();
        } catch (error) {
            Swal.fire({
                icon: 'alert',
                title: `<p>Error al actualizar los Datos</p>`,
                showCancelButton: true,
            })
            console.log(error)
            setOpen(true);
        }
    }

    const editarPartido = async () => {
        try {
            await Promise.all([Axios.put(`/api/tablasgenerales/partido/${partidoId}`, partidoData)])
            Swal.fire({
                icon: 'alert',
                title: `<p>Los datos han sido actualizados con éxito</p>`,
            })
            handleOpen();
            obtenerArchivos();
        } catch (error) {
            Swal.fire({
                icon: 'alert',
                title: `<p>Error al actualizar los Datos</p>`,
                showCancelButton: true,
            })
            console.log(error)
            setOpen(true);
        }
    }

    const borrarPartido = async (e) => {
        Swal.fire({
            title: "Esta seguro que desea Eliminarlo?",
            text: "Este cambio es irreversible!",
            type: "warning",
            confirmButtonText: 'Si, eliminar!',
            cancelButtonText: "No, cancelar!",
            showCancelButton: true,
            // allowOutsideClick: false
        }).then(
            async resultado => {
                if (resultado.value) {
                    try {
                        await Promise.all([Axios.delete(`/api/tablasgenerales/partido/${e.id_partido}`)])
                        obtenerArchivos();
                    } catch (error) {
                        console.log(error)
                        setBuscandoDatos(false);
                    }
                }
            }
        )
    }

    const limpiarBusqueda = async () => {
        obtenerArchivos();
        setNameSearch('')
    }

    const prepareExcelData = () => {
        return partidosList.map(item => ({ Nombre: item.nombre, Provincias: item.provincias, Distrito: item.distrito, Region: item.region }))
    }

    if (buscandoDatos) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando archivos...</span>
            </div>
        </Main>
    }

    if (partidosList.length == 0) {
        return <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '400px', textAlign: 'center' }} >
            <Grid item>
                <Typography variant="h5">
                    No se encontraron archivos
                </Typography>
            </Grid>
        </Grid>
    }

    return <div className='w-full flex flex-col justify-center items-center lg:!m-6 xl:!m-6'>
        <Typography variant="h4" className="flex ">
            Partidos
        </Typography>
        <Card className='w-full justify-center items-center flex p-5 justify-left mt-4'>
            <div className='w-full lg:w-96 xl:w-96'>
                <Input
                    type="text"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    label="Buscar nombre de Partido"
                    value={nameSearch}
                    onChange={(e) => { setNameSearch(e.target.value) }}
                    color='black'
                />
            </div>
            {partidosList.length > 0 && <div className='mt-8 w-full'>
                <div className="flex xl:!justify-end">
                    <Button className="flex ml-2 items-center gap-3 bg-green-500" onClick={verCrearPartido}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-4 w-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                            />
                        </svg>
                        Crear
                    </Button>
                {nameSearch && <Button variant='text' className='text-red-400 bg-red-100 hover:bg-red-200' onClick={limpiarBusqueda}>Limpiar Busqueda</Button>}
                    <ExportToExcelGeneric data={prepareExcelData()} fileName="Listado de Partidos" isProvider={true}/>
                </div>
                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none"
                                >
                                    Se han encontrado {partidosList.length} Partidos
                                </Typography>
                {partidosList.length > 0 &&
                    <PiePagina>
                        <div className="">
                            <Paginacion configuracion={configuracion} paginar={paginar} />
                        </div>
                    </PiePagina>}
                <table className="text-left w-full mt-2">
                    <thead>
                        <tr>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Nombre
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Provincia
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Región
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Distrito
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Acciones
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {partidosList.filter((item, index) => {
                                return index >= configuracion.iIni && index <= configuracion.iFin
                            }).map((item, index) => {
                            const isLast = index === partidosList.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                            return (
                                <tr key={index}>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.provincia}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.region}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.distrito}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <div className="flex items-center gap-2">
                                            <Tooltip content="Ver Detalles">
                                                <IconButton variant="text" size="sm" onClick={() => verPartido(item)}>
                                                    <PencilIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip content="Eliminar">
                                                <IconButton variant="text" size="sm" onClick={() => borrarPartido(item)}>
                                                    <TrashIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {partidosList.length > 0 &&
                    <PiePagina>
                        <div className="">
                            <Paginacion configuracion={configuracion} paginar={paginar} />
                        </div>
                    </PiePagina>}
            </div>}
        </Card>
        <Dialog open={open} handler={handleOpen}>
            <DialogHeader>{partidoId !== 0 ? 'Editar' : 'Crear'} Partido</DialogHeader>
            <DialogBody className="xl:p-10">

                <Tabs value="Contacto" className="max-w-full mt-6">
                    <TabsBody>
                        <div className="flex flex-col w-full mt-4">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Nombre
                            </Typography>
                            <Input
                                onChange={(e) => {
                                    setPartidoData({...partidoData, nombre: e.target.value})
                                }}
                                value={partidoData.nombre}
                                type='text'
                                id='name'
                                name='name'
                                color='black'
                                className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                        </div>

                        <div className="flex flex-col w-full mt-4 select-ui-data">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Provincias
                            </Typography>
                            <Select
                                label="Seleccione una Provincia"
                                value={partidoData.id_provincia}
                                onChange={(e) => setPartidoData({...partidoData, id_provincia: e})}
                                className=""
                            >
                                {provinciasList.map((item, index) => (
                                    <Option key={item.id_provincia} value={item.id_provincia}>
                                        {item.nombre}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        <div className="flex flex-col w-full mt-4 select-ui-data">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Regiones
                            </Typography>
                            <Select
                                label="Seleccione una Región"
                                value={partidoData.id_region}
                                onChange={(e) => setPartidoData({...partidoData, id_region: e})}
                                className=""
                            >
                                {regionesList.map((item, index) => (
                                    <Option key={item.id_region} value={item.id_region}>
                                        {item.nombre}
                                    </Option>
                                ))}
                            </Select>
                        </div>

                        <div className="flex flex-col w-full mt-4 select-ui-data">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Distritos
                            </Typography>
                            {!buscandoDistritos && (
                                <Select
                                    label="Seleccione un Distrito"
                                    value={partidoData.id_distrito}
                                    onChange={(e) => setPartidoData({...partidoData, id_distrito: e})}
                                    className=""
                                >
                                    {distritosList.map((item, index) => (
                                        <Option key={item.id_distrito} value={item.id_distrito}>
                                            {item.nombre}
                                        </Option>
                                    ))}
                                </Select>
                            )}

                        </div>
                    </TabsBody>
                </Tabs>
            </DialogBody>
            <DialogFooter className="space-x-2">
                <Button 
                    variant="gradient" 
                    color="blue" 
                    onClick={() => partidoId !== 0 ? editarPartido() : crearPartido()}
                >
                    Guardar
                </Button>
                <Button variant="text" color="blue-gray" onClick={handleOpen}>
                    Cancelar
                </Button>
            </DialogFooter>
        </Dialog>
    </div>
}

