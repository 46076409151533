import React, { useState, useEffect, useRef } from 'react';
import {IconButton} from "@material-ui/core";
import Alert from '@mui/material/Alert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const RowCopia = ({titulo,link})=>{
    const [copiado,setCopiado]=React.useState(false);

    const copiarLink = async ()=>{
        try{
            const copy = await navigator.clipboard.writeText(link);
            setCopiado(true);
            setTimeout(() => {
                    setCopiado(false)
            }, 600);
        }catch(err){
            alert(err)
        }
    }

    return  <tr>
    <td colSpan="2">
        <Alert severity="info" variant='outlined'>
                <b>{titulo}</b>
                <div style={{display:'flex',alignItems:'center',maxWidth:'300px'}}>
                        <p>{link}</p>
                    </div>
        </Alert>
    </td>
    <td>
        <IconButton title='Copiar el link' onClick={copiarLink}  aria-label="upload picture" component="label">
            <ContentCopyIcon />
        </IconButton>
         {copiado && <p className='color-red text-small'>Link copiado</p>} 
    </td>
</tr>
}


 

