import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSortAmountDown,faSortAmountUp} from '@fortawesome/free-solid-svg-icons';
import { Input, Typography } from '@material-tailwind/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

export default function LogAsambleaRegistros({registros,todos,esCelular}){

    const [texto,setTexto] = useState('')
    const [ascendente,setAscendente]=useState(true)
    const [tipo,setTipo]=useState('todos')

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            const copy = event.target.value;
            if(copy.includes('ministros.uad.digital')){
                setTexto(copy.substring(copy.length-4,copy.length))
            }
        }
    }

    const callbackFiltro = item=>((item.nombre.toUpperCase().includes(texto.toUpperCase())
                    || item.iglesia.toUpperCase().includes(texto.toUpperCase())
                    || item.pastor.toUpperCase().includes(texto.toUpperCase())
                    || item.id_asamblea_presente == texto
                    || item.id_obrero == texto
                    || item.dni.toString().toUpperCase().includes(texto.toUpperCase()))
                    && (item.tipo==tipo || tipo=='todos'))
    

    if(todos){
        return <div>
                    <div className="relative mt-4">
                        <Typography variant="h4" className="flex justify-center items-center">
                            Logs de registros
                        </Typography>  
                    </div>
            
                    <div className="flex f-col mt-4" >
                            <div className='w-full lg:w-96 xl:w-96'>
                                <Input  
                                    type="text" 
                                    icon={<MagnifyingGlassIcon className="h-5 w-5" />} 
                                    label="Buscar por Nombre, DNI, Id o QR" 
                                    autoFocus={!esCelular} 
                                    value={texto} 
                                    onKeyPress={handleKeyPress} 
                                    onChange={(e)=>setTexto(e.target.value)}
                                    color='black'
                                />
                            </div>
                        <p className='cursor-pointer p-2 fw-700' title='Cambiar orden ascendente o descendente por hora' onClick={()=>setAscendente(!ascendente)}>Cambiar orden <FontAwesomeIcon icon={ascendente ? faSortAmountDown : faSortAmountUp}/></p>
                        <div className="flex mt-2 items-center m-2 text-small" style={{marginLeft:'-15px'}}>
                                <input className="ml-2" checked={tipo=='todos'} type="radio" name='tipo' value='todos' onChange={(e)=>setTipo(e.target.value)}/>
                                <label onClick={()=>setTipo('todos')} for="cri-td">Todos</label>
                                <input checked={tipo=='Ingreso'} type="radio" name='tipo' value='Ingreso' onChange={(e)=>setTipo(e.target.value)}/>
                                <label onClick={()=>setTipo('Ingreso')} for="cri-td">Ingreso</label>
                                <input checked={tipo=='Retiro'} type="radio" name='tipo' value='Retiro' onChange={(e)=>setTipo(e.target.value)}/>
                                <label onClick={()=>setTipo('Retiro')} for="cri-td">Retiro</label>
                                <input checked={tipo=='Reingreso'} type="radio" name='tipo' value='Reingreso' onChange={(e)=>setTipo(e.target.value)}/>
                                <label onClick={()=>setTipo('Reingreso')} for="cri-td">Reingreso</label>
                        </div>
                    </div>
                    <table className='w-full'>
                        <tbody>
                        {registros.filter(callbackFiltro).sort((a,b)=>ascendente ?  a.id_log - b.id_log : b.id_log - a.id_log).map(item=><>
                            <tr key={`lid-${item.id_log}`} className='even:bg-blue-gray-50/50'>
                                <td>
                                    <p title={item.fecha} className="text-large p-2">{item.fecha.slice(10)}</p>
                                </td>
                                <td>
                                    <p className={`text-lg`}>{item.tipo}{item.id_iglesia? ' delegado ' : ' ministro '}</p>
                                    <p>{item.nombre}  DNI: ({item.dni})</p>
                                    {item.id_iglesia && <p>{item.iglesia} {item.pastor}</p>}
                                    <p>{item.usuario}</p>
                                </td>
                            </tr>
                    
                        </>)}
                    </tbody>
                    </table>
            </div>
    }else{
    return <div>
             <div className="flex f-col">
                  <input type="text" placeholder='Buscar por Nombre, DNI, Id o QR' className='m-2' autoFocus value={texto} onKeyPress={handleKeyPress} onChange={(e)=>setTexto(e.target.value)} />            
            </div>
    <table className='w-full'>
        <tbody>
            {registros.map(item=><tr>
                <td>{item.fecha}</td>
                <td>{item.usuario}</td>
                <td>{item.tipo}</td>
            </tr>)}
        </tbody>
    </table></div>}
}
