import React, { useState, useEffect } from 'react';
import { 
    Spinner, 
    Card, 
    Typography, 
    Dialog, 
    DialogHeader, 
    DialogBody, 
    DialogFooter, 
    Button, 
    List, 
    ListItem,
    Input,
    Checkbox
} from '@material-tailwind/react';
import { Main, Axios } from '../../../Helpers/importacionesComunes'
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Tabla } from '../componentes/Tabla';
import { Cabecera } from '../componentes/Cabecera';
import { Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

export default function DestinoIngresos({ match, history }) {
    const [registros, setRegistros] = useState([])
    const [backupRegistros, setBackupRegistros] = useState([])
    const [rubros, setRubros] = useState([])
    const [fondosTesoreria, setFondosTesoreria] = useState([]);
    const [cargandoRegistros, setCargandoRegistros] = useState(true);
    const { esCelular, usuario } = useContextoGlobal();
    const [open, setOpen] = useState(false)
    const [openCreate, setOpenCreate] = useState(false)
    const [huboError, setHuboError] = useState(false)
    const [filtros, setFiltros] = useState({ nombre: '', fondo_tesoreria_nombre: '', rubro_ingreso: '' });
    const [activeIcome, setActiveIncome] = useState(null)
    const [dataValues, setDataValues] = useState({
        id_rubro: null,
        id_fondo_tesoreria: null,
        porcentaje: null,
        nombre: null
    });

    useEffect(() => {
        buscarDatos();
        buscarRubros();
        buscarFondosTesoreria();
    }, [])

    const buscarDatos = async (params = {}) => {
        const { conLoading } = params // con loading se usa para la búsqueda inicial de registros
        // Cuando se modificó una iglesia queremos mantener el modal abierto y no mostramos el loading

        setHuboError(false)
        setRegistros([])
        conLoading && setCargandoRegistros(true)
        try {
            const { data } = await Axios.get(`/api/tablasgenerales/destino-ingresos/lista`)
            console.log("icome", data);
            setRegistros(data)
            setBackupRegistros(data)
            setCargandoRegistros(false)
        } catch (err) {
            console.log(err)
            setHuboError(true)
            setCargandoRegistros(false)
        }
    }
    
    useEffect(() => {
        const filtrarRegistros = () => {
            const { fondo_tesoreria_nombre, nombre, rubro_ingreso } = filtros;
        
            const registrosFiltrados = backupRegistros.filter(registro => {
                const cumpleFondo = fondo_tesoreria_nombre ? registro.fondo_tesoreria_nombre.toLowerCase().includes(fondo_tesoreria_nombre) : true;
                const cumpleNombre = nombre ? registro.nombre.toLowerCase().includes(nombre.toLowerCase()) : true;
                const cumpleRubro = rubro_ingreso ? registro.rubro_ingreso.toLowerCase().includes(rubro_ingreso.toLowerCase()) : true;
        
                return cumpleFondo && cumpleNombre && cumpleRubro;
            });
        
            setRegistros(registrosFiltrados);
        };

        filtrarRegistros();
    }, [filtros])

    const buscarRubros = async () => {
        try {
            const resultado = await Axios.post('/api/tablasgenerales/rubro-egresos/lista')
            setRubros(resultado.data)
        } catch (err) {
            alert(err)
        }
    }

    const buscarFondosTesoreria = async () => {
        try {
            const resultado = await Axios.get('/api/tablasgenerales/fondos-tesoreria/lista')
            setFondosTesoreria(resultado.data)
        } catch (err) {
            alert(err)
        }
    }

    const handleOpen = () => { 
        setOpen(!open);
    }

    const handleCreateOpenDialog = () => { 
        setOpenCreate(true);
    }

    const handleDataChanges = (value, type) => {
        setDataValues({ ...dataValues, [type]: value });
    }

    const submitIncome = async () => {
        const { nombre, porcentaje, id_fondo_tesoreria, id_rubro } = dataValues;
        if (!nombre || !porcentaje || !id_fondo_tesoreria || !id_rubro) { 
            Swal.fire({
                icon: 'error',
                title: `<p>Por favor, complete todos los campos</p>`,
                showCancelButton: true,
            });
            setOpen(true);
            return;
        }

        const formValues = {
            ...dataValues,
            porcentaje: dataValues.porcentaje ? Number(dataValues.porcentaje) : null,
        }

        try {
            await Axios.post(`api/tablasgenerales/destino-ingresos/crear`, formValues);
            Swal.fire({
                icon: 'success',
                title: `<p>El ingreso ha sido creado con éxito</p>`,
            });
            closeDialogs();
            buscarDatos();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `<p>Error al crear un ingreso</p>`,
                showCancelButton: true,
            });
            console.log(error);
        }
    };

    const deleteIncome = async () => { 
        if (activeIcome) { 
            Swal.fire({
                icon: 'error',
                title: `<p>Por favor, seleccione un destino de fondo</p>`,
                showCancelButton: true,
            });
            setOpen(true);
            return;
        }

        try {
            await Axios.post(`api/tablasgenerales/fondos-tesoreria/delete`, {  id_destino_ingreso: activeIcome });
            Swal.fire({
                icon: 'success',
                title: `<p>El ingreso ha sido eliminado con éxito</p>`,
            });
            closeDialogs();
            buscarDatos();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `<p>Error al eliminar un ingreso</p>`,
                showCancelButton: true,
            });
            console.log(error);
        }
    }

    const closeDialogs = () => { 
        setOpen(false);
        setOpenCreate(false);
    }

    if (huboError) {
        return <Main center>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <span>Se produjo un error al cargar los datos para esta vista</span>
            </div>
        </Main>
    }

    if (cargandoRegistros || !usuario) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
            </div>
        </Main>
    };

    return (
        <Card className='w-full lg:!m-6 xl:!m-6'>
            <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4 w-full">
                <Typography variant="h5" className="w-full mb-2 flex justify-center lg:hidden xl:hidden">
                    Fondos de Tesorería
                </Typography>
                <div className='mb-2'>
                    <Cabecera
                        setFiltros={setFiltros}
                        filtros={filtros}
                    />
                </div>


                {esCelular && <TablaMobile registrosAmostrar={registros} setOpenCreate={setOpenCreate} setActiveIncome={setActiveIncome} handleOpen={handleOpen} />}

                {!esCelular && <Tabla
                    registrosAmostrar={registros}
                    setOpenCreate={setOpenCreate}
                    setActiveIncome={setActiveIncome}
                    handleOpen={handleOpen}
                />}
            </div>

            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Eliminar Registro</DialogHeader>
                <DialogBody className="xl:p-10">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        ¿Está seguro de realizar esta operación?
                    </Typography>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button 
                        variant="gradient" 
                        color="blue" 
                        onClick={deleteIncome}
                    >
                        Aceptar
                    </Button>
                    <Button variant="text" color="blue-gray" onClick={closeDialogs}>
                        Cancelar
                    </Button>
                </DialogFooter>
            </Dialog>

            <Dialog open={openCreate} handler={handleCreateOpenDialog}>
                <DialogHeader>Cargar destino de ingreso</DialogHeader>
                <DialogBody className="xl:p-10">
                    <Parametros 
                        parametros={dataValues}
                        setParametros={setDataValues}
                        rubros={rubros}
                        fondosTesoreria={fondosTesoreria}
                    />
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button 
                        variant="gradient" 
                        color="blue" 
                        onClick={submitIncome}
                    >
                        Aceptar
                    </Button>
                    <Button variant="text" color="blue-gray" onClick={closeDialogs}>
                        Cancelar
                    </Button>
                </DialogFooter>
            </Dialog>
        </Card>)
}

function TablaMobile({ registrosAmostrar }) {

    return <Card className='mt-2'>
        <List>
            {registrosAmostrar.map((item, index) =>
                <>
                    <ListItem key={index}>
                        <div className='f-col space-y-2'>
                            <p><span className='font-bold'>Nombre: </span>{item.nombre}</p>
                        </div>
                    </ListItem>
                    <Divider />
                </>
            )}
        </List>
    </Card>
}

function Parametros({ 
    parametros, 
    setParametros,
    rubros,
    fondosTesoreria
}) {

    const { esCelular } = useContextoGlobal();

    const handleParamsChange = (type, value) => {
        setParametros({ ...parametros, [type]: value })
    }

    return <div className="w-full mb-2">
        <div className="mt-[-30px] flex f-col">
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Nombre
                </Typography>
                <div className="col-span-2 mt-1">
                    <Input
                        onChange={(e) => handleParamsChange('nombre', e.target.value)} 
                        name="nombre" 
                        color='black'
                        placeholder="Nombre" 
                        maxLength={200}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
            </div>
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Porcentaje
                </Typography>
                <div className="col-span-2 mt-1">
                    <Input
                        onChange={(e) => handleParamsChange('porcentaje', e.target.value)} 
                        name="nombre" 
                        color='black'
                        placeholder="Porcentaje" 
                        type='number'
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
            </div>
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Rubros
                </Typography>
                <div className="col-span-2 mt-1">
                    <Seleccionador 
                        id='select-rubros' 
                        nombre="rubros" 
                        vector={rubros} 
                        valor={parametros.id_rubro} 
                        onchange={(e) => setParametros({ ...parametros, id_rubro: e.target.value })} 
                        claves={{ id: 'id_rubro', nombre: 'nombre' }} 
                    />
                </div>
            </div>
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Fondos Tesorería
                </Typography>
                <div className="col-span-2 mt-1">
                    <Seleccionador 
                        id='select-fondos' 
                        nombre="fondos" 
                        vector={fondosTesoreria} 
                        valor={parametros.id_fondo_tesoreria} 
                        onchange={(e) => setParametros({ ...parametros, id_fondo_tesoreria: e.target.value })} 
                        claves={{ id: 'id_fondo_tesoreria', nombre: 'nombre' }} 
                    />
                </div>
            </div>

        </div>
    </div>
}

function Seleccionador({ vector, onchange, valor, nombre, noDefault, name, classancho, limpiar, claves, id }) {
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";

    if (classancho) {
        clasesSelect = `block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }
    return (
        <div className="flex flex-col sel select-ui-data w-full">
            <select value={valor} name={name ? name : ''} className='h-9' onChange={onchange} id={id ? id : null}>
                <option value={null}>Seleccionar</option>
                {vector.map(item => <option value={item[claves.id]} key={item[claves.id]}>{item[claves.nombre]}</option>)}
            </select>
            {valor != "-1" && limpiar &&
                <button>
                    <FontAwesomeIcon className="ic-abm"
                        icon={faWindowClose}
                        onClick={limpiar} />
                </button>}
        </div>
    )
}