import React from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

export const Cerrar = ({cerrar})=>{
return <button onClick={cerrar}
            data-dismiss="modal" 
            aria-label="Close" 
            title="Cerrar" 
            className="absolute botonAbm right-4 top-2">
        <XCircleIcon className="h-6 w-6"/>
    </button>
}