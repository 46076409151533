import React, { useState, useEffect, useRef } from 'react';
import { Main, Loading, Axios, Modal, usePaginacion, useModal, Paginacion } from '../../../Helpers/importacionesComunes'
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Tabla } from '../componentes/Tabla';
import { Cabecera } from '../componentes/Cabecera';
import { obtenerFechaDiamenosN } from '../../../Helpers/fechas'
import { Card, List, ListItem, Option, Select, Spinner, Typography } from '@material-tailwind/react';
import { Divider } from '@mui/material';

export default function Interdepositos({ match, history }) {
    const [registros, setRegistros] = useState([])
    const [registrosAmostrar, setRegistrosAmostrar] = useState([])
    const [cargandoRegistros, setCargandoRegistros] = useState(true);
    const { esCelular, usuario } = useContextoGlobal();
    const [hayFiltrosActivos, setHayFiltrosActivos] = useState(false)
    const [huboError, setHuboError] = useState(false)
    const filtrosRef = useRef()
    const [dataValues, setDataValues] = useState({
        id_cuenta_bancaria: 1,
        id_region: null,
        estado: null,
        fecha_desde: obtenerFechaDiamenosN(365).date,
        fecha_hasta: new Date(),
    });

    const [regiones, setRegiones] = useState([]);
    const [cuentas, setCuentas] = useState([]);

    useEffect(() => {
        const buscarRegiones = async () => {
            try {
                setCargandoRegistros(true)
                const resultado = await Axios.get('/api/tablasgenerales/regiones/*')
                setRegiones(resultado.data.filter(item => item.id_region > 0))
                setCargandoRegistros(false)
            } catch (err) {
                alert(err)
                setCargandoRegistros(false)
            }
        }
        if (usuario.sede_admin) {
            buscarRegiones()
        }
        buscarCuentasBancarias();
    }, [])

    useEffect(() => {
        buscarDatos({ conLoading: true })
    }, [usuario, dataValues]) // agregué usuario porque necesito que se dispare el evento cuando no sea vacío usuario, al principio es vacío por unos milisegundos...

    useEffect(() => {

        if (registrosAmostrar.length != registros.length) {
            setHayFiltrosActivos(true)
        } else {
            setHayFiltrosActivos(false)
        }

    }, [registrosAmostrar])

    const buscarCuentasBancarias = async () => {
        try {
            const resultado = await Axios.get('/api/tablasgenerales/banco/cuentas')
            setCuentas(resultado.data)
        } catch (err) {
            alert(err)
        }
    }

    const handleChangeSelectRegion = (e) => {
        handleDataChanges(e, 'id_region');
    }

    const handleDataChanges = (value, type) => {
        setDataValues({ ...dataValues, [type]: value });
    }

    const buscarDatos = async (params = {}) => {
        const { conLoading } = params // con loading se usa para la búsqueda inicial de registros
        // Cuando se modificó una iglesia queremos mantener el modal abierto y no mostramos el loading

        setHuboError(false)
        setRegistros([])
        conLoading && setCargandoRegistros(true)
        try {
            const params ={
                ...dataValues,
                id_region: usuario.sede_admin ? dataValues.id_region : usuario.id_region
            }
            const { data } = await Axios.post(`/api/tablasgenerales/interdepositos/lista`, params)
            setRegistros(data)
            setCargandoRegistros(false)
        } catch (err) {
            console.log(err)
            setHuboError(true)
            setCargandoRegistros(false)
        }
    }

    const filtrarLista = (filterCallback) => {

        let vectorRegistrosOriginalFiltrado;

        vectorRegistrosOriginalFiltrado = registros.filter(filterCallback)

        filtrosRef.current = filterCallback

        setRegistrosAmostrar(vectorRegistrosOriginalFiltrado)

    }

    /*if (usuario.sede_admin){
        return <Main center><span>Esta vista está disponible solo para el perfil de secretaría regional</span></Main>
    }*/


    /*
    if(usuario.sede_admin && !regionSeleccionada){
        return <SolicitarRegion usuario={usuario} 
                regionSeleccionada={regionSeleccionada} 
                setRegionSeleccionada={setRegionSeleccionada}/>
    }*/

    if (huboError) {
        return <Main center>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <span>Se produjo un error al cargar los datos para esta vista</span>
            </div>
        </Main>
    }

    if (cargandoRegistros || !usuario) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
            </div>
        </Main>
    };

    return (
        <Card className='w-full lg:!m-6 xl:!m-6'>
            <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4 w-full">
                <Typography variant="h5" className="w-full mb-2 flex justify-center lg:hidden xl:hidden">
                    Depósitos sin identificar
                </Typography>
                <div className='mb-2'>
                    <Cabecera
                        filtrarLista={filtrarLista}   // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                        hayFiltrosActivos={hayFiltrosActivos}
                        usuario={usuario}
                        regiones={regiones}
                        cuentas={cuentas}
                        handleDataChanges={handleDataChanges}
                        handleChangeSelectRegion={handleChangeSelectRegion}
                        dataValues={dataValues}
                    />
                </div>


                {esCelular && <TablaMobile registrosAmostrar={registros} />}

                {!esCelular && <Tabla
                    registrosAmostrar={registros}
                />}
            </div>
        </Card>)
}

const convertirFormato = (numero) => {
    // Reemplaza la coma por un punto
    let numeroConPunto = numero.replace(/,/g, '.');
    // Reemplaza el punto (de los decimales) por una coma
    let numeroEnNuevoFormato = numeroConPunto.replace(/\.(?=[^.]*$)/, ',');
    return numeroEnNuevoFormato;
}

const formatearImporte = (numero) => {
    return numero.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

function TablaMobile({ registrosAmostrar }) {

    return <Card className='mt-2'>
        <List>
            {registrosAmostrar.map((item, index) =>
                <>
                    <ListItem key={index}>
                        <div className='f-col space-y-2'>
                            <p><span className='font-bold'>Fecha: </span>{item.fecha}</p>
                            <p><span className='font-bold'>Banco: </span>{item.desc_sucursal}</p>
                            <p><span className='font-bold'>Concepto: </span>{item.concepto}</p>
                            <p><span className='font-bold'>Importe: </span>{formatearImporte(item.importe_pesos || 0)}</p>
                        </div>
                    </ListItem>
                    <Divider />
                </>
            )}
        </List>
    </Card>
}

function buscarApi(usuario, regionSeleccionada) {
    if (usuario.sede_admin) {
        if (!regionSeleccionada) {
            alert('Falta seleccionar una región')
            return
        }
        return `/api/tablasgenerales/interdepositos/${regionSeleccionada}`;
    } else {
        return `/api/tablasgenerales/interdepositos/${usuario.id_region}`;
    }
}