import React, { useEffect, useState, useRef } from 'react';
import { usePaginacion, Paginacion, FontAwesomeIcon, PiePagina, Ventana } from '../../../Helpers/importacionesComunes'
import { faCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faIdCard, faAt, faEdit, faSort } from '@fortawesome/free-solid-svg-icons';
import { IglesiasMinistro } from './IglesiasMinistro'
import AbmObrero from '../../../abms/Abm-obrero'
import { useContextoGlobal } from '../../../Context/contextoGlobal'
import { EstadisticasMail } from '../componentes/EstadisticasMail'
import { ListadoCelular } from '../componentes/ListadoCelular'
import { CardBody, Card, Typography } from '@material-tailwind/react';
import { ChevronUpDownIcon, ClipboardDocumentListIcon, PencilSquareIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import ExportToExcelGeneric from '../../../componentes/exportExcelGeneric';
import { HeartIcon } from '@heroicons/react/24/outline'


export const Tabla = ({ registrosAmostrar,
    reinicializar,
    resetearLista,
    obreroSeleccionado,
    setObreroSeleccionado,
    setIsObreroSeleccionado
}) => {

    const { configuracion, paginar, inicializar: inicializarPaginacion } = usePaginacion()
    // Hago una copia de los datos que se reciben para poder modificar el listado a conveniencia
    // y mostrar el listado en base a registrosAmostrar por ejemplo para mostrar los datos ordenados por diferentes criterios
    const [copiaRegistrosaMostrar, setCopiaRegistrosaMostrar] = useState([...registrosAmostrar])
    const [orden, setOrden] = useState(null)
    const [nuevoCampo, setNuevoCampo] = useState(true)
    const [contadorOrden, setContadorOrden] = useState(0)
    const [obreroSeleccionadoCredencial, setObreroSeleccionadoCredencial] = useState(null);
    const [obreroSeleccionadoEstadisticasMail, setObreroSeleccionadoEstadisticasMail] = useState(null)
    const { usuario, esCelular } = useContextoGlobal()

    useEffect(() => {
        const data_mas_selector = registrosAmostrar.map((item) => { return { ...item, seleccion: false } })
        setCopiaRegistrosaMostrar([...data_mas_selector])
        inicializarPaginacion(null, data_mas_selector)
    }, [registrosAmostrar])

    useEffect(() => { // el ordenamiento se maneja con las propiedades orden que almacena el campo por el cual ordenar
        // y nuevoCampo que es un flag que indica si es otro campo o el mismo a la inversa
        // cada vez que se cambia el orden en click de la cabecera se cambia contadorOrden como un trigger 
        ordenarDatos()
    }, [contadorOrden])

    const ordenarDatos = () => {
        setCopiaRegistrosaMostrar([...registrosAmostrar.sort((a, b) => { return comparacion(a, b) })
        ])
    }

    const funcionOrden = (nombre_campo) => {

        if (orden == nombre_campo) {
            setNuevoCampo(false)
        } else {
            setNuevoCampo(true)
        }

        setOrden(nombre_campo)
        setContadorOrden(contadorOrden + 1)

    }

    const comparacion = (a, b) => {

        switch (orden) {
            case null: return 0
            case 'edad':
            case 'id_region':
            case 'id_distrito':

                if (nuevoCampo == true) {
                    return a[orden] - b[orden]
                } else {
                    if (contadorOrden % 2 == 0) {
                        return b[orden] - a[orden]
                    } else {
                        return a[orden] - b[orden]
                    }
                }
            case 'ult_ascenso':
            case 'f_solicitud':

                const dia_a = Number(a[orden].substring(0, 2));
                const mes_a = Number(a[orden].substring(3, 5));
                const anio_a = Number(a[orden].substring(6, 10));

                const fa = new Date(anio_a, mes_a, dia_a);

                const dia_b = Number(b[orden].substring(0, 2));
                const mes_b = Number(b[orden].substring(3, 5));
                const anio_b = Number(b[orden].substring(6, 10));

                const fb = new Date(anio_b, mes_b, dia_b);

                if (nuevoCampo == true) {
                    return fa - fb
                } else {
                    if (contadorOrden % 2 == 0) {
                        return fb - fa
                    } else {
                        return fa - fb
                    }
                }
            default:

                if (nuevoCampo == true) {
                    return a[orden].localeCompare(b[orden])
                } else {
                    if (contadorOrden % 2 == 0) {
                        return b[orden].localeCompare(a[orden])
                    } else {
                        return a[orden].localeCompare(b[orden])
                    }
                }
        }
    }

    const marcarTodo = () => {
        const aux = copiaRegistrosaMostrar.map(item => { return { ...item, seleccion: true } })
        setCopiaRegistrosaMostrar(aux)
    }

    const desMarcarTodo = () => {
        const aux = copiaRegistrosaMostrar.map(item => { return { ...item, seleccion: false } })
        setCopiaRegistrosaMostrar(aux)
    }

    const cambiarCheck = (e) => {

        const aux = copiaRegistrosaMostrar.map(item => {
            if (item.id_obrero != e.target.value) {
                return item
            } else {
                return { ...item, seleccion: !item.seleccion }
            }
        })

        setCopiaRegistrosaMostrar(aux)

    }

    const gestionarChecks = (marcarTodos) => {

        if (marcarTodos) {
            marcarTodo();
        } else {
            desMarcarTodo();
        }
    }


    if (esCelular) {
        return <>
            <ListadoCelular datos={copiaRegistrosaMostrar} funcionSeleccion={setObreroSeleccionado} setIsObreroSeleccionado={setIsObreroSeleccionado} />
        </>
    }

    //const verDatosdeMinistroSeleccionado = (id_obrero)=>{
    //   <Redirect to="/datos-ministro" /> 
    //}

    const TABLE_HEAD = ["Nombre", "Dirección", "Provincia", "Localidad", "Email", "Teléfono", "Rango", "Acciones"];

    return <Card className="h-full md:w-[62rem] 2xl:w-full">
            {copiaRegistrosaMostrar.length > 0 &&
                <div className="flex items-center justify-between pr-3">
                    <Paginacion configuracion={configuracion} paginar={paginar} />
                    <ExportToExcelGeneric data={copiaRegistrosaMostrar} fileName="Listado de Ministros" isProvider={true}/>
                </div>
            }
            <CardBody className="overflow-x-auto px-0 pt-0 pb-0">
                <table className="table-auto text-left w-full">
                    <thead>
                        <tr>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Nombre' sort orden={orden === 'nombre_obrero'} callback={() => funcionOrden('nombre_obrero')} />
                            </th>
                            {usuario.perfil == 'region' && <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Distrito' sort orden={orden === 'id_distrito'} callback={() => funcionOrden('id_distrito')} />
                            </th>}
                            {usuario.perfil == 'sede' && <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Región' sort orden={orden === 'id_region'} callback={() => funcionOrden('id_region')} />
                            </th>}
                            {usuario.perfil == 'sede' && <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Distrito' sort orden={orden === 'id_distrito'} callback={() => funcionOrden('id_distrito')} />
                            </th>}
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Dirección' sort orden={orden === 'direccion'} callback={() => funcionOrden('direccion')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Provincia' sort orden={orden === 'provincia'} callback={() => funcionOrden('provincia')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Localidad' sort orden={orden === 'localidad'} callback={() => funcionOrden('localidad')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='E-mail' sort orden={orden === 'email'} callback={() => funcionOrden('email')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Teléfono' sort orden={orden === 'telefono'} callback={() => funcionOrden('telefono')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Rango' sort orden={orden === 'rango'} callback={() => funcionOrden('rango')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="flex justify-between gap-2 font-normal leading-none opacity-70"
                                >
                                    Acciones
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {copiaRegistrosaMostrar
                            .filter((item, index) => {
                                return index >= configuracion.iIni && index <= configuracion.iFin
                            })
                            .map(
                                (item, index) => {
                                    const isLast = index === copiaRegistrosaMostrar.length - 1;
                                    const classes = isLast
                                        ? "p-4"
                                        : "p-4 border-b border-blue-gray-50";

                                    return (
                                        <>
                                            {item.id_obrero == obreroSeleccionadoCredencial?.id_obrero && <Ventana estiloMas={{ width: '500px' }} titulo={'Iglesias del ministro'} cerrar={() => setObreroSeleccionadoCredencial(null)}>
                                                <IglesiasMinistro ministro={item} />
                                            </Ventana>}
                                            {item.id_obrero == obreroSeleccionadoEstadisticasMail?.id_obrero && <Ventana titulo={'Estadísticas de mails'} cerrar={() => setObreroSeleccionadoEstadisticasMail(null)}>
                                                <EstadisticasMail obrero={obreroSeleccionadoEstadisticasMail}
                                                    finalizar={() => setObreroSeleccionadoEstadisticasMail(null)}
                                                    esModal={true} usuario={usuario} />
                                            </Ventana>}

                                            <tr key={item.id_obrero}>
                                                <td className={`${classes} cursor-pointer bg-blue-50 relative`} onClick={() => { setObreroSeleccionado(item.id_obrero); setIsObreroSeleccionado(true) }}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.nombre_obrero}
                                                    </Typography>
                                                </td>
                                                {usuario.perfil == 'region' && <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal opacity-70"
                                                    >
                                                        {item.id_distrito}
                                                    </Typography>
                                                </td>}
                                                {usuario.perfil == 'sede' && <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal opacity-70"
                                                    >
                                                        {item.id_region}
                                                    </Typography>
                                                </td>}
                                                {usuario.perfil == 'sede' && <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal opacity-70"
                                                    >
                                                        {item.id_distrito}
                                                    </Typography>
                                                </td>}
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal opacity-70"
                                                    >
                                                        {item.direccion}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.provincia}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.localidad}
                                                    </Typography>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.email}
                                                    </Typography>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.telefono}
                                                    </Typography>
                                                </td>
                                                <td className={`${classes} flex items-center justify-start ${ item.tieneCredencial==='SI' ? "bg-blue-200xx" :  "bg-redxx"}`}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal w-8"
                                                    >
                                                            {item.rango}
                                                    </Typography>
                                                    { item.tieneCredencial==='SI' && <CheckCircleIcon title='Tiene credencial activa' width={24} className="text-blue-200"/>}
                                                    {item.tieneCredencial==='NO' && <XCircleIcon  title='Sin credencial' width={24} className="text-red-500"/>}
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex items-center justify-center">
                                                        <span onClick={() => { setObreroSeleccionadoCredencial(item) }}
                                                            title={`Verificar condición para activar la credencial`} className="filas-lista-nw cursor-copy" >
                                                            <ClipboardDocumentListIcon className="cursor-pointer h-6 w-6" icon={faIdCard} />
                                                        </span>
                                                        <span onClick={() => { setObreroSeleccionado(item.id_obrero); setIsObreroSeleccionado(true) }}
                                                            title={`Editar los datos del ministro`} className="filas-lista-nw cursor-copy" >
                                                            <PencilSquareIcon className="cursor-pointer h-6 w-6" icon={faEdit} />
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    );
                                },
                            )}
                    </tbody>
                </table>
            </CardBody>
            {copiaRegistrosaMostrar.length > 0 &&
                <PiePagina>
                    <div className="items-center">
                        <Paginacion configuracion={configuracion} paginar={paginar} />
                    </div>
                </PiePagina>
            }
    </Card>


    return <>
        <div className="flex f-col centro-w300 mt-4 res-lista">
            <div>
                <span className="text-xl">{copiaRegistrosaMostrar.length}</span><span className="text-large">{copiaRegistrosaMostrar.length == 1 ? ` registro encontrado` : ` registros encontrados`}</span>
            </div>
            <Paginacion configuracion={configuracion} paginar={paginar} />
        </div>
        {copiaRegistrosaMostrar.length > 0 && <table className="table mt-10 table-cent">
            <thead>
                <tr>
                    <td>
                        <a onClick={() => gestionarChecks(true)}
                            title="Marcar todos para recibir un mail"
                            className="tdec-none cursor-pointer ml-2 color-63 ">
                            <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faCheckCircle} />
                        </a>

                        <a onClick={() => gestionarChecks(false)}
                            title="Desmarcar todos para recibir un mail"
                            className="tdec-none cursor-pointer ml-2 mr-2 color-63 ">
                            <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faCircle} />
                        </a>
                    </td>
                    <td className="color-63 fw-x  text-small fw-700 td-ministros">
                        <span className={orden == 'nombre_obrero' ? "filas-lista-nwx p-2 cursor-pointer ti-nombre orden-activo" : "filas-lista-nwx p-2 cursor-pointer ti-nombre"}
                            onClick={() => funcionOrden('nombre_obrero')}>
                            Nombre
                        </span>
                        <span title="Región" className='filas-lista-nwx p-2 ti-idregion cursor-pointer'>Reg</span>
                        {/*<span className={ orden == 'edad' ? "filas-lista-nwx p-2 ti-edad cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-edad cursor-pointer"} onClick={()=>funcionOrden('edad')}>Edad</span>*/}
                        <span className={orden == 'direccion' ? "filas-lista-nwx p-2 ti-domicilio cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-domicilio cursor-pointer"} onClick={() => funcionOrden('direccion')}>Dirección</span>
                        <span className={orden == 'provincia' ? "filas-lista-nwx p-2 ti-provincia-alt cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-provincia-alt cursor-pointer"}
                            onClick={() => funcionOrden('provincia')}>Provincia</span>
                        <span className={orden == 'localidad' ? "filas-lista-nwx p-2 ti-localidad cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-localidad cursor-pointer"}
                            onClick={() => funcionOrden('localidad')}>Localidad</span>
                        <span className={orden == 'email' ? "filas-lista-nwx p-2 ti-email cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-email cursor-pointer"} onClick={() => funcionOrden('email')}>Email</span>
                        <span className={orden == 'telefono' ? "filas-lista-nwx p-2 ti-telefono cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-telefono cursor-pointer"} onClick={() => funcionOrden('telefono')}>Teléfono</span>
                        {/*<span className={ orden == 'ministerio' ? "filas-lista-nwx ti-tipo cursor-pointer orden-activo" : "filas-lista-nwx ti-tipo cursor-pointer"} onClick={()=>funcionOrden('ministerio')}>Ministerio</span>*/}
                        <span className={orden == 'rango' ? "filas-lista-nwx cursor-pointer ti-rango orden-activo" : "filas-lista-nwx ti-rango cursor-pointer"} onClick={() => funcionOrden('rango')}>Rango</span>
                        <span className={orden == 'credencial' ? "filas-lista-nwx cursor-pointer ti-credencial orden-activo" : "filas-lista-nwx ti-credencial cursor-pointer"} onClick={() => funcionOrden('tieneCredencial')}>Credencial Activa</span>
                    </td>
                    <td>

                    </td>
                </tr>
            </thead>
            <tbody className="body-list">
                {
                    copiaRegistrosaMostrar
                        .map((item, index) => { return { ...item, indice: index + 1 } })
                        .filter((item, index) => {
                            return index >= configuracion.iIni && index <= configuracion.iFin
                        })
                        .map(curso => {
                            return (
                                <>
                                    <tr className="border-bottom-solid cursor-pointer">
                                        <td className="text-center"><input value={curso.id_obrero}
                                            checked={curso.seleccion}
                                            onChange={(e) => cambiarCheck(e)} type="checkbox"
                                            title="Marque o desmarque para que ésta persona reciba un mail" />
                                        </td>
                                        <td onClick={() => { setObreroSeleccionado(curso.id_obrero) }} key={curso.id_obrero} >
                                            <div>

                                                <span className="filas-lista-nw ti-nombre" >
                                                    {curso.nombre_obrero}
                                                </span>
                                                <span title={`Región ${curso.id_region}`} className="filas-lista-nw ti-idregion">{curso.id_region}</span>
                                                {/*<span className="filas-lista-nw ti-edad">{curso.edad}</span>*/}
                                                <span className="filas-lista-nw ti-domicilio">{curso.direccion}</span>
                                                <span className="filas-lista-nw ti-provincia-alt">{curso.provincia}</span>
                                                <span className="filas-lista-nw ti-localidad">{curso.localidad}</span>
                                                <span className="filas-lista-nw ti-email">{curso.email}</span>
                                                <span className="filas-lista-nw ti-telefono">{curso.telefono}</span>
                                                <span className="filas-lista-nw ti-rango">{curso.rango}<p className="text-xxsmall" title="Fecha de último ascenso">{curso.ult_ascenso}</p></span>
                                                <span title={curso.tieneCredencial ? 'Credencial activa' : 'Credencial inactiva'} className="filas-lista-nw ti-credencial">{curso.tieneCredencial}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <a onClick={() => setObreroSeleccionadoCredencial(curso)}
                                                title="Verificar condición para activar la credencial"
                                                className="tdec-none cursor-pointer ml-2 mr-2 color-63 text-large">
                                                <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faIdCard} />
                                            </a>
                                            <a onClick={() => { setObreroSeleccionadoEstadisticasMail(curso) }}
                                                title="Ver las estadísticas de envíos de mail"
                                                className="tdec-none cursor-pointer ml-2 mr-2 color-63 text-large">
                                                <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faAt} />
                                            </a>
                                        </td>
                                    </tr>
                                    {curso.id_obrero == obreroSeleccionadoCredencial?.id_obrero && <Ventana estiloMas={{ width: '500px' }} titulo={'Iglesias del ministro'} cerrar={() => setObreroSeleccionadoCredencial(null)}>
                                        <IglesiasMinistro ministro={curso} />
                                    </Ventana>}
                                    {curso.id_obrero == obreroSeleccionado && <Ventana cerrar={() => { setObreroSeleccionado(null); resetearLista({ respetarFiltros: true }) }}>
                                        <AbmObrero id_obrero={obreroSeleccionado ? obreroSeleccionado : null}
                                            usuario={usuario} esModal={true} finalizarAltaOcopia={() => reinicializar()} />
                                    </Ventana>}
                                    {curso.id_obrero == obreroSeleccionadoEstadisticasMail?.id_obrero && <Ventana titulo={'Estadísticas de mails'} cerrar={() => setObreroSeleccionadoEstadisticasMail(null)}>
                                        <EstadisticasMail obrero={obreroSeleccionadoEstadisticasMail}
                                            finalizar={() => setObreroSeleccionadoEstadisticasMail(null)}
                                            esModal={true} usuario={usuario} />
                                    </Ventana>}
                                </>
                            )
                        })}
            </tbody>
        </table>}
        {copiaRegistrosaMostrar.length > 0 && <PiePagina>
            <div className="flex f-col items-center">
                <Paginacion configuracion={configuracion} paginar={paginar} />
            </div>
        </PiePagina>}
    </>

}

const ThTitle = ({ title, sort, orden, callback }) => {
    return <div className='flex f-col justify-center items-center'>
        <Typography
            variant="small"
            color="blue-gray"
            className="flex justify-between gap-2 font-normal leading-none opacity-70"
        >
            {title}{" "}
            {sort &&
                <ChevronUpDownIcon strokeWidth={2} className={`cursor-pointer ${orden ? 'text-blue-500' : ''} h-4 w-4`} icon={faSort} onClick={callback} />
            }
        </Typography>
    </div>
}
