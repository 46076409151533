import React, { useState, useEffect, useRef } from 'react';
import { Axios, Main, Loading } from '../../../Helpers/importacionesComunes'
import { Spinner } from '@material-tailwind/react';

export const BalancesYdiezmos = ({ id_iglesia }) => {
    const [balances, setBalances] = useState([]);
    const [diezmos, setDiezmos] = useState([]);
    const [buscandoDatos, setBuscandoDatos] = useState(false)
    const [huboError, setHuboError] = useState(false)

    useEffect(() => {
        let mounted = true

        const buscarBalancesYdiezmos = async () => {
            try {
                setBuscandoDatos(true)
                const vectorResultados = await Promise.all([
                    Axios.get(`/api/tablasgenerales/balances/${id_iglesia ? id_iglesia : 0}`),
                    Axios.get(`/api/tablasgenerales/mesesdiezmados/${id_iglesia ? id_iglesia : 0}`)
                ])

                if (mounted) { // para evitar el warning can't perform...
                    setBalances(vectorResultados[0].data)
                    setDiezmos(vectorResultados[1].data)
                    setBuscandoDatos(false)
                }

            } catch (error) {
                setHuboError(true)
                setBuscandoDatos(false)
            }
        }

        buscarBalancesYdiezmos();

        return () => { mounted = false } // para evitar el warning can't perform...
    }, [])

    if (huboError) {
        return <Main center>
            <div className='flex flex-col justify-center items-center h-[calc(50vh-5rem)]'>
                <span>Se produjo un error al cargar los datos para esta vista</span>
            </div>
        </Main>
    }

    if (buscandoDatos) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(50vh-5rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Buscando balances y diezmos...</span>
            </div>
        </Main>
    }

    if (!balances.some(item => item.estado == 1) && !diezmos.some(item => Number(item.diezmo) > 0)) {
        return <div className="listado-al text-center" >
            <span className="p-4 text-red-300 inline-block-1 text-large">- No se encontraron diezmos ni balances</span>
        </div>
    }

    return <div className="FormAbmContainer flex f-row justify-center !text-[16px] mt-4 mb-2">
        <div className="flex f-col">
            <span className="text-center font-bold text-blue-400">Balances</span>
            {balances.map(item => <span className={item.estado == 0 ? 'bal-np !text-[16px]' : ''}>{item.periodo}</span>)}
        </div>
        <div className="flex f-col ml-4">
            <span className="text-center font-bold text-blue-400">Diezmos</span>
            {diezmos.map(item => <span className={Number(item.diezmo) > 0 ? " w-full ml-2" : 'w-full ml-2'}>{item.periodo}</span>)}
        </div>
        {diezmos.length == 0 && <span className="dm-np">0.00</span>}
    </div>

}