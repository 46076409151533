import React, {useState, useEffect} from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import { Spinner, Card, List, ListItem, Typography, Input, Button } from '@material-tailwind/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

export default function ResetPassword ({usuario}){

const [palabraClave, setPalabraClave]= useState('')

useEffect(()=>{
    

},[])


function handleChange(e) {
  setPalabraClave(e.target.value)
}

  ///70011 valor actual
  const resetpasswords = async () => {
    try{
        if (palabraClave && palabraClave.toUpperCase()=='RESET'){
          const {data} = await Axios.get('/api/tablasgenerales/colaboradores/resetpasswords')
          alert(`Los passwords de los usuarios colaboradores se ha reseteado al valor: ${data.password}`)
          setPalabraClave('')
        }else{
            if(palabraClave){
              alert(`Ingresó el texto: "${palabraClave}" No se actualizaron los passwords`)
            }else{
              alert(`No se ingresó un texto. No se actualizaron los passwords`)
            }
        }
    }catch(err){
        alert(err)
    }
  }
 
    return  <Main>
              <div className='flex flex-col justyfy-center ml-auto mr-auto'>
                <Typography 
                      variant="h4" 
                      className="mb-2 mt-4 ml-4 md:ml-0"
                      color="black">
                        Resetear los passwords de los colaboradores 
                    </Typography>
                <Card className='p-4 flex flex-col items-center  lg:p-20'>
                    <div className="flex f-col mb-2">
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 font-medium "
                          >
                            Para continuar ingrese el texto "reset"
                        </Typography>
                        <Input
                          id="abm-asunto"
                          name="asunto" 
                          maxLength="80"
                          value = {palabraClave} 
                          onChange={(e) => handleChange(e)}
                          color='black'
                          className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                          labelProps={{
                              className: "before:content-none after:content-none",
                          }}
                          />
                    </div>

                    <Button 
                      variant="gradient" 
                      className="flex items-center gap-3" 
                      onClick={resetpasswords}>
                            Enviar
                    </Button>
                </Card>
              </div>
            </Main>
}


