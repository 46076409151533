import React from 'react';
import { useState, useEffect, useRef } from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Swal from 'sweetalert2';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faEdit, faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faSync, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { hacerScroll, hacerfocoEnPrimerInput, seleccionarTextoInput } from '../Helpers/utilidades-globales';
import { faReply, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Button, Checkbox, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Textarea, Typography } from '@material-tailwind/react';
import { useContextoGlobal } from '../Context/contextoGlobal';

export default function FormularioAlerta({ alerta, usuario, finAlerta }) {

    const [mensaje, setMensaje] = useState('')
    const [asunto, setAsunto] = useState('')
    const [destinatario, setDestinatario] = useState('region1')
    const [grabandoDatos, setGrabandoDatos] = useState('')
    const [destinatarioNombre, setDestinatarioNombre] = useState('Region 1')
    const [errores, setErrores] = useState({ asunto: '', mensaje: '' })
    const [open, setOpen] = React.useState(true);
    const {esCelular} = useContextoGlobal();

    const handleOpen = () => {
        setOpen(!open)
        finAlerta()
    };

    useEffect(() => {
        hacerfocoEnPrimerInput('abm-asunto')
    }, [])

    function handleChangeMensaje(e) {
        setMensaje(e.target.value)
    }

    function handleChangeAsunto(e) {
        setAsunto(e.target.value)
    }

    function handleChangeDestinatario(e, nombre) {
        setDestinatario(e.target.value)
        setDestinatarioNombre(nombre)
    }

    const grabarDatos = async (values) => {

        const objetoAgrabar = {
            usuario: usuario,
            asunto: asunto,
            mensaje: mensaje,
            destinatario: destinatario
        }

        setGrabandoDatos(true)

        try {
            const respuesta = await Axios.post('/api/tablasgenerales/alerta', objetoAgrabar)
            finAlerta()
        } catch (err) {
            console.log(err)
        }

    }

    const mensajeValido = () => {
        return mensaje != '' && asunto != '' && destinatario != ''
    }


    const iniciarGrabar = (e) => {

        e.preventDefault()

        if (!mensajeValido()) {
            return
        }

        let texto;
        let textoConfirmacion;

        texto = `¿Confirma el envío del mensaje para la ${destinatarioNombre}?`
        textoConfirmacion = 'Si, enviar el mensaje'

        Swal.fire({
            text: texto,
            showCancelButton: true,
            confirButtonText: textoConfirmacion,
            cancelButtonText: 'Cancelar'
        }).then(
            resultado => {
                if (resultado.value) {
                    //                grabarDatos(values);
                    grabarDatos();

                } else {
                    console.log("Se canceló el envío del mensaje")
                }
            }
        )
    }

    return <Main center>
        <Dialog open={open} size="md">
            <form onSubmit={iniciarGrabar}>
                <div className="flex items-center justify-between">
                    <DialogHeader className="flex flex-col items-start">
                        <div className="flex space-x-2 f-row border-bottom-dotted-gray">
                            <FontAwesomeIcon icon={faCommentDots} className="color-green mt-1" />
                            <span className="font-bold inline-block-1">NUEVA ALERTA</span>
                        </div>
                    </DialogHeader>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="mr-3 h-5 w-5 -mt-2"
                        onClick={handleOpen}
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                            clipRule="evenodd"
                        />
                    </svg>
                </div>
                <DialogBody>
                        <div className="flex f-col">
                            <div className="flex f-col">
                                <div className="flex f-col">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="mb-1 font-medium "
                                    >
                                        Destinatario
                                    </Typography>
                                    <div className={`${esCelular? 'grid grid-cols-3 gap-2' : 'flex f-row space-x-2'} mt-2 !text-sm`}>
                                        <Checkbox
                                            label="Region 1"
                                            ripple={false}
                                            checked={destinatario == 'region1'}
                                            id="region1"
                                            name="destinatario"
                                            value="region1"
                                            onChange={(e) => handleChangeDestinatario(e, 'Region 1')}
                                            className="w-4 h-4 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
                                        />
                                        <Checkbox
                                            label="Region 2"
                                            ripple={false}
                                            checked={destinatario == 'region2'}
                                            id="region2"
                                            name="destinatario"
                                            value="region2"
                                            onChange={(e) => handleChangeDestinatario(e, 'Region 2')}
                                            className="w-4 h-4 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
                                        />

                                        <Checkbox
                                            label="Region 3"
                                            ripple={false}
                                            name="destinatario"
                                            checked={destinatario == 'region3'}
                                            id="region3"
                                            value="region3"
                                            onChange={(e) => handleChangeDestinatario(e, 'Region 3')}
                                            className="w-4 h-4 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
                                        />
                                        <Checkbox
                                            label="Region 4"
                                            ripple={false}
                                            name="destinatario"
                                            checked={destinatario == 'region4'}
                                            id="region4"
                                            value="region4"
                                            onChange={(e) => handleChangeDestinatario(e, 'Region 4')}
                                            className="w-4 h-4 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
                                        />
                                        <Checkbox
                                            label="Central"
                                            name="destinatario"
                                            ripple={false}
                                            checked={destinatario == 'central'}
                                            id="central"
                                            value="central"
                                            onChange={(e) => handleChangeDestinatario(e, 'Central')}
                                            className="w-4 h-4 rounded-full border-gray-900/20 bg-gray-900/10 transition-all hover:scale-105 hover:before:opacity-0"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex f-col mb-2">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Asunto
                            </Typography>
                            <Input
                                id="abm-asunto"
                                type="text"
                                name="asunto"
                                maxLength="80"
                                value={asunto}
                                onChange={(e) => handleChangeAsunto(e)}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                        </div>
                        <div className="flex f-col">
                            <Textarea
                                id="abm-respuesta"
                                maxLength="500"
                                label="Mensaje"
                                rows={7}
                                name="mensaje"
                                required
                                value={mensaje}
                                onChange={(e) => handleChangeMensaje(e)}
                            />
                        </div>
                </DialogBody>
                <DialogFooter >
                    {mensajeValido() &&
                        <div className="space-x-2">
                            <Button color="gray" onClick={handleOpen}>
                                Cancelar
                            </Button>
                            <Button type="submit" variant="gradient">
                                Responder
                            </Button>
                        </div>
                    }
                </DialogFooter>
            </form>
        </Dialog>
    </Main>

}


function filasDinamicas(mensaje) {
    const longitudMensaje = mensaje.length;
    let ancho = 0;

    console.log(longitudMensaje)

    ancho = Number(longitudMensaje) / 30;

    console.log(ancho)

    return ancho
}












