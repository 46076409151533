import React, { useEffect, useState} from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import CircleIcon from '@mui/icons-material/Circle';
import ListItemButton from '@mui/material/ListItemButton';
import {hacerScroll} from '../../../Helpers/utilidades-globales' 
import { Button, Card } from '@material-tailwind/react';

export const ListadoCelular= ({datos,
    funcionSeleccion,
    setIsObreroSeleccionado
    })=>{

const [maximo,setMaximo] = useState(50)

useEffect(()=>{
    setMaximo(50)
},[datos])

useEffect(()=>{
    if(maximo>50){
        hacerScroll(`id-index-${maximo-50}`)
    }
},[maximo])

const mostrarMas = ()=>{
    setMaximo(maximo+50)
}

return <>
        <h4 className='mb-1'>{resumen(datos,maximo)}</h4>
        <Card>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {datos
                .filter((item,index)=>index<=maximo)
                .map((item,index)=>(
                    <>
                        <ListItem key={item.id_obrero}>
                            <ListItemIcon sx={{minWidth:'0'}} id={`id-index-${index}`}>
                                <CircleIcon  />
                            </ListItemIcon>
                            <ListItemButton onClick={()=>{funcionSeleccion(item.id_obrero); setIsObreroSeleccionado(true)}}>
                                <ListItemText primary={item.nombre_obrero} secondary={<>
                                                                                    <p>{item.provincia}</p>
                                                                                    <p>{item.localidad}</p>
                                                                                    <p>{item.rango}</p>
                                                                                    <p>R-{item.id_region}</p>
                                                                                </>}
                                />
                                </ListItemButton>
                            </ListItem>
                        <Divider variant="inset" component="li" className='relative right-4' />
                    </>
                ))}
            </List>
        </Card>
        <div className='grid grid-cols-2 mt-2'>
            <p className='mt-2'>{resumen(datos,maximo)}</p>
            {hayMasDatos(datos, maximo) && <Button variant="text" className="flex items-center gap-2" onClick={mostrarMas}>
                Mostrar mas
                <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-5 w-5"
                >
                <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
                </svg>
            </Button>
            }
        </div>
        </>
}

const hayMasDatos = (datos,maximo) =>{
    if(datos.length>maximo){
        return true
    }
    return false
}

const resumen = (datos,maximo)=>{
return datos.length < maximo ? datos.length == 1 ? '1 resultado': `${datos.length} resultados` : `${maximo} resultados de ${datos.length}`

}

