import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import Main from '../componentes/Main';
import {Link} from 'react-router-dom'
import Axios from 'axios';
import Loading from '../componentes/Loading';
import {Container, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import { Spinner, Card, List, ListItem, Typography } from '@material-tailwind/react';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

export default function Qr ({ocultar,usuario}){

const [url,setUrl] = useState(null);
const [archivos, setArchivos]= useState([])
const [status, setStatus]= useState(false)
const [buscandoDatos,setBuscandoDatos] = useState(false)

useEffect(()=>{
    const obtenerArchivos = async ()=>{

        setBuscandoDatos(true);

        try{
            const vectorResultado = await Promise.all([Axios.get(`/api/tablasgenerales/documentos/${usuario.perfil==='sede' ? 'sede':'region'}`)]) 
            setArchivos(vectorResultado[0].data.documentos)
            setBuscandoDatos(false);
        }catch(err){
            console.log(err)
            setBuscandoDatos(false);
        }
    }

    obtenerArchivos()       

},[])


    if(buscandoDatos){
        return <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando archivos...</span>
                    </div>
                </Main>
    }

    if(archivos.length==0){
        return <Grid container justifyContent='center' alignItems='center' style={{minHeight:'400px',textAlign:'center'}} >
            <Grid item>
                <Typography variant="h5">
                    No se encontraron archivos
                </Typography>
            </Grid>
        </Grid>
    }

    return  <div className='w-full flex flex-col justify-center items-center lg:!m-6 xl:!m-6'>
                <Typography variant="h4" className="flex ">
                    Documentos
                </Typography>
                <Card className='w-full  flex p-5 justify-left mt-4'>
                    <List> 
                        {archivos.map((item, index)=>
                            <ListItem key={index} className="cursor-pointer">
                                <div className='flex space-x-2'>
                                    <ArrowDownTrayIcon className="w-6 h-6" />
                                    <a target="_blank" className="ml-2 text-large color-black" href={item.ruta}>{item.nombre}</a>
                                </div>
                            </ListItem>
                        )}
                    </List>
                </Card>
    </div>
}

