import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {_imagenLogo} from '../imagenesBase64/logo'
import Swal from 'sweetalert2';
import {transformarIso8601,fechaActual} from '../Helpers/fechas';


const bkmonto = '';

const estilos = {
    header: {
        fontSize: 12,
        bold: true
    },
    caption: {
        fontSize: 10,
        bold: true
    },
    underline:{
        decoration:'underline'
    },
    info:{
        fontSize: 10,
        alignment:'left'
    },
    headerBig: {
        fontSize: 16,
        bold: true
    },                    
    center: {
        alignment: 'center',
        decoration: 'underline'
    },
    estilo_columna: {
        fontSize: 8,
        characterSpacing: -0.3
    },
    estilo_tabla: {
        fontSize: 8,
        characterSpacing: -0.3,
        margin: [0, 10, 0, 0]
    },
    anotherStyle: {
        fontSize: 15,
        bold: true
    },
    body: {
        lineHeight: 1.5
    },
    tableHeader: {
        bold: true,
        fontSize: 12
    }
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export function imprimir(datos, nombrePDF, tituloPDF,callbackMap,arrayCampos,orientacion,parametros){
    try{
        Swal.fire({
            html: 'Buscando Historial de Aportes...',
            timer: 1000,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        }).then(data=>{
            if(parametros.id_region && parametros.id_region !== '-1'){
                generarDocumento(datos, nombrePDF, tituloPDF,callbackMap,arrayCampos,orientacion ? orientacion : 'portrait',parametros)
            }else{
                let datosAgrupadosPorRegion = agruparDatosPorRegion(datos);
                generarDocumentoPorRegiones(datosAgrupadosPorRegion, nombrePDF, tituloPDF, callbackMap, arrayCampos, orientacion ? orientacion : 'portrait', parametros);
            }
        })
    }catch(err){

            const mensaje_html = `<p>Hubo un error al generar el documento</p><p>${err}</p>`

            Swal.fire({
                html:mensaje_html,
                icon: 'warning',
                confirmButtonColor: '#3085d6',
            })
        }
}


// Función para agrupar los datos por región
function agruparDatosPorRegion(datos) {
    return datos.reduce((agrupado, item) => {
        let region = item.region_obr;
        if (!agrupado[region]) {
            agrupado[region] = [];
        }
        agrupado[region].push(item);
        return agrupado;
    }, {});
}

function generarDocumento(datos,nombrePDF, tituloPDF,callbackMap,arrayCampos,orientacion,parametros) {

    const datos_por_iglesia = generar_datos_por_iglesia(datos);

    var cuerpo_del_reporte = 

// atención: Los datos vienen ordenados desde el stored por año,mes,día,caja y proveedor.

            datos.map((item,index,vector)=>{
                return [
                    {
                        columns: [
                            {text:item.periodo
                            },
                            {text:item.aporte
                            },
                            {text:item.comprobante
                            }
                        ]
                    },
            ]})

            var docDefinition = {
                pageOrientation: 'portrait', // O según la orientación que prefieras
                pageMargins: [25, 110, 25, 50], // Aumentar margen superior para más espacio bajo el header
                header: function (currentPage, pageCount, pageSize) {
                    if (currentPage > 1) {
                        return [
                            { text: tituloPDF, alignment: 'left' ,margin:[20,20,0,50] },
                            {
                                width: pageSize.width,
                                canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1,lineColor: 'black' }],
                            } 
                          ]
                    } else {
                        return {
                            margin: [10, 10, 10, 0], // Ajustar margen superior del header
                            stack: [
                                {
                                    columns: [
                                        {
                                            image: _imagenLogo, // Logo de la cabecera
                                            width: 50,
                                            alignment: 'left',
                                        },
                                        {
                                            text: 'Unión de las Asambleas de Dios - Aportantes', // Título general
                                            alignment: 'left',
                                            margin: [20, 10]
                                        }
                                    ],
                                    columnGap: 40 // Ajuste de separación entre la primera y segunda columna
                                },
                                {
                                    columns: [
                                        {
                                            text: parametros.id_region ? `Región: ${datos[0].region_obr}` : 'Región: No especificada', // Segunda fila con datos de la región
                                            margin: [20, 0], // Ajuste de margen entre filas
                                            alignment: 'left'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: parametros.id_distrito ? `Distrito: ${datos[0].distrito_igl}` : 'Distrito: No especificado', // Tercera fila con datos del distrito
                                            margin: [20, 0], // Ajuste de margen entre filas
                                            alignment: 'left'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: parametros.id_obrero ? `Obrero: ${parametros.nombre_obrero}` : 'Obrero: No especificado', // Cuarta fila con datos del obrero
                                            margin: [20, 0], // Ajuste de margen entre filas
                                            alignment: 'left'
                                        }
                                    ]
                                },
                                {
                                    columns: [
                                        {
                                            text: parametros.id_iglesia !== '-1' ? `Iglesia: ${datos[0].iglesia}` : 'Iglesia: No especificada', // Quinta fila con datos de la iglesia
                                            margin: [20, 0], // Ajuste de margen entre filas
                                            alignment: 'left'
                                        }
                                    ]
                                },
                                {
                                    text: tituloPDF, // Título del PDF en la cabecera
                                    alignment: 'left',
                                    margin: [10, 10, 0, 0]
                                },
                                {
                                    width: pageSize.width, // Separador de línea debajo del título
                                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1, lineColor: 'black' }]
                                }
                            ]
                        };
                    }
                },
                footer: function (currentPage, pageCount, pageSize) {
                    return {
                        margin: [0, 30, 30, 0],
                        table: {
                            widths: ['*', '*'],
                            body: [
                                [
                                    { text: fechaActual('DD/MM/YYYY'), alignment: 'right', fontSize: 8 },
                                    { text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'right', fontSize: 8 }
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    };
                },
                content: [
                    //cuerpo_del_reporte // Aquí va el contenido del cuerpo del reporte
                    datos_por_iglesia                    
                ],
                styles: estilos,
                images: {
                    building: 'data:/Content/img/logo1.jpg'
                }
            };
            
            // Descargar o abrir el PDF
            if (nombrePDF != "") {
                pdfMake.createPdf(docDefinition).download(nombrePDF);
            } else {
                pdfMake.createPdf(docDefinition).open();
            }
            
}

// Genera el documento con la información agrupada por regiones
function generarDocumentoPorRegiones(datosAgrupadosPorRegion, nombrePDF, tituloPDF, callbackMap, arrayCampos, orientacion, parametros) {

    const iglesiasDelReporte = {};

    // datosAgrupadosPorRegion.forEach(aporte=>{
    //     if(!iglesiasDelReporte[aporte.id_iglesia]){
    //         iglesiasDelReporte[aporte.id_iglesia]=aporte.iglesia;
    //     }
    // })

    // const cuerpo2 = [];
    // Object.keys(iglesiasDelReporte).forEach(iglesia=>{
    //     const descripcion = iglesiasDelReporte[iglesia.id_iglesia]
    //     cuerpo2.push(descripcion)

    //     let aportes_iglesia = datosAgrupadosPorRegion.filter(aporte=>aporte.id_iglesia===iglesia.id_iglesia).map((item,index,vector)=>{
    //         return [
    //             {
    //                 columns: [
    //                     {text:item.periodo
    //                     },
    //                     {text:item.condición
    //                     },
    //                     {text:item.comprobante
    //                     }
    //                 ]
    //             },
    //     ]})

    //     cuerpo2.push(aportes_iglesia)
    // })

    // console.log('pablo',cuerpo2)

    console.log(datosAgrupadosPorRegion)
    let contenido = [];

    Object.keys(datosAgrupadosPorRegion).forEach(region => {
        contenido.push({
            text: `Región: ${region}`,
            style: 'header',
            margin: [0, 10, 0, 10]
        });

        // datos por cada región
        let cuerpo_del_reporte = datosAgrupadosPorRegion[region].map((item) => {
            return {
                columns: [
                    { text: item.periodo, style: 'estilo_columna' },
                    { text: item.aporte, style: 'estilo_columna' },
                    { text: item.comprobante, style: 'estilo_columna' }
                ],
                margin: [0, 5, 0, 5]
            };
        });

        const datos_por_iglesia = generar_datos_por_iglesia(datosAgrupadosPorRegion[region]);

//        contenido.push(cuerpo_del_reporte);
        contenido.push(datos_por_iglesia);
        // separador para diferenciar entre las regiones
        contenido.push({
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }]
        });
    });

    var docDefinition = {
        pageOrientation: orientacion,
        pageMargins: [25, 110, 25, 50],
        header: function (currentPage, pageCount, pageSize) {
            if (currentPage > 1) {
                return [
                    { text: tituloPDF, alignment: 'left' ,margin:[20,20,0,50] },
                    {
                        width: pageSize.width,
                        canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1,lineColor: 'black' }],
                    } 
                  ]
            } else {
                return [
                    { margin: [10, 10,10,0], columns: [
                            { image: _imagenLogo, width: 50, alignment: 'left' },
                            { text: 'Unión de las Asambleas de Dios - Aportantes', margin: [20, 20] },
                        ]
                    },
                    { text: tituloPDF, alignment: 'left', margin: [10, 0, 0, 0] },
                    {
                        width: pageSize.width,
                        canvas: [{ type: 'line', x1: 0, y1: 5, x2: pageSize.width, y2: 5, lineWidth: 1, lineColor: 'black' }]
                    }
                ];
            }
        },
        footer: function (currentPage, pageCount, pageSize) {
            return {
                margin: [0, 30, 30, 0],
                table: {
                    widths: ['*', '*'],
                    body: [
                        [{ text: fechaActual('DD/MM/YYYY'), alignment: 'right', fontSize: 8 }, 
                         { text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'right', fontSize: 8 }]
                    ]
                },
                layout: 'noBorders'
            };
        },
        content: contenido,
        styles: estilos
    }

    if (nombrePDF != "") {
        pdfMake.createPdf(docDefinition).download(nombrePDF);
    } else {
        pdfMake.createPdf(docDefinition).open();
    }
}



function fechaDelDia(){
    let fecha;

    const fecha_aux  = new Date()
    const dia = fecha_aux.getDate() < 10 ? `0${fecha_aux.getDate()}` : fecha_aux.getDate();
    let mes = fecha_aux.getMonth() + 1 ;
    mes = mes < 10 ? `0${mes}` : mes;
    fecha = `${dia}/${mes}/${fecha_aux.getUTCFullYear()}`

    return fecha;
}

function imprimirTotalDiario(item,index,vector){
    //esta función analiza si corresponde imprimir un total
    // evalúa si cambia de fecha preguntando si la fecha del registro actual
    // es diferente a la fecha del registro anterior
    // si es diferente calcula el total de todos los registros que tengan
    // la misma fecha del último registro
    // Si es la misma fecha el registro actual que el anterior no imprime total
    // si el vector tiene solo 1 registro imprime el total directamente tomando el monto tal como está en ese único registro
    // si el vector tiene 2 filas o mas evalúa la fecha cuando el indice es mayor a 0
    // si el indice es cero no imprime porque no tiene contra qué comparar
    // No comparamos con el item.fecha sino que antes de comparar la transformamos a un formato DD/MM/YYYY
    // el campo fecha viene en un formato que contiene horas minutos y segundos por eso
    // si no se convierte el formato aunque tenga la misma fecha daría que son diferentes fechas
    // por otro lado el campo monto es un string que tiene formato moneda
    // por eso la totalización se hace sobre el campo monto_numerico

    if(vector.length>1){
        if (index>0){
            if (transformarIso8601(vector[index-1].fecha)==transformarIso8601(item.fecha)){
                return {text:''}
            }else{
                const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(vector[index-1].fecha)).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
                const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                const fecha = new Date(vector[index-1].fecha);
                //return {text:`Total fecha ${transformarIso8601(vector[index-1].fecha)}${total_formateado}`}
                return 	{
                    fontSize: 10,
                    bold:true,
                    margin:[0,10,0,10],
                    columns: [
                        {
                            width: 50,
                            text: 'TOTAL'
                        },
                        {
                            width: 60,
                            text: `Año  ${fecha.getFullYear()}`
                        },
                        {
                            width: 60,
                            text: `Mes  ${fecha.getMonth() + 1}`
                        },
                        {
                            width: 60,
                            text: `Día  ${fecha.getDate()}`
                        },
                        {
                            width: 220,
                            canvas: [{ type: 'line', x1: 0, y1: 5, x2: 220, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                        },
                        {
                            width: 60,
                            background:bkmonto,alignment:'right',
                            table: {
                                widths:[60],
                                body: [
                                    [{text:total_formateado,alignment:'right'}],
                                ]
                            }
                        },
                    ]
                }
            }
        }else{
            return {text:''}
        }
    }else if (vector.length==1){
        const fecha = new Date(item.fecha);
        return {stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${fecha.getFullYear()}`
                },
                {
                    width: 60,
                    text: `Mes  ${fecha.getMonth() + 1}`
                },
                {
                    width: 60,
                    text: `Día  ${fecha.getDate()}`
                },
                {
                    width: 220,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 220, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 60,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[60],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true}
    }else{
        return {text:''}
    }

}


function imprimirTotalDiarioFinal(item,index,vector){
        const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(item.fecha)).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
        const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
       // return {text:`Total fecha ${transformarIso8601(vector[index-1].fecha)}${total_formateado}`}
        const fecha = new Date(item.fecha);
        return {stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${fecha.getFullYear()}`
                },
                {
                    width: 60,
                    text: `Mes  ${fecha.getMonth() + 1}`
                },
                {
                    width: 60,
                    text: `Día  ${fecha.getDate()}`
                },
                {
                    width: 240,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 240, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 60,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[60],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true }   
    }





function imprimirTotalCaja(item,index,vector){
    //esta función analiza si corresponde imprimir un total
    // evalúa si cambia de fecha preguntando si la fecha del registro actual
    // es diferente a la fecha del registro anterior
    // si es diferente calcula el total de todos los registros que tengan
    // la misma fecha del último registro
    // Si es la misma fecha el registro actual que el anterior no imprime total
    // si el vector tiene solo 1 registro imprime el total directamente tomando el monto tal como está en ese único registro
    // si el vector tiene 2 filas o mas evalúa la fecha cuando el indice es mayor a 0
    // si el indice es cero no imprime porque no tiene contra qué comparar
    // No comparamos con el item.fecha sino que antes de comparar la transformamos a un formato DD/MM/YYYY
    // el campo fecha viene en un formato que contiene horas minutos y segundos por eso
    // si no se convierte el formato aunque tenga la misma fecha daría que son diferentes fechas
    // por otro lado el campo monto es un string que tiene formato moneda
    // por eso la totalización se hace sobre el campo monto_numerico

    if(vector.length>1){
        if (index>0){
            if (transformarIso8601(vector[index-1].fecha)==transformarIso8601(item.fecha)){
                if (vector[index-1].caja==item.caja){
                    return {text:''}
                }else{
                    const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(vector[index-1].fecha) && i.caja==vector[index-1].caja).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
                    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    const fecha = new Date(vector[index-1].fecha);
//                    return {text:`Total caja ${vector[index-1].caja}${total_formateado}`}
                    return {stack:[{
                        fontSize: 10,
                        bold:true,
                        margin:[0,10,0,10],
                        columns: [
                            {
                                width: 50,
                                text: 'TOTAL'
                            },
                            {
                                width: 60,
                                text: `Año  ${fecha.getFullYear()}`
                            },
                            {
                                width: 60,
                                text: `Mes  ${fecha.getMonth() + 1}`
                            },
                            {
                                width: 60,
                                text: `Día  ${fecha.getDate()}`
                            },
                            {
                                width: 100,
                                text: `${vector[index-1].caja}`
                            },
                            {
                                width: 120,
                                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 120, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                            },
                            {
                                width: 60,
                                background:bkmonto,alignment:'right',
                                table: {
                                    widths:[60],
                                    body: [
                                        [{text:total_formateado,alignment:'right'}],
                                    ]
                                }
                            },
                        ]
                    }],unbreakable:true}              

                
                }
            }else{
                // si hubo cambio de fecha siempre calculo la caja
                    const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(vector[index-1].fecha) && i.caja==vector[index-1].caja).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
                    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                    //return {text:`Total caja ${vector[index-1].caja}${total_formateado}`}
                    const fecha = new Date(vector[index-1].fecha);

                    return 	{stack:[{
                        fontSize: 10,
                        bold:true,
                        margin:[0,10,0,10],
                        columns: [
                            {
                                width: 50,
                                text: 'TOTAL'
                            },
                            {
                                width: 60,
                                text: `Año  ${fecha.getFullYear()}`
                            },
                            {
                                width: 60,
                                text: `Mes  ${fecha.getMonth() + 1}`
                            },
                            {
                                width: 60,
                                text: `Día  ${fecha.getDate()}`
                            },
                            {
                                width: 100,
                                text: `${vector[index-1].caja}`
                            },
                            {
                                width: 120,
                                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 120, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                            },
                            {
                                width: 60,
                                background:bkmonto,alignment:'right',
                                table: {
                                    widths:[60],
                                    body: [
                                        [{text:total_formateado,alignment:'right'}],
                                    ]
                                }
                            },
                        ]
                    }],unbreakable:true}
            }
        }else{
            return {text:''}
        }
    }else if (vector.length==1){
        const fecha = new Date(item.fecha);

        return 	{stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${fecha.getFullYear()}`
                },
                {
                    width: 60,
                    text: `Mes  ${fecha.getMonth() + 1}`
                },
                {
                    width: 60,
                    text: `Día  ${fecha.getDate()}`
                },
                {
                    width: 100,
                    text: `${vector[index-1].caja}`
                },
                {
                    width: 120,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 120, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 60,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[60],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true}
    }else{
        return {text:''}
    }

}

function imprimirTotalMes(item,index,vector){

    if(vector.length>1){
        if (index>0){
            if (transformarIso8601(vector[index-1].fecha,'MM')==transformarIso8601(item.fecha,'MM')){
                return {text:''}
            }else{
                const total = vector.filter(i=>transformarIso8601(i.fecha,'MM')==transformarIso8601(vector[index-1].fecha,'MM')).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
                const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                const fecha = new Date(vector[index-1].fecha);
               // return {text:`Total mes ${transformarIso8601(vector[index-1].fecha,'MM')} ${total_formateado}`}
                return {stack:[{
                    fontSize: 10,
                    bold:true,
                    margin:[0,10,0,10],
                    columns: [
                        {
                            width: 50,
                            text: 'TOTAL'
                        },
                        {
                            width: 60,
                            text: `Año  ${fecha.getFullYear()}`
                        },
                        {
                            width: 60,
                            text: `Mes  ${fecha.getMonth() + 1}`
                        },
                        {
                            width: 280,
                            canvas: [{ type: 'line', x1: 0, y1: 5, x2: 280, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                        },
                        {
                            width: 60,
                            background:bkmonto,alignment:'right',
                            table: {
                                widths:[60],
                                body: [
                                    [{text:total_formateado,alignment:'right'}],
                                ]
                            }
                        },
                    ]
                }],unbreakable:true}
            }
        }else{
            return {text:''}
        }
    }else if (vector.length==1){
        const fecha = new Date(item.fecha);
        return {stack:[{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${fecha.getFullYear()}`
                },
                {
                    width: 60,
                    text: `Mes  ${fecha.getMonth() + 1}`
                },
                {
                    width: 280,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 280, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 60,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[60],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }],unbreakable:true}
    }else{
        return {text:''}
    }

}

function imprimirTotalAño(item,index,vector){

    if(vector.length>1){
        if (index>0){
            if (transformarIso8601(vector[index-1].fecha,'YYYY')==transformarIso8601(item.fecha,'YYYY')){
                return {text:''}
            }else{
                const total = vector.filter(i=>transformarIso8601(i.fecha,'YYYY')==transformarIso8601(vector[index-1].fecha,'YYYY')).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
                const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                //return {text:`Total año ${transformarIso8601(vector[index-1].fecha,'YYYY')} ${total_formateado}`}
                const fecha = new Date(vector[index-1].fecha);
    
                return 	{
                    fontSize: 10,
                    bold:true,
                    margin:[0,10,0,10],
                    columns: [
                        {
                            width: 50,
                            text: 'TOTAL'
                        },
                        {
                            width: 100,
                            text: `Año  ${fecha.getFullYear()}`
                        },
                        {
                            width: 340,
                            canvas: [{ type: 'line', x1: 0, y1: 5, x2: 330, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                        },
                        {
                            width: 80,
                            background:bkmonto,alignment:'right',
                            table: {
                                widths:[60],
                                body: [
                                    [{text:total_formateado,alignment:'right'}],
                                ]
                            }
                        },
                    ]
                }
            }
        }else{
            return {text:''}
        }
    }else if (vector.length==1){
        const fecha = new Date(item.fecha);
    
        return 	{
            fontSize: 10,
            bold:true,
            margin:[0,10,0,10],
            columns: [
                {
                    width: 50,
                    text: 'TOTAL'
                },
                {
                    width: 60,
                    text: `Año  ${fecha.getFullYear()}`
                },
                {
                    width: 340,
                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 330, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
                {
                    width: 60,
                    background:bkmonto,alignment:'right',
                    table: {
                        widths:[60],
                        body: [
                            [{text:item.monto,alignment:'right'}],
                        ]
                    }
                },
            ]
        }
    }else{
        return {text:''}
    }
}

function imprimirTotalCajaFinal(item,index,vector){
    const total = vector.filter(i=>transformarIso8601(i.fecha)==transformarIso8601(item.fecha) && i.caja==item.caja).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
   // return {text:`Total caja ${vector[index-1].caja}${total_formateado}`}

    const fecha = new Date(item.fecha);
                        return 	{
                            fontSize: 10,
                            bold:true,
                            margin:[0,10,0,10],
                            columns: [
                                {
                                    width: 50,
                                    text: 'TOTAL'
                                },
                                {
                                    width: 60,
                                    text: `Año  ${fecha.getFullYear()}`
                                },
                                {
                                    width: 60,
                                    text: `Mes  ${fecha.getMonth() + 1}`
                                },
                                {
                                    width: 60,
                                    text: `Día  ${fecha.getDate()}`
                                },
                                {
                                    width: 100,
                                    text: `${vector[index-1].caja}`
                                },
                                {
                                    width: 140,
                                    canvas: [{ type: 'line', x1: 0, y1: 5, x2: 140, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                                },
                                {
                                    width: 60,
                                    background:bkmonto,alignment:'right',
                                    table: {
                                        widths:[60],
                                        body: [
                                            [{text:total_formateado,alignment:'right'}],
                                        ]
                                    }
                                },
                            ]
                        }

}

function imprimirTotalMesFinal(item,index,vector){
    const total = vector.filter(i=>transformarIso8601(i.fecha,'MM')==transformarIso8601(item.fecha,'MM')).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    //return {text:`Total mes ${transformarIso8601(item.fecha,'MM')} ${total_formateado}`}
    const fecha = new Date(item.fecha);
    
     return 	{
         fontSize: 10,
         bold:true,
         margin:[0,10,0,10],
         columns: [
             {
                 width: 50,
                 text: 'TOTAL'
             },
             {
                 width: 60,
                 text: `Año  ${fecha.getFullYear()}`
             },
             {
                 width: 60,
                 text: `Mes  ${fecha.getMonth() + 1}`
             },
             {
                 width: 300,
                 canvas: [{ type: 'line', x1: 0, y1: 5, x2: 300, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
                },
             {
                 width: 60,
                 background:bkmonto,alignment:'right',
                 table: {
                    widths:[60],
                    body: [
                        [{text:total_formateado,alignment:'right'}],
                    ]
                }
             },
         ]
     }
}

function imprimirTotalAñoFinal(item,index,vector){
    const total = vector.filter(i=>transformarIso8601(i.fecha,'YYYY')==transformarIso8601(item.fecha,'YYYY')).reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    //return {text:`Total año ${transformarIso8601(item.fecha,'YYYY')} ${total_formateado}`}
    const fecha = new Date(item.fecha);
    
    return 	{
        fontSize: 10,
        bold:true,
        margin:[0,10,0,10],
        columns: [
            {
                width: 50,
                text: 'TOTAL'
            },
            {
                width: 60,
                text: `Año  ${fecha.getFullYear()}`
            },
            {
                width: 360,
                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 360, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
            },
            {
                width: 60,
                background:bkmonto,alignment:'right',
                table: {
                    widths:[60],
                    body: [
                        [{text:total_formateado,alignment:'right'}],
                    ]
                }
            },
        ]
    }
}

function imprimirTotalGeneral(item,index,vector){
    const total = vector.reduce((ac,valor)=>{return ac = ac + valor.monto_numerico},0)
    const total_formateado = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
   // return {text:`Total: ${total_formateado}`}

    return 	{
        fontSize: 10,
        bold:true,
        margin:[0,10,0,10],
        columns: [
            {
                width: 50,
                text: 'TOTAL'
            },
            {
                width: 420,
                canvas: [{ type: 'line', x1: 0, y1: 5, x2: 420, y2: 5, lineWidth: 0.5,lineColor: 'black' }],
            },
           /* {
                width: 100,
                text: ``
            },
            {
                width: 100,
                text: ``
            },
            {
                width: 100,
                text: ``
            },*/
            {
                width: 60,
                background:bkmonto,alignment:'right',
                table: {
                    widths:[60],
                    body: [
                        [{text:total_formateado,alignment:'right'}],
                    ]
                }
            },
        ]
    }
}

const generar_datos_por_iglesia = (datos)=>{
    const iglesiasDelReporte = {};

    datos.forEach(aporte=>{
        if(!iglesiasDelReporte[aporte.id_iglesia]){
            iglesiasDelReporte[aporte.id_iglesia]={iglesia:aporte.iglesia,
                                                    locacion:aporte?.locacion,
                                                    region:aporte?.region_igl,
                                                    total:aporte?.total_monto_por_iglesia,
                                                    distrito:aporte?.distrito_igl,
                                                    encargado:aporte?.encargado};
        }
    })

    const datos_por_iglesia_stacked = [];


    Object.keys(iglesiasDelReporte).forEach(iglesia=>{
        const detalle = iglesiasDelReporte[iglesia]
        
        const datos_por_iglesia=[];

        datos_por_iglesia.push({stack:[
        {
            text: `${detalle?.iglesia}`,
            style: ['header','underline','headerBig'],
            margin: [0, 10, 0, 10]
        },
        {
            columns:
                [{text:'Dirección: ',style: 'caption',width: 50},{text:detalle?.locacion,style: 'info'}]
            },        
        {
                    columns: [
                        {
                        columns:
                            [{text:'Región: ',style: 'caption',width: 54},{text:detalle?.region,style: 'info'}],
                        width:200
                        }
                        ,
                        {
                        columns:
                            [{text:'Distrito: ',style: 'caption',width: 54},{text:detalle?.distrito,style: 'info'}]
                        },
                    ],
                },
                {
                    columns:
                        [{text:'Encargado: ',style: 'caption',width: 54},{text:detalle?.encargado || '',style: 'info'}]
                    },
        ],margin: [0, 10, 0, 10]});
        
        let total=0;

        let aportes_iglesia = datos.filter(aporte=>aporte.id_iglesia==iglesia).map((item,index,vector)=>{
            total = total + item?.monto || 0;
            return [
                {
                    columns: [
                        {text:item.periodo
                        },
                        {text:item.aporte
                        },
                        {text:item.comprobante,margin:[0,5,0,5]
                        }
                    ]
                },]
    })

        datos_por_iglesia.push(aportes_iglesia)

        datos_por_iglesia.push(
            {
                columns:[{
                    columns:
                    [{text:'Promedio de meses aportados: ',style: 'caption',width: 'auto'},{text:detalle?.total || 0,style: 'info'}]
                },
                {
                    columns:
                    [{text:'Total de meses aportados: ',style: 'caption',width: 'auto'},{text:aportes_iglesia.length,style: 'info'}]
                }
            ],
            margin: [0, 10, 0, 10]
            }
        )
        datos_por_iglesia.push({
            canvas: [{ type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1 }]
        });

        // se envuelve en un stack para que no escriba datos de una iglesia cortado, todo debe entrar en 1 página.
        datos_por_iglesia_stacked.push({
            stack:datos_por_iglesia,
            unbreakable:true
        })

    })
  
   return datos_por_iglesia_stacked;

}
