
import React, {useContext,useEffect,useState} from 'react'
import {TextField, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {useCupones} from '../hooks/useCupones'
import {formatearMontoMoneda} from '../../../Helpers/utilidades-globales'
import {Alert} from '@mui/material'
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import { Button, Textarea } from '@material-tailwind/react';

const regex_solo_numeros = /^[0-9\b]+$/;

export const ActualizarCupon = ({cupon,refrescar})=>{
    const {actualizarCupon,cargandoCupones} = useCupones()
    const [recibo,setRecibo] = useState('')
    const [motivo,setMotivo] = useState('')
    const [verMas,setVerMas] = useState(false)

    const actualizar = () =>{
        actualizarCupon(cupon,recibo,motivo)
        .then(()=>{
            refrescar() // tuve que pasar la función refrescar como prop para que la ejecute el componente padre Listado porque cuando ejecuto actulizarCupon se llama a cargarCupones pero en el componente Listado no se refresca y no me cierra el dialogo ni actualiza la lista, ahora si lo paso por prop y ejecuto desde el padre si lo  hace, debe ser por un closure
            // normalmente lo haría directamente desde el contexto pero por algún motivo no registra el cambio de la variable global cargandoCupones
        })
    }

    const handleChangeRecibo = (e)=>{

        if(regex_solo_numeros.test(e.target.value.trim())){
            setRecibo(e.target.value)
        }

    }

    const handleChangeMotivo = (e)=>{
        setMotivo(e.target.value)

    }

    if (!cupon?.id_evento_cupon){
        return null
    }

    return <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
      <Box sx={{display:'flex', justifyContent:'end',width:'100%'}}>
          <Button className='mb-2' onClick={()=>setVerMas(true)}>Ver más datos</Button>
      </Box>
      <table>
        <tbody>
            <tr>
                <td>Cupón</td>
                <td>{cupon.nro_cupon}</td>
            </tr>
            <tr>
                <td>Importe</td>
                {/*<td>{formatearMontoMoneda(cupon.importe)}</td>*/}
                <td>{cupon.importe}</td>
            </tr>
            <tr>
                <td>Evento</td>
                <td>{cupon.evento}</td>
            </tr>
            <tr>
                <td>Item</td>
                <td>{cupon.detalle}</td>
            </tr>
            <tr>
                <td>Cantidad de personas</td>
                <td>{cupon.cantidad_personas}</td>
            </tr>
            <tr>
                <td>Estado:</td>
                <td style={{color: cupon.validado==1 ? 'green': 'red',textAlign:'center',fontWeight:'600'}}>{cupon.validado==true ? 'APROBADO' : 'PENDIENTE'}</td>
            </tr>
            <tr>
                <td>Comprobante</td>
                <td>{cupon.nro_comprobante ? cupon.nro_comprobante : '--'}</td>
            </tr>
            <tr>
                <td>Motivo</td>
                <td>{cupon.observaciones}</td>
            </tr>
            <tr>
                <td>Fecha de creación</td>
                <td>{cupon.f_alta} ({cupon.usuario})</td>
            </tr>
        </tbody>
      </table>
      {cupon.validado==false && <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'center'}}>
            <Alert severity="info">
                Para aprobar el cupón debe emitir el recibo correspondiente por la suma de ${cupon.importe}. Primero verifique que el dinero se acreditó.
                <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'center'}}>
                    <Textarea 
                            id="con-recibo" 
                            label="Número de recibo" 
                            value={recibo} 
                            onChange={handleChangeRecibo} 
                        />
                        </Box>
            </Alert>
        </Box>}

        {cupon.validado==true && <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'center'}}>
            <Alert severity="info">
                Para inhabilitar el cupón primero debe especificar un motivo.
                <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'center'}}>
                    <Textarea 
                        id="con-recibo" 
                        label="Motivo" 
                        value={motivo} 
                        onChange={handleChangeMotivo} 
                    />
                        </Box>
            </Alert>
        </Box>}

        {(recibo && recibo?.trim().length>3) && <Box sx={{marginTop:'1rem',marginBottom:'1rem',display:'flex',justifyContent:'center',flexDirection:'column'}}>  
               <Button onClick={actualizar} variant="contained" color='primary' size='large'>
                    {cupon.validado==true ? 'INHABILITAR' : 'APROBAR'}
               </Button>    
        </Box>}

        {(motivo && motivo?.trim().length>10) && <Box sx={{marginTop:'1rem',marginBottom:'1rem',display:'flex',justifyContent:'center',flexDirection:'column'}}>  
               <Button onClick={actualizar} variant="contained" color='primary' size='large'>
                    {cupon.validado==true ? 'INHABILITAR' : 'APROBAR'}
               </Button>    
        </Box>}

        <Dialogos open={verMas} 
                    closeHeader
                    titulo={`Más de talles del cupón ${cupon.nro_cupon}`} 
                    procesarCancelar = {()=>setVerMas(false)}
                   >
                   <MasDetalles cupon={cupon}/> 
        </Dialogos>

</Box>

}


const MasDetalles = ({cupon}) =>{
    return <div>
        <table>
            <tbody>
                {Object.entries(cupon).map(item=><tr>
                    <td>{item[0]}</td>
                    <td>{item[1]}</td>
                </tr>)}
            </tbody>
        </table>
    </div>
}