import { Button } from '@material-tailwind/react';
import * as XLSX from 'xlsx';
import {Axios} from '../Helpers/importacionesComunes'

const ExportToExcelAsamblea = ({ fileName,id_asamblea }) => {

  const exportToExcel = async () => {
    let dataExcel = [];

    const data = await buscarPresentes(id_asamblea);

    data.map(item => {
      let newData = {
        Nombre:  item.nombre,
        Categoría: item.categoria,
        Rango: item.rango,
        Región: item.region,
        Ubicación: item.ubicacion,
        ["Pastor delega"]:item.pastor_delegado,
        ["Iglesia delega"]: item.iglesia_delegado, 
        Observaciones: item.observaciones,
      }
      dataExcel.push(newData);
    })
    // Crear la hoja de cálculo desde un arreglo de datos
    const worksheet = XLSX.utils.json_to_sheet(dataExcel, { origin: 'A1' }); 
    
    // Ancho de columnas
    worksheet['!cols'] = [
      { wch: 30 },  
      { wch: 10 }, 
      { wch: 5, }, 
      { wch: 5 }, 
      { wch: 30 },  
      { wch: 30 }, 
      { wch: 30 }, 
      { wch: 30 },
      // Añade más columnas si es necesario
    ];

    // Crear un nuevo libro de trabajo
    const workbook = XLSX.utils.book_new();

    // Añadir la hoja al libro
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Descargar el archivo
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <Button variant="gradient" className="flex ml-2 items-center gap-3" onClick={exportToExcel}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-4 w-4/"
            >
            <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
            />
        </svg>
      Exportar Excel
    </Button>
  );
};

export default ExportToExcelAsamblea;

const buscarPresentes = async (id_asamblea)=>{
    try{
        const {data} = await Axios.get(`/api/tablasgenerales/asamblea/listadoc/${id_asamblea}`)
        return data
    }catch(err){
        console.log('Error al buscar los presentes en la asamblea en exportación a excel',err)
        return []
    }
}