import React, { useEffect, useState,useRef} from 'react';
import {usePaginacion,Paginacion,FontAwesomeIcon,PiePagina,Ventana} from '../../../Helpers/importacionesComunes'
import {faCircle,faCheckCircle} from '@fortawesome/free-regular-svg-icons';
import { faIdCard, faAt, faEdit, faSort} from '@fortawesome/free-solid-svg-icons';
import { IglesiasMinistro } from './IglesiasMinistro'
import  AbmObrero from '../../../abms/Abm-obrero'
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import {EstadisticasMail} from '../componentes/EstadisticasMail'
import {ListadoCelular} from '../componentes/ListadoCelular'
import Excel from '../../../componentes/Excel'

const columnas =    [{label:'Nombre',value:'nombre_obrero'},
{label:'Región',value:'id_region'},
{label:'Distrito',value:'id_distrito'},
{label:'Domicilio',value:'direccion'},
{label:'Provincia',value:'provincia'},
{label:'Localidad',value:'localidad'},
{label:'Email',value:'email'},
{label:'Teléfono',value:'telefono'},
{label:'Rango',value:'rango'},
{label:'Credencial',value:'tieneCredencial'},
{label:'Compromiso ministerial',value:'compromiso_ministerial'}]

export const Tabla= ({registrosAmostrar,
        reinicializar,
        resetearLista
    })=>{

const {configuracion,paginar,inicializar:inicializarPaginacion} = usePaginacion()
// Hago una copia de los datos que se reciben para poder modificar el listado a conveniencia
// y mostrar el listado en base a registrosAmostrar por ejemplo para mostrar los datos ordenados por diferentes criterios
const [copiaRegistrosaMostrar,setCopiaRegistrosaMostrar] = useState([...registrosAmostrar])
const [orden,setOrden]=useState(null)
const [nuevoCampo,setNuevoCampo]=useState(true)
const [contadorOrden,setContadorOrden]=useState(0)
const [obreroSeleccionadoCredencial,setObreroSeleccionadoCredencial] = useState(null);
const [obreroSeleccionado,setObreroSeleccionado] = useState(null)
const [obreroSeleccionadoEstadisticasMail,setObreroSeleccionadoEstadisticasMail] = useState(null)
const {usuario,esCelular} = useContextoGlobal()

useEffect(()=>{
    const data_mas_selector = registrosAmostrar.map((item)=>{return{...item,seleccion:false}})
    setCopiaRegistrosaMostrar([...data_mas_selector])   
    inicializarPaginacion(null,data_mas_selector)
},[registrosAmostrar])

useEffect(()=>{ // el ordenamiento se maneja con las propiedades orden que almacena el campo por el cual ordenar
                // y nuevoCampo que es un flag que indica si es otro campo o el mismo a la inversa
                // cada vez que se cambia el orden en click de la cabecera se cambia contadorOrden como un trigger 
    ordenarDatos()
},[contadorOrden])

const ordenarDatos = ()=>{
    setCopiaRegistrosaMostrar([...registrosAmostrar.sort((a,b)=>{return comparacion(a,b)})
    ])
}

const funcionOrden = (nombre_campo)=>{

    if (orden==nombre_campo){
        setNuevoCampo(false)
    }else{
        setNuevoCampo(true)
    }

    setOrden(nombre_campo)
    setContadorOrden(contadorOrden+1)

}

const comparacion = (a,b)=>{

    switch (orden){
        case null : return 0 
        case 'edad':
        case 'id_region' :
        case 'id_distrito':
   
        if(nuevoCampo==true){
                return a[orden]- b[orden]
            }else{
                if (contadorOrden%2==0){
                    return b[orden] - a[orden]
                }else{
                    return a[orden] - b[orden]
                }
            }
            case 'ult_ascenso':
            case 'f_solicitud':
    
                const dia_a = Number(a[orden].substring(0,2));
                const mes_a  = Number(a[orden].substring(3,5));
                const anio_a = Number(a[orden].substring(6,10));
    
                const fa = new Date(anio_a,mes_a,dia_a);
    
                const dia_b = Number(b[orden].substring(0,2));
                const mes_b  = Number(b[orden].substring(3,5));
                const anio_b = Number(b[orden].substring(6,10));
    
                const fb = new Date(anio_b,mes_b,dia_b);
    
                if(nuevoCampo==true){
                    return fa-fb
                }else{
                    if (contadorOrden%2==0){
                        return fb-fa
                    }else{
                        return fa-fb
                    }
                }        
        default : 

            if(nuevoCampo==true){
                return a[orden].localeCompare(b[orden])
            }else{
                if (contadorOrden%2==0){
                    return b[orden].localeCompare(a[orden])
                }else{
                    return a[orden].localeCompare(b[orden])
                }
            }
    }
 }

 const marcarTodo =()=>{
    const aux = copiaRegistrosaMostrar.map(item=>{return {...item,seleccion:true}})
    setCopiaRegistrosaMostrar(aux)
}

const desMarcarTodo =()=>{
    const aux = copiaRegistrosaMostrar.map(item=>{return {...item,seleccion:false}})
    setCopiaRegistrosaMostrar(aux)
}

const cambiarCheck =(e)=>{

    const aux = copiaRegistrosaMostrar.map(item=>{
        if (item.id_obrero!=e.target.value){
            return item
        }else{
            return {...item,seleccion:!item.seleccion}
        }
    })

    setCopiaRegistrosaMostrar(aux)
    
}

 const gestionarChecks = (marcarTodos)=>{

    if (marcarTodos){
        marcarTodo();
    }else{
        desMarcarTodo();
    }
}   

if(esCelular){
    return <>
    {obreroSeleccionado && <Ventana cerrar={()=>{setObreroSeleccionado(null)}}>
    <AbmObrero id_obrero={obreroSeleccionado ? obreroSeleccionado : null} 
            esModal={true}
            usuario={usuario} finalizarAltaOcopia ={reinicializar}/>
    </Ventana>}
    <ListadoCelular datos={copiaRegistrosaMostrar} funcionSeleccion={setObreroSeleccionado}/>
    </>
}

return <>
   {copiaRegistrosaMostrar.length > 0 && <Excel  datos={copiaRegistrosaMostrar} 
                    descripcion='Ministros'
                    columnas={columnas}
                    titulo='Ministros' />}
    <div className="flex f-col centro-w300 mt-4 res-lista">
        <h1>{obreroSeleccionado}</h1>
        <div>
            <span className="text-xl">{copiaRegistrosaMostrar.length}</span><span className="text-large">{copiaRegistrosaMostrar.length== 1 ? ` registro encontrado`:` registros encontrados`}</span> 
        </div>
        <Paginacion configuracion={configuracion} paginar={paginar}/>
    </div>
    {copiaRegistrosaMostrar.length > 0 && 
        <table className='table mt-8 table-cent' style={{tableLayout:'fixed'}}>
            <thead className="text-center fw-700">
                <tr>
                    <th>
                        <ThTitle title='Nombre' sort orden={orden==='nombre_obrero'} callback={()=>funcionOrden('nombre_obrero')}/>
                    </th> 
                    <th>
                        <ThTitle title='Usuario' sort orden={orden==='nombre_obrero'} callback={()=>funcionOrden('nombre_obrero')}/>
                    </th> 
                    <th>
                        <ThTitle title='Región' sort orden={orden==='nombre_obrero'} callback={()=>funcionOrden('nombre_obrero')}/>
                    </th> 
                    <th>
                        <ThTitle title='Tipo' sort orden={orden==='nombre_obrero'} callback={()=>funcionOrden('nombre_obrero')}/>
                    </th> 
                    <th>
                        <ThTitle title='Estado' sort orden={orden==='nombre_obrero'} callback={()=>funcionOrden('nombre_obrero')}/>
                    </th> 
                </tr>
               
            </thead>
            <tbody>
            {
                    copiaRegistrosaMostrar
                    .map((item,index)=>{return {...item,indice:index+1}})
                    .filter((item,index)=>{
                        return index>= configuracion.iIni && index<=configuracion.iFin
                    })
                    .map(ministro => {
                    return (
                    <> 
                        <tr  className="border-bottom-solid"  key={ministro.id_obrero}>
                            <td>{ministro.nombre}</td>
                            <td>{ministro.nombre_usuario}</td>
                            <td>{ministro.id_region}</td>
                            <td>{ministro.sede_admin ? 'Sede' : 'Región'}</td>
                            <td>{ministro.estado}</td>
                        </tr>
                    </>
            )})}
            </tbody>
        </table>}
        {copiaRegistrosaMostrar.length > 0 && 
                <PiePagina>
                    <div className="flex f-col items-center">
                        <Paginacion configuracion={configuracion} paginar={paginar}/>
                    </div>
                </PiePagina>}
    </>


return <>
        <div className="flex f-col centro-w300 mt-4 res-lista">
            <div>
                <span className="text-xl">{copiaRegistrosaMostrar.length}</span><span className="text-large">{copiaRegistrosaMostrar.length== 1 ? ` registro encontrado`:` registros encontrados`}</span> 
            </div>
            <Paginacion configuracion={configuracion} paginar={paginar}/>
        </div>
        {copiaRegistrosaMostrar.length > 0 && <table className="table mt-10 table-cent">
            <thead>
            <tr>
                <td>
                    <a onClick={()=>gestionarChecks(true)} 
                        title="Marcar todos para recibir un mail" 
                        className="tdec-none cursor-pointer ml-2 color-63 ">
                        <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faCheckCircle}/> 
                    </a> 

                    <a onClick={()=>gestionarChecks(false)} 
                        title="Desmarcar todos para recibir un mail" 
                        className="tdec-none cursor-pointer ml-2 mr-2 color-63 ">
                        <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faCircle}/> 
                    </a> 
                </td>
                <td className="color-63 fw-x  text-small fw-700 td-ministros">
                    <span className={ orden == 'nombre_obrero' ? "filas-lista-nwx p-2 cursor-pointer ti-nombre orden-activo" : "filas-lista-nwx p-2 cursor-pointer ti-nombre"}
                    onClick={()=>funcionOrden('nombre_obrero')}>
                            Nombre
                    </span>     
                    <span title="Región" className='filas-lista-nwx p-2 ti-idregion cursor-pointer'>Reg</span>
                    {/*<span className={ orden == 'edad' ? "filas-lista-nwx p-2 ti-edad cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-edad cursor-pointer"} onClick={()=>funcionOrden('edad')}>Edad</span>*/}
                    <span className={ orden == 'direccion' ? "filas-lista-nwx p-2 ti-domicilio cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-domicilio cursor-pointer"} onClick={()=>funcionOrden('direccion')}>Dirección</span>
                    <span className={ orden == 'provincia' ? "filas-lista-nwx p-2 ti-provincia-alt cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-provincia-alt cursor-pointer"}
                    onClick={()=>funcionOrden('provincia')}>Provincia</span>
                    <span className={ orden == 'localidad' ? "filas-lista-nwx p-2 ti-localidad cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-localidad cursor-pointer"}
                    onClick={()=>funcionOrden('localidad')}>Localidad</span>                        
                    <span className={ orden == 'email' ? "filas-lista-nwx p-2 ti-email cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-email cursor-pointer"} onClick={()=>funcionOrden('email')}>Email</span>
                    <span className={ orden == 'telefono' ? "filas-lista-nwx p-2 ti-telefono cursor-pointer orden-activo" : "filas-lista-nwx p-2 ti-telefono cursor-pointer"} onClick={()=>funcionOrden('telefono')}>Teléfono</span>
                    {/*<span className={ orden == 'ministerio' ? "filas-lista-nwx ti-tipo cursor-pointer orden-activo" : "filas-lista-nwx ti-tipo cursor-pointer"} onClick={()=>funcionOrden('ministerio')}>Ministerio</span>*/}
                    <span className={ orden == 'rango' ? "filas-lista-nwx cursor-pointer ti-rango orden-activo" : "filas-lista-nwx ti-rango cursor-pointer"} onClick={()=>funcionOrden('rango')}>Rango</span>
                    <span className={ orden == 'credencial' ? "filas-lista-nwx cursor-pointer ti-credencial orden-activo" : "filas-lista-nwx ti-credencial cursor-pointer"} onClick={()=>funcionOrden('tieneCredencial')}>Credencial Activa</span>
                </td>
                <td>

                </td>
            </tr>
            </thead>
            <tbody className="body-list">
            {
            copiaRegistrosaMostrar
            .map((item,index)=>{return {...item,indice:index+1}})
            .filter((item,index)=>{
            return index>= configuracion.iIni && index<=configuracion.iFin
            })
            .map(curso => {
            return (
            <>
            <tr className="border-bottom-solid cursor-pointer">
                 <td className="text-center"><input value={curso.id_obrero} 
                    checked={curso.seleccion} 
                    onChange={(e)=>cambiarCheck(e)} type="checkbox" 
                    title="Marque o desmarque para que ésta persona reciba un mail"/>
                </td>
                <td onClick={()=>{setObreroSeleccionado(curso.id_obrero)}} key={curso.id_obrero} >
                    <div>
                        
                        <span className="filas-lista-nw ti-nombre" >
                                {curso.nombre_obrero}
                        </span>         
                        <span title={`Región ${curso.id_region}`} className="filas-lista-nw ti-idregion">{curso.id_region}</span>
                        {/*<span className="filas-lista-nw ti-edad">{curso.edad}</span>*/}
                        <span className="filas-lista-nw ti-domicilio">{curso.direccion}</span>
                        <span className="filas-lista-nw ti-provincia-alt">{curso.provincia}</span>
                        <span className="filas-lista-nw ti-localidad">{curso.localidad}</span>
                        <span className="filas-lista-nw ti-email">{curso.email}</span>
                        <span className="filas-lista-nw ti-telefono">{curso.telefono}</span>
                        <span className="filas-lista-nw ti-rango">{curso.rango}<p className="text-xxsmall" title="Fecha de último ascenso">{curso.ult_ascenso}</p></span>
                        <span title={curso.tieneCredencial ? 'Credencial activa' : 'Credencial inactiva'} className="filas-lista-nw ti-credencial">{curso.tieneCredencial}</span>
                    </div>
                </td>
                <td>
                    <a onClick={()=>setObreroSeleccionadoCredencial(curso)} 
                        title="Verificar condición para activar la credencial" 
                        className="tdec-none cursor-pointer ml-2 mr-2 color-63 text-large">
                        <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faIdCard}/> 
                    </a> 
                    <a onClick={()=>{setObreroSeleccionadoEstadisticasMail(curso)}} 
                        title="Ver las estadísticas de envíos de mail" 
                        className="tdec-none cursor-pointer ml-2 mr-2 color-63 text-large">
                        <FontAwesomeIcon className="cursor-pointer color-tomato" icon={faAt}/>
                    </a> 
                </td>
            </tr>
            {curso.id_obrero==obreroSeleccionadoCredencial?.id_obrero && <Ventana estiloMas={{width:'500px'}} titulo={'Iglesias del ministro'} cerrar={()=>setObreroSeleccionadoCredencial(null)}>
                        <IglesiasMinistro ministro={curso}/>
                </Ventana>}
            {curso.id_obrero==obreroSeleccionado && <Ventana  cerrar={()=>{setObreroSeleccionado(null);resetearLista({respetarFiltros:true})}}>
                <AbmObrero id_obrero={obreroSeleccionado ? obreroSeleccionado : null} 
                        usuario={usuario} esModal={true} finalizarAltaOcopia = {()=>reinicializar()}/>
            </Ventana>} 
            {curso.id_obrero==obreroSeleccionadoEstadisticasMail?.id_obrero && <Ventana titulo={'Estadísticas de mails'} cerrar={()=>setObreroSeleccionadoEstadisticasMail(null)}>
                <EstadisticasMail obrero={obreroSeleccionadoEstadisticasMail} 
                       finalizar={()=>setObreroSeleccionadoEstadisticasMail(null)}
                       esModal={true} usuario={usuario}/> 
            </Ventana>}       
            </>
            )})}
            </tbody>
        </table>}
        {copiaRegistrosaMostrar.length > 0 && <PiePagina>
            <div className="flex f-col items-center">
                <Paginacion configuracion={configuracion} paginar={paginar}/>
            </div>
        </PiePagina>}
    </>

}

const ThTitle = ({title,sort,orden,callback})=>{
    return <div title={`Ordenar por ${title}`} className='flex f-col justify-center items-center'>
     <span>{title}</span>
    {sort && <FontAwesomeIcon className={`cursor-pointer ${orden ? 'color-red' : ''}`} icon={faSort} onClick={callback}/>}
    </div>   
}
