import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Logo from '../Logo'
import {
  Bars3Icon
} from "@heroicons/react/24/solid";
import { useContextoGlobal } from '../../Context/contextoGlobal';

export const MenuAppBar = ({usuario})=> {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { mostrarMenu, esCelular } = useContextoGlobal();

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className='w-full ml-0 mr-0 lg:ml-6 lg:mr-6'>
      <AppBar 
        position="static" 
        className='py-2 !shadow-none !bg-white border-b-[2px] border-b-[#ccc] lg:border-[2px] lg:border-white lg:rounded-md lg:!shadow-md lg:mt-4'
      >
        <Toolbar sx={{justifyContent:'space-between'}}>
         
          <Box className="flex flex-row pl-1">
            <Bars3Icon className="flex 4xl:hidden relative h-6 w-6 text-[#111] mr-4"  onClick={() => { mostrarMenu(); }}/>
           {!esCelular ? <div className='-mt-2'>
              <Logo width={75}/>
            </div> : null}
          </Box>
          <div>
          </div>
          <div className="hidden lg:flex lg:flex-col lg:text-right !text-[#2196f3]">
            <Typography variant="h5" className="w-full">
              Bienvenido
            </Typography>
            <Typography variant="h5" className="w-full !font-bold">
              {`${usuario.nombre}`}
            </Typography>
          </div>
          {esCelular ? <div className='relative bottom-1.5'>
            <Logo width={70}/>
          </div> : null}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

