//import ReactExport from "react-export-excel";
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

/*const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;*/

export default function Excel({datos,descripcion,columnas,titulo}){

    //const copia = [agregarFila(datos,descripcion),...datos]
        
       return '' /*<ExcelFile filename={titulo} element={
            <span className="cursor-pointer botonNc ml-6 text-large" >
                <FontAwesomeIcon className="color-tomato" icon={faFileExcel}/> Exportar a Excel
            </span> 
        }>
            <ExcelSheet data={datos} name={descripcion}>
                {columnas.map(item=>{
                     return <ExcelColumn label={item.label} value={item.value}/>
                })}
            </ExcelSheet>
    </ExcelFile>*/
    }
    
    function agregarFila(datos,descripcion){
        return {...datos[0],
                rubro:descripcion,
                mes_largo:null,
                año:null,
                egreso_numerico:null,
                codigo_rubro:null}
    }