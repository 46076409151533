import React, { useState, useEffect, useRef } from 'react';
import { Fecha } from '../../../componentes/Fecha'
import { esMayor, fechasEstaIncluida } from '../../../Helpers/fechas'
import { TextField, Box } from "@material-ui/core";
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Option, Select, Typography } from '@material-tailwind/react';
import ImportFromExcel from '../../../componentes/ImportReadExcel';

export const SucursalBancaria = ({ setCrearIglesia,
    toggle,
    filtrarLista,
    usuario,
    regiones,
    cuentas,
    handleDataChanges,
    dataValues,
    processedData,
    setProcessedData,
    handleChangeSelectRegion,
}) => {

    const [textoSucursal, setTextoSucursal] = useState("");
    const [textoConcepto, setTextoConcepto] = useState("");
    const { esCelular } = useContextoGlobal();

    useEffect(() => {

        const callback = (item) =>
            item.desc_sucursal.toUpperCase().includes(textoSucursal.toUpperCase())
            && item.concepto.toUpperCase().includes(textoConcepto.toUpperCase())
            && fechasEstaIncluida(dataValues.fecha_desde, dataValues.fecha_hasta, item.fecha)

        // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        if (esMayor(dataValues.fecha_desde, dataValues.fecha_hasta)) {
            alert('El orden de las fechas es incorrecto')
        } else {
            filtrarLista(callback)
        }

    }, [textoSucursal,
        textoConcepto, dataValues.fecha_desde, dataValues.fecha_hasta])
    
    const handleChangeInputSucursal = (e) => {

        setTextoSucursal(e.target.value)

    }

    const handleChangeInputConcepto = (e) => {

        setTextoConcepto(e.target.value)

    }

    const limpiarSucursal = () => {

        setTextoSucursal("")

    }

    const handleStatusChange = (e) => {
        console.log(e);
        handleDataChanges(e, 'estado')
    }

    const handleCuentaChange = (e) => {
        const cuenta = cuentas.find(item => item.id_cuenta_bancaria === e);
        handleDataChanges(e, 'id_cuenta_bancaria');
        handleDataChanges(cuenta.id_banco, 'id_banco');
    }

    return <div className='flex f-col mt-10 min-h-[100px] m-auto'>
        <div className={`flex ${esCelular ? 'f-col space-y-2' : 'f-row space-x-4'} justify-center flex-wrap abc`}>
            <SeleccionadorXLabel titulo='Cuenta Bancaria'
                vector={cuentas.map(item => ({ id_region: item.id_cuenta_bancaria, nombre: item.descripcion }))}
                onchange={handleCuentaChange}
                claves={{ id: 'id_region', nombre: 'nombre' }}
                valor={dataValues.id_cuenta_bancaria}
                nombre='Seleccione una cuenta'
            />

          <div className='flex flex-col'>
            <Typography
              variant="small"
              className="font-medium mb-4"
            >
              Cargar Movimientos de cuentas
            </Typography>
            <ImportFromExcel processedData={processedData} setProcessedData={setProcessedData} />
          </div>
        </div>
    </div>
}

const SeleccionadorXLabel = ({ vector, onchange, valor, nombre, limpiar, claves, titulo, noDefault }) => {

    return <div className='select-ui-data limit-select-h'>
        <Typography
            variant="small"
            className="mb-1 font-medium "
        >
            {titulo}
        </Typography>
        <Select
            label="Seleccione una opción"
            value={valor}
            onChange={onchange}
            className='h-14'
        >
            {vector.map((item, index) => (
                <Option key={index} value={item.id_region}>
                    {item.nombre}
                </Option>
            ))}
        </Select>
    </div>
}




