import React, { useState, useEffect } from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import { Grid } from "@material-ui/core";
import { Spinner, Card, Typography, Input, IconButton, Tooltip, DialogFooter, Button, DialogBody, DialogHeader, Dialog } from '@material-tailwind/react';
import { MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import ExportToExcelNacionalidades from '../componentes/exportNacionalidades';

export default function TipoComprobante({ usuario }) {

    const [comprobantesList, setComprobantesList] = useState([])
    const [nameSearch, setNameSearch] = useState('')
    const [name, setName] = useState('')
    const [comprobanteId, setComprobanteId] = useState(0)
    const [buscandoDatos, setBuscandoDatos] = useState(false)
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        obtenerArchivos()
    }, [])

    useEffect(() => {
        if (nameSearch.trim() === '') {
            setComprobantesList(comprobantesList);
        } else {
            const listaFiltrada = comprobantesList.filter(item =>
                item.nombre.toLowerCase().includes(nameSearch.toLowerCase())
            );
            setComprobantesList(listaFiltrada.length > 0 ? listaFiltrada : comprobantesList);
        }
    }, [nameSearch, comprobantesList]);


    const handleOpen = () => setOpen(!open);

    const obtenerArchivos = async () => {
        setBuscandoDatos(true);
        try {
            const resultList = await Promise.all([Axios.get(`/api/tablasgenerales/tipocomprobantesegr`)])
            setComprobantesList(resultList[0].data)
            setBuscandoDatos(false);
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const verComprobanteSeleccionada = async (e) => {
        try {
            const comprobante = await Promise.all([Axios.get(`/api/tablasgenerales/tipocompegr/${e.id_tipo_comp_egr}`)])
            const comprobanteSeleccionada = comprobante[0].data.recordset[0];
            setComprobanteId(comprobanteSeleccionada.id_tipo_comp_egr);
            setName(comprobanteSeleccionada.nombre);
            handleOpen();
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const borrarComprobante = async (e) => {
        Swal.fire({
            title: "Esta seguro que desea Eliminarlo?",
            text: "Este cambio es irreversible!",
            type: "warning",
            confirmButtonText: 'Si, eliminar!',
            cancelButtonText: "No, cancelar!",
            showCancelButton: true,
            // allowOutsideClick: false
        }).then(
            async resultado => {
                if (resultado.value) {
                    try {
                        await Promise.all([Axios.delete(`/api/tablasgenerales/tipocompegr/${e.id_tipo_comp_egr}`)]);
                        obtenerArchivos();
                    } catch (error) {
                        console.log(error)
                        setBuscandoDatos(false);
                    }
                }
            }
        )

    }

    const editarComprobante = async () => {
        const datos = {
            nombre: name,
        }

        try {
            await Promise.all([Axios.put(`/api/tablasgenerales/tipocompegr/${comprobanteId}`, datos)])
            Swal.fire({
                icon: 'alert',
                title: `<p>Los datos han sido actualizados con éxito</p>`,
            })
            handleOpen();
            obtenerArchivos();
        } catch (error) {
            console.log(error)
        }
    }


    const limpiarBusqueda = async () => {
        obtenerArchivos();
        setNameSearch('')
    }


    if (buscandoDatos) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando archivos...</span>
            </div>
        </Main>
    }

    if (comprobantesList.length == 0) {
        return <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '400px', textAlign: 'center' }} >
            <Grid item>
                <Typography variant="h5">
                    No se encontraron archivos
                </Typography>
            </Grid>
        </Grid>
    }

    return <div className='w-full flex flex-col justify-center items-center lg:!m-6 xl:!m-6'>
        <Typography variant="h4" className="flex ">
            Tipo de Comprobantes de Egresos
        </Typography>
        <Card className='w-full justify-center items-center flex p-5 justify-left mt-4'>
            <div className='w-full lg:w-96 xl:w-96'>
                <Input
                    type="text"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    label="Buscar nombre"
                    value={nameSearch}
                    onChange={(e) => { setNameSearch(e.target.value) }}
                    color='black'
                />
            </div>
            {comprobantesList.length > 0 && <div className='mt-8 w-full'>
               <div className="flex xl:!justify-end">
                    {nameSearch &&  <Button variant='text' className='text-red-400 bg-red-100 hover:bg-red-200' onClick={limpiarBusqueda}>Limpiar Busqueda</Button>}
                    <ExportToExcelNacionalidades data={comprobantesList} fileName="Listado de Tipo de Comprobantes de Egresos"/>
                </div>
                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none"
                                >
                                    Se han encontrado {comprobantesList.length} Comprobantes
                                </Typography>
                <table className="w-full min-w-max table-auto text-left mt-2">
                    <thead>
                        <tr>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Nombre
                                </Typography>
                            </th>
                            <th
                                className="border-b flex justify-end border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Acciones
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {comprobantesList.map((item, index) => {
                            const isLast = index === comprobantesList.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                            return (
                                <tr key={index}>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </td>
                                    <td className="p-4 flex justify-end border-b border-blue-gray-50">
                                        <div className="flex items-center gap-2">
                                            <Tooltip content="Ver Detalles">
                                                <IconButton variant="text" size="sm" onClick={() => verComprobanteSeleccionada(item)}>
                                                    <PencilIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip content="Eliminar">
                                                <IconButton variant="text" size="sm" onClick={() => borrarComprobante(item)}>
                                                    <TrashIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>}
        </Card>
        <Dialog open={open} handler={handleOpen}>
            <DialogHeader>Unión de las Asambleas de Dios</DialogHeader>
            <DialogBody className="xl:p-10">

                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Nombre
                    </Typography>
                    <Input
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        value={name}
                        type='text'
                        id='name'
                        name='name'
                        maxLength={200}
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
            </DialogBody>
            <DialogFooter className="space-x-2">
                <Button variant="gradient" color="blue" onClick={editarComprobante}>
                    Guardar
                </Button>
                <Button variant="text" color="blue-gray" onClick={handleOpen}>
                    Cancelar
                </Button>
            </DialogFooter>
        </Dialog>
    </div>
}

