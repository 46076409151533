import React, { useState, useEffect, useRef } from 'react';

export const TotalImportes = ({ registros, callbackQuitarNulos, campoImporte, campoNumerico }) => {

      // este algoritmo es complejo porque hay que convertir los montos que llegan como strings en formato "xx,xxx.xx" y no se pueden sumar
      // para poder sumarlos primero hay que remover la coma y dejar el numero plano con los 2 decimales que ya trae con punto decimal 
      // luego con parseFloat lo transfomamos de string a número
      const registros_sin_null = registros.filter(callbackQuitarNulos)

      /*
      const nuevo_registro = registros_sin_null.map(item=>{return {...item,[campoImporte]:parseFloat(item[campoImporte].replace(",",""))}})
      // luego sumamos
  
      const total = nuevo_registro.reduce((total,item)=>{return total+item[campoImporte]},0)
      */

      const total = registros_sin_null.reduce((total, item) => { return total + item[campoNumerico] }, 0)


      let ingresos = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(total)
      var ingresosFormat = ingresos.replace(/[€]/g, '');

      // luego mostramos el total con un un patron (tomado de stackoverflow) para formatear el importe a un tipo moneda

      return <div className="text-center text-xl mt-2 fw-100 font-bold">
            <p className="mt-2">Total: $<span className='ml-2'>{ingresosFormat}</span></p>
      </div>

}