import React, {useContext,useEffect,useState} from 'react'
import {TextField, CircularProgress, Box ,Grid,makeStyles,Badge,Backdrop,IconButton} from "@material-ui/core";
import Axios from 'axios'
import { tramitesContext } from '../contexto/TramitesContext'
import {gql,useQuery,useMutation} from '@apollo/client'
import {useContextoGlobal} from '../../../Context/contextoGlobal';
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import MessageIcon from '@mui/icons-material/Message';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ThreePIcon from '@mui/icons-material/ThreeP';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {transformarFechaTramiteCompleta,transformarFechaTramiteSimple} from '../../../Helpers/fechas'
import {eliminarArchivos, hacerFoco,doPostFile} from '../../../Helpers/utilidades-globales'
import Alert from '@mui/material/Alert';
import Swal from 'sweetalert2';
import { Acciones } from './Acciones';
import { InfoNuevoT1 } from '../componentes/modulos/InfoNuevoT1';
import { InfoAfiliacion as InfoAfiliacion3} from '../componentes/modulos/InfoAfiliacion';
import { InfoAnexo as InfoAnexo3 } from '../componentes/modulos/InfoAnexo';
import { InfoAscenso as InfoAscenso3} from '../componentes/modulos/InfoAscenso';
import { InfoAutonomia as InfoAutonomia3 } from '../componentes/modulos/InfoAutonomia';
import { InfoIglFormacion as InfoIglFormacion3 } from '../componentes/modulos/InfoIglFormacion';
import { InfoMinistro as InfoMinistro3 } from '../componentes/modulos/InfoMinistro';
import {puedeEditarse,
    mensajeSegunCambios,
    condicionesCumplicadasParaElEstado,
    estadosPosiblesConComentarios,
    mapearTipo,
    tratamientodeAprobacion,
    grabarAprobacionAfiliacion,
    grabarAprobacionNuevoMinistro,
    grabarAprobacionIglFormacion,
    grabarAprobacionAnexo,
    grabarAprobacionAutonomia,
    grabarAprobacionAscenso,objestados,
    esUnRechazo,
    esReversionDeEstado} from '../funcionesGenerales';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { Button, Input, Spinner, Typography } from '@material-tailwind/react';
import Main from '../../../componentes/Main';
import Ventana from '../../../componentes/Ventana';

const regex_solo_numeros = /[0-9]+$/;

export const Tramite = ({id_tramite,finalizar})=>{

  const {usuario,snackbar} = useContextoGlobal();
  const [loadingFile,setLoadingFile] = React.useState(false);

  let adjuntos = [];

const GET_LISTADO = gql`
query listado ($codigo:String) {
    tramiteInfo(codigo: $codigo){
      _id,
      tipo,
      region,
      fecha,
      distrito{
        id,
        nombre
      },
      ministro{
        nombre,
        id
      }
      status{
        texto,
        fecha,
        usuario{
            nombre
        }
      },
      codigo_web,
      descripcion,
      observaciones{
        texto,
        fecha,
        usuario{
            nombre
        }
      },
      archivado,
      eliminado,
      respuesta{
        texto,
        fecha,
        leido,
        fechaLeido,
        usuario{
            nombre
        }
      }
    }
    }

`;

/* atención podría pasar a una matation de graphql un objeto como parametro pero para ello
hay que crear un type input con GraphQLInputObjectType
*/

const ELIMINAR_TRAMITE = gql`
    mutation eliminarTramite($id:ID!,
                      $usuario:String!,
                      $id_usuario:Int!,
                      $observaciones:String!){
        eliminarTramite(
            id:$id,
            usuario:$usuario,
            id_usuario:$id_usuario,
            observaciones:$observaciones,
        )
    }
`
const MODIFICAR_TRAMITE = gql`
mutation updateTramiteV2($id:ID!,
                      $region:Int,
                      $distrito:String,
                      $distrito_id:Int,
                      $usuario:String!,
                      $usuario_id:Int!,
                      $status:String,
                      $respuesta:String,
                      $observaciones:String,){
                          updateTramiteV2(
                                id:$id,
                                usuario:$usuario,
                                id_usuario:$usuario_id,
                                region:$region,
                                distrito:$distrito,
                                distrito_id:$distrito_id,
                                respuesta:$respuesta,
                                observaciones:$observaciones,
                                status:$status
                            )}`;


   const {loading,error,data} = useQuery(GET_LISTADO,{variables:{codigo:id_tramite},
    onError:(err)=>alert(err.message)})
   
    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

  const [updateTramiteV2,{data:data2,loading:loading2}] = useMutation(MODIFICAR_TRAMITE)

  const [eliminarTramite,{data:data3,loading:loading3}] = useMutation(ELIMINAR_TRAMITE)

 const eliminarUnTramite  = async (datos)=>{

    try{
        const {data} = await eliminarTramite({variables:{id:datos.id,
                                                usuario:datos.usuario,
                                                id_usuario:datos.id_usuario,
                                                observaciones:datos.observaciones},
                                                refetchQueries:[GET_LISTADO],
                                                onCompleted:()=>finalizar(true)
                                            })
        snackbar.showMessage(data.eliminarTramite)
    }catch(err){
        console.log(JSON.stringify(err))
        console.log(err.message) // Response not successful: Received status code 500
 
        if(err?.networkError?.result?.errors){
         snackbar.showMessage(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''),'error')
        }else{
         snackbar.showMessage(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.','error')
        }
    }
 }



 const iniciarEliminacion = async (datos)=>{

 // NO PUDE IMPLEMNETAR EL SWAL CON INPUT PORQUE QUEDA DEBAJO DEL ALERT DE MUI Y NO SE PUEDE
 // EDITAR EL INPUT, Hay que ver como ponerlo en primer plano.
 // Provisoriamente dejo eliminar sin observaciones...

    /*const { value: observacion ,isConfirmed,isDismissed } = await Swal.fire({
        html: '<p>Por favor ingrese una observación que explique el motivo de la eliminación</p>',
        width: 400,
        input: 'text',
        inputLabel: 'Observación',
        inputPlaceholder: 'Observación',
        showCancelButton: true,
        customClass:{input:'input-rec-id'},
        inputAttributes: {
          maxlength: 10,
          autocapitalize: 'off',
          autocorrect: 'off'
        }})

            
        if (isConfirmed){
            if (observacion){
                eliminarUnTramite({...datos,observacion})
                .then((resultado)=>{
                    Swal.fire({
                        icon: 'alert',
                        title: 'EEEEEE',
                        html: `<p>${JSON.stringify(resultado)}</p>`,
                    })
                })
                .catch(err=>{
                    Swal.fire({
                        icon: 'error',
                        text: err
                    })
                })
            }else{
                Swal.fire({
                    icon: 'error',
                    html: '<p>Para eliminar un trámite ingrese una obsevación que explique el motivo de la eliminación</p>',
                  })
            }       
        }*/

    Swal.fire({
        text:"Por favor confirme la eliminación del trámite",
        showCancelButton:true,
        confirmButtonText:'Eliminar',
        cancelButtonText:'Cancelar'
    }).then(
        resultado=>{
            if (resultado.value){
                eliminarUnTramite(datos);
            }else{
                console.log("Se canceló la operación de la solicitud")
            }
        }
    )

}

  const grabar = async (values)=>{

    try{
       const {data} = await updateTramiteV2({variables:{...values,
                                                        usuario:usuario.nombre,
                                                        usuario_id:usuario.id_usuario,
                                                        id:id_tramite},
                                                        refetchQueries:[GET_LISTADO],
                                                        onCompleted: (result) => {
                                                            if(values.ascenso){ // solo viene este objeto cuando se aprueba
                                                                grabarAprobacionAscenso(values.ascenso)
                                                                .catch(err=>{
                                                                    snackbar.showMessage(err.message,'error')
                                                                })  
                                                            }

                                                            if(values.autonomia){ // solo viene este objeto cuando se aprueba
                                                                grabarAprobacionAutonomia(values.autonomia)
                                                                .catch(err=>{
                                                                    snackbar.showMessage(err.message,'error')
                                                                })  
                                                            }
                                                           
                                                            if(values.anexo){ // solo viene este objeto cuando se aprueba
                                                                grabarAprobacionAnexo(values.anexo)
                                                                .catch(err=>{
                                                                    snackbar.showMessage(err.message,'error')
                                                                })  
                                                            }

                                                            if(values.iglesiaFormacion){ // solo viene este objeto cuando se aprueba
                                                                grabarAprobacionIglFormacion(values.iglesiaFormacion)
                                                                .catch(err=>{
                                                                    snackbar.showMessage(err.message,'error')
                                                                })  
                                                            }

                                                            if(values.nuevo_ministro){ // solo viene este objeto cuando se aprueba
                                                                grabarAprobacionNuevoMinistro(values.nuevo_ministro)
                                                                .catch(err=>{
                                                                    snackbar.showMessage(err.message,'error')
                                                                })  
                                                            }

                                                            if(values.afiliacion){ // solo viene este objeto cuando se aprueba
                                                                grabarAprobacionAfiliacion(values.afiliacion)
                                                                .catch(err=>{
                                                                    snackbar.showMessage(err.message,'error')
                                                                })  
                                                            }
                                                          },})
       return true // para el formulario formik asi luego de cambiar de estado puede resetarse
   }catch(err){
       console.log(JSON.stringify(err))
       console.log(err.message) // Response not successful: Received status code 500

       if(err?.networkError?.result?.errors){
        snackbar.showMessage(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''),'error')
       }else{
        snackbar.showMessage(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.','error')
       }

       return false
   }

  }

 useEffect(()=>{
 },[data])

    if(loading2 || loading){
       return  <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando...</span>
                    </div>
                </Main>
    }

    return <Grid container spacing={2}>
      
    <Grid item xs={12}>
        <InfoTramite id={data.tramiteInfo._id} 
                    cabecera={data.tramiteInfo} 
                    usuario={usuario}
                    dataTramite={data} 
                    grabar={grabar}
                    eliminar={iniciarEliminacion}
                    snackbar={snackbar}
                    finalizar={finalizar}/>   
    </Grid>
   
  </Grid>
}


const HistorialStatus = ({vector})=>{
  
    const copia = [...vector]

    const arrayordenado = copia.sort((a,b)=>Number(b.fecha)-Number(a.fecha))

    

  if (!vector || vector?.length==0){
    return null
  }

    return <div>
      <h5>Status</h5>
      <table>
          <tbody>
              {arrayordenado.map(item=><tr>
                <td>{transformarFechaTramiteCompleta(Number(item.fecha))}</td>
                  <td>{item.texto}</td>
                  <td>{item.usuario.nombre}</td>
              </tr>)
            }
          </tbody>
      </table>
    </div>  
}

const HistorialObservaciones = ({vector,label})=>{

    const copia = [...vector]

    const arrayordenado = copia.sort((a,b)=>Number(b.fecha)-Number(a.fecha))

    
  if (!vector || vector?.length==0){
    return null
}

  return <div>
      {label && <h5>{label}</h5>}
      {!label && <h5>Observaciones</h5>}
      <table>
          <tbody>
              {arrayordenado.map(item=><tr>
                  <td>{transformarFechaTramiteCompleta(Number(item.fecha))}</td>
                  <td>{item.texto}</td>
                  <td>{item.usuario.nombre}</td>
              </tr>)
            }
          </tbody>
      </table>
    </div>  
  
}

const HistorialRespuestas = ({vector,label})=>{

if (!vector || vector?.length==0){
    return null
}

// Debo hacer una copia para ordenar porque al tratar de ordenar directamente vector da un error
// esto ---> vector.sort((a,b)=>Number(b.fecha)-Number(a.fecha))
//genera este error ----> Cannot assign to read only property '0' of object '[object Array]'
// parece que fuera inmutable y sort lo modifica, por eso hago una copia, la ordeno y la renderizo en lugar de renderizar directamente vector

const copia = [...vector]

const arrayordenado = copia.sort((a,b)=>Number(b.fecha)-Number(a.fecha))

  return <div>
      {label && <h5>{label}</h5>}
      {!label && <h5>Respuestas</h5>}
      <table>
          <tbody>
              {arrayordenado.map(item=><tr>
                  <td>{transformarFechaTramiteCompleta(Number(item.fecha))}</td>
                  <td>{item.texto}</td>
                  <td>{item.usuario.nombre}</td>
                  <td>{item?.leido ? <CheckCircleIcon title="Leído" sx={{color:'green'}}/> : <HighlightOffIcon title="No leído" sx={{color:'red'}}/>}</td>
                  <td>{item?.leido? `Visto el: ${transformarFechaTramiteSimple(Number(item?.fechaLeido))}` : ''}</td>
              </tr>)
            }
          </tbody>
      </table>
    </div>  
  
}

const InfoTramite = ({id,cabecera,usuario,finalizar,dataTramite,grabar,snackbar,eliminar})=>{

const status_actual_tramite = cabecera.status[cabecera.status.length-1].texto;

const [pedirInfoAdicional,setPedirInfoAdicional] = useState(null)
const [cambiarStatus,setCambiarStatus]=useState(null)
const [observaciones,setObservaciones] = useState(null)
const [respuesta,setRespuesta] = useState(null)
const [dataDetalle,setDataDetalle] = useState(null)

useEffect(()=>{
    if(!cambiarStatus || !pedirInfoAdicional){
        setObservaciones(null)
        setRespuesta(null)
    }
},[cambiarStatus,pedirInfoAdicional])

const estadosX = estadosPosiblesConComentarios(cabecera,usuario)

const iniciarGrabarStatusPlus = (objetoInfoAdicional,observaciones,respuesta)=>{

    let objeto = {status:objetoInfoAdicional.status,observaciones:observaciones}
    
    if(respuesta){
        Object.assign(objeto,{respuesta:respuesta})
    }

    iniciarGrabar(objeto)
}

    const iniciarGrabar = (objeto)=>{
        const validacionEstado = esReversionDeEstado(objeto,status_actual_tramite);
        const validacionRechazo = esUnRechazo(objeto);

        if(!validacionEstado[0]){ // Si se pasa a un estado anterior es obligatorio ingresar una observación interan

            if(!observaciones){
                setPedirInfoAdicional({operacion:validacionEstado[2],texto:validacionEstado[1],status:validacionEstado[3]})
                return
            }
        }

        if(!validacionRechazo[0]){ // si es un rechazo es obligatorio ingresar una respuesta al ministro y una observaciión interna
            if(!observaciones || !respuesta){ 
                setPedirInfoAdicional({operacion:validacionRechazo[2],texto:validacionRechazo[1],status:validacionRechazo[3]})
                return
            }
        }
        
        if(objeto?.status==objestados.aprobado.nombre){
                tratamientodeAprobacion(objeto,dataTramite,dataDetalle,grabar,snackbar,usuario)
        }else{
            const mensajes = objeto.status ? mensajeSegunCambios(objeto,dataTramite) : ''

            Swal.fire({
                html:`<Div>Por favor confirme la modificación
                    ${mensajes ? `<p>${mensajes}</p>`:''}
                </Div>`,
                showCancelButton:true,
                confirmButtonText:'Modificar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        grabar2(objeto);
                    }else{
                        console.log("Se canceló la operación de la solicitud")
                    }
                }
            )
        }

    }

    const subirAdjunto = async (file,solicitante,identificador,adjuntos)=>{

        try{
    
          const resultado_acta = await doPostFile(file,{id_obrero:solicitante},`${identificador}`,'pdf')
          
          adjuntos.push(resultado_acta[1] || 'unknown')
    
          if(!resultado_acta[0])
          {
            //setLoadingFile(false)
            eliminarArchivos(adjuntos)
            return [false,resultado_acta[1]]
          }
          
          //setLoadingFile(false)
    
          return [true,{
            acta:resultado_acta[1]
          }]
    
        }catch(err){
          //setLoadingFile(false)
          console.log(err)
          eliminarArchivos(adjuntos)
          return [false,err?.message || 'Se produjo un error al ejecutar al inicializar el proceso de guardado de datos']
        }
    
    } 

    const grabar2 = (objeto)=>{

        //const condicionOK =  objeto.status ? condicionesCumplidasParaElEstadoGenerico(dataTramite,data,objeto,usuario) : [true];
        // el caso en que objeto.status no está definido es cuando no es un cambio de estado sino una respuesta o una observación
        const condicionOK =  dataDetalle ? objeto.status ? condicionesCumplicadasParaElEstado(dataTramite,dataDetalle,objeto) : [true] : [false,'Faltan los datos del ascenso para validar si se puede ejecutar la modificación'];

        if(!condicionOK[0]){
            snackbar.showMessage(condicionOK[1],'error')
        }else{
                grabar(objeto)
                .then(resultado=>{
                    if(resultado){
                        Swal.fire({
                            text:'Los datos fueron grabados',
                            icon: 'success',
                            timer: 1000,
                            showConfirmButton: false,
                        })   
                    }
                })
        }
       
    }

    return <Box id="solicitud-proceso" sx={{marginRight:'auto',marginLeft:'auto',padding:'5px'}}>
                {/*status_actual_tramite=='Iniciado' && (usuario.perfil=="region" || (usuario.perfil=="sede" && usuario.rol_sede>95)) && <Box sx={{margin:'10px',display:'flex',justifyContent:'space-evenly'}}>
                    {cabecera.tipo!="nuevo_patrocinado" && <Button className='blink' title="Como primer paso debe ingresar el trámite a la secretaría para que se tome en cuenta para su procesamiento" startIcon={<ArrowRightAltIcon/>} variant="contained"  color='secondary' 
                     onClick={()=>iniciarGrabar({status:objestados.procRegion.nombre})}>
                                    Ingresar a secretaría regional
                    </Button>}
                    <Button title='Puede eliminar un trámite cuando el solicitante lo requiera a la secretaría siempre que se encuentre en estado Iniciado' startIcon={<DeleteIcon/>} variant="contained"  color='secondary' 
                    onClick={()=>eliminar({id:cabecera._id,usuario:usuario.nombre,id_usuario:usuario.id_usuario,observaciones:'Eliminado desde módulo administrativo'})}>
                                        Eliminar
                    </Button>
                </Box>*/}
                <Acciones 
                 status_actual_tramite = {status_actual_tramite}
                 usuario = {usuario}
                 iniciarGrabar = {iniciarGrabar}
                 eliminar = {eliminar}
                 objestados = {objestados}
                 cabecera = {cabecera}
                />
              {<Backdrop
                style={{ background: 'black', zIndex: 1000,opacity:'0.96' }}
                open={cambiarStatus}
                //onClick={()=>alert(1)}
                >
                <div className='bg-white p-10 items-center rounded-md'>
                    <button onClick={()=>setCambiarStatus(null)}
                            data-dismiss="modal" 
                            aria-label="Close" 
                            title="Cerrar" 
                            className="absolute botonAbm right-4 top-2">
                        <XCircleIcon className="h-6 w-6"/>
                    </button>


                    {cambiarStatus=='estado' && <div>
                        <h5 >Estado actual:</h5>
                        <h4 className='text-blue-500 font-bold mt-2'>{status_actual_tramite}</h4>
                        <h5 className='mt-2 mb-1'>Seleccione un nuevo estado:</h5>
                            {estadosX.estados.filter(item=>item[0]!=status_actual_tramite).map(item=><div style={{padding:'5px'}}>
                                <Button startIcon={<ArrowRightAltIcon/>} variant="contained" onClick={()=>iniciarGrabar({status:item[0]})}>
                                    {item[0]}
                                </Button>
                            </div>)}
                    </div>}
                    <div id="tramite-texts">
                            {(cambiarStatus=='observacion' || cambiarStatus=='respuesta') && <EditarTexto
                                texto = {cambiarStatus=='observacion' ? observaciones : respuesta}
                                campo = {cambiarStatus=='observacion' ? 'observaciones' : 'respuesta'}
                                updateTexto = {cambiarStatus=='observacion' ? setObservaciones : setRespuesta}
                                label = {cambiarStatus=='observacion' ? 'Nueva observación' : 'Nueva respuesta'}
                                iniciarGrabar = {iniciarGrabar}
                            />}
                            <Box sx={{display:'flex',justifyContent:'space-evenly'}}>
                                {(cambiarStatus=='observacion' || cambiarStatus=='consulta_obs') && <HistorialObservaciones label='Observaciones anteriores' vector={cabecera.observaciones}/>}
                                {(cambiarStatus=='historial' ||  cambiarStatus=='consulta_status' ) && <HistorialStatus vector={cabecera.status}/>}
                                {(cambiarStatus=='respuesta' || cambiarStatus=='historial' || cambiarStatus=='consulta_resp') && <HistorialRespuestas label='Respuestas anteriores' vector={cabecera.respuesta}/>}
                            </Box>
                    </div>
                </div>
            </Backdrop>
            }
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',height:'20px'}}>
                 
                <span className='text-xl font-bold mb-4'>Solicitud de {mapearTipo(cabecera.tipo)}: {cabecera.codigo_web}</span>
                    <button onClick={finalizar} 
                            data-dismiss="modal" 
                            aria-label="Close" 
                            title="Cerrar" 
                            className="absolute botonAbm right-4 top-2">
                        <XCircleIcon className="h-6 w-6"/>
                    </button>
                
            </Box>
            <h4 style={{marginTop:'10px'}}>Región {cabecera.region} / {cabecera.distrito.nombre}</h4>
            <Box id="infosolicitu">
                    <Alert variant="filled" severity="">
                        <p><span className='fw-600'>Solicitado por:</span> {cabecera.ministro.nombre} el {transformarFechaTramiteSimple(Number(cabecera.fecha))}</p>
                        <p>{cabecera.descripcion}</p>
                        <p className='text-xsmall'>ID {cabecera._id}</p>
                    </Alert>
            </Box>
            <div className='mb-4 w-full grid grid-cols-1 md:grid-cols-2'>
                <div className='w-full'>
                    <Alert variant="filled">
                        <Typography
                            variant="h6"
                            color="blue-gray"
                            className="font-medium mb-2 flex justify-center"
                            >
                            Estado de la solicitud:
                        </Typography>
                        <span className='bg-blue-400 mb-2 text-white p-2 flex justify-center rounded-md'>{status_actual_tramite}</span>
                        {status_actual_tramite==objestados.iniciado.nombre && usuario.perfil=="region" && cabecera.tipo!="nuevo_patrocinado" && cabecera.tipo!="afiliacion" && <p className='color-red fw-600'>Debe ingresar el trámite a secretaría regional</p>}
                        {status_actual_tramite==objestados.iniciado.nombre && usuario.perfil=="sede" && usuario?.rol_contabilidad > 50 && cabecera.tipo=="afiliacion" && <p className='color-red fw-600'>Debe ingresar el trámite a tesorería</p>}
                        {!puedeEditarse(estadosX)[0] && <p>{puedeEditarse(estadosX)[1]}</p>}

                        <div className=''>
                            {cabecera?.observaciones?.length > 0 && <Box onClick={()=>setCambiarStatus('consulta_obs')} style={{marginTop:'10px',cursor:'pointer'}}><Badge color="secondary" badgeContent={cabecera.observaciones.length}>
                                <p>Observaciones</p><MessageIcon/>
                                </Badge>
                            </Box>}
                            {!puedeEditarse(estadosX)[0] && <>
                            {cabecera?.respuesta?.length > 0 && <Box onClick={()=>setCambiarStatus('consulta_resp')} style={{marginTop:'10px',cursor:'pointer'}}><Badge color="secondary" badgeContent={cabecera.respuesta.length}>
                                <p>Respuestas</p><MessageIcon/>
                                </Badge>
                            
                            </Box>}
                            {cabecera?.status?.length > 0 && <Box onClick={()=>setCambiarStatus('consulta_status')} style={{marginTop:'10px',cursor:'pointer'}}><Badge color="secondary" badgeContent={cabecera.status.length}>
                                <p>Estados</p><MessageIcon/>
                                </Badge>
                            
                            </Box>}
                            </>}
                        </div>
                    </Alert>
                </div>
               
                {puedeEditarse(estadosX)[0] && <div className='flex flex-col space-y-1 mt-1'>
                    <Button size='sm' onClick={()=>{setCambiarStatus('estado')}}>
                        Cambiar estado
                    </Button>
                    <Button size='sm' onClick={()=>setCambiarStatus('observacion')}>
                        Ingresar una observación
                    </Button>
                    {cabecera.tipo!='igl_formacion' && <Button size='sm' onClick={()=>setCambiarStatus('respuesta')}>
                        Responder al ministro
                    </Button>}
                    <Button size='sm' onClick={()=>setCambiarStatus('historial')}>
                        Ver Historial
                    </Button>
                </div>}
            </div>
           
            <Backdrop
                style={{ background: 'black', zIndex: 20001,opacity:'1' }}
                open={pedirInfoAdicional}
                >
                    <div id="tramite-texts" className='bg-white p-10 items-center rounded-md'>
                        <h4>{pedirInfoAdicional?.texto}</h4>
                        {(pedirInfoAdicional?.operacion=='obs_status_menor' 
                                || pedirInfoAdicional?.operacion=='obs_eliminacion'
                                || pedirInfoAdicional?.operacion=='obs_resp_rechazo') 
                        && <EditarTexto
                            texto = {observaciones}
                            campo = {'observaciones'}
                            id={'tx-observaciones'}
                            updateTexto = {setObservaciones}
                            label = {'Nueva observación interna'}
                            iniciarGrabar = {null}
                        />}
                        {(pedirInfoAdicional?.operacion=='obs_resp_rechazo')
                         &&<EditarTexto
                            texto = {respuesta}
                            campo = {'respuesta'}
                            id={null}
                            updateTexto = {setRespuesta}
                            label = {'Respuesta al ministro'}
                            iniciarGrabar = {null}
                        />}
                         <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                                <Button style={{background:'steelblue'}} variant="outlined" onClick={()=>iniciarGrabarStatusPlus(pedirInfoAdicional,observaciones,respuesta)}>
                                    Grabar
                                </Button>
                                <Button style={{background:'tomato'}} variant="outlined" onClick={()=>setPedirInfoAdicional(null)}>
                                    cancelar
                                </Button>
                        </Box>

                    </div>
            </Backdrop>
            {cabecera.tipo=='ascenso' && <InfoAscenso3
                id={id}
                setData={setDataDetalle}
                cabecera={cabecera}
                usuario={usuario}
                finalizar={finalizar}
                snackbar={snackbar}
                puedeEditarse={puedeEditarse(estadosX)[0]}
                cambiarStatus={()=>setCambiarStatus('estado')}
            />}
            {cabecera.tipo=='nuevoT1' && <InfoNuevoT1
                id={id}
                setData={setDataDetalle}
                cabecera={cabecera}
                usuario={usuario}
                finalizar={finalizar}
                snackbar={snackbar}
                puedeEditarse={puedeEditarse(estadosX)[0]}
                cambiarStatus={()=>setCambiarStatus('estado')}
            />}
             {cabecera.tipo=='afiliacion' && <InfoAfiliacion3
                id={id}
                setData={setDataDetalle}
                cabecera={cabecera}
                subirAdjunto = {subirAdjunto}
                usuario={usuario}
                finalizar={finalizar}
                snackbar={snackbar}
                puedeEditarse={puedeEditarse(estadosX)[0]}
                cambiarStatus={()=>setCambiarStatus('estado')}
            />}
             {cabecera.tipo=='autonomia' && <InfoAutonomia3
                id={id}
                setData={setDataDetalle}
                cabecera={cabecera}
                usuario={usuario}
                finalizar={finalizar}
                snackbar={snackbar}
                puedeEditarse={puedeEditarse(estadosX)[0]}
                cambiarStatus={()=>setCambiarStatus('estado')}
            />}
           {cabecera.tipo=='anexo' && <InfoAnexo3
                id={id}
                setData={setDataDetalle}
                cabecera={cabecera}
                usuario={usuario}
                finalizar={finalizar}
                snackbar={snackbar}
                puedeEditarse={puedeEditarse(estadosX)[0]}
                cambiarStatus={()=>setCambiarStatus('estado')}
            />}
            {cabecera.tipo=='nuevo_ministro' && <InfoMinistro3
                id={id}
                setData={setDataDetalle}
                cabecera={cabecera}
                usuario={usuario}
                finalizar={finalizar}
                snackbar={snackbar}
                puedeEditarse={puedeEditarse(estadosX)[0]}
                cambiarStatus={()=>setCambiarStatus('estado')}
            />}
            {cabecera.tipo=='igl_formacion' && <InfoIglFormacion3
                id={id}
                setData={setDataDetalle}
                cabecera={cabecera}
                usuario={usuario}
                finalizar={finalizar}
                snackbar={snackbar}
                puedeEditarse={puedeEditarse(estadosX)[0]}
                cambiarStatus={()=>setCambiarStatus('estado')}
            />}
    </Box> 
}



const EditarTexto = ({texto,campo,updateTexto,label,iniciarGrabar,id})=>{
    // iniciarGrabarEsUnaFunción pero puedo enviar un null si no quiero que se grabe desde aquí.
    const objetoUpdate = {};
   
    useEffect(()=>{
        updateTexto(null)
        setTimeout(() => {
            hacerFoco("tr-texto-update")
        }, 500);

    },[id])

    const grabar = ()=>{
        objetoUpdate[campo]=texto;
        iniciarGrabar(objetoUpdate);
    }
    // el margintop de 10px se incluye cuando no se envía un ID que es cuando hay varios textos y no es el primero por lo tanto no deseamos que se haga foco
    return <Box sx={{display:'flex',flexDirection:'column',marginTop:!id ? '10px' : ''}}>
        <Typography
            variant="small"
            color="blue-gray"
            className="mb-1 font-medium "
            >
             {label}
        </Typography>
        <Input multiline id="tr-texto-update" 
            value={texto} 
            style={{color:'black'}}
            onChange={(e)=>{
                    updateTexto(e.target.value.toUpperCase())
            }} 
            color='black'
            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
            labelProps={{
                className: "before:content-none after:content-none",
            }}
        />
        {iniciarGrabar && texto?.length > 0 && <Button startIcon={<ArrowRightAltIcon/>} variant="contained" className='mt-2' onClick={grabar}>
            Grabar
        </Button>}
    </Box>
}



