import React, { useState, useEffect,useRef } from 'react';
import {Main,Loading,Axios,uuidv4,useModal,Swal,Paginacion,usePaginacion} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import { faFileDownload,faFilePdf,faWindowClose,faSearchDollar,faFileExcel } from '@fortawesome/free-solid-svg-icons';
import GoBack from '../componentes/GoBack';
import { CSVLink, CSVDownload } from "react-csv";
import {useContextoGlobal} from '../Context/contextoGlobal';
import DatePicker from "react-datepicker";
import BusquedaSinForm from '../componentes/BusquedaSinForm';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import {formatearComparacion,formatoMoneda,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import {transformarIso8601,obtenerFechaDiamenosN,obtenerFechaUltimoDiaMes} from '../Helpers/fechas';
import ReportesEgresosIntegrados from '../reportes/ReportesEgresosIntegrados';
import {imprimir as imprimirEcronologicos} from '../impresiones/ecronologicos';
//import ReactExport from "react-export-excel";
import ImpresionPDF from '../componentes/ImpresionPDF';
import {listaTiposIngresosIntegrales as tiposReporte} from '../Listas/tiposReportes';
import {listaTiposCriteriosIngresosIntegrales as tiposCriterios} from '../Listas/tiposCriteriosReportes';

/*const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;*/

const anchoPaginacion = 50;


export default function ReportesIngIntegrales(){
    const [cargandoRegistros,setCargandoRegistros] = useState(false);
    const [huboError,setHuboError] = useState(false);
    const [datos,setDatos] = useState([]);
    const [registrosAmostrar,setRegistrosAmostrar] = useState([]);
    const [textoBusqueda,setTextoBusqueda] = useState('');
    const {usuario} = useContextoGlobal();
    const [contadorConsulta,setContadorConsulta]=useState(0);
    /*const [parametros,setParametros]=useState({id_region:usuario.id_region,
        id_rango:'-1',
        cant_meses_desde:-1,
        cant_meses_hasta:-1,
        promedio_desde:-1,
        promedio_hasta:-1,
        total_desde:-1,
        total_hasta:-1,
        mesesSi:false,
        promedioSi:false,
        aportesSi:false,
        fechaDesde:new Date(obtenerFechaDiamenosN(365,'MM/DD/YYYY').diamenosn),
        fechaHasta:new Date()})*/    
    const [parametros,setParametros]=useState({id_tanda:103,
                                                id_año_fiscal:10,
                                                mesAño:new Date(),
                                                año:new Date(),
                                                tipo:tiposReporte[0].id,
                                                criterio:tiposCriterios.fecha, 
                                                destino:null,
                                                nombre_destino:'',
                                                id_obrero:null,
                                                id_iglesia:null,
                                                id_proveedor:null,                                                
                                                formapago:null,
                                                rubro:null,
                                                fondo:null,
                                                moneda:1,
                                                caja:null,
                                                fechaDesde:new Date(obtenerFechaDiamenosN(365,'MM/DD/YYYY').diamenosn),
                                                fechaHasta:new Date(),
                                                fecha:new Date(),descripcionCriterio:'',
                                                total_caja:false,
                                                total_diario:false,
                                                total_mensual:false,
                                                total_año:false,
                                                no_agrupar:false})
    const [periodosFiscales,setPeriodosFiscales] = useState([]);
    const [tandas,setTandas] = useState([]);
    const [rubros,setRubros] = useState([]);
    const [monedas,setMonedas] = useState([]);
    const [cajas,setCajas] = useState([]);
    const [fondos,setFondos] = useState([]);
    const [formasPago,setFormasPago] = useState([]);
    const [reporteEnEjecucion,setReporteEnEjecucion] = useState(false)
    const meses = [0,1,2,3,4,5,6,7,8,9,10,11,12];
    const {configuracion,paginar,inicializar} = usePaginacion();
    registerLocale('es', es)


    const subtitulo = useRef('');

    useEffect(()=>{
        buscarTablasGenerales()
        .then(()=>{
           // buscarDatos()
        })
    },[])

    useEffect(()=>{
        setReporteEnEjecucion(false)
    },[parametros])
    
    useEffect(()=>{
        if(!parametros.destino){
            setParametros({...parametros,nombre_destino:'',id_obrero:null,id_iglesia:null,id_proveedor:null})
        }else if(parametros.destino=='pastor'){
            setParametros({...parametros,nombre_destino:'',id_iglesia:null,id_proveedor:null})
            hacerfocoEnPrimerInput('destino-n')
        }else if(parametros.destino=='proveedor'){
            setParametros({...parametros,nombre_destino:'',id_iglesia:null,id_obrero:null})
            hacerfocoEnPrimerInput('destino-n')
        }else{
            setParametros({...parametros,nombre_destino:'',id_obrero:null,id_proveedor:null})
            hacerfocoEnPrimerInput('destino-n')
        }
    },[parametros.destino])

    useEffect(()=>{
        subtitulo.current = crearSubtitulosSegunParametros()
    },[parametros,cargandoRegistros]) // agrego a las dependencias la variable cargandoRegistros para que cree el subtitulo inmediatamente aunque no hayan cambiado los parametros porque podría ejecutar el reporte sin modificar la selección o sea con los valores iniciales

      const buscarTablasGenerales = async ()=>{
        try{
            setCargandoRegistros(true)
            const vectorResultados = await Promise.all([Axios.get('/api/tablasgenerales/reportes/tandas'),
            Axios.get('/api/tablasgenerales/reportes/perfiscales'),
            Axios.get('/api/reportes/tablas/rubros'),
            Axios.get('/api/reportes/tablas/monedas'),
            Axios.get('/api/reportes/tablas/cajas'),
            Axios.get('/api/reportes/tablas/fondos'),
            Axios.get('/api/reportes/tablas/formaspago')])
            setTandas(vectorResultados[0].data)
            setPeriodosFiscales(vectorResultados[1].data)
            setRubros(vectorResultados[2].data)
            setMonedas(vectorResultados[3].data)
            setCajas(vectorResultados[4].data)
            setFondos(vectorResultados[5].data)
            setFormasPago(vectorResultados[6].data)

            setCargandoRegistros(false)
        }catch(err){
            setCargandoRegistros(false)
            setHuboError(true)
            console.log('Error al buscar los datos generales')  
        }
    }

    const ejecutarReporte = ()=>{
        setReporteEnEjecucion(true)
    }

    const finalizarReporte = ()=>{
        setReporteEnEjecucion(false)
    }

    const crearSubtitulosSegunParametros = ()=>{
        let descripcion = '';

        switch(parametros.criterio){
            case tiposCriterios.añofiscal:
                descripcion = `${descripcion} Año fiscal: ${periodosFiscales.filter(item=>item.id_año_fiscal==parametros.id_año_fiscal).map(item=>item.nombre)}`; break
            case tiposCriterios.mesaño:
//                descripcion = `${descripcion} ${document.getElementById('select-mesaño').options[document.getElementById('select-mesaño').selectedIndex].text}`; break
                descripcion = `${descripcion} Mes y año: ${obtenerValueDeID('select-mesaño')}`; break
            case tiposCriterios.año:
                descripcion = `${descripcion} Año: ${obtenerValueDeID('select-año')}`; break
            case tiposCriterios.periodo:
                descripcion = `${descripcion} Período: ${obtenerValueDeID('select-periodo-fdesde')} -->  ${obtenerValueDeID('select-periodo-fhasta')}`; break
            case tiposCriterios.fecha:
                descripcion = `${descripcion} Fecha: ${obtenerValueDeID('select-fecha')}`; break
        }

            //dos formas de recuperar el texto de un select, usando el vector y filter o usando el id y accediendo al DOM
            descripcion = `${descripcion} Moneda: ${monedas.filter(item=>item.id_tipo_moneda==parametros.moneda).map(item=>item.nombre)}`; 
//            descripcion = `${descripcion} Moneda: ${document.getElementById('select-moneda').options[document.getElementById('select-moneda').selectedIndex].text}`
        
            if (parametros.destino){
                descripcion = `${descripcion} Origen: ${parametros.destino=='pastor' ? 'Pr. ' : parametros.destino=='iglesia' ? 'Igl.' : ' '} ${parametros.nombre_destino}`
            }

            if (parametros.caja){
                descripcion = `${descripcion} Caja: ${document.getElementById('select-caja').options[document.getElementById('select-caja').selectedIndex].text}`
            }

            if (parametros.fondo){
                descripcion = `${descripcion} Fondo: ${document.getElementById('select-fondo').options[document.getElementById('select-fondo').selectedIndex].text}`
            }

            if (parametros.rubro){
                descripcion = `${descripcion} Rubro: ${document.getElementById('select-rubro').options[document.getElementById('select-rubro').selectedIndex].text}`
            }

            if (parametros.formapago){
                descripcion = `${descripcion} Forma de pago: ${document.getElementById('select-fpago').options[document.getElementById('select-fpago').selectedIndex].text}`
            }

        return descripcion
    }

    const cb_filtro = item=>
    formatearComparacion(item.codigo_rubro).includes(formatearComparacion(textoBusqueda)) ||
    formatearComparacion(item.rubro).includes(formatearComparacion(textoBusqueda))


    if (huboError){
        return <Main center>
            <div className="flex f-row justify-center items-center mb-4">
                <p>Se produjo un error al cargar los datos para esta vista</p>
            </div>
        </Main>
    }

    if (cargandoRegistros){
        return <Main center><Loading/><span className="cargando">Cargando registros...</span></Main>
    };

    return (  
    <Main center>  
        <div className="flex nocelular f-row justify-center items-center mb-4">
            <GoBack titulo="Volver a Reportes"/>
        </div>
        <div className={determinarClases(reporteEnEjecucion)}>
            <div className="flex f-row justify-center items-center mb-4">
                <p className="text-large">Reporte de Ingresos</p>
            </div>
            <Parametros parametros = {parametros} 
                        setParametros={setParametros} 
                        tandas={tandas} 
                        periodosFiscales ={periodosFiscales} 
                        centrado={contadorConsulta==0 ? true : false}
                        cajas = {cajas}
                        rubros = {rubros}
                        monedas = {monedas}
                        fondos = {fondos}
                        formasPago = {formasPago}
                        ejecutarReporte = {ejecutarReporte}/>
        </div>
        {/*El reporte se renderiza en una tabla usando los objetos titulos y columnas 
           Pero si hiciera falta hacemos el body de la tabla aquí y lo pasamos en bodyTablaCustomizado
           Esta funcionalidad se implementa de esta manera porque puede haber N reportes
           con diferentes necesidades respecto de como debe renderizarse.
           Depende del caso, a veces con definir titulos y columnas es suficiente
        */}
        {reporteEnEjecucion && <ReportesEgresosIntegrados 
                                descripcion = {subtitulo.current}
                                parametros={parametros} 
                                finalizarReporte={finalizarReporte}
                                bodyTablaCustomizado = {parametros.tipo ? bodyTablaEgresosCronologicos(objetosSegunParametros(parametros,'clasesbody')) : null}
                                api={objetosSegunParametros(parametros,'api')}
                                titulo={objetosSegunParametros(parametros,'titulo')}
                                titulos={objetosSegunParametros(parametros,'titulos')}
                                columnas={objetosSegunParametros(parametros,'columnas')}
                                filtro = {objetosSegunParametros(parametros,'filtro')}
                                excel = {objetosSegunParametros(parametros,'excel')}
                                callbackCSV = {objetosSegunParametros(parametros,'csv')}
                                callbackPDF = {objetosSegunParametros(parametros,'pdf')}
                                arrayPDF = {objetosSegunParametros(parametros,'arraypdf')}
                                orientacionPDF = {objetosSegunParametros(parametros,'orientacion')}
                                subtitulo = {objetosSegunParametros(parametros,'subtitulo')}
                                clasesbody = {objetosSegunParametros(parametros,'clasesbody')}
                                claseshead = {objetosSegunParametros(parametros,'claseshead')}
                                mostrarResumenLateral
                    />}
    </Main>
    )
}

function Parametros({parametros,
                    setParametros,
                    cajas,
                    tandas,
                    periodosFiscales,
                    centrado,
                    rubros,
                    monedas,
                    fondos,
                    formasPago,ejecutarReporte}){

    const inputBusquedaRef = useRef()
    const r2 = useRef('null')

    const [buscarDestino,setBuscarDestino] = useState(false)

    const handleCheckCriterio = (e)=>{
        setParametros({...parametros,criterio : e.target.value})
        hacerfocoEnPrimerInput(`select-${e.target.value}`)
    }

    const handleCheckTipo = (e)=>{
        setParametros({...parametros,tipo : e.target.value,criterio:tiposCriterios.tanda})
    }

    const handleChangeTipo = (e)=>{
        setParametros({...parametros,tipo : e.target.value,criterio:tiposCriterios.tanda})
    }

    const handleCheckDestino = (e)=>{
        setParametros({...parametros,destino : e.target.name=="checkdestino" ? parametros.destino ? null : 'pastor' : e.target.value})
    }

    const handleCheckCaja = (e)=>{
        setParametros({...parametros,caja : parametros.caja ? null : e.target.name=="checkcaja" ? 1 : e.target.value})
    }

    const handleCheckNoAgrupar = (e)=>{
        setParametros({...parametros,no_agrupar : parametros.no_agrupar ? null : e.target.name=="checknoagrupar" ? 1 : e.target.value})
    }

    const handleCheckFormaPago = (e)=>{
        setParametros({...parametros,formapago : parametros.formapago ? null : e.target.name=="checkformapago" ? 1 : e.target.value})
    }

    const handleCheckRubro = (e)=>{
        setParametros({...parametros,rubro : parametros.rubro ? null : e.target.name=="checkrubro" ? 1 : e.target.value})
    }

    const handleCheckTotalCaja = (e)=>{
        setParametros({...parametros,total_caja : parametros.total_caja ? false : true})
    }

    const handleCheckTotalDiario = (e)=>{
        setParametros({...parametros,total_diario : parametros.total_diario ? false : true})
    }

    const handleCheckTotalMensual = (e)=>{
        setParametros({...parametros,total_mensual : parametros.total_mensual ? false : true})
    }

    const handleCheckTotalAño = (e)=>{
        setParametros({...parametros,total_año : parametros.total_año ? false : true})
    }

    const handleCheckFondo = (e)=>{
        setParametros({...parametros,fondo : parametros.fondo ? null : e.target.name=="checkfondo" ? 1 : e.target.value})
    }

    const handleChangeNombre = (e)=>{
        setParametros({...parametros,nombre_destino : e.target.value})
    }

    const finalizarSeleccionObrero = (item)=>{
        setParametros({...parametros,id_obrero : item.id_obrero,nombre_destino:item.nom_simple,id_proveedor:null,id_iglesia:null})
        setBuscarDestino(false)
    }

    const finalizarSeleccionProveedor = (item)=>{
        setParametros({...parametros,id_proveedor : item.id_proveedor,nombre_destino:item.nombre,id_obrero:null,id_iglesia:null})
        setBuscarDestino(false)
    }

    const finalizarSeleccionIglesia = (item)=>{
        setParametros({...parametros,id_iglesia : item.id_iglesia,nombre_destino:item.nombre,id_proveedor:null,id_obrero:null})
        setBuscarDestino(false)
    }

    const validarParametros = ()=>{
        if (parametros.destino=="proveedor" && !parametros.id_proveedor){
            alert('Falta seleccionar un proveedor')
            inputBusquedaRef.current.focus()
            return
        }
        if (parametros.destino=="pastor" && !parametros.id_obrero){
            alert('Falta seleccionar un ministro')
            inputBusquedaRef.current.focus()
            return
        }
        if (parametros.destino=="iglesia" && !parametros.id_iglesia){
            alert('Falta seleccionar una iglesia')
            inputBusquedaRef.current.focus()
            return
        }

        if (parametros.criterio=="tanda" && !parametros.id_tanda){
            alert('Falta seleccionar una tanda')
            return
        }

        if (parametros.criterio=="añofiscal" && !parametros.id_año_fiscal){
            alert('Falta seleccionar un año fiscal')
            return
        }
        if (parametros.criterio=="año" && !parametros.año){
            alert('Falta seleccionar un año')
            return
        }
        if (parametros.criterio=="mesaño" && !parametros.mesAño){
            alert('Falta seleccionar un mes y un año')
            return
        }
        if (parametros.criterio=="periodo" && (!parametros.fechaHasta || !parametros.fechaDesde)){
            alert('Falta seleccionar un mes y un año')
            return
        }
        if (parametros.criterio=="fecha" && !parametros.fecha){
            alert('Falta seleccionar un mes y un año')
            return
        }
        ejecutarReporte()
    }
    
    return <div className={centrado ? "flex f-row justify-center reporte-egresos border-dotted-gray border-radius-7 p-2":"absolute top-50 left-50 reporte-egresos border-dotted-gray border-radius-7 p-2"}>
        <div className="flex f-col">
            {/*<Prueba ref2={r2}/>
            <button onClick ={()=>alert(r2.current.options[r2.current.selectedIndex].text)}>macho</button>*/}
        <div className="flex f-row p-1 items-center">
                <span title={JSON.stringify(parametros)} className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Reporte:
                </span>
                <div className="flex mt-2 justify-center">
                    <TipoReporte parametros={parametros} handleChangeTipo={handleChangeTipo}/>
                </div>           
            </div>
            <div className="flex f-row p-1 items-center">
                        <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                                    Criterio de búsqueda:
                        </span>
                <div className="flex mt-2 justify-center">
                    {/*<input type="radio" id="cri-td" title="Filtrar por una tanda" name="criterio" value="tanda" checked={parametros.criterio==tiposCriterios.tanda} onClick={handleCheckCriterio}/>
                    <label for="cri-td">Tanda</label>*/}
                    <input type="radio" id="cri-fe" title="Filtrar por una fecha específica" name="criterio" value="fecha" onClick={handleCheckCriterio} checked={parametros.criterio==tiposCriterios.fecha}/>
                    <label for="cri-pe">Fecha</label>
                    <input type="radio" id="cri-af" title="Filtrar por un año fiscal" name="criterio" value="añofiscal" checked={parametros.criterio==tiposCriterios.añofiscal} onClick={handleCheckCriterio}/>
                    <label for="cri-af">Año fiscal</label>
                    <input type="radio" id="cri-ma" title="Filtrar por un mes y un año" name="criterio" value="mesaño" onClick={handleCheckCriterio} checked={parametros.criterio==tiposCriterios.mesaño}/>
                    <label for="cri-ma">Mes / Año</label>
                    <input type="radio" id="cri-an" title="Filtrar por un año específico" name="criterio" value="año" onClick={handleCheckCriterio} checked={parametros.criterio==tiposCriterios.año}/>
                    <label for="cri-an">Año</label>
                    <input type="radio" id="cri-pe" title="Filtrar por un período comprendido entre dos fechas" name="criterio" value="periodo" onClick={handleCheckCriterio} checked={parametros.criterio==tiposCriterios.periodo}/>
                    <label for="cri-pe">Período</label>
                </div>           
            </div>

            {parametros.criterio=="tanda" && <div className="flex f-row justify-content-end p-1">
                <Seleccionador id="select-tanda" noDefault vector={tandas} valor={parametros.id_tanda} nombre="Todas" onchange={(e)=>setParametros({...parametros,id_tanda:e.target.value})} claves={{id:'id_tanda',nombre:'nombre'}}/>
            </div>}

            {parametros.criterio=="añofiscal" && <div className="flex f-row justify-content-end p-1">
                <Seleccionador id="select-añofiscal" noDefault vector={periodosFiscales} valor={parametros.id_año_fiscal} nombre="Todos" onchange={(e)=>setParametros({...parametros,id_año_fiscal:e.target.value})} claves={{id:'id_año_fiscal',nombre:'nombre'}}/>
            </div>}     

            {parametros.criterio=="mesaño" && <div className="flex f-row justify-content-end p-1">
                        {<DatePicker selected={parametros.mesAño} 
                            onChange={date => setParametros({...parametros,mesAño:date})} 
                            locale="es"
                            dateFormat="MM/yyyy"
                            id="select-mesaño"
                            showMonthYearPicker
                            showFullMonthYearPicker
                            showFourColumnMonthYearPicker
                        />}
            </div>}

            {parametros.criterio=="año" && <div className="flex f-row justify-content-end p-1">
                        {<DatePicker selected={parametros.año} 
                            onChange={date => setParametros({...parametros,año:date})} 
                            locale="es"
                            id="select-año"
                            showYearPicker
                            dateFormat="yyyy"
                        />}
            </div>}

            {parametros.criterio=="periodo" && <div className="flex f-row justify-content-end p-1">
                    <div className="flex f-row">
                                <div className="flex f-col">
                                    <span className="text-xsmall">Desde</span>
                                    <DatePicker selected={parametros.fechaDesde} 
                                        onChange={date => setParametros({...parametros,fechaDesde:date})} 
                                        locale="es"
                                        dateFormat="MM/yyyy"
                                        id="select-periodo-fdesde"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                        showFourColumnMonthYearPicker/>
                                </div>
                                <div className="flex f-col">
                                    <span className="text-xsmall">Hasta</span>
                                    <DatePicker selected={parametros.fechaHasta} 
                                        onChange={date => setParametros({...parametros,fechaHasta:date})} 
                                        locale="es"
                                        dateFormat="MM/yyyy"
                                        id="select-periodo-fhasta"
                                        showMonthYearPicker
                                        showFullMonthYearPicker
                                        showFourColumnMonthYearPicker/>
                                </div>
                    </div>
            
            </div>}

            {parametros.criterio=="fecha" && <div className="flex f-row justify-content-end p-1">
                    <div className="flex f-row">
                            <DatePicker selected={parametros.fecha} 
                                id="select-fecha"
                                onChange={date => setParametros({...parametros,fecha:date})} 
                                locale="es"/>
                    </div>
            </div>}

            {true && <>
            {/* lo comento porque no figura este parametro en ingresos integrales del sistema en uso
            <div className="flex f-row p-1">
                <div className="flex">
                    <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Moneda
                    </span>
                </div>
               
                {parametros.moneda && <div className="flex f-row items-center">
                    <div className="flex justify-center">
                        <Seleccionador id='select-moneda' noDefault vector={monedas} valor={parametros.moneda} nombre="Todos" onchange={(e)=>setParametros({...parametros,moneda:e.target.value})} claves={{id:'id_tipo_moneda',nombre:'nombre'}}/>
                    </div>   

                </div>}
            </div>*/}

            <div className="flex f-row p-1">
                <div className="flex">
                    <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Origen
                    </span>

                    <input type="checkbox" name="checkdestino" checked={parametros.destino} title="Filtrar por el destino del egreso: Proveedor, iglesia o pastor" onClick={handleCheckDestino}/>
                </div>
               
                {parametros.destino && <div className="flex f-row items-center">
                <div className="flex justify-center">
                    <input type="radio" id="per-pa" title="El destino del egreso es un pastor" name="persona" value="pastor" onClick={handleCheckDestino} checked={parametros.destino=='pastor'}/>
                    <label for="per-pa">Pastor</label>
                    <input type="radio" id="per-igl" title="El destino del egreso es una iglesia" name="persona" value="iglesia" checked={parametros.destino=='iglesia'} onClick={handleCheckDestino}/>
                    <label for="per-igl">Iglesia</label>
                </div>   

                </div>}
            </div>

            
                {parametros.destino && <div>
                    <div className="relative">
                        <input autocomplete="off" className="w-300 texto-busqueda-alumno ml-4" onInput={()=>setBuscarDestino(true)} id="destino-n" onClick={()=>seleccionarTextoInput("destino-n")} type="text" value={parametros.nombre_destino} onChange={handleChangeNombre} ref={inputBusquedaRef} placeholder={parametros.destino == 'pastor' ? 'Buscar un ministro' : parametros.destino=='proveedor' ? 'Buscar un proveedor' : 'Buscar una iglesia'}/>
                        { parametros.id_pastor && 
                            <button>
                                <FontAwesomeIcon className="ic-abm"
                                                icon={faWindowClose} 
                                                onClick={()=>console.log('pastor')}/>
                            </button>}  
                            {buscarDestino && parametros.destino=='pastor' && parametros.nombre_destino.length > 2 && 
                                <div className="listbusqueda absolute w-100pc z-50">
                                    <BusquedaSinForm texto={parametros.nombre_destino} finalizarSeleccion={finalizarSeleccionObrero} origen="obreros"/>
                                </div>
                            }
                            {buscarDestino && parametros.destino=='proveedor' && parametros.nombre_destino.length > 2 && 
                                <div className="listbusqueda absolute w-100pc z-50">
                                    <BusquedaSinForm texto={parametros.nombre_destino} finalizarSeleccion={finalizarSeleccionProveedor} origen="proveedores"/>
                                </div>
                            }
                            {buscarDestino && parametros.destino=='iglesia' && parametros.nombre_destino.length > 2 && 
                                <div className="listbusqueda absolute w-100pc z-50">
                                    <BusquedaSinForm texto={parametros.nombre_destino} finalizarSeleccion={finalizarSeleccionIglesia} origen="iglesias"/>
                                </div>
                            }
                    </div>
 
               </div>}

            {/* lo comento porque este parametro no figura en ingresos integrales del sistema en uso
            <div className="flex f-row p-1">
                <div className="flex">
                    <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Caja
                    </span>

                    <input type="checkbox" name="checkcaja" checked={parametros.caja} title="Filtrar por la caja asociada al egreso" onClick={handleCheckCaja}/>
                </div>
               
                {parametros.caja && <div className="flex f-row items-center">
                    <div className="flex justify-center">
                        <Seleccionador id='select-caja' noDefault vector={cajas} valor={parametros.caja} nombre="Todos" onchange={(e)=>setParametros({...parametros,caja:e.target.value})} claves={{id:'id_caja',nombre:'nombre'}}/>
                    </div>   

                </div>}
            </div>*/}

            <div className="flex f-row p-1">
                <div className="flex">
                    <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Rubro
                    </span>

                    <input type="checkbox" name="checkrubro" checked={parametros.rubro} title="Filtrar por el rubro asociado al egreso" onClick={handleCheckRubro}/>
                </div>
               
                {parametros.rubro && <div className="flex f-row items-center">
                    <div className="flex justify-center">
                        <Seleccionador  id='select-rubro' noDefault vector={rubros} valor={parametros.rubro} nombre="Todos" onchange={(e)=>setParametros({...parametros,rubro:e.target.value})} claves={{id:'id_rubro',nombre:'nombre'}}/>
                    </div>   

                </div>}
            </div>

            <div className="flex f-row p-1">
                <div className="flex">
                    <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Fondo de tesorería
                    </span>

                    <input type="checkbox" name="checkfondo" checked={parametros.fondo} title="Filtrar por el fondo de tesorería asociado al egreso" onClick={handleCheckFondo}/>
                </div>
               
                {parametros.fondo && <div className="flex f-row items-center">
                    <div className="flex justify-center">
                        <Seleccionador  id='select-fondo' noDefault vector={fondos} valor={parametros.fondo} nombre="Todos" onchange={(e)=>setParametros({...parametros,fondo:e.target.value})} claves={{id:'id_fondo_tesoreria',nombre:'nombre'}}/>
                    </div>   

                </div>}
            </div>

            <div className="flex f-row p-1">
                <div className="flex">
                    <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Forma de pago
                    </span>

                    <input type="checkbox" name="checkformapago" checked={parametros.formapago} title="Filtrar por la forma de pago asociada al egreso" onClick={handleCheckFormaPago}/>
                </div>
               
                {parametros.formapago && <div className="flex f-row items-center">
                    <div className="flex justify-center">
                        <Seleccionador  id='select-fpago' noDefault vector={formasPago} valor={parametros.formapago} nombre="Todos" onchange={(e)=>setParametros({...parametros,formapago:e.target.value})} claves={{id:'id_forma_pago',nombre:'nombre'}}/>
                    </div>   

                </div>}
            </div>
           </>}  

           {parametros.tipo=='resumen' && <div className="flex f-row mt-2 p-2 border-dotted-gray border-radius-7 justify-center">
                <div className="flex">
                    <span className="cursor-pointer p2-2 w-200 text-small botonNc inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Reporte sin agrupar en meses
                    </span>

                    <input type="checkbox" name="checknoagrupar" checked={parametros.no_agrupar} title="No agrupar por meses" onClick={handleCheckNoAgrupar}/>
                </div>
            </div>}
            {parametros.tipo=='cronologico' && <div className="flex f-row mt-2 p-2 border-dotted-gray border-radius-7 justify-center">
                <div className="flex">
                    <span className="cursor-pointer p2-2 text-small botonNc w-100  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Totales por caja
                    </span>

                    <input type="checkbox" name="checkcaja" checked={parametros.total_caja} title="Calcular totales por caja" onClick={handleCheckTotalCaja}/>
                </div>
                <div className="flex ml-4">
                    <span className="cursor-pointer p2-2 text-small botonNc w-100  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Totales diarios
                    </span>

                    <input type="checkbox" name="checkcaja" checked={parametros.total_diario} title="Calcular totales por día" onClick={handleCheckTotalDiario}/>
                </div>
                <div className="flex ml-4">
                    <span className="cursor-pointer p2-2 text-small botonNc w-150  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Totales mensuales
                    </span>

                    <input type="checkbox" name="checkcaja" checked={parametros.total_mensual} title="Calcular totales por mes" onClick={handleCheckTotalMensual}/>
                </div>
                <div className="flex ml-4">
                    <span className="cursor-pointer p2-2 text-small botonNc w-100  inline-block-1 border-bottom-dotted-gray text-left color-gray" >
                        Totales por año
                    </span>

                    <input type="checkbox" name="checkcaja" checked={parametros.total_año} title="Calcular totales por año" onClick={handleCheckTotalAño}/>
                </div>
            </div>}

           <button className="button-reporte-x mr-auto ml-auto mt-4" onClick={validarParametros}>Ejecutar </button>              
        </div>
 </div>
}

function Seleccionador({vector,onchange,valor,nombre,noDefault,name,classancho,limpiar,claves,id}){
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";
    let clasesActivo = "block appearance-none w-full select-titulo rounded shadow leading-tight";

    if (classancho){
        clasesSelect=`block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }

    //recordar que un objeto puedo leerlo de 2 formas como vector o como objeto
    // es lo mismo usuario['nombre'] que usuario.nombre
    // aprovecho esta característica para hacer un seleccionador genérico y le paso
    // el nombre de la clave y el texto como un string para referenciarlo en notación vector
    return (            
        <div className="input-field col s12 flex f-row">
            <select value={valor} name={name? name : ''} onChange = {onchange} className={valor=="-1" ? clasesSelect : clasesActivo} id={id? id : null}>
                { noDefault ? null : <option value="-1" key="-1">{nombre}</option>}
                {vector.map(item=><option value={item[claves.id]} key={item[claves.id]}>{item[claves.nombre]}</option> )}
            </select>
            { valor!="-1" && limpiar && 
                        <button>
                            <FontAwesomeIcon className="ic-abm"
                                            icon={faWindowClose} 
                                            onClick={limpiar}/>
                        </button>}  
        </div>
        )
        
}   

function determinarClases(reporteEnEjecucion){
    return reporteEnEjecucion ? "items-center justify-center ml-auto mr-auto" : "flex f-col items-center justify-center ml-auto mr-auto"
}

function objetosSegunParametros(parametros,objeto){

//    if (parametros.tipo=='cronologico'){
    if (parametros.tipo){
        switch(objeto){
            case 'api': return `api/reportes/reporteingresos/${masParametros(parametros)}`
            case 'titulo': return 'Ingresos integrales'
            case 'titulos': return {col1:'Fecha',col2:'Comprobante',col3:'Contribuyente',col4:'Titular',col5:'Monto',col6:'Rubro',col7:'Pago',col8:'Detalle'}
            case 'columnas' : return {col1:'Manejo las columnas en la funcion bodyTablaEgresosCronologicos'}
            case 'filtro' : return {campo1:'rubro',campo2:'comprobante', campo3:'contribuyente', campo4:'titular',campo5:'monto_fp',campo6:'provincia',campo7:'det_rc'}
            case 'csv' : return item=>{return {fecha:transformarIso8601(item.fecha),proveedor:item.proveedor,tipo_comp:item.tipo_compr,nro_comprobante:item.nro_compr,descripcion:item.descripcion,monto:item.monto,caja:item.caja,rubro:item.rubro,fondo:item.fondo,datos_bancarios:item.datos_bcrios,tanda:item.tanda_desc}}
            case 'pdf' : return item => { return { 
                fecha: transformarIso8601(item.fecha), 
                proveedor: item.proveedor, 
                comprobante: `${item.tipo_compr}/${item.nro_compr}`, 
                descripcion: item.descripcion, 
                monto: item.monto, 
                caja: item.caja, 
                rubro: item.rubro,
                fondo:item.fondo,
                tanda:item.tanda_desc,
                datos_bancarios: item.datos_bcrios}
            }
            case 'excel' : return [{label:'Proveedor',value:'proveedor'},
                        {label:'Rubro',value:'rubro'},
                        {label:'Caja',value:'caja'},
                        {label:'Descripción',value:'descripcion'},
                        {label:'Datos bancarios',value:'datos_bcrios'},
                        {label:'Fondo',value:'fondo'},
                        {label:'TC',value:'tipo_compr'},
                        {label:'Comprobante',value:'nro_compr'},
                        {label:'Monto',value:'monto'},{label:'Monto plano',value:'monto_numerico'}]
            case 'arraypdf' : return [{ text: 'Fecha', style: 'tableHeader' }, 
            { text: 'Proveedor', style: 'tableHeader' }, 
            { text: 'Comprobante', style: 'tableHeader' }, 
            { text: 'Descripción', style: 'tableHeader' }, 
            { text: 'Monto', style: 'tableHeader' }, 
            { text: 'Caja', style: 'tableHeader' }, 
            { text: 'Rubro', style: 'tableHeader' },
            { text: 'Fondo', style: 'tableHeader' },
            { text: 'Tanda', style: 'tableHeader' },
            { text: 'Info Banco', style: 'tableHeader' }]
            case 'orientacion' : return 'landscape'
            case 'subtitulo' : return 'landscape'
            case 'clasesbody' : return {col1:'filas-lista-em ti-reporte-mn',col2:'filas-lista-em ti-reporte-xl',col3:'filas-lista-em ti-reporte-xl',col4:'filas-lista-em ti-reporte-xl',col5:'filas-lista-em ti-reporte-sm text-right'}
            case 'claseshead' : return {col1:'filas-lista-em ti-reporte-mn',col2:'filas-lista-em ti-reporte-xl',col3:'filas-lista-em ti-reporte-xl',col4:'filas-lista-em ti-reporte-xl text-center',col5:'filas-lista-em ti-reporte-sm text-right'}
        }
    }else{
        switch(objeto){
            case 'api': return `api/reportes/resumenegresos/${masParametros(parametros)}`
            case 'titulo': return 'Resumen de egresos'
            case 'titulos': return {col1:'Rubro',col2:'Código',col3:'Mes',col4:'Año',col5:'Monto'}
            case 'columnas' : return {col1:'rubro',col2:'codigo_rubro',col3:'mes_largo',col4:'año',col5:'egreso'}
            case 'filtro' : return {campo1:'rubro',campo2:'codigo_rubro',campo3:'egreso_numerico'}
            case 'csv' : return item=>{return {rubro:item.rubro,codigo_rubro:item.codigo_rubro,egreso:item.egreso,periodo:`${item.mes_largo} ${item.año}`}}
            case 'pdf' : return item => { return { 
                rubro: item.rubro, 
                codigo_rubro: item.codigo_rubro, 
                año: item.año,
                mes:item.mes,
                periodo: `${item.mes_largo} ${item.año}`, 
                egreso: item.egreso}
            }
            case 'excel' : return [{label:'Rubro',value:'rubro'},{label:'Código',value:'codigo_rubro'},{label:'Egreso',value:'egreso'},{label:'Mes',value:'mes_largo'},{label:'Año',value:'año'}]
            case 'arraypdf' : return [{ text: 'Rubro', style: 'tableHeader' }, 
            { text: 'Cod. Rubro', style: 'tableHeader' }, 
            { text: 'Período', style: 'tableHeader' }, 
            { text: 'Egreso', style: 'tableHeader' }]
            case 'orientacion' : return 'portrait'
            case 'clasesbody' : return {col1:'ti-reporte-xl',col2:'ti-reporte-mn',col3:'ti-reporte-sm',col4:'ti-reporte-sm',col5:'ti-reporte-mn text-right'}
            case 'claseshead' : return {col1:'ti-reporte-xl',col2:'ti-reporte-mn',col3:'ti-reporte-sm',col4:'ti-reporte-sm',col5:'ti-reporte-mn text-right'}
        }
    }
}


function bodyTablaEgresosCronologicos (clasesbody){
    return item => {
        return (
            <tbody key={uuidv4()}>
                <tr  className="border-bottom-solid">
                    <td>
                        <span className={clasesbody.col1}>
                                {transformarIso8601(item.fecha)} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                        </span>         
                        <span className={clasesbody.col2}>{item.comprobante}</span>
                        <span className={clasesbody.col3}>{item.contribuyente}</span>
                        <span className={clasesbody.col4}>{item.titular}</span>
                        <span className={clasesbody.col5}>{item.monto}</span>
                        <span className={clasesbody.col6}>{item.rubro}</span>
                        <span className={clasesbody.col7}>{item.forma_pago}</span>
                        <span className={clasesbody.col8}>{item.det_rec}</span>
                    </td>
                </tr>
            </tbody>
            )
        }
}



function masParametros(parametros){

    /*
    reporteegresos/
    :dia
    :mes
    :anio
    :id_af
    :id_tanda
    :f_desde
    :f_hasta
    :id_proveedor
    :id_iglesia
    :id_obrero
    :id_moneda
    :id_mov_banco
    :id_fondo
    :id_rubro
    :id_caja'

    */
        switch(parametros.criterio){
            case tiposCriterios.tanda: return `*/*/*/*/${parametros.id_tanda}/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case tiposCriterios.añofiscal: return `*/*/*/${parametros.id_año_fiscal}/*/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case tiposCriterios.año: return `*/*/${parametros.año.getFullYear()}/*/*/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case tiposCriterios.mesaño: return `*/${parametros.mesAño.getMonth()+1}/${parametros.mesAño.getFullYear()}/*/*/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case tiposCriterios.periodo: return `*/*/*/*/*/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
            case tiposCriterios.fecha: return `${parametros.mesAño.getDate()}/${parametros.mesAño.getMonth()+1}/${parametros.mesAño.getFullYear()}/*/*/*/*/${noNull(parametros.id_proveedor)}/${noNull(parametros.id_iglesia)}/${noNull(parametros.id_obrero)}/${noNull(parametros.moneda)}/${noNull(parametros.formapago)}/${noNull(parametros.fondo)}/${noNull(parametros.rubro)}/${noNull(parametros.caja)}`
        }
}

function noNull(valor){
    if (valor){
        return valor
    }else{
        return '*'
    }
}

function convertirMesAñoAfechaDesde(fecha){
    
    // el propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha desde es el primer día del mes y año seleccionados
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

   // const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    

  // return `${mes < 10 ? `0${mes}` : mes}-01-${anio}`
   return `${anio}-${mes < 10 ? `0${mes}` : mes}-01`

}

function convertirMesAñoAfechaHasta(fecha){
        
    // el propósito de esta conversión es recibir una fecha que fue tomada del datepicker en formato
    // mes/año y asegurar que la fecha hasta es el último día del mes y año seleccionados
    // también asegurarnos que se envía separado por guiones para que la fecha pueda viajar
    // sin errores en el request (no puedo mandar barras invertidas por ejemplo)

    const fecha_new = new Date(fecha)

    const mes = fecha_new.getMonth() + 1

    const dia = fecha_new.getDate()

    const anio = fecha_new.getFullYear()
    
   const nueva_fecha =  `${anio}-${mes < 10 ? `0${mes}` : mes}-${dia < 10 ? `0${dia}` : dia}`

    return obtenerFechaUltimoDiaMes(nueva_fecha,'YYYY-MM-DD','YYYY-MM-DD')
}

function Prueba({ref2}){
    const reff = useRef('')

    return <><select title={`dd ${reff.current.value}`} ref={ref2} name="" id="">
        <option value="55">asdfasdf</option>
        <option value="78">gggg</option>
    </select>
    </>
}

function Impresiones({datos,callbackCSV,titulo,callbackPDF,arrayPDF,orientacionPDF,descripcion,parametros}){

    const [iniciarImprimirPDF,setIniciarImprimirPDF] = useState(false)

    const datosCSV = datos.map(callbackCSV)
    
    const cerrarImpresionPDF = ()=>{
        setIniciarImprimirPDF(false)
    }

    const iniciarImpresionPDF = ()=>{
        if (iniciarImprimirPDF){
            setIniciarImprimirPDF(false)
        }else{
            setIniciarImprimirPDF(true)
        }
    }

    return <div className="flex f-col">
            {datos.length > 0 && <div className="centro-w100pc">
                <span onClick={iniciarImpresionPDF} className="cursor-pointer botonNc ml-6" >
                        <FontAwesomeIcon onClick={iniciarImpresionPDF} className="color-tomato" icon={faFilePdf}/> Archivo PDF
                </span> 
                <div className="relative w-100 pdfcontainer inline">
                    {iniciarImprimirPDF && <ImpresionPDF registrosAmostrar ={datos} cerrar={cerrarImpresionPDF} impresion={imprimirEcronologicos} callbackPDF={callbackPDF} arrayPDF={arrayPDF} orientacion={orientacionPDF} tituloDefault={descripcion} parametros={parametros}/>}
                </div>
                {/*<CSVLink
                        data={datosCSV}
                        filename={`${titulo}.csv`}
                        target="_blank"
                        className="tdec-none"
                        >
                         <span className="cursor-pointer botonNc ml-6 text-black tdec-none">
                            <FontAwesomeIcon className="color-tomato" icon={faFileDownload}/> Archivo CSV 
                        </span>
                </CSVLink>*/}
                <Excel datos={datos} descripcion={descripcion}/>
            </div>}
    </div>
}

function Excel({datos,descripcion}){

    const copia = [agregarFila(datos,descripcion),...datos]
    
   return '' /*<ExcelFile filename={`Egresos cronológicos`} element={
        <span className="cursor-pointer botonNc ml-6" >
            <FontAwesomeIcon className="color-tomato" icon={faFileExcel}/> Exportar a Excel
        </span> 
    }>
        <ExcelSheet data={copia} name="Egresos">
            <ExcelColumn label="Proveedor" value="proveedor"/>
            <ExcelColumn label="Monto" value="monto_numerico"/>
            <ExcelColumn label="Rubro" value="rubro"/>
            <ExcelColumn label="Caja" value="caja"/>
            <ExcelColumn label="Rubro" value="rubro"/>
            <ExcelColumn label="Descripción" value="descripcion"/>
            <ExcelColumn label="Datos bancarios" value="datos_bcrios"/>
            <ExcelColumn label="Fondo" value="fondo"/>
            <ExcelColumn label="TC" value="tipo_compr"/>
            <ExcelColumn label="Comprobante" value="nro_compr"/>
        </ExcelSheet>
</ExcelFile>*/
}

function agregarFila(datos,descripcion){
    return {...datos[0],
            proveedor:descripcion,
            monto_numerico:null,
            rubro:null,
            caja:null,
            rubro:null,
            descripcion:null,
            datos_bcrios:null,
            fondo:null,
            tipo_compr:null,
            nro_compr:null}
}

/*
<input className="ml-4" type="radio" title={item.title} checked={parametros.tipo==item.id} id={item.id} name = "tipo" value={item.id} onClick={handleCheckTipo}/>
             <label for={item.id}>{item.nombre}</label>
*/
const TipoReporte = ({handleChangeTipo,parametros})=>{
    return <select value={parametros.tipo} onChange={handleChangeTipo}> {
        tiposReporte.map(item=><option value={item.id}>
             {item.nombre}
        </option>)
    }
    </select>
}

const TipoReporte_old = ({handleCheckTipo,parametros})=>{
    return <> <input className="ml-4" type="radio" title="Seleccionar Recibo General" checked={parametros.tipo=='recibo-general'} id="tipo-rg" name = "tipo" value="recibo-general" onClick={handleCheckTipo}/>
        <label for="tipo-rg">Recibo general</label>
        <input type="radio" title="Seleccionar Rubros" checked={parametros.tipo=='rubro'} id = "tipo-ru" name="tipo" value="rubro" onClick={handleCheckTipo}/>
        <label for="tipo-ru">Rubros</label>
        <input type="radio" title="Seleccionar Rubros y sus FP" checked={parametros.tipo=='rubros-fp'} id = "tipo-rfp" name="tipo" value="rubros-fp" onClick={handleCheckTipo}/>
        <label for="tipo-rfp">Rubros y sus FP</label>
        <input type="radio" title="Seleccionar FP" checked={parametros.tipo=='tipo-fp'} id = "tipo-fp" name="tipo" value="fp" onClick={handleCheckTipo}/>
        <label for="tipo-fp">FP</label>
        <input type="radio" title="Seleccionar FP y sus rubros" checked={parametros.tipo=='fpr'} id = "tipo-fpr" name="tipo" value="fpr" onClick={handleCheckTipo}/>
        <label for="tipo-fpr">FP y sus rubros</label>
        <input type="radio" title="Seleccionar Reporte CDN" checked={parametros.tipo=='cdn'} id = "tipo-cdn" name="tipo" value="cdn" onClick={handleCheckTipo}/>
        <label for="tipo-cdn">Reporte CDN</label>
        <input type="radio" title="Seleccionar Rubros y sus aportantes" checked={parametros.tipo=='rap'} id = "tipo-rap" name="tipo" value="rap" onClick={handleCheckTipo}/>
        <label for="tipo-rap">Rubros y sus aportantes</label>
        <input type="radio" title="Seleccionar Aportantes y sus rubros" checked={parametros.tipo=='apr'} id = "tipo-apr" name="tipo" value="apr" onClick={handleCheckTipo}/>
        <label for="tipo-crono">Aportantes y sus rubros</label>
    </>
}

const obtenerValueDeID = (id)=>{
    const element = document.getElementById(id)

    return element ? document.getElementById(id).value : '' 
 
}