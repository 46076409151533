import React, { useState, useEffect, useRef } from 'react';
import { Fecha } from '../../../componentes/Fecha'
import { TextField, Box } from "@material-ui/core";
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Option, Select, Typography } from '@material-tailwind/react';

export const Cabecera = ({
    filtros,
    setFiltros,
}) => {

    const [textoNombre, setTextoNombre] = useState("");
    const [textoFondo, setTextoFondo] = useState("");
    const [textoRubro, setTextoRubro] = useState("");
    const { esCelular } = useContextoGlobal();

    const handleFilterChange = (e, type) => {
        if (type === 'nombre') setTextoNombre(e.target.value);
        if (type === 'fondo_tesoreria_nombre') setTextoFondo(e.target.value);
        if (type === 'rubro_ingreso') setTextoRubro(e.target.value);
        setFiltros({ ...filtros, [type]: e.target.value });
    }

    return <div className='flex f-col mt-4'>
        <div className={`flex ${esCelular ? 'f-col space-y-2' : 'f-row space-x-4'} justify-center flex-wrap abc`}>
            <Box sx={{}}>
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Nombre
                </Typography>
                <TextField fullWidth id="texto-nombre" inputProps={{}} value={textoNombre} onChange={(e) => handleFilterChange(e, 'nombre') } variant="outlined" />
            </Box>

            <Box sx={{}}>
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Fondo Tesorería
                </Typography>
                <TextField fullWidth id="texto-resoreria" inputProps={{}} value={textoFondo} onChange={(e) => handleFilterChange(e, 'fondo_tesoreria_nombre') } variant="outlined" />
            </Box>

            <Box sx={{}}>
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Rubro Ingreso
                </Typography>
                <TextField fullWidth id="texto-rubro" inputProps={{}} value={textoRubro} onChange={(e) => handleFilterChange(e, 'rubro_ingreso') } variant="outlined" />
            </Box>
        </div>
    </div>
}




