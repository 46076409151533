import React, {useState, useEffect} from 'react';
import {Main,Axios,Swal,Loading,Modal,useModal,uuidv4} from '../../../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare, faUsers, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { Button, Card, IconButton, List, ListItem, Spinner, Tooltip, Typography } from '@material-tailwind/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { DocumentIcon, PencilIcon, UserIcon } from '@heroicons/react/24/solid';
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Divider } from '@material-ui/core';
import moment from 'moment';
import EventoForm from './EventoForm';
import EventoValorForm from './EventoValorForm';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import {fechaHoy,transformarFechaTramiteSimple,esMayor} from '../../../Helpers/fechas';
import {Cerrar} from './Cerrar';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import {formatearMontoMoneda} from '../../../Helpers/utilidades-globales';

export default function EventosValor({id_evento,cerrar}){

    const [datos,setDatos]=useState([]);
    const [datosEventos,setDatosEventos]=useState([]);
    const [buscando,setBuscando]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [idSeleccionado, setIdSeleccionado]=useState(null)
    const [contadorOperaciones,setContadorOperaciones]=useState(0);
    const [ejecutarAlta, setEjecutarAlta]=useState(false)
    const {toggle, isShowing } = useModal();
    const { esCelular } = useContextoGlobal();
    const [idEventoSeleccionado, setIdEventoSeleccionado]=useState(null)
    const [evento,setEvento] = useState(null);

    useEffect(()=>{
       
        setBuscando(true)

        const buscarAsambleas = async ()=>{

           try{
                const eventos = await Axios.get(`/api/tablasgenerales/eventos/eventosvalor/${id_evento}`)
                setDatosEventos(eventos.data)
                const {data} = await Axios.get(`api/tablasgenerales/evento/${id_evento}`) 
                setEvento({...data,fecha:transformarFechaTramiteSimple(data.fecha,'DD/MM/YYYY','YYYY-MM-DD')})

                setBuscando(false)
            }catch(err){
                setBuscando(false)
                setHuboError(true)
            }
        }
        
        buscarAsambleas()
    },[contadorOperaciones])

    useEffect(()=>{
        if(!isShowing){
           // si hubiera un modal
        }
    },[isShowing])

    const finalizarAltaOcopia= (valor)=>{

        setIdEventoSeleccionado(null)
        setEjecutarAlta(false)

       if (valor){
            setContadorOperaciones(contadorOperaciones+1)
        }
    }

    const seleccionar=(e,item)=>{

        if(!item){
            setIdEventoSeleccionado(null)
            return
        }

        setIdEventoSeleccionado(item.id_evento_valor)
    }

    if (huboError){
        return <Main center>
                        <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                            <span>Se produjo un error al cargar los datos para esta vista</span>
                        </div>
                </Main>
    }

    if (buscando){
        return <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
                    </div>
                </Main>
    };

    return(
        <div className='flex flex-col m-5 lg:m-auto xl-m-auto'>
            <div className="h-full justify-center mt-4">
                <Button variant="text" size='md' className="rounded-full mt-4 flex justify-center items-center" onClick={()=>setEjecutarAlta(true)}>
                        Crear un nuevo item
                </Button>
                {!idSeleccionado && <div className='flex flex-col mb-4 relative'>
                    <Typography variant="h4" color="black" className="flex justify-center items-center">
                        Items de: {evento?.titulo} 
                    </Typography>  
                    <Cerrar cerrar={cerrar}/>
                </div>}
                
                { esCelular ? <TablaMobile datos={datos} 
                                                seleccionar={seleccionar}
                                                textoBusqueda={''} 
                                                setIdSeleccionado={setIdSeleccionado}
                                                idSeleccionado={idSeleccionado}
                                                finalizarAltaOcopia={finalizarAltaOcopia}
                                                toggle={toggle}   /> 
                                                : 
                                                <ListaEventosValor tabla={datosEventos} 
                                                    seleccionar={seleccionar} 
                                                    textoBusqueda={''} 
                                                    idSeleccionado={idEventoSeleccionado}
                                                    finalizarAltaOcopia={finalizarAltaOcopia}
                                                    toggle={toggle}
                                                    /> 
                }  
            </div> 
            {/* {idEventoSeleccionado && <EventoValorForm id_evento_valor={53} finalizarAltaOcopia={()=>console.log('finalizando form evento valor')}/>} */}
            <Dialogos open={idEventoSeleccionado}      
                                fullscreen={true}
                                >
                    <EventoValorForm id_evento_valor={idEventoSeleccionado} detalle={evento?.titulo} id_evento={id_evento} finalizarAltaOcopia={finalizarAltaOcopia}/>
            </Dialogos> 
            <Dialogos open={ejecutarAlta}      
                                fullscreen={true}
                                >
                    <EventoValorForm id_evento={id_evento} detalle={evento?.titulo} finalizarAltaOcopia={finalizarAltaOcopia}/>
            </Dialogos>
        </div>
    )
}


function TablaMobile({datos,seleccionar,
                    textoBusqueda, 
                    idSeleccionado}){

if(!idSeleccionado){
    return <Card>
                <List> 
                    {datos.filter(
                        item=>item.descripcion.toUpperCase().includes(textoBusqueda.toUpperCase())).map((item, index)=>
                    <>
                    <ListItem key={index} className="cursor-pointer">
                        <div className='f-col space-y-2'>
                            <p><span className='font-bold'>Nombre: </span>{item.titulo}</p>
                            <p><span className='font-bold'>Descripción: </span>{item.detalles}</p>
                            <p><span className='font-bold'>Fecha: </span>{item.fecha}</p>
                            <div className='flex space-x-2'>
                                <Tooltip content="Editar">
                                    <IconButton variant="text" onClick={(e)=>{seleccionar(e,item)}}>
                                        <PencilIcon className="h-4 w-4" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                    </ListItem>
                    <Divider />
                    </>
                    )}
                </List>
            </Card>
    }
}

function ListaEventosValor({tabla,
                    seleccionar,
                    textoBusqueda, 
                    idSeleccionado
                    }){


              
    if(!idSeleccionado){

    const TABLE_HEAD = ["Id","Detalle",  "Valor", "Activo", "Acciones"];

    return (
    <div>
        <Card className="h-full w-full overflow-scroll-none">
            <table className="w-full table-auto text-left">
                <thead>
                    <tr>
                        {TABLE_HEAD.map((head) => (
                        <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                        >
                            <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                            >
                            {head}
                            </Typography>
                        </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                {tabla
                    .filter(
                        item=>item.detalle.toUpperCase().includes(textoBusqueda.toUpperCase()))
                    .map((item, index) => {
                    const isLast = index === tabla.length - 1;
                    const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
        
                    return (
                    <tr key={uuidv4()}>
                        <td className={classes}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                        >
                            {item.id_evento_valor}
                        </Typography>
                        </td>
                        <td className={classes}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                        >
                            {item.detalle}
                        </Typography>
                        </td>
                        {/* <td className={classes}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                        >
                            {item.detalles}
                        </Typography>
                        </td> */}
                        <td className={`${classes} text-right w-7`}>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                        >
                            {formatearMontoMoneda(item.valor)}
                        </Typography>
                        </td>
                        <td className={`${classes} felx space-x-2 w-7`}>
                            {item.activo==1 && <CheckCircleIcon title='Activo' width={24} className="text-blue-200 ml-auto mr-auto"/>}
                            {item.activo==0 && <XCircleIcon  title='Inactivo' width={24} className="text-red-500 ml-auto mr-auto"/>}
                        </td>
                        <td className={`${classes} felx space-x-2`}>
                            <Tooltip content="Editar">
                                <IconButton variant="text" onClick={(e)=>{seleccionar(e,item)}}>
                                    <PencilIcon className="h-4 w-4" />
                                </IconButton>
                            </Tooltip>
                        </td>
                    </tr>
                    );
                })}
                </tbody>
            </table>
            </Card>
    </div>
    )}
    else{
       return <>
            <EventoForm id_evento={idSeleccionado} finalizarAltaOcopia={()=>seleccionar(null)}/>
       </>

    }
}

const analizarFecha = (fecha)=>{

    const ayer = new moment().subtract(1, 'days')
    // por default moment utiliza el formato de fecha ISO 8601 (YYYY-MM-DD) para interpretar las fechas, si la fecha viene en otro formato hay que indicarlo para que pueda comparar correctamente las fechas
    const fechaAsamblea = new moment(fecha,'DD/MM/YYYY');

    if(fechaAsamblea.isAfter(ayer)){
        return true
    }else{
        return false
    }
}