import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@material-ui/core';
import { Typography } from '@material-tailwind/react';
// este componente se usa en conjunto con el hook usePaginacion
// primero se debe importar el hook

// import usePaginacion from '../hooks/usePaginacion';

// Luego se extraen los métodos y variables

// const {iIni, iFin,iMenos,fMenos,iMas,fMas,paginar,mostrar,mostrarMas,mostrarMenos,inicializar} = usePaginacion();

// Luego se pasan los parámetros correspondientes a este componente

export default function Paginacion({ configuracion, paginar }) {

    return <div className='flex justify-end gap-4 p-3'>
        {configuracion.mostrar && configuracion.mostrarMenos &&
            <Button variant="outlined" size="sm" className='!bg-white' onClick={() => paginar(configuracion.iMenos, configuracion.fMenos)}>
                Previo
            </Button>
        }
        <Typography variant="h6" className="font-normal mt-2">
            {configuracion.iIni + 1} - {configuracion.iFin + 1}
        </Typography>
        {configuracion.mostrar && configuracion.mostrarMas &&
            <Button variant="outlined" size="sm" className='!bg-white' onClick={() => paginar(configuracion.iMas, configuracion.fMas)}>
                Siguiente
            </Button>
        }
    </div>
}