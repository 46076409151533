import React from 'react';
import {useState, useEffect, useRef} from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import Loading from '../componentes/Loading';
import Swal from 'sweetalert2';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots, faEdit,faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {faSync,faWindowClose} from '@fortawesome/free-solid-svg-icons';
import {hacerScroll,hacerfocoEnPrimerInput,seleccionarTextoInput} from '../Helpers/utilidades-globales';
import { faReply, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Button, Dialog, DialogBody, DialogFooter, DialogHeader, Input, Textarea, Typography } from '@material-tailwind/react';

export default function FormularioAlerta({alerta,usuario,finAlerta}){

    const [responder,setResponder] = useState(false)
    const [respuesta,setRespuesta] = useState('')
    const [original,setOriginal] = useState('')
    const [asunto,setAsunto] = useState('')
    const [destinatario,setDestinatario] = useState('')
    const [grabandoDatos,setGrabandoDatos] = useState('')
    const [errores,setErrores]=useState({asunto:'',mensaje:''})
    const [open, setOpen] = React.useState(true);
 
    const handleOpen = () => {
        setOpen(!open)
        finAlerta()
    };

    useEffect(()=>{
        const concatenacionMensaje = 
`------------------------------------------------------------\n
${alerta.mensaje}`

        setOriginal(concatenacionMensaje)

        const nuevoAsunto = `RT/${alerta.asunto}`

        setAsunto(nuevoAsunto)
    },[])

    function handleChangeRespuesta(e){
        setRespuesta(e.target.value)
    }

    function iniciarResponder(){
        setRespuesta('')
        setResponder(true)
        hacerfocoEnPrimerInput('abm-respuesta')
    }

    const grabarDatos = async (values)=>{
        const concatenacion = 
            `${respuesta}\n
            ${original}`

        const objetoAgrabar = {
            usuario: usuario,
            asunto: asunto,
            mensaje: concatenacion,
            destinatario: alerta.usuario
        }
    
        setGrabandoDatos(true)

        try{
            const respuesta = await Axios.post('/api/tablasgenerales/alerta',objetoAgrabar)
            finAlerta()
        }catch(err){
            console.log(err)
        }

    }

    const mensajeValido = ()=>{
        return respuesta!=''
    }
    
        
    const iniciarGrabar = (e)=>{

        e.preventDefault()
    
        if (!mensajeValido()){
            return
        }

        let texto;
        let textoConfirmacion;
    
            texto = `¿Confirma el envío del mensaje?`
            textoConfirmacion = 'Si, enviar el mensaje'
    
        Swal.fire({
            text:texto,
            showCancelButton:true,
            confirButtonText:textoConfirmacion,
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
    //                grabarDatos(values);
                    grabarDatos();
    
                }else{
                    console.log("Se canceló el envío del mensaje")
                }
            }
        )
    }      
          
    return <Main center>
                <Dialog open={open} size="md">
                    <form onSubmit={iniciarGrabar}>
                        <div className="flex items-center justify-between">
                            <DialogHeader className="flex flex-col items-start">
                                <div className="lg:flex space-x-2 lg:f-row border-bottom-dotted-gray">
                                    <div className='flex space-x-2'>
                                        <FontAwesomeIcon icon={faCommentDots} className="color-tomato mt-1"/>
                                        <span className="fw-700">{alerta.usuario.toUpperCase()}</span>
                                    </div>
                                    <div><span>{alerta.f_detallada}</span></div>
                                </div>
                            </DialogHeader>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="mr-3 h-5 w-5 -mt-2"
                                onClick={handleOpen}
                            >
                                <path
                                fillRule="evenodd"
                                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                                clipRule="evenodd"
                                />
                            </svg>
                        </div>
                        <DialogBody>
                            <span className="inline-block-1 font-bold">{alerta.asunto}</span>
                            <div>
                                <span className="fw-700 mb-2 text-small inline-block-1">{alerta.usuario.toUpperCase()} dice: </span>
                                <Textarea 
                                    label="Message"
                                    rows={7} 
                                    value={alerta.mensaje}
                                    disabled
                                />
                            </div>
                            {!responder && <Button variant="gradient" onClick={iniciarResponder}>
                                        <FontAwesomeIcon className="color-tomato mr-2" icon={faReply}/> Responder
                                    </Button>
                            }

                            {responder &&  <>
                                    <FontAwesomeIcon icon={faCommentDots} className="color-green mt-1" />
                                    <span className="fw-700 ml-2 mt-2 text-small inline-block-1">Mi respuesta a {alerta.usuario}: </span>
                                        {   <div className="flex f-col mb-2">
                                                <Typography
                                                    variant="small"
                                                    color="blue-gray"
                                                    className="mb-1 font-medium "
                                                >
                                                    Asunto
                                                </Typography>
                                                <Input
                                                    id="abm-asunto"
                                                    name="asunto" 
                                                    maxLength="80"
                                                    value = {asunto} 
                                                    color='black'
                                                    disabled
                                                />
                                            </div>
                                        }
                                        <div className="flex f-col">
                                            <Textarea 
                                                id="abm-respuesta"
                                                maxLength="500"
                                                label="Mensaje"
                                                rows={7} 
                                                value = {respuesta}
                                                onChange={(e)=>handleChangeRespuesta(e)}
                                            />
                                        </div>
                                    </>
                            }
                        </DialogBody>
                        <DialogFooter >
                            {mensajeValido() && 
                                <div className="space-x-2">
                                    <Button color="gray" onClick={handleOpen}>
                                        Cancelar
                                    </Button>
                                    <Button type="submit" variant="gradient">
                                        Responder
                                    </Button>
                                </div>
                            }  
                        </DialogFooter>
                    </form>
                </Dialog>
        </Main>
    
}


function filasDinamicas(mensaje){
    const longitudMensaje = mensaje.length;
    let ancho = 0;

    console.log(longitudMensaje)

    ancho = Number(longitudMensaje) / 30;

    console.log(ancho)

    return ancho
}












