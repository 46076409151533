import React, {useState, useEffect, useRef} from 'react';
import {Main,Axios,Swal} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faSignOutAlt,faSignInAlt,faWindowClose,faCircle,faPortrait,faUser } from '@fortawesome/free-solid-svg-icons';
import {useContextoGlobal} from '../Context/contextoGlobal';
import useRangosAsamblea from '../hooks/useRangosAsamblea';
import Ventana from '../componentes/Ventana'
import LogAsambleaRegistros from '../componentes/LogAsambleaRegistros'
import { Button, IconButton, Input, Spinner, Typography } from '@material-tailwind/react';
import { IdentificationIcon, UserGroupIcon, UserIcon } from '@heroicons/react/24/solid';
import { InformationCircleIcon, MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/outline';
import ExportToExcelAsamblea from '../componentes/exportExcelAsamblea';

export default function AsambleaPresentes({id_asamblea,finalizarAltaOcopia,nombre}){

    const [huboError,setHuboError]=useState(false)
    const [grabandoDatos,setGrabandoDatos]=useState(false)
    const [datos,setDatos]=useState([])
    const [rangos,setRangos]=useState([])
    const [regiones,setRegiones]=useState([])
    const [buscando,setBuscando]=useState(false)
    const [texto,setTexto]=useState('')
    const [regSeleccionadas,setRegSeleccionadas]=useState([])
    const [rangosSeleccionados,setRangosSeleccionados]=useState([])
    const [statusSeleccionados,setStatusSeleccionados]=useState([])
    const [sexoSeleccionados,setSexoSeleccionados]=useState([])
    const {conexionWebSocket,usuario,esCelular} = useContextoGlobal();
    const [mensaje,setMensaje]=useState('')
    const [orden,setOrden]=useState('f_ingreso')
    const [ab,setAb]=useState(true)
    const [qr,setQr]=useState(null)
    const {rangosAsamblea,buscarRangos,buscandoRangos} = useRangosAsamblea()
    const [logs,setLogs]=useState(false)
    const [registros,setRegistros] = useState([])
    const todos = useRef(false)
    const [filas,setFilas] = useState(20)
    const [modoCompleto,setModoCompleto] = useState(true)
    const [exportar,setExportar] = useState(false)

    useEffect(()=>{
        conexionWebSocket.on('mensaje_nuevo_registrado',data=>{
            //alert(`registrado websocket ${data.id_asamblea}`)
            if(data.id_asamblea==id_asamblea){
                setMensaje(data.mensaje)
                setTimeout(() => {
                    setMensaje('')
                }, 800);
                buscarPresentes()
            }
        })
        conexionWebSocket.on('mensaje_modificacion_registrado',data=>{
            if(data.id_asamblea==id_asamblea){
                setMensaje(data.mensaje)
                setTimeout(() => {
                    setMensaje('')
                }, 800);
                buscarPresentes()
            }
        })
    },[])

    useEffect(()=>{
        
        window.addEventListener('keydown', escape);

        return () => {
            window.removeEventListener('keydown', escape);
        };

    },[])

    useEffect(()=>{
        buscarRangos(id_asamblea)
    },[])

    useEffect(()=>{
       
        if(rangosAsamblea.length>0){
            setBuscando(true)
            buscarTablasGenerales()
            .then(()=>{
                buscarPresentes()
            })
        }
       
    },[rangosAsamblea])

    const buscarPresentes = async ()=>{

        try{
             const {data}= await Axios.get(`/api/tablasgenerales/asambleapresentes/${id_asamblea}`)
     
             if(data.length==0){
                 finalizarAltaOcopia()
                 informarNoHayPresentes(nombre)
             }

             setDatos(data)
             setBuscando(false)
         }catch(err){
             setBuscando(false)
             setHuboError(true)
         }
     }

    const buscarTablasGenerales = async ()=>{
        try{
            const vectorResultados = await Promise.all([Axios.get('/api/tablasgenerales/rangos'),Axios.get('/api/tablasgenerales/regiones/*')])
            setRangos(vectorResultados[0].data.filter(item=>rangosAsamblea.some(rango=>item.id_rango==rango)))
            setRegiones(vectorResultados[1].data)
        }catch(err){
            setHuboError(true)
            console.log('Error al buscar los datos generales')  
        }
    }

    const filtroRango = (id_rango)=>{
        if (!rangosSeleccionados.some(rango=>rango==id_rango)){
            setRangosSeleccionados([...rangosSeleccionados,id_rango])
        }else{
            setRangosSeleccionados(rangosSeleccionados.filter(rango=>rango!=id_rango))
        }
    }

    const filtroRegion = (id_region)=>{
        if (!regSeleccionadas.some(region=>region==id_region)){
            setRegSeleccionadas([...regSeleccionadas,id_region])
        }else{
            setRegSeleccionadas(regSeleccionadas.filter(region=>region!=id_region))
        }
    }

    const filtroSexo = (id_sexo)=>{
        if (!sexoSeleccionados.some(sexo=>sexo==id_sexo)){
            setSexoSeleccionados([...sexoSeleccionados,id_sexo])
        }else{
            setSexoSeleccionados(sexoSeleccionados.filter(sexo=>sexo!=id_sexo))
        }
    }

    const filtroStatus = (id_status)=>{
        if (!statusSeleccionados.some(status=>status==id_status)){
            setStatusSeleccionados([...statusSeleccionados,id_status])
        }else{
            setStatusSeleccionados(statusSeleccionados.filter(status=>status!=id_status))
        }
    }

    const handleOrden = (campo)=>{
        if(campo==orden){
            setAb(!ab)
        }else{
            setAb(true)
            setOrden(campo)
        }
    }

    const handleKeyPress = (event) => {
        // el código qr solo se toma al enter si presiona otra tecla se borra el qr
        if(event.key === 'Enter'){

            const copy = event.target.value;
            if(copy.includes('ministros.uad.digital')){
                setTexto(copy.substring(copy.length-4,copy.length))
                setQr(copy.substring(copy.length-4,copy.length))
            }else{
                setQr(copy)
            }
        }else{
            setQr(null)
        }

       

    }

    const handleKeyDown = (event) => {
       // el código qr solo se toma al enter si presiona otra tecla se borra el qr
       // el escape borra todo qr y texto
       if(event.keyCode==27){
            setTexto("")
            setQr(null)
        }else{
            setQr(null)
        }
    }

    const listarLogs = async (id_presente)=>{
        todos.current = true

        try{
    //        const {data} = await Axios.get(`api/tablasgenerales/asamblea/logs/${id_asamblea}/*`)
            const {data} = await Axios.get(`api/tablasgenerales/asamblea/logs/${id_asamblea}/${id_presente}`)
            setRegistros(data)
            setLogs(true)
        }catch(err){
            alert(err)
        }
    }

    const listarAllLogs = async ()=>{
        todos.current = true

        try{
           const {data} = await Axios.get(`api/tablasgenerales/asamblea/logs/${id_asamblea}/*`)
            
           setRegistros(data)
            setLogs(true)
        }catch(err){
            alert(err)
        }
    }

    const iniciarRetiro = (presente)=>{

        Swal.fire({
            text:`¿Confirma el retiro de ${presente.nombre}?`,
            showCancelButton:true,
            confirButtonText:'Si,retirar',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    retirarPresente(presente.id_asamblea_presente,presente.id_obrero!=null,presente.nombre)
                }else{
                    console.log("Se canceló el retiro")
                 }
            }
        )
    }

    const iniciarReingreso = (presente)=>{

        Swal.fire({
            text:`¿Confirma el reingreso de ${presente.nombre}?`,
            showCancelButton:true,
            confirButtonText:'Si,reingresar',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    reingresarPresente(presente.id_asamblea_presente,presente.id_obrero!=null,presente.nombre)
                }else{
                    console.log("Se canceló el retiro")
                 }
            }
        )
    }

    const limpiarSeleccion = ()=>{
        setTexto('')
        setRegSeleccionadas([])
        setSexoSeleccionados([])
        setStatusSeleccionados([])
        setRangosSeleccionados([])
    }

    const retirarPresente = async (id_asamblea_presente,ministro,nombre)=>{
        try{
            
            const objetoAgrabar = { 
                id_asamblea: id_asamblea,
                id_usuario: usuario.id_usuario,
                retirar:1,
                nombre:nombre
            }

            const url = ministro ? `/api/tablasgenerales/asambleapresente/${id_asamblea_presente}` : `/api/tablasgenerales/asambleadelegado/${id_asamblea_presente}`
            await Axios.put(url,objetoAgrabar);

            Swal.fire({
                icon: 'success',
                text: `Retiro exitoso`,
                showConfirmButton: false,
                timer: 1500
              })

        }catch(err){
            Swal.fire({
                icon: 'error',
                text: `${err.response.data.message}`
              })
        }
    }

    const switchModo = ()=>{
        setModoCompleto(!modoCompleto)
    }

    const reingresarPresente = async (id_asamblea_presente,ministro,nombre)=>{
        try{
            
            const objetoAgrabar = { 
                id_asamblea: id_asamblea,
                id_usuario: usuario.id_usuario,
                retirar:0,
                nombre:nombre
            }

            const url = ministro ? `/api/tablasgenerales/asambleapresente/${id_asamblea_presente}` : `/api/tablasgenerales/asambleadelegado/${id_asamblea_presente}`
            await Axios.put(url,objetoAgrabar);

            Swal.fire({
                icon: 'success',
                text: `Reingreso exitoso`,
                showConfirmButton: false,
                timer: 1500
              })

        }catch(err){
            Swal.fire({
                icon: 'error',
                text: `${err.response.data.message}`
              })
        }
    }

    if (huboError){
        return <Main center>
                        <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                            <span>Se produjo un error al cargar los datos para esta vista</span>
                        </div>
                </Main>
    }

    if (buscando){
        return <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando datos...</span>
                    </div>
                </Main>
    };

    if (buscandoRangos){
        return <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Buscando registros...</span>
                    </div>
                </Main>
    }; 

    if (datos.length==0){
        return null
    };


    return (
        <div className="mt-6 text-large relative">
                {logs && <Ventana cerrar={()=>setLogs(false)}>
                    <LogAsambleaRegistros registros={registros} todos={todos.current} esCelular={esCelular}/>
                </Ventana>}
                <div className="flex space-x-2">
                    {!esCelular && 
                        <Button variant="gradient" className="" onClick={listarAllLogs}>
                            Logs de registros
                        </Button>
                    }
                    {!esCelular && 
                        <Button variant="gradient" className="" onClick={switchModo}>
                            {modoCompleto ? 'Ocultar detalles' : 'Mostrar detalles'}
                        </Button>
                    }
                    {!esCelular && <>
                        <ExportToExcelAsamblea id_asamblea={id_asamblea} fileName="Asamblea presentes"/>
                    </>} 
                </div>
                
                <div className="relative mt-4">
                    <Typography variant="h4" className="flex justify-center items-center">
                        {nombre}
                    </Typography>  
                </div>

                <div className="text-center mt-4 relative">
                    {/*<div className="flex justify-center p-4">
                        <p className="m-2">Registrados: {datos.length}</p>
                        <p className="m-2">Presentes: {datos.filter(presente=>presente.retirado==0).length}</p>
                        <p className="m-2">Retirados: {datos.filter(presente=>presente.retirado==1).length}</p>
                    </div>*/}
                        <p className={mensaje?'block color-red text-xl' : 'hide'}>{mensaje}</p>
                        <div className={esCelular ? 'space-y-2' : 'grid grid-col lg:grid-cols-3 xl:grid-cols-3 gap-4'}>
                            <div title="Registrados" className="btn border-2 border-blue-400 flex flex-col items-center rounded-md p-3 cursor-pointer hover:bg-blue-gray-100">
                                <IdentificationIcon className='w-6 h-6'/>
                                <p className="text-md w-80 text-center">Registrados</p>
                                <p className="fw-700">{datos.length}</p>
                            </div>
                            <div title="Presentes" className={`${es_status_seleccionado(statusSeleccionados,0)} btn border-2 border-blue-400 flex flex-col items-center rounded-md p-3 cursor-pointer hover:bg-blue-gray-100`} onClick={()=>filtroStatus(0)}>
                                <FontAwesomeIcon className="color-green" icon={faCircle}/>
                                <p className="text-md w-80 text-center">Presentes</p>
                                <p className="fw-700">{datos.filter(presente=>presente.retirado==0).length}</p>
                            </div>
                            <div title="Retirados" className={`${es_status_seleccionado(statusSeleccionados,1)} btn border-2 border-blue-400 flex flex-col items-center rounded-md p-3 cursor-pointer hover:bg-blue-gray-100`} onClick={()=>filtroStatus(1)}>
                                <FontAwesomeIcon className="color-red" icon={faCircle}/>
                                <p className="text-md w-80 text-center">Retirados</p>
                                <p className="fw-700">{datos.filter(presente=>presente.retirado==1).length}</p>
                            </div>
                            <div title="Hombres" className={`${es_sexo_seleccionado(sexoSeleccionados,'M')} btn border-2 border-blue-400 flex flex-col items-center rounded-md p-3 cursor-pointer hover:bg-blue-gray-100`} onClick={()=>filtroSexo('M')}>
                                <UserIcon className='w-6 h-6'/>
                                <p className="text-md w-80 text-center">Hombres</p>
                                <p className="fw-700">{datos.filter(presente=> presente.sexo=="M").length}</p>
                            </div>
                            <div title="Mujeres" className={`${es_sexo_seleccionado(sexoSeleccionados,'F')} btn border-2 border-blue-400 flex flex-col items-center rounded-md p-3 cursor-pointer hover:bg-blue-gray-100`} onClick={()=>filtroSexo('F')}>
                                <UserIcon className='w-6 h-6'/>
                                <p className="text-md w-80 text-center">Mujeres</p>
                                <p className="fw-700">{datos.filter(presente=>presente.sexo=="F").length}</p>
                            </div>
                            <div title="Delegado" className={`${es_sexo_seleccionado(sexoSeleccionados,'D')} btn border-2 border-blue-400 flex flex-col items-center rounded-md p-3 cursor-pointer hover:bg-blue-gray-100`} onClick={()=>filtroSexo('D')}>
                                <UserGroupIcon className='w-6 h-6'/>
                                <p className="text-md w-80 text-center">Delegados</p>
                                <p className="fw-700">{datos.filter(presente=>presente.sexo=="D").length}</p>
                            </div>
                        </div>
                        {modoCompleto && 
                            <div className="flex flex-col justify-center items-center mt-6 mb-4">
                                <Typography variant="h5" className="flex justify-center items-center">
                                    Mas Detalles de Busqueda
                                </Typography>  
                                <div className={esCelular ? "space-y-1" : "flex space-x-2 mt-2"}>
                                    {rangos.map(rango=>
                                        <div className={`${es_rango_seleccionado(rangosSeleccionados,rango.id_rango)} btn bg-blue-400 text-white flex flex-col items-center rounded-md p-3 cursor-pointer hover:bg-blue-300/60`} onClick={()=>filtroRango(rango.id_rango)}>
                                            {rango.nombre.substring(2, rango.nombre.lenght)}
                                            {rango.iniciales}
                                            {datos.filter(presente=>presente.id_rango==rango.id_rango).length}
                                        </div>
                                    
                                    )}
                                </div>
                                <div className={esCelular ? "grid grid-cols-5 gap-1 mt-2" :"flex space-x-2 mt-2"}>
                                    {regiones.map(region=>
                                        <div className={`${es_region_seleccionada(regSeleccionadas,region.id_region)} btn bg-black text-white flex flex-col items-center rounded-md cursor-pointer hover:bg-black/60`} onClick={()=>filtroRegion(region.id_region)}>
                                            <p className="text-small text-left p-3" >R-{region.id_region} <span className="fw-700">({datos.filter(presente=> presente.id_region==region.id_region).length})</span></p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                {esCelular && <Button className='bg-tomato text-white' title='Ver los logs de registros' onClick={listarAllLogs}>Logs de registros</Button>}


                    {haySeleccion(texto,regSeleccionadas,rangosSeleccionados,statusSeleccionados,sexoSeleccionados) && 
                    <div className="flex justify-center space-x-2">
                        <div className="text-xl mt-1.5">
                            Total : {datos.filter(callbackBusqueda(texto,regSeleccionadas,rangosSeleccionados,statusSeleccionados,sexoSeleccionados)).length}  
                        </div>
                        <IconButton variant="text" color='red' onClick={()=>limpiarSeleccion()}>
                            <XCircleIcon className="h-6 w-6"/>
                        </IconButton>
                    </div>
                    }
                   
                </div>
                <div className={"flex flex-col w-full mt-8"}>
                        <div className={esCelular? "grid grid-rows-1 justify-left space-y-2" : "flex space-x-2"}>
                            <Typography variant="h5" className="flex mt-1.5">
                                Ordenar por:
                            </Typography> 
                            <Button variant="gradient" className="" oonClick={()=>handleOrden('fecha_alta_string')}>
                                Hora de ingreso
                            </Button>
                            <Button variant="gradient" className="" oonClick={()=>handleOrden('nombre')}>
                                Nombre
                            </Button>
                            <Button variant="gradient" className="" oonClick={()=>handleOrden('fecha_baja_string')}>
                                Hora de egreso
                            </Button>
                            <div className='w-full lg:w-96 xl:w-96'>
                                <Input  
                                    type="text" 
                                    onKeyPress={handleKeyPress}
                                    onKeyDown={handleKeyDown} 
                                    icon={<MagnifyingGlassIcon className="h-5 w-5" />} 
                                    label="Busque un nombre, DNI o QR" 
                                    onChange={(e)=>{setTexto(e.target.value)}}
                                    color='black'
                                />
                            </div>
                        </div>
                        <table className="mt-4 w-full">
                            <tbody>
                                <tr>
                                    <td colSpan="4">
                                    Registros encontrados : {datos.filter(callbackBusqueda(texto,regSeleccionadas,rangosSeleccionados,statusSeleccionados,sexoSeleccionados,qr)).length} 
                                    </td>
                                </tr>
                                {/*ab ? a[orden].localeCompare(b[orden]) : b[orden].localeCompare(a[orden])*/}
                                {datos.filter(callbackBusqueda(texto,regSeleccionadas,rangosSeleccionados,statusSeleccionados,sexoSeleccionados,qr)).filter((item,index)=>index<=filas).sort((a,b)=>ordenar(a,b,ab,orden)).map(item=>{
                                    return <>
                                    <tr title={item.id_iglesia ? `Delegado IGL-ID ${item.id_iglesia}` : `Ministro ID ${item.id_obrero}`} className='even:bg-blue-gray-50/50 cursor-pointer'>
                                        <td className="w-3r a-col0">
                                            <div className="flex f-col text-xl mb-2 mt-2">
                                            <div className="flex justify-content-space-evenly">
                                                {/*item.retirado==0 ? <FontAwesomeIcon title="Presente" className="color-green" icon={faCircle}/> : <FontAwesomeIcon className="color-red" title="Retirado" icon={faCircle}/>*/}
                                                {item.id_iglesia ? <FontAwesomeIcon title="Delegado" className={item.retirado==0 ? "color-green" : "color-red"}  icon={faPortrait}/> : <FontAwesomeIcon className={item.retirado==0 ? 'color-green' : 'color-red'} title="Ministro" icon={faUser}/>}                                                
                                                <span>{item.id_iglesia ? `DELEGADO ` : `Ministro ${item.rango}`}</span>
                                                <span>R-{item.id_region}</span>
                                            </div>
                                            <span className="text-center fw-600 mb-2 p-2">{item.id_obrero ? item.nombre : `${item.nombre} (${item.nro_documento})`}</span>
                                            {item.id_iglesia && <span className="text-center">{item.id_iglesia ? `${item.delegado}` : `${item.rango}`}</span>}   
                                                
                                                <div className="flex justify-content-space-evenly mb-2 mt-2">
                                                    <div className='b-as-fh'>
                                                        
                                                        <p><FontAwesomeIcon icon={faSignInAlt}  className='color-green'/> {item.f_ingreso.slice(10)}</p>
                                                        <p className="text-xsmall">{item.usuario_alta}</p>
                                                    </div> 
                                                    {item.f_egreso && <div className='b-as-fh'>
                                                        
                                                        <p><FontAwesomeIcon icon={faSignOutAlt} className='color-red'/>{item.f_egreso.slice(10)}</p>
                                                        <p className="text-xsmall">{item.usuario_baja}</p>
                                                    </div>}
                                                </div>
                                                {item.retirado==0 ? <Button variant="gradient" size='sm' onClick={()=>iniciarRetiro(item)}>
                                                                        Retirar
                                                                    </Button> : 
                                                                    <div className="flex f-col items-center">
                                                                        <p className="color-red mb-2 mt-2 text-center">Retirado</p>
                                                                       <Button variant="gradient" size='sm' onClick={()=>iniciarReingreso(item)}>
                                                                            Reingresar
                                                                        </Button>
                                                                    </div>
                                                                    }                      
                                                <Button color='black' className='mt-2 flex justify-center items-center' size='sm' onClick={()=>listarLogs(item.id_asamblea_presente)}>
                                                    <InformationCircleIcon className='w-4 h-4 mr-2'/>
                                                    Logs
                                                </Button>
                                            </div>
                                        </td>
                                        <td className="a-col1 p-2">{item.retirado==0 ? <FontAwesomeIcon title="Presente" className="color-green" icon={faCircle}/> : <FontAwesomeIcon className="color-red" title="Retirado" icon={faCircle}/>}</td>
                                        <td className="a-col2 p-2">{item.id_iglesia ? <FontAwesomeIcon title="Delegado" className="color-orange" icon={faPortrait}/> : <FontAwesomeIcon className="color-tomato" title="Ministro" icon={faUser}/>}</td>
                                        {/*<td className="a-col3">{item.id_obrero ? item.nombre : `${item.nombre} (${item.nro_documento}) ID: ${item.id_asamblea_presente}`}</td>*/}
                                        <td className="a-col3">
                                                {item.id_obrero && <div>{item.nombre}</div>}
                                                {!item.id_obrero && <div><p className=''>{`Delegado: ${item.nombre} (${item.nro_documento}) ID: ${item.id_asamblea_presente}`}</p><p className='text-small mt-2 mb-2 fw-700'>{item.delegado}</p>
                                                </div>}
                                        <span className='color-red text-xsmall'>{item.observaciones}</span>
                                        </td>
                                        <td className="a-col4" title={item.id_iglesia ? `Delegado` : `Ministro`}>{item.id_iglesia ? null : `Ministro`}</td>
                                        <td className={item.id_iglesia ? "a-col5 p-2" : "a-col5 text-center"} >{item.id_iglesia ? null : `${item.rango}`}</td>
                                        <td className="a-col5 whitespace-no-wrap p-4">R-{item.id_region}</td>
                                        <td className="a-col " title="Hora de ingreso">
                                            <div className="text-larg p-2">
                                                <p title={item.f_ingreso} className="text-large ws-no-wrap">{item.f_ingreso.slice(10)} <FontAwesomeIcon icon={faSignInAlt} className="text-small"/></p>
                                                <p className="text-xsmall">{item.usuario_alta}</p>
                                            </div>
                                        </td>
                                        <td className={`a-col5 ${item.f_egreso ? 'bg-red' : ''}`} title="Hora de egreso">
                                            {item.f_egreso && <div className=" text-large text-white p-2">
                                                <p title={item.f_egreso} className="text-large ws-no-wrap">{item.f_egreso.slice(10)} <FontAwesomeIcon icon={faSignOutAlt} className='text-small'/></p>
                                                <p className="text-xsmall">{item.usuario_baja}</p>
                                            </div>}
                                        </td>
                                        <td className="a-col5">{item.retirado==0 ? 
                                                                    <Button variant="gradient" size='sm' color="red" onClick={()=>iniciarRetiro(item)}>Retirar
                                                                    </Button> : 
                                                                    <div className="flex f-col items-center">
                                                                        <p className="color-red">Retirado</p> 
                                                                        <Button variant="gradient" size='sm' onClick={()=>iniciarReingreso(item)}>Reingresar</Button>
                                                                    </div>
                                                                    }
                                        </td>
                                        <td className="a-col5">
                                            <Button color='black' size='sm' className='mt-2 flex items-center' onClick={()=>listarLogs(item.id_asamblea_presente)}>
                                                <InformationCircleIcon className='w-4 h-4 mr-2'/>
                                                Logs
                                            </Button>
                                        </td>
                                    </tr>
                                    {/*item.id_iglesia && <tr className="">
                                        <td colSpan="9">{item.delegado}</td>
                                    </tr>*/}
                                    </>
                                    })}
                            </tbody>
                        </table>
                        <div className="flex justify-end mt-4">
                            <Button variant="text" className="flex items-center gap-2" onClick={()=>setFilas(filas+10)}>
                                Mostrar más resultados...
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-5 w-5"
                                >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                                />
                                </svg>
                            </Button>
                        </div>
                </div>
        </div>
    )

    return (
        <div className="mt-6 FormAbmContainer-x1 relative">
            
            <button onClick={()=>finalizarAltaOcopia(null)} title={"Cerrar"} className="absolute left-0 top-0"><FontAwesomeIcon icon={faWindowClose}/><span className="ml-2 fw-100 text-smaller">Cancelar</span> </button> 
            
            <p className="absolute right-10 top-0">Presentes en {nombre}</p>
            {datos.map(item=>{
                return <p>{item.nombre}</p>
            })}
        </div>
    )
}

function callbackBusqueda(texto,regiones,rangos,status,sexos,qr){
    return presente=>((presente.nombre.toUpperCase().includes(texto.toUpperCase()) || presente.delegado.toUpperCase().includes(texto.toUpperCase()) || Number(presente.id_asamblea_presente)==Number(texto) || (Number(presente.id_obrero)==Number(qr) && qr!=null) || presente.nro_documento.toString().includes(texto)) && (regiones.some(region=>region==presente.id_region)||regiones.length==0) && (rangos.some(rango=>rango==presente.id_rango)||rangos.length==0) && (status.some(status=>status==presente.retirado)||status.length==0) && (sexos.some(sexo=>sexo==presente.sexo)||sexos.length==0))
}

function haySeleccion(texto,regiones,rangos,status,sexos){
    return texto.length>0 || sexos.length > 0 || regiones.length > 0 || rangos.length > 0 || status.length > 0
}

function es_rango_seleccionado(rangos,id_rango){

    if(rangos.some(rango=>rango==id_rango)){
        return 'bg-blue-400/70'
    }else{
        return ''
    }
}

function es_region_seleccionada(regiones,id_region){

    if(regiones.some(region=>region==id_region)){
        return 'bg-black/70'
    }else{
        return ''
    }
}

function es_status_seleccionado(status,id_status){

    if(status.some(status=>status==id_status)){
        return 'bg-blue-400'
    }else{
        return ''
    }
}

function es_sexo_seleccionado(sexos,id_sexo){

    if(sexos.some(sexo=>sexo==id_sexo)){
        return 'bg-blue-400'
    }else{
        return ''
    }
}

function ordenar(a,b,ab,orden){

return ab ? a[orden].localeCompare(b[orden]) : b[orden].localeCompare(a[orden])


return  a[orden].localeCompare(b[orden])
}

function informarNoHayPresentes(nombre){
    Swal.fire({
        icon: 'warning',
        text: `No se encontraron presentes en la asamblea ${nombre}`
      })
}

function Excel({nombre,id_asamblea,setExportar}){
   const [listadoExcel,setListadoExcel] = useState([])

   useEffect(()=>{
        const buscarListadoExcel = async ()=>{
            try{
                const {data} = await Axios.get(`/api/tablasgenerales/asamblea/listadoc/${id_asamblea}`)
                setListadoExcel(data)
                setExportar(false)
            }catch(e){
                alert(e)
                setExportar(false)
            }
        }

        buscarListadoExcel()
   },[])

   // https://www.npmjs.com/package/react-export-excel 
   // Excel Props --> 
   //hideElement	bool	false	false	To hide the button & directly download excel file
   // element	HTMLElement	<button>	false	Element to download excel file

   if (listadoExcel.length==0){
       return null
   }else{
    return '' /*<ExcelFile filename={nombre} hideElement={true}>
                <ExcelSheet data={listadoExcel} name="Egresos">
                    <ExcelColumn label="Nombre" value="nombre"/>
                    <ExcelColumn label="Categoría" value="categoria"/>
                    <ExcelColumn label="Rango" value="rango"/>
                    <ExcelColumn label="Región" value="region"/>
                    <ExcelColumn label="Ubicación" value="ubicacion"/>
                    <ExcelColumn label="Pastor delega" value="pastor_delegado"/>
                    <ExcelColumn label="Iglesia delega" value="iglesia_delegado"/>
                    <ExcelColumn label="Observaciones" value="observaciones"/>
                </ExcelSheet>
        </ExcelFile>*/
   }

}