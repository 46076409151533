import React, {useState, useEffect} from 'react';
import {Axios, Main} from '../Helpers/importacionesComunes'
import { Button, Card, Input, Typography } from '@material-tailwind/react';
import { Upload } from '@mui/icons-material';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';


export default function Ejemplo(){

    const [selectedFile,setSelectedFile]= useState(null)

    const onChangeHandler = (event)=>{
        setSelectedFile(event.target.files[0])
    }

    const onClickHandler = () => {
        const data = new FormData() 
        data.append('file', selectedFile)
        Axios.post("/api/tablasgenerales/stats/", data, { 
            headers: {
                "Content-type": "multipart/form-data"
            }, 
        })

      .then(res => { // then print response status
          console.log(res.statusText)
       })
    }




    return (
        <Main center>
            <Typography variant="h4" color="black" className="flex justify-center items-center">
                    Subir Archivo
            </Typography> 
            <Card className={`min-h-screen m-auto lg:m-6 p-4 flex flex-col items-center justify-center}`}>
                <div className="mt-6">
                    <Typography variant="h5" color="black" className="flex justify-center items-center mb-4">
                        Se incluirá en esta ventana una o más funciones más adelante
                    </Typography> 
                    <div className='flex space-x-2'>
                        <Input 
                            type="file" 
                            name="file" 
                            onChange={onChangeHandler}
                            color='black'
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                           {selectedFile && <Button variant="gradient" className="flex items-center gap-3" onClick={onClickHandler}>
                                    <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={2}
                                    stroke="currentColor"
                                    className="h-4 w-4"
                                    >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                    />
                                    </svg>
                                    Subir
                                </Button>} 
                    </div>
                </div> 
            </Card>
        </Main>
        
    )
}