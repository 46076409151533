import React, { useState, useEffect } from 'react';
import { Main, Axios } from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import Alerta from '../componentes/Alerta';
import FormularioNuevaAlerta from '../abms/FormularioNuevaAlerta';
import useModal from '../hooks/useModal'
import { Button, Checkbox, Spinner, Typography } from '@material-tailwind/react';
import { ArrowPathIcon, ChatBubbleLeftIcon, PlusIcon } from '@heroicons/react/24/solid';
import { useContextoGlobal } from '../Context/contextoGlobal';
import { Card } from '@material-tailwind/react';

const alertaPrueba = { asunto: 'test', mensaje: 'Testing', usuario: 'test', f_detallada: 'test' }
export default function Alertas({ usuario }) {

    const [alertas, setAlertas] = useState([]);
    const [alertaSeleccionada, setAlertaSeleccionada] = useState([]);
    const [abiertas, setAbiertas] = useState(true);
    const [propias, setPropias] = useState(false);
    const [buscando, setBuscando] = useState(false)
    const [huboError, setHuboError] = useState(false)
    const [contadorOperaciones, setContadorOperaciones] = useState(0)
    const { toggle, isShowing } = useModal();
    const [nuevaAlerta, setNuevaAlerta] = useState(false);
    const {esCelular} = useContextoGlobal();

    useEffect(() => {

        let mounted = true;


        if (mounted) {
            buscarAlertas()
        }

        return () => mounted = false
    }, [])

    useEffect(() => {

        let mounted = true;

        if (mounted) {
            buscarAlertas()
        }

        return () => mounted = false
    }, [abiertas, propias, contadorOperaciones])

    useEffect(() => {

        if (!isShowing) {

            if (nuevaAlerta) {
                setNuevaAlerta(false)
            }

            if (alertaSeleccionada) {
                setAlertaSeleccionada(null)
            }
        }
    }, [isShowing])

    const finalizarAlerta = () => {
        setAlertaSeleccionada(null)
        setContadorOperaciones(contadorOperaciones + 1)
    }

    const finNuevaAlerta = () => {
        setNuevaAlerta(false)
        setContadorOperaciones(contadorOperaciones + 1)
        toggle()
    }

    const buscarAlertas = async () => {

        setBuscando(true)

        try {
            const usuarioAlerta = usuario.id_region ? `region${usuario.id_region}` : 'Central'
            const vector_promesas = await Promise.all([Axios.get(`/api/tablasgenerales/alertas/${usuarioAlerta}/${abiertas ? 1 : 0}/${propias ? 1 : 0}`)])

            setAlertas(vector_promesas[0].data)
            setBuscando(false)

        } catch (err) {
            setBuscando(false)
            setHuboError(true)
        }
    }

    if (huboError) {
        return <Main center>
                        <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                            <span>Se produjo un error al cargar los datos para esta vista</span>
                        </div>
                </Main>
    }

    if (buscando) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
            </div>
        </Main>
    };

    return (
        <Card center className='w-full lg:!m-6 xl:!m-6'>
            {isShowing && alertaSeleccionada &&
                <Alerta id_alerta={alertaSeleccionada}
                    usuario={usuario.id_region ? `region${usuario.id_region}` : 'Central'}
                    finAlerta={finalizarAlerta}
                />
            }
            {isShowing && nuevaAlerta &&
                <FormularioNuevaAlerta alerta={alertaPrueba} usuario={usuario.id_region ? `region${usuario.id_region}` : 'Central'} finAlerta={finNuevaAlerta} />
            }
            <Typography variant="h5" className="w-full mb-2 flex justify-center lg:hidden xl:hidden">
                Alertas
            </Typography>
            <div className="flex f-col w-full justify-center p-4 lg:p-10 xl:p-10">
                <div className={`flex ${esCelular? 'grid grid-cols-2 gap-2' : 'space-x-2'}`}>
                    <Checkbox
                        label="Alertas abiertas"
                        onClick={() => { setAbiertas(!abiertas) }}
                        checked={abiertas}
                    />
                    <Checkbox
                        label="Alertas propias"
                        onClick={() => { setPropias(!propias) }}
                        checked={propias}
                    />
                    <Button variant="text" className="flex items-center gap-2 !bg-transparent !text-black/70" onClick={() => setContadorOperaciones(contadorOperaciones + 1)} >
                        <ArrowPathIcon className='w-4 h-4' /> Refrescar
                    </Button>
                    <Button variant="text" className="flex items-center gap-2 !bg-transparent !text-black/70" onClick={() => { setNuevaAlerta(true); toggle() }}  >
                        <PlusIcon className='w-4 h-4' />  Nueva alerta
                    </Button>
                </div>
                <table className="mt-6">
                    <tbody>
                        {alertas.map((item, index) => (
                            <tr key={index} className="even:bg-blue-gray-50/50 cursor-pointer" onClick={() => { setAlertaSeleccionada(item.id_alerta); toggle() }}>
                                <td className="p-4">
                                    <div className="flex items-center space-x-2">
                                        <ChatBubbleLeftIcon width={20} />
                                        <Typography variant="small" color="black" className="font-normal">
                                            {item.usuario_origen.toUpperCase()}
                                        </Typography>
                                    </div>
                                </td>
                                {propias && <td className="p-4">
                                    <Typography variant="small" color="black" className="font-normal">
                                        | {item.usuario_para} |
                                    </Typography>
                                </td>}
                                <td className="p-4">
                                    <Typography variant="small" color="black" className="font-normal">
                                        {item.f_detallada}
                                    </Typography>
                                </td>
                                <td className="p-4">
                                    <Typography variant="small" color="black" className="font-normal">
                                        {item.asunto}
                                    </Typography>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Card>
    )
}



