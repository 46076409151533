import React, {useEffect} from "react";
import {
  Drawer,
} from "@material-tailwind/react";
import { MenuSidebar } from "./sidebar";
import { useContextoGlobal } from "../Context/contextoGlobal";

export function MenuDrawer({ usuario, logout}) {
  const { ocultarMenu, verMenu } = useContextoGlobal()

  useEffect(() => {
    console.log('verMenu',verMenu)
  }, [verMenu])

  const closeDrawer = () => ocultarMenu();

  return (
    <Drawer open={verMenu} onClose={closeDrawer} className="p-4 overflow-y-auto">
      <MenuSidebar usuario={usuario} logout={logout}  displaySidebar={true} />
    </Drawer>
  );
}