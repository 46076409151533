import React from 'react';
import ReactDOM from 'react-dom';
import '../modal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-regular-svg-icons';
import { faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { Typography } from '@material-tailwind/react';

//const estiloDefault = {width:'500px'}
const estiloDefault = { background: 'white' }
const estiloWDefault = {}

const Modal = ({ isShowing, hide, children, titulo, estilo, estiloWrapper, closeOnclickOutside }) => isShowing ? ReactDOM.createPortal(
    <React.Fragment>
        <div className="modal-overlay z-20" />
        {/*<div className="modal-wrapper" id={'mwp'} onClick={(e)=>cerrar(e,hide,closeOnclickOutside)} aria-modal aria-hidden tabIndex={-1} role="dialog" style={!estiloWrapper ? estiloWDefault : estiloWrapper }>*/}
        <div className="modal-wrapper" id={'mwp'} onClick={(e) => cerrar(e, hide, true)} aria-modal aria-hidden tabIndex={-1} role="dialog" style={!estiloWrapper ? estiloWDefault : estiloWrapper}>
            {/*<button onClick={hide} data-dismiss="modal" className="fixed color-red ipad-close"><FontAwesomeIcon icon={faTimesCircle}/></button>*/}
            <div className="modal" onClick={(e) => { stopClick(e) }} style={!estilo ? estiloDefault : estilo}>
                <div>
                    <div className="flex justify-center border-bottom-solid-light">
                        <Typography
                            variant="h4"
                            className="font-normal flex justify-center mb-4"
                        >
                            {titulo ? titulo : ''}
                        </Typography>
                    </div>
                    <button onClick={hide} data-dismiss="modal" aria-label="Close" title="Cancelar" className="absolute botonAbm right-3 top-2"><XCircleIcon className="h-6 w-6" /></button>
                </div>
                {children}
            </div>
        </div>
    </React.Fragment>, document.body
) : null;

export default Modal;

function cerrar(e, hide, closeOnclickOutside) {
    e.preventDefault()
    if (e.target.id = "mwp" && closeOnclickOutside) {
        hide()
    }
}

function stopClick(e) {
    e.stopPropagation();
}