import React, { useEffect, useState } from 'react';
import { usePaginacion, Paginacion, FontAwesomeIcon, PiePagina, uuidv4 } from '../../../Helpers/importacionesComunes'
import { } from '@fortawesome/free-regular-svg-icons';
import { faSearchDollar, faWindowClose, faEdit, faSort } from '@fortawesome/free-solid-svg-icons';
import { useContextoGlobal } from '../../../Context/contextoGlobal'
import { TotalImportes } from '../../ComponentesComunes/TotalImportes'
import Excel from '../../../componentes/Excel'
import { Card, CardBody, Typography, Button } from '@material-tailwind/react';
import { ChevronUpDownIcon } from '@heroicons/react/24/solid';
import ExportToExcelGeneric from '../../../componentes/exportExcelGeneric';
import { TrashIcon } from '@heroicons/react/24/solid';
import { NavLink } from 'react-router-dom';

const columnas = [
    { label: 'Nombre', value: 'nombre' },
    { label: 'Fondo Tesorería', value: 'fondo_tesoreria_nombre' },
    { label: 'Rubro Ingreso', value: 'rubro_ingreso' }
]

export const Tabla = ({ registrosAmostrar, setOpenCreate, setActiveFondo, handleOpen }) => {

    const { configuracion, paginar, inicializar: inicializarPaginacion } = usePaginacion()
    // Hago una copia de los datos que se reciben para manejar el ordenamiento
    const [copiaRegistrosaMostrar, setCopiaRegistrosaMostrar] = useState([...registrosAmostrar])
    const [orden, setOrden] = useState(null)
    const [nuevoCampo, setNuevoCampo] = useState(true)
    const [contadorOrden, setContadorOrden] = useState(0)
    const { usuario } = useContextoGlobal()


    useEffect(() => {
        setCopiaRegistrosaMostrar([...registrosAmostrar])
        inicializarPaginacion(null, registrosAmostrar)
    }, [registrosAmostrar])


    useEffect(() => {// el ordenamiento se maneja con las propiedades orden que almacena el campo por el cual ordenar
        // y nuevoCampo que es un flag que indica si es otro campo o el mismo a la inversa
        // cada vez que se cambia el orden en click de la cabecera se cambia contadorOrden como un trigger 

        ordenarDatos()
    }, [contadorOrden])

    const ordenarDatos = () => {
        setCopiaRegistrosaMostrar([...registrosAmostrar.sort((a, b) => { return comparacion(a, b) })
        ])
    }

    const funcionOrden = (nombre_campo) => {

        if (orden == nombre_campo) {
            setNuevoCampo(false)
        } else {
            setNuevoCampo(true)
        }

        setOrden(nombre_campo)
        setContadorOrden(contadorOrden + 1)

    }

    const comparacion = (a, b) => {

        switch (orden) {
            case null: return 0
            case 'id_region':
            case 'edad':

                if (nuevoCampo == true) {
                    return a[orden] - b[orden]
                } else {
                    if (contadorOrden % 2 == 0) {
                        return b[orden] - a[orden]
                    } else {
                        return a[orden] - b[orden]
                    }
                }
            case 'ult_ascenso':
            case 'fecha':

                const dia_a = Number(a[orden].substring(0, 2));
                const mes_a = Number(a[orden].substring(3, 5));
                const anio_a = Number(a[orden].substring(6, 10));

                const fa = new Date(anio_a, mes_a, dia_a);

                const dia_b = Number(b[orden].substring(0, 2));
                const mes_b = Number(b[orden].substring(3, 5));
                const anio_b = Number(b[orden].substring(6, 10));

                const fb = new Date(anio_b, mes_b, dia_b);

                if (nuevoCampo == true) {
                    return fa - fb
                } else {
                    if (contadorOrden % 2 == 0) {
                        return fb - fa
                    } else {
                        return fa - fb
                    }
                }
            default:
                if (nuevoCampo == true) {
                    return a[orden].localeCompare(b[orden])
                } else {
                    if (contadorOrden % 2 == 0) {
                        return b[orden].localeCompare(a[orden])
                    } else {
                        return a[orden].localeCompare(b[orden])
                    }
                }
        }
    }

    const convertirFormato = (numero) => {
        // Reemplaza la coma por un punto
        let numeroConPunto = numero.replace(/,/g, '.');
        // Reemplaza el punto (de los decimales) por una coma
        let numeroEnNuevoFormato = numeroConPunto.replace(/\.(?=[^.]*$)/, ',');
        return numeroEnNuevoFormato;
    }

    
    const formatearImporte = (numero) => {
        return numero.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    return <div style={{ marginTop: '1rem' }}>
        {copiaRegistrosaMostrar.length > 0 && <Excel datos={copiaRegistrosaMostrar}
            descripcion='Movimientos'
            columnas={columnas}
            titulo='Fondos tesorería' />}
        {copiaRegistrosaMostrar.length > 0 && <div className="flex f-col">
            <div className="flex justify-center gap-2">
                <div>
                    <span className="text-xl">{copiaRegistrosaMostrar.length}</span>
                    <span className="text-large">{copiaRegistrosaMostrar.length == 1 ? ` registro encontrado` : ` registros encontrados`}</span>
                </div>
            </div>
        </div>}
        {copiaRegistrosaMostrar.length > 0 &&
            <Card className="h-full  rounded-none">
                <div className="w-full flex items-end justify-end pr-3 pb-2">
                    <ExportToExcelGeneric data={copiaRegistrosaMostrar} fileName="Listado de Fondos de Tesoreria" isProvider={true}/>
                    <Button onClick={() => setOpenCreate(true)} variant="outlined" className='ml-2 bg-[#2FB18F] text-white'>
                        Añadir
                    </Button> 
                </div>
                <CardBody className="overflow-scroll-none px-0 pt-0 pb-0">
                    <table className="min-w-max w-full table-auto text-left">
                        <thead>
                            <tr>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <ThTitle title='Nombre' sort orden={orden === 'nombre'} callback={() => funcionOrden('nombre')} />
                                </th>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <ThTitle title='Fondo Tesorería' sort orden={orden === 'fondo_tesoreria_nombre'} callback={() => funcionOrden('fondo_tesoreria_nombre')} />
                                </th>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50">
                                    <ThTitle title='Rubro Ingreso' sort orden={orden === 'rubro_ingreso'} callback={() => funcionOrden('rubro_ingreso')} />
                                </th>
                                <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50">
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="flex justify-between gap-2 font-normal leading-none opacity-70"
                                    >
                                        Acciones
                                    </Typography>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {copiaRegistrosaMostrar
                                .filter((item, index) => {
                                    return index >= configuracion.iIni && index <= configuracion.iFin
                                })
                                .map(
                                    (item, index) => {
                                        const isLast = index === copiaRegistrosaMostrar.length - 1;
                                        const classes = isLast
                                            ? "p-4"
                                            : "p-4 border-b border-blue-gray-50";

                                        return (
                                            <>
                                                <tr key={uuidv4()}>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {item.nombre}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {item.fondo_tesoreria_nombre}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <Typography
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal"
                                                        >
                                                            {item.rubro_ingreso}
                                                        </Typography>
                                                    </td>
                                                    <td className={classes}>
                                                        <div className="flex items-center justify-center">
                                                            <span onClick={() => { setActiveFondo(item.id_fondo_tesoreria); handleOpen(); }}
                                                                title={`Eliminar Fondo Tesoreria`} className="filas-lista-nw cursor-copy" >
                                                                <TrashIcon className="h-6 w-6 cursor-pointer" />
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    }
                                )}
                        </tbody>
                    </table>

                    {copiaRegistrosaMostrar.length > 0 &&
                        <PiePagina>
                            <div className="items-center">
                                <Paginacion configuracion={configuracion} paginar={paginar} />
                            </div>
                        </PiePagina>}
                </CardBody>
            </Card>
        }
    </div>
}

const ThTitle = ({ title, sort, orden, callback }) => {
    return <div className='flex f-col justify-center items-center'>
        <Typography
            variant="small"
            color="blue-gray"
            className="flex justify-between gap-2 font-normal leading-none opacity-70"
        >
            {title}{" "}
            {sort &&
                <ChevronUpDownIcon strokeWidth={2} className={`cursor-pointer ${orden ? 'text-blue-500' : ''} h-4 w-4`} icon={faSort} onClick={callback} />
            }
        </Typography>
    </div>
}