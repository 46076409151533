import * as XLSX from "xlsx";

const ImportFromExcel = ({
  processedData,
  setProcessedData,
}) => {
  const convertExcelDateToJSDate = (excelDate) => {
    const date = new Date((excelDate - 25569) * 86400 * 1000); // Convertir número de serie a milisegundos
    date.setUTCDate(date.getUTCDate() + 1); // Ajustar un día adicional
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`; // Formato DD-MM-YYYY
  };

  const normalizeHeader = (header) => {
    return header
      .replace(/\./g, "") // Quitar puntos
      .replace(/\s+/g, "_") // Reemplazar espacios por guiones bajos
      .toLowerCase(); // Convertir a minúsculas
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        let unifiedData = []; // Aquí almacenaremos los datos unificados

        workbook.SheetNames.forEach((sheetName) => {
          const sheet = workbook.Sheets[sheetName];
          const rawData = XLSX.utils.sheet_to_json(sheet, {
            header: 1, // Leer como array (sin headers definidos)
            defval: "", // Evitar celdas vacías
          });

          const matrices = [];
          let currentHeaders = null;

          rawData.forEach((row) => {
            // Identificar si la fila tiene encabezados
            const nonEmptyCells = row.filter((cell) => cell !== "");
            if (nonEmptyCells.length > 3 && !currentHeaders) {
              // Considerar esta fila como encabezado si tiene suficientes celdas no vacías
              currentHeaders = row.map((header) =>
                normalizeHeader(header || "")
              );
            } else if (currentHeaders) {
              // Si hay encabezados definidos, procesar las filas siguientes como datos
              const rowData = {};
              currentHeaders.forEach((header, index) => {
                if (header) {
                  let cellValue = row[index] || "";
                  // Convertir números de serie de Excel a fechas legibles
                  if (
                    header.toLowerCase().includes("fecha") &&
                    typeof cellValue === "number"
                  ) {
                    cellValue = convertExcelDateToJSDate(cellValue);
                  }
                  rowData[header] = cellValue;
                }
              });

              // Solo agregar filas con datos no vacíos
              if (Object.values(rowData).some((value) => value !== "")) {
                if (
                  matrices.length === 0 ||
                  matrices[matrices.length - 1].headers !== currentHeaders
                ) {
                  // Crear una nueva matriz si es la primera o si cambia el conjunto de encabezados
                  matrices.push({
                    headers: currentHeaders,
                    rows: [],
                  });
                }
                matrices[matrices.length - 1].rows.push(rowData);
              }
            }
          });

          // Guardar las matrices procesadas por hoja
          matrices.forEach((matrix) => {
            // Filtrar filas con mayoría de datos vacíos
            let cleanedRows = matrix.rows.filter((row) => {
              const values = Object.values(row);
              const nonEmptyValues = values.filter((value) => value !== "");
              // Mantener si tiene más de la mitad de sus valores no vacíos
              return nonEmptyValues.length > values.length / 2;
            });

            // Filtrar filas que tienen encabezados como valores
            cleanedRows = cleanedRows.filter((row) => {
              return !Object.keys(row).every((key) => row[key] === key);
            });

            // Añadir filas limpias al array unificado
            unifiedData = [...unifiedData, ...cleanedRows];
          });
        });

        // Guardar el array unificado en el estado
        setProcessedData(unifiedData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div className="flex flex-col items-center gap-4">
      <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        className="file-input"
      />

      <div className="hidden mt-4">
        <h3 className="text-lg font-semibold">Datos del archivo:</h3>
        <pre className="text-sm bg-gray-100 p-2 rounded">
          {JSON.stringify(processedData, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default ImportFromExcel;
