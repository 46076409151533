import React, {useContext,useEffect,useState} from 'react'
import {Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {Listado} from '../componentes/Listado'
import EventoForm from '../componentes/EventoForm'
import Eventos from '../componentes/Eventos'
import {EventoContextProvider} from '../contexto/EventoContext'
import {NuevoLink} from '../componentes/NuevoLink'
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import { Button, Typography } from '@material-tailwind/react';
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { v4 as uuidv4 } from 'uuid';

export const Evento = ({usuario})=>{
    const [nuevoLink,setNuevoLink] = useState(false)
    const { esCelular } = useContextoGlobal();
    const [altaEvento,setAltaEvento] = useState(false)
    const [idRefresh,setIdRefresh] = useState(null);
    
    const finalizarAlta = (id_nuevo_evento)=>{
        setAltaEvento(false);
        setIdRefresh(id_nuevo_evento);
    }

    return <EventoContextProvider> 
                {/* <Button variant="text" size='md' className="rounded-full mt-4 flex justify-center items-center" onClick={()=>setAltaEvento(true)}>
                        Crear un nuevo evento
                </Button> */}
                <Eventos usuario={usuario} idRefresh={idRefresh}/>
                {/* <div className={esCelular ? 'flex flex-col justify-center ... ml-8 md:ml-52' : 'flex flex-col items-center justify-center'}> 
                    <Typography variant="h4" color="black" className="flex justify-center items-center">
                        Convención
                    </Typography> 
                    <Button variant="text" size='md' className="rounded-full mt-4 flex justify-center items-center" onClick={()=>setNuevoLink(true)}>
                        Generar un link para colaboradores
                    </Button>
                    <Listado/>
                </div> */}
                <Dialogos open={nuevoLink}      
                    titulo='Crear un nuevo link para los colaboradores' 
                    procesarCancelar = {()=>setNuevoLink(false)}
                   >
                    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center'}}>
                        <NuevoLink/>
                    </Box>
                </Dialogos> 
                {/* <Dialogos open={altaEvento}      
                    fullscreen={true}
                    titulo='Cree un nuevo evento' 
                    procesarCancelar = {()=>setAltaEvento(false)}
                   >
                        <EventoForm finalizarAltaOcopia={(id_nuevo_evento)=>finalizarAlta(id_nuevo_evento)}/>
                </Dialogos>  */}
        </EventoContextProvider> 
}

