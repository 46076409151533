import React, { useState, useEffect } from 'react';
import { 
    Spinner, 
    Card, 
    Typography, 
    Dialog, 
    DialogHeader, 
    DialogBody, 
    DialogFooter, 
    Button, 
    List, 
    ListItem,
    Input,
    Checkbox
} from '@material-tailwind/react';
import { Main, Axios } from '../../../Helpers/importacionesComunes'
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Tabla } from '../componentes/Tabla';
import { Cabecera } from '../componentes/Cabecera';
import { Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';

export default function RubroEgresos({ match, history }) {
    const [registros, setRegistros] = useState([])
    const [backupRegistros, setBackupRegistros] = useState([])
    const [cargandoRegistros, setCargandoRegistros] = useState(true);
    const { esCelular, usuario } = useContextoGlobal();
    const [open, setOpen] = useState(false)
    const [openCreate, setOpenCreate] = useState(false)
    const [huboError, setHuboError] = useState(false)
    const [filtros, setFiltros] = useState({ codigo: '', nombre: '', cuenta_padre: '' });
    const [activeRubro, setActiveRubro] = useState(null)
    const [dataValues, setDataValues] = useState({
        codigo: null,
        nombre: null,
        id_rubro_padre: null,
        imputable: null,
        id_fondo_tesoreria: null
    });

    const [fondosTesoreria, setFondosTesoreria] = useState([]);

    useEffect(() => {
        buscarDatos();
        buscarFondosTesoreria();
    }, [])

    const buscarDatos = async (params = {}) => {
        const { conLoading } = params // con loading se usa para la búsqueda inicial de registros
        // Cuando se modificó una iglesia queremos mantener el modal abierto y no mostramos el loading

        setHuboError(false)
        setRegistros([])
        conLoading && setCargandoRegistros(true)
        try {
            const params ={
                ...dataValues,
                id_region: usuario.sede_admin ? dataValues.id_region : usuario.id_region
            }
            const { data } = await Axios.post(`/api/tablasgenerales/rubro-egresos/lista`, params)
            setRegistros(data)
            setBackupRegistros(data)
            setCargandoRegistros(false)
        } catch (err) {
            console.log(err)
            setHuboError(true)
            setCargandoRegistros(false)
        }
    }

    const buscarFondosTesoreria = async () => {
        try {
            const resultado = await Axios.get('/api/tablasgenerales/fondos-tesoreria/lista')
            setFondosTesoreria(resultado.data)
        } catch (err) {
            alert(err)
        }
    }
    
    useEffect(() => {
        const filtrarRegistros = () => {
            const { codigo, nombre, cuenta_padre } = filtros;
        
            const registrosFiltrados = backupRegistros.filter(registro => {
                const cumpleCodigo = codigo ? registro.codigo.includes(codigo) : true;
                const cumpleNombre = nombre ? registro.nombre.toLowerCase().includes(nombre.toLowerCase()) : true;
                const cumpleCuentaPadre = cuenta_padre ? registro.cuenta_padre.toLowerCase().includes(cuenta_padre.toLowerCase()) : true;
        
                return cumpleCodigo && cumpleNombre && cumpleCuentaPadre;
            });
        
            setRegistros(registrosFiltrados);
        };

        filtrarRegistros();
    }, [filtros])

    const handleOpen = () => { 
        setOpen(!open);
    }

    const handleCreateOpenDialog = () => { 
        setOpenCreate(true);
    }

    const handleChangeSelectRegion = (e) => {
        handleDataChanges(e, 'id_region');
    }

    const handleDataChanges = (value, type) => {
        setDataValues({ ...dataValues, [type]: value });
    }

    const submitRubro = async () => {
        const { codigo, nombre } = dataValues;
        if (!codigo || !nombre) { 
            Swal.fire({
                icon: 'error',
                title: `<p>Por favor, complete al menos los campos: Código y nombre.</p>`,
                showCancelButton: true,
            });
            setOpen(true);
            return;
        }

        const formValues = {
            ...dataValues,
            id_rubro_padre: dataValues.id_rubro_padre ? Number(dataValues.id_rubro_padre) : null,
        }
        try {
            await Axios.post(`api/tablasgenerales/rubro-egresos/crear`, formValues);
            Swal.fire({
                icon: 'success',
                title: `<p>El rubro ha sido creado con éxito</p>`,
            });
            closeDialogs();
            buscarDatos();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `<p>Error al crear un rubro</p>`,
                showCancelButton: true,
            });
            console.log(error);
        }
    };

    const deleteRubro = async () => { 
        if (activeRubro) { 
            Swal.fire({
                icon: 'error',
                title: `<p>Por favor, seleccione un rubro</p>`,
                showCancelButton: true,
            });
            setOpen(true);
            return;
        }

        try {
            await Axios.post(`api/tablasgenerales/rubro-egresos/delete`, {  id_rubro: activeRubro });
            Swal.fire({
                icon: 'success',
                title: `<p>El rubro ha sido eliminado con éxito</p>`,
            });
            closeDialogs();
            buscarDatos();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `<p>Error al eliminar un rubro</p>`,
                showCancelButton: true,
            });
            console.log(error);
        }
    }

    const closeDialogs = () => { 
        setOpen(false);
        setOpenCreate(false);
    }

    if (huboError) {
        return <Main center>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <span>Se produjo un error al cargar los datos para esta vista</span>
            </div>
        </Main>
    }

    if (cargandoRegistros || !usuario) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
            </div>
        </Main>
    };

    return (
        <Card className='w-full lg:!m-6 xl:!m-6'>
            <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4 w-full">
                <Typography variant="h5" className="w-full mb-2 flex justify-center lg:hidden xl:hidden">
                    Depósitos sin identificar
                </Typography>
                <div className='mb-2'>
                    <Cabecera
                        handleDataChanges={handleDataChanges}
                        handleChangeSelectRegion={handleChangeSelectRegion}
                        setFiltros={setFiltros}
                        filtros={filtros}
                    />
                </div>


                {esCelular && <TablaMobile registrosAmostrar={registros} setOpenCreate={setOpenCreate} setActiveRubro={setActiveRubro} handleOpen={handleOpen} />}

                {!esCelular && <Tabla
                    registrosAmostrar={registros}
                    setOpenCreate={setOpenCreate}
                    setActiveRubro={setActiveRubro}
                    handleOpen={handleOpen}
                />}
            </div>

            <Dialog open={open} handler={handleOpen}>
                <DialogHeader>Eliminar Registro</DialogHeader>
                <DialogBody className="xl:p-10">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        ¿Está seguro de realizar esta operación?
                    </Typography>
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button 
                        variant="gradient" 
                        color="blue" 
                        onClick={deleteRubro}
                    >
                        Aceptar
                    </Button>
                    <Button variant="text" color="blue-gray" onClick={closeDialogs}>
                        Cancelar
                    </Button>
                </DialogFooter>
            </Dialog>

            <Dialog open={openCreate} handler={handleCreateOpenDialog}>
                <DialogHeader>Cargar Rubro</DialogHeader>
                <DialogBody className="xl:p-10">
                    <Parametros 
                        parametros={dataValues}
                        setParametros={setDataValues}
                        fondos={fondosTesoreria}
                        rubros={backupRegistros}
                    />
                </DialogBody>
                <DialogFooter className="space-x-2">
                    <Button 
                        variant="gradient" 
                        color="blue" 
                        onClick={submitRubro}
                    >
                        Aceptar
                    </Button>
                    <Button variant="text" color="blue-gray" onClick={closeDialogs}>
                        Cancelar
                    </Button>
                </DialogFooter>
            </Dialog>
        </Card>)
}

function TablaMobile({ registrosAmostrar }) {

    return <Card className='mt-2'>
        <List>
            {registrosAmostrar.map((item, index) =>
                <>
                    <ListItem key={index}>
                        <div className='f-col space-y-2'>
                            <p><span className='font-bold'>Código: </span>{item.codigo}</p>
                            <p><span className='font-bold'>Nombre: </span>{item.nombre}</p>
                            <p><span className='font-bold'>Cuenta Padre: </span>{item.cuenta_padre}</p>
                        </div>
                    </ListItem>
                    <Divider />
                </>
            )}
        </List>
    </Card>
}

function Parametros({ 
    parametros, 
    setParametros, 
    fondos, 
    rubros
}) {

    const { esCelular } = useContextoGlobal();

    const handleParamsChange = (type, value) => {
        setParametros({ ...parametros, [type]: value })
    }

    return <div className="w-full mb-2">
        <div className="mt-[-30px] flex f-col">
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Nombre
                </Typography>
                <div className="col-span-2 mt-1">
                    <Input
                        onChange={(e) => handleParamsChange('nombre', e.target.value)} 
                        name="nombre" 
                        color='black'
                        placeholder="Nombre" 
                        maxLength={200}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
            </div>
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Código <span className='text-[13px] text-[#293039]'>(format: 0000/00/00/00)</span>
                </Typography>
                <div className="col-span-2 mt-1">
                    <Input
                        onChange={(e) => handleParamsChange('codigo', e.target.value)} 
                        name="codigo" 
                        color='black'
                        placeholder="Código" 
                        maxLength={20}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
            </div>
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Fondo de Tesorería
                </Typography>
                <div className="col-span-2 mt-1">
                    <Seleccionador 
                        id='select-fondo-tesoreria' 
                        nombre="Todos" 
                        vector={fondos} 
                        valor={parametros.id_fondo_tesoreria} 
                        onchange={(e) => setParametros({ ...parametros, id_fondo_tesoreria: e.target.value })} 
                        claves={{ id: 'id_fondo_tesoreria', nombre: 'nombre' }} 
                    />
                </div>
            </div>
            <div className={`${esCelular ? 'mt-2' : 'grid grid-cols-3 mt-4'} justify-left`}>
                <Typography variant="h5" color="blue-gray" className="mt-1">
                    Cuenta Padre
                </Typography>
                <div className="col-span-2 mt-1">
                    <Seleccionador 
                        id='select-cuenta-padre' 
                        nombre="Todos" 
                        vector={rubros} 
                        valor={parametros.id_rubro_padre} 
                        onchange={(e) => setParametros({ ...parametros, id_rubro_padre: e.target.value })} 
                        claves={{ id: 'codigo', nombre: 'codigo' }} 
                    />
                </div>
            </div>
            <div className="grid grid-cols-3 justify-left mt-2">
                <Typography variant="h5" color="blue-gray" className="mt-2">
                    Imputable
                </Typography>
                <div className="col-span-2 flex space-x-2 w-full mt-1">
                    <Checkbox
                        ripple={false}
                        checked={parametros.imputable} 
                        onClick={() => handleParamsChange('imputable', !parametros.imputable)}
                        className='w-5 h-5'
                    />
                </div>
            </div>

        </div>
    </div>
}

function Seleccionador({ vector, onchange, valor, nombre, noDefault, name, classancho, limpiar, claves, id }) {
    let clasesSelect = "block appearance-none w-100 select-titulo rounded shadow leading-tight";

    if (classancho) {
        clasesSelect = `block appearance-none ${classancho} select-titulo rounded shadow leading-tight`
    }
    return (
        <div className="flex flex-col sel select-ui-data w-full">
            <select value={valor} name={name ? name : ''} className='h-9' onChange={onchange} id={id ? id : null}>
                <option value={null}>Seleccionar</option>
                {vector.map(item => <option value={item[claves.id]} key={item[claves.id]}>{item[claves.nombre]}</option>)}
            </select>
            {valor != "-1" && limpiar &&
                <button>
                    <FontAwesomeIcon className="ic-abm"
                        icon={faWindowClose}
                        onClick={limpiar} />
                </button>}
        </div>
    )
}