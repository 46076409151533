import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {TramitesContextProvider} from '../contexto/TramitesContext'
import {Listado} from '../componentes/Listado'
import {ListLazyQuery} from '../componentes/ListLazyQuery'
import {Tramite} from '../componentes/Tramite';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import {Cabecera} from '../componentes/Cabecera'

import Main from '../../../componentes/Main'
import { TramRounded } from '@mui/icons-material';
import { Typography } from '@material-tailwind/react';
import { useContextoGlobal } from '../../../Context/contextoGlobal';

export const Tramites = ({usuario})=>{
    const [tramiteSeleccionado,setTramiteSeleccionado] = React.useState(null)
    const [filtros,setFiltros] = useState({region:usuario.id_region})
    const [refresh,setrefresh] = useState(null)
    const {esCelular} = useContextoGlobal();

    return <TramitesContextProvider>
                <Main>
                        <div className=''>
                                <div>   
                                    <Typography variant="h4" className="w-full flex justify-center">
                                        Trámites
                                    </Typography>
                                    {/*<Cabecera filtrarLista={()=>alert(1)} setFiltros={setFiltros}/>*/}
                                    <Listado refresh={refresh} seleccionarTramite = {setTramiteSeleccionado} filtros={filtros}/>
                                </div>
                                {/*<div>
                                    <h1>Lazy query</h1>
                                    <ListLazyQuery/>
                                </div>*/}
                            {/*<div>
                                <Tramite id_tramite={tramiteSeleccionado}/>
                            </div>*/}
                        </div>
                        <Dialogos open={tramiteSeleccionado} 
                                maxWidth={esCelular? '' : 'sm'}
                               // titulo={`Solicitud ${tramiteSeleccionado?.codigo_web}`} 
                                procesarCancelar = {()=>setTramiteSeleccionado(null)}
                            >
                                {/*<p>{JSON.stringify(tramiteSeleccionado)}</p>*/}
                                {tramiteSeleccionado && <Tramite id_tramite={tramiteSeleccionado?._id}
                                                        finalizar={(refrescar)=>{setTramiteSeleccionado(null);
                                                            if(refrescar){
                                                                setrefresh(Math.random())
                                                            }}
                                                        }/>}
                        </Dialogos>
                </Main> 
            </TramitesContextProvider> 
}
