import React, { useEffect, useState} from 'react'
import {Box,Button,TextField,Typography} from "@material-ui/core";
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Axios from 'axios'
import {useParams} from 'react-router-dom'
import Alert from '@mui/material/Alert';
import {AlertDialog} from './MUI/AlertDialog'
import {useContextoGlobal} from '../Context/contextoGlobal'
import REG_EX from '../Helpers/regex';
import {Loader} from './Loader';
import { Card } from '@material-tailwind/react';
import Main from './Main';

const regex_solo_numeros = /^[0-9\b]+$/;

export const CambioPassword = ()=>{
    const [user,setUser] = useState(null)
    const [codigoActual,setCodigoActual] = useState(null)
    const [codigoNuevo,setCodigoNuevo] = useState(null)
    const [confirmacion,setConfirmacion] = useState(null)
    const [cargando,setCargando]= useState(false)
    const [contador,setContador]= useState(0)
    const [error,setError] = useState(null)
    const [errorPaso2,setErrorPaso2] = useState(null)
    const [mensaje,setMensaje] = useState(null)
    const [ver,setVer] = useState(false)

    const [codigoActualCorrecto,setCodigoActualCorrecto] = useState(true)
    const {esCelular,usuario} = useContextoGlobal()

    const parametros = useParams()

    useEffect(()=>{
            setError(null)
            setCodigoActualCorrecto(false)
            setConfirmacion(null)
            setCodigoNuevo(null)
            setErrorPaso2(null)
            setVer(false)
    },[codigoActual])

    useEffect(()=>{
        if(contador>2){
            login()
        }
    },[contador])

    useEffect(()=>{
        
        if(codigoNuevo==codigoActual){
            setErrorPaso2('El nuevo código debe ser diferente al actual')
            return
        }

        if(!(REG_EX.PASSWORD.test(codigoNuevo))){
            setErrorPaso2('El nuevo código no respeta el formato esperado')
            return
        }

        if(codigoNuevo && confirmacion){
            if(codigoNuevo!=confirmacion){
                setErrorPaso2('No coinciden')
                return
            }
        }

        setErrorPaso2(null)
    },[codigoNuevo,confirmacion])

    useEffect(()=>{
        if(usuario.resetpass){
            setErrorPaso2(null)
            setError(null)
            setCodigoActualCorrecto(true)
        }
    },[])

    const validarCodigoActual = async ()=>{
        try{
            const {data} = await Axios.post(`/api/usuarios/validar/codigo`,{opcion:usuario.usuario,password:codigoActual})

            if (data?.usuario.dni==usuario.dni){
                setError(null)
                setCodigoActualCorrecto(true)
              }else{
                    setError('El código ingresado no coincide con sus credenciales')
                    setContador(contador+1)
                    setCodigoActualCorrecto(false)
              }

        }catch(err){
            setError(err?.response?.data?.message || 'Error en la validación')
            setContador(contador+1)
            setCodigoActualCorrecto(false)
        }
    }

    const handleKeyPress = (e)=>{
        if(e.key === 'Enter'){
            validarCodigoActual()
        }
    }  

    const cambiarCodigo = async ()=>{

        if(!(codigoNuevo && codigoNuevo===confirmacion && codigoNuevo!=codigoActual)){
            alert('Los códigos ingresados son inválidos')
            return
        }
        try{
            const {data} = await Axios.post(`/api/usuarios/actualizar/codigo`,{opcion:usuario.id_usuario,password:codigoNuevo})
            
            setMensaje(data.mensaje)
            setCargando(true)
            setTimeout(() => {
                login()
            }, 1000);

        }catch(err){
            setErrorPaso2(err?.response?.data?.message || 'Error en la actualización del código de seguridad')
        }
    }

    const login = ()=>{
        sessionStorage.removeItem('REG_TOKEN')
        setTimeout(() => {
            window.location = '/login'
        }, 1000);
    }

    const handleChangeCodigoActual = (e)=>{
           if(e.target.value || e.target.value==''){
                setCodigoActual(e.target.value.trim())
        }
    }

    const handleChangeCodigoNuevo = (e)=>{
        if(e.target.value || e.target.value==''){
            setCodigoNuevo(e.target.value.trim())
        }
    }

    const handleChangeConfirmacion = (e)=>{
        if(e.target.value || e.target.value==''){
            setConfirmacion(e.target.value.trim())
        }
    }

    const cancelar = ()=>{
       setCodigoNuevo(null)
       setConfirmacion(null)
       setCodigoActualCorrecto(false)
       setVer(false)
    }

    if (cargando){
        return  <Loader open={true} 
        texto='Cargando...'/>
    }

    if (contador>2){
        return <div style={{minHeight:'350px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                <Alert style={{fontSize:'large'}} severity="error" variant='outlined'>
                    <p>Superó la cantidad de 3 intentos permitidos</p>
                    <p>Por favor vuelva a loguearse</p>
                </Alert>
        </div>
    }


    if (!codigoActualCorrecto){
        return <div style={{minHeight:'350px',minWidth:'280px',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        <Box sx={{marginTop:'1rem',marginBottom:'1rem',minWidth:'280px'}}>
            <TextField  onKeyPress={handleKeyPress} autoFocus={!esCelular} fullWidth value={codigoActual} label="Ingrese su código de seguridad actual"  type="password" inputProps={{maxLength: 100}} placeholder="Ingrese su código de seguridad actual" onChange={handleChangeCodigoActual} />
            {codigoActual && contador > 0 && <p>Puede intentar {3-contador} {3-contador>1 ? 'veces' : 'vez'} más</p>}
        </Box>

        {codigoActual && !error && <Box sx={{display:'flex',justifyContent:'space-between', marginTop:'1rem'}}>
            <Button variant="contained" color="primary" onClick={validarCodigoActual}>Continuar</Button>
        </Box>}

        {error && <Alert style={{fontSize:'large'}} severity="error" variant='outlined'>{error}</Alert>}
        </div>
    }

    return  <Main center>
                <Card>
                    <Button variant="text" onClick={cancelar}>Cancelar</Button>
                    <Alert variant="filled" severity="info" sx={{background:'#0288d1'}}>
                        <p>Asegúrese que el nuevo password respete las siguientes reglas:</p>
                        <ul>
                            <li><Box sx={{display:'flex',alignItems:'center'}}>Al menos 8 caracteres {codigoNuevo && (/^.{8,}$/).test(codigoNuevo) ? <CheckIcon sx={{color:"green"}}/> : <ClearIcon sx={{color:"red"}}/>}</Box></li>
                            {/*<li><Box sx={{display:'flex',alignItems:'center'}}>Al menos 1 letra mayúscula {codigoNuevo && (/(?=.*?[A-Z])/).test(codigoNuevo) ? <CheckIcon sx={{color:"green"}}/> : <ClearIcon sx={{color:"red"}}/>}</Box></li>*/}
                            <li><Box sx={{display:'flex',alignItems:'center'}}>Al menos 1 letra minúscula {codigoNuevo && (/.*[a-z].*/).test(codigoNuevo) ? <CheckIcon sx={{color:"green"}}/> : <ClearIcon sx={{color:"red"}}/>}</Box></li>
                            <li><Box sx={{display:'flex',alignItems:'center'}}>Al menos un número {codigoNuevo && (/(?=.*?[0-9])/).test(codigoNuevo) ? <CheckIcon sx={{color:"green"}}/> : <ClearIcon sx={{color:"red"}}/>}</Box></li>
                            <li><Box sx={{display:'flex',alignItems:'center'}}>Al menos un caracter especial (# ? ! @ $ % & * -) {codigoNuevo && (/(?=.*?[#?!@$%^&*-])/).test(codigoNuevo) ? <CheckIcon sx={{color:"green"}}/> : <ClearIcon sx={{color:"red"}}/>}</Box></li>
                        </ul>
                        <br />
                        <p>Ejemplo: minombre@2023</p>
                    </Alert>
                    <Box sx={{width:!esCelular ? '380px' : '100%'}}>
                        
                        {mensaje && <Alert style={{fontSize:'large'}} severity="info" variant='outlined'>{mensaje}</Alert>}
                        
                        {!mensaje && <>
                        <Box sx={{marginTop:'1rem',marginBottom:'1rem',display:'flex',alignItems:'center'}}>
                            <TextField autoFocus={!esCelular} value={codigoNuevo} inputProps={{maxLength: 100}} type={ver ? "" : "password"} fullWidth placeholder="Nuevo código de seguridad" label="Nuevo código de seguridad" onChange={handleChangeCodigoNuevo} />
                            {!ver && <VisibilityIcon title="Visualice los campos de password" className='cursor-pointer' onClick={()=>setVer(true)}/>}
                            {ver && <VisibilityOffIcon title="Oculte los campos de password" className='cursor-pointer' onClick={()=>setVer(false)}/>}
                        </Box>        
                        <Box sx={{marginTop:'1rem',marginBottom:'1rem'}}>
                            <TextField value={confirmacion} inputProps={{maxLength: 100}} type={ver ? "" : "password"} fullWidth placeholder="Repita el código" label="Repita el código" onChange={handleChangeConfirmacion} />
                        </Box>
                        
                        {errorPaso2 && <Alert style={{fontSize:'large'}} severity="error" variant='outlined'>{errorPaso2}</Alert>}
                        
                        <Box sx={{display:'flex',justifyContent:'space-between', marginTop:'1rem'}}>
                            <Button style={{border:'none'}} color="primary" onClick={()=>setCodigoActual(null)}>Cancelar</Button>
                            {!errorPaso2 && !mensaje && <Button variant="contained" color="primary" onClick={cambiarCodigo}>Cambiar</Button>}
                        </Box>
                        
                        </>}
                    </Box>
                    
                </Card>
            </Main>
}
/*
import React from 'react';

export const CambioPassword = ()=>{

    return (
        <div className="flex f-col">
                        <h1>Cambio de password</h1> 
        </div> 
    )
}
*/