import React, { useState, useEffect, useRef } from 'react';
import { SeleccionadorX } from '../../../componentes/SeleccionadorX'
import { useTablasGenerales } from '../hooks/useTablasGenerales'
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Button, Checkbox, Input, Option, Select, Typography } from '@material-tailwind/react';

const estadosCredencialCustom = [{ id: 'SI', nombre: 'Si' }, { id: 'NO', nombre: 'No' }]
const compromisoMinisterialCustom = [{ id: 'SI', nombre: 'Si' }, { id: 'NO', nombre: 'No' }]

export const Cabecera = ({ hayFiltrosActivos,
    filtrarLista, // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
    resetearLista, // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
    tablasDelListado }) => {

    const { usuario, esCelular } = useContextoGlobal();
    const [textoNombre, setTextoNombre] = useState("");
    const [textoLocalidad, setTextoLocalidad] = useState("");
    const [ministerioSeleccion, setMinisterioSeleccion] = useState('-1')
    const [regionSeleccionada, setRegionSeleccionada] = useState(usuario?.id_region || '-1')
    const [tieneCredObligatoria, setTieneCredObligatoria] = useState(false)
    const [estadoCredencial, setEstadoCredencial] = useState(-1)
    const [soloConFechaAlta, setSoloConFechaAlta] = useState(false)
    const [compromisoMinisterial, setCompromisoMinisterial] = useState(-1)
    const [rangoObreroAbreviadoSeleccion, setRangoObreroAbreviadoSeleccion] = useState(-1)
    const { regiones, rangos, distritos } = useTablasGenerales()
    const [distritoSeleccionado, setDistritoSeleccionado] = useState('-1')
    const [distritosRegion, setDistritosRegion] = useState([])

    useEffect(() => {

        const callback = (item) =>
            item.nombre_obrero.toUpperCase().includes(textoNombre.toUpperCase())
            && (item.localidad.toUpperCase().includes(textoLocalidad.toUpperCase()) || item.provincia.toUpperCase().includes(textoLocalidad.toUpperCase()))
            && ((item.ministerio == ministerioSeleccion && ministerioSeleccion != '-1') ||
                ministerioSeleccion == '-1')
            && ((item.id_region == regionSeleccionada && regionSeleccionada != '-1') ||
                regionSeleccionada == '-1')
            && ((item.id_distrito == distritoSeleccionado && distritoSeleccionado != '-1') ||
                distritoSeleccionado == '-1')
            && ((item.cred_obligatoria == 1 && tieneCredObligatoria) || tieneCredObligatoria == false)
            && ((item.tieneCredencial == estadoCredencial && estadoCredencial != '-1') ||
                estadoCredencial == '-1')
            && ((item.compromiso_ministerial == compromisoMinisterial && compromisoMinisterial != '-1') ||
                compromisoMinisterial == '-1')
            && ((item.ult_ascenso != '' && soloConFechaAlta) || !soloConFechaAlta)
            && ((item.rango == rangoObreroAbreviadoSeleccion && rangoObreroAbreviadoSeleccion != '-1') ||
                rangoObreroAbreviadoSeleccion == '-1')

        // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        filtrarLista(callback)

    }, [textoLocalidad,
        textoNombre,
        rangoObreroAbreviadoSeleccion,
        ministerioSeleccion,
        estadoCredencial,
        compromisoMinisterial,
        soloConFechaAlta,
        regionSeleccionada,
        distritoSeleccionado,
        tieneCredObligatoria])

    useEffect(() => {
        if (regionSeleccionada == -1) { // si es perfil sede usa distritosRegion en el select, si es perfil distrito o region usa distrito en el select que ya viene filtrado del hook
            setDistritoSeleccionado('-1')
            setDistritosRegion([])
        } else {
            setDistritosRegion(distritos.filter(item => item.id_distrito > 0).filter(item => item.id_region == regionSeleccionada))
        }
    }, [regionSeleccionada])

    useEffect(() => {
        if (distritosRegion.length > 0) {
            //                setDistritoSeleccionado(distritosRegion[0].id_distrito)
            setDistritoSeleccionado('-1') //todos
        }
    }, [distritosRegion])

    const handleChangeSelectRegion = (e) => {
        setRegionSeleccionada(e.target.value)
    }

    const handleChangeSelectDistrito = (e) => {
        setDistritoSeleccionado(e.target.value)
    }

    const handleChangeSelectMinisterio = (e) => {
        setMinisterioSeleccion(e)
    }

    const handleChangeSelectRangoAbreviado = (e) => {
        setRangoObreroAbreviadoSeleccion(e.target.value)
    }

    const handleChangeInputNombre = (e) => {
        setTextoNombre(e.target.value)
    }

    const handleChangeInputLocalidad = (e) => {
        setTextoLocalidad(e.target.value)
    }


    const handleChangeSelectEstadoCredencial = (e) => {
        setEstadoCredencial(e)
    }

    const handleChangeSelectCompromisoMinisterial = (e) => {
        setCompromisoMinisterial(e)
    }

    const limpiarNombre = () => {
        setTextoNombre("")
    }

    const limpiarLocalidad = () => {
        setTextoLocalidad("")
    }

    const limpiarRegion = () => {
        setRegionSeleccionada(-1)
    }

    const limpiarDistrito = () => {

        setDistritoSeleccionado(-1)

    }

    const limpiarRangos = () => {
        setRangoObreroAbreviadoSeleccion(-1)
    }

    const limpiarMinisterios = () => {
        setMinisterioSeleccion(-1)
    }

    const limpiarEstadoCredencial = () => {
        setEstadoCredencial(-1)
    }

    const limpiarCompromisoMinisterial = () => {
        setCompromisoMinisterial(-1)
    }

    const limpiarFiltros = () => {
        setTextoNombre("")
        setTextoLocalidad("")
        setMinisterioSeleccion(-1)
        setRangoObreroAbreviadoSeleccion(-1)
        setTieneCredObligatoria(false)
        setSoloConFechaAlta(false)
        setEstadoCredencial(-1)
        setCompromisoMinisterial(-1)
        setDistritoSeleccionado(-1)
        setRegionSeleccionada(-1)
        resetearLista()
    }

    return <div className='flex f-col mb-6'>
        <div className={`flex ${esCelular ? 'f-col space-y-2' : 'grid grid-cols-4 gap-4'} justify-center flex-wrap abc mb-2 select-ui-data`}>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Nombre
                </Typography>
                <Input
                    type='text'
                    onChange={handleChangeInputNombre}
                    name="texto-nombre"
                    value={textoNombre}
                    color='black'
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    value={textoLocalidad}
                    className="mb-1 font-medium "
                >
                    Ubicación
                </Typography>
                <Input
                    type='text'
                    onChange={handleChangeInputLocalidad}
                    name="texto-localidad"
                    color='black'
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Ministerio
                </Typography>
                <Select
                    label="Seleccione un Ministerio"
                    value={ministerioSeleccion}
                    onChange={handleChangeSelectMinisterio}
                >
                    {tablasDelListado.ministerios.map((ministerio, index) => (
                        <Option key={index} value={ministerio}>
                            {ministerio}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Rango
                </Typography>
                <SeleccionadorXLabel titulo='Rango' vector={rangos} onchange={handleChangeSelectRangoAbreviado}
                    valor={rangoObreroAbreviadoSeleccion} nombre='Todos' limpiar={limpiarRangos} />
            </div>
        </div>

        <div className={`flex ${esCelular ? 'f-col space-y-2' : 'grid grid-cols-4 gap-4'} justify-center flex-wrap abc mb-2 select-ui-data`}>
            {usuario.perfil == 'sede' &&
                <div className="flex flex-col">
                    <Typography
                        variant="small"
                        className="mb-1 font-medium "
                    >
                        Región
                    </Typography>
                    <SeleccionadorXLabel titulo='Región' vector={regiones} onchange={handleChangeSelectRegion} claves={{ id: 'id_region', nombre: 'nombre' }}
                        valor={regionSeleccionada} nombre='Todas' noDefault={!!usuario.id_region} limpiar={usuario.id_region ? false : limpiarRegion} />
                </div>
            }
            {usuario.perfil != 'distrito' &&
                <div className="flex flex-col">
                    <Typography
                        variant="small"
                        className="mb-1 font-medium "
                    >
                        Distrito
                    </Typography>
                    <SeleccionadorXLabel titulo='Distrito' vector={usuario.id_region ? distritos : distritosRegion} onchange={handleChangeSelectDistrito} claves={{ id: 'id_distrito', nombre: 'nombre' }}
                        valor={distritoSeleccionado} nombre='Todos' NO_VAnoDefault={!!usuario.id_region} limpiar={usuario.id_region ? false : limpiarDistrito} />
                </div>
            }
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Tiene credencial activa
                </Typography>
                <Select
                    label="Seleccione un Estado"
                    value={estadoCredencial}
                    onChange={handleChangeSelectEstadoCredencial}
                    className=""
                >
                    {estadosCredencialCustom.map((estado, index) => (
                        <Option key={estado.id} value={estado.id}>
                            {estado.nombre}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Compromiso ministerial
                </Typography>
                <Select
                    label="Seleccione un Compromiso"
                    value={compromisoMinisterial}
                    onChange={handleChangeSelectCompromisoMinisterial}
                    className=""
                >
                    {compromisoMinisterialCustom.map((compromiso, index) => (
                        <Option key={compromiso.id} value={compromiso.id}>
                            {compromiso.nombre}
                        </Option>
                    ))}
                </Select>
            </div>
        </div>

        <div className='lg:flex f-row justify-center lg:space-x-4'>
            {usuario.sede_admin ?
                <Checkbox
                    label="Tiene credencial habilitada por WF"
                    onClick={() => { setTieneCredObligatoria(!tieneCredObligatoria) }}
                    checked={tieneCredObligatoria}
                />
                : null}
            <Checkbox
                label="Tiene fecha de ascenso"
                onClick={() => { setSoloConFechaAlta(!soloConFechaAlta) }}
                checked={soloConFechaAlta}
            />
        </div>
        <div className="mt-2 flex md:justify-end">
            <Button variant='text' className='text-red-400 bg-red-100 hover:bg-red-200' onClick={limpiarFiltros}>Limpiar Filtros</Button>
        </div>
    </div>
}


const SeleccionadorXLabel = ({ vector, onchange, valor, nombre, limpiar, claves, titulo, noDefault }) => {

    return <div className='w-full'>
        <SeleccionadorX vector={vector}
            onchange={onchange}
            valor={valor}
            nombre={nombre}
            claves={claves}
            limpiar={limpiar}
            noDefault={noDefault ? true : false}
        />
    </div>
}