import React from "react";
import '../Assets/css/cssloader.css';
import { Spinner } from "@material-tailwind/react";

export const LoaderCircular = (props) => {
  return (

    <div id="loader-wrapper">
       <Spinner className="h-12 w-12" />
    </div>

  )
}