import React, { useState, useEffect } from 'react';
import {hacerFoco,
    moment,
    Swal,
    gql,
    useQuery,
    useMutation,
    objestados,
    Axios,
    analizarFecha,fechasDifieren,formatearTexto,subirAdjunto} from '../componentes/modulos/index';

import {useTramites} from './useTramites.js'

export const useMinistro = (cabecera,id,setData,usuario,snackbar)=>{

    const FUNCION_BUSQUEDA_TRAMITE_GQL = gql`
    query ministro ($id:ID!){
        ministroInfo(id:$id){
            _id,
            id_entrevista{
                usuario{nombre},
                entrevistador,
                fecha_entrevista,
                activo,
                _id
            },
               personales{
              nombre,
              apellido,
              tipo_documento{id,nombre},
              estado_civil{id,nombre},
              sexo,
              nro_documento,
              fecha_nacimiento,
              conyuge,
              nacionalidad{id,nombre},
            },
                contacto{
                    telefono,
                    celular,
                    email
                },
               domicilio{
                    direccion,
                    localidad,
                    barrio,
                    cod_postal,
                    partido{
                        id,nombre
                    },
                provincia{
                    id,
                    nombre
                }
            },
              id_tramite_patrocinio{
                  _id,
            },
              referencias{
                pastor{
                    nombrepastor,
                    emailpastor,
                     activo,
                      fecha_respuesta
            },
                anciano{
                    nombreanciano,
                    emailanciano,
                    activo,
                    fecha_respuesta
                },
                conyuge{
                    nombreconyuge,
                    emailconyuge,
                    activo,
                    fecha_respuesta
                }},
                  adjuntos{
                    analitico,
                    dni_frente,
                    dni_reverso,
                    foto_4x4,
                    antecedentes_pen,
                    cert_mat,
                    extra_1URL
                },
                acepta{
                        doctrina,
                        estatutos,
                        compromiso
                },
                iglesia{
                    provincia{id,nombre},
                    partido{id,nombre},
                    iglesia{id,nombre}
                },
                  finanzas{diezmo,
                        sostenimiento},
                  conversion{ year_bautismo,
                            year_conversion,
                              bautismo_es},
                      pastores,
                      acta_cdn,
                      fecha_acta_cdn,
                      fecha_acta_regional,
                      acta_regional,
                    analitico_validado,
                    entrevista_validada,
                    referencias_validadas,
                    condiciones_validadas,
            id_tramite{
                ministro{
                    nombre,
                    id
                },
                region,
                distrito{id,nombre}
            },
            urls{
                pastor,anciano,conyuge
            }
        }
    }`
    
    const status_actual_tramite = cabecera.status[cabecera.status.length-1].texto;
    
        const {loading,error,data,refetch} = useQuery(FUNCION_BUSQUEDA_TRAMITE_GQL,{variables:{id:id},
            fetchPolicy: "no-cache", 
            // tuve que agregar esta política porque los campos que son tipo PropType o sea con id y nombre entraban en conflicto por un tema de cache
            // sucede por el agregado de __typename
            // sucedía que por ejemplo en casos como estado_civil(id,nombre) o nacionalidad(id,nombre) mezclaba el nombre
            // podría haber deshabilitado totalmente el inmemory cache en app.js en donde configuro... new ApolloClient ,
            // pero en este caso probé con forzar a que no use la caché la query especifica en este caso GET_MINISTRO
            // ver... https://kamranicus.com/graphql-apollo-object-caching/
            /*
            Don't add __typename wholesale
        If you control your Apollo client caching, there is an option to disable automatic addition of __typename
            */
            onError:(err)=>alert(err.message)})

    const FUNCION_MODIFICACION_TRAMITE_GQL = gql`
        mutation updateNuevoMinistro($id:ID!,$usuario:String!,$usuario_id:Int!,$analitico_validado:Boolean,
                                $entrevista_validada:Boolean,$condiciones_validadas:Boolean,$referencias_validadas:Boolean,
                                $acta_cdn:Int,$acta_regional:Int,$fecha_acta_cdn:String,
                                $fecha_acta_regional:String,
                                $analiticoURL:String,
                                $dni_frenteURL:String,
                                $dni_reversoURL:String,
                                $foto_4x4URL:String,
                                $antecedentes_penURL:String,
                                $cert_matURL:String,
                                $extra_1URL:String,){
                            updateNuevoMinistro(id:$id,
                            usuario:$usuario,
                            id_usuario:$usuario_id,
                            analitico_validado:$analitico_validado,
                            entrevista_validada:$entrevista_validada,
                            condiciones_validadas:$condiciones_validadas,
                            referencias_validadas:$referencias_validadas,
                            acta_regional:$acta_regional,
                            acta_cdn:$acta_cdn,
                            fecha_acta_cdn:$fecha_acta_cdn,
                            fecha_acta_regional:$fecha_acta_regional,
                            analiticoURL:$analiticoURL,
                            dni_frenteURL:$dni_frenteURL,
                            dni_reversoURL:$dni_reversoURL,
                            foto_4x4URL:$foto_4x4URL,
                            antecedentes_penURL:$antecedentes_penURL,
                            cert_matURL:$cert_matURL,
                            extra_1URL:$extra_1URL)
        }
    `    
    const [funcionActualizacionTramite,{ data:data2, loading:loading2, error:error2 }] = useMutation(FUNCION_MODIFICACION_TRAMITE_GQL)
    const [datosDelTramite, setDatosDelTramite] = React.useState(null);
    const [analitico,setAnalitico] = useState(null)
    const [condiciones,setCondiciones] = useState(null)
    const [entrevista,setEntrevista] = useState(null)
    const [referencias,setReferencias] = useState(null)
    const [copia,setCopia] = useState({pastor:false,anciano:false,conyuge:false})
    const [nuevoExtra1,setNuevoExtra1]=React.useState(null);
    const [cambiarExtra1,setCambiarExtra1]=React.useState(false);
    const [nuevoAnalitico,setNuevoAnalitico]=React.useState(null);
    const [cambiarAnalitico,setCambiarAnalitico]=React.useState(false);
    const [nuevoDniFrente,setNuevoDniFrente]=React.useState(null);
    const [cambiarDniFrente,setCambiarDniFrente]=React.useState(false);
    const [nuevoDniReverso,setNuevoDniReverso]=React.useState(null);
    const [cambiarDniReverso,setCambiarDniReverso]=React.useState(false);
    const [nuevoFoto4x4,setNuevoFoto4x4]=React.useState(null);
    const [cambiarFoto4x4,setCambiarFoto4x4]=React.useState(false);
    const [nuevoAntecedentesPen,setNuevoAntecedentesPen]=React.useState(null);
    const [cambiarAntecedentesPen,setCambiarAntecedentesPen]=React.useState(false);
    const [nuevoCertMat,setNuevoCertMat]=React.useState(null);
    const [cambiarCertMat,setCambiarCertMat]=React.useState(false);
    const [adjuntos,setAdjuntos] = React.useState([]);
    const [linkEntrevista,setLinkEntrevista]=React.useState(null)

    const {acta_regional,
            setActa_regional,
            acta_cdn,
            setActa_cdn,
            procesando,
            setProcesando,
            fechaAR,
            fechaACDN,
            touched,
            setTouched,
            refrescando,
            setRefrescando,
            expanded,handleChangeFechaAR,
            handleChangeFechaACDN,
            handleChange,
            //inicioActualizacionTramite, // no usamos el standard sino un custom creado aqui. Es necesario porque ahora se pueden modificar adjuntos desde administración
            setFechaACDN,traerUrl,
            setFechaAR,
            validarDatosLuegoActualizar
        } = useTramites()
    
    useEffect(()=>{
        const _datosDelTramite = data?.ministroInfo;
    
        setDatosDelTramite(_datosDelTramite);
    
        if(_datosDelTramite){
            setAnalitico(_datosDelTramite?.analitico_validado)
            setEntrevista(_datosDelTramite?.entrevista_validada)
            setReferencias(_datosDelTramite?.referencias_validadas)
            setCondiciones(_datosDelTramite?.condiciones_validadas)
            setActa_regional(_datosDelTramite?.acta_regional)
            setActa_cdn(_datosDelTramite?.acta_cdn)
            setFechaAR(_datosDelTramite?.fecha_acta_regional ? moment(Number(_datosDelTramite?.fecha_acta_regional)).format('YYYY-MM-DD'): null) 
            setFechaACDN(_datosDelTramite?.fecha_acta_cdn ? moment(Number(_datosDelTramite?.fecha_acta_cdn)).format('YYYY-MM-DD'): null) 
            setData(_datosDelTramite)
            setLinkEntrevista(`${process.env.REACT_APP_API_UAD_BASE}/documentos/cre15/cred/${usuario.usuario}/${_datosDelTramite._id}/${usuario.id_usuario}/delegate`)
        }
    },[data])
    
    useEffect(()=>{
        if(datosDelTramite){
            const vector_analisis_campos = camposTocadosPorElUsuario();
            setTouched(vector_analisis_campos)
        }
    },[analitico,
        acta_regional,
        fechaAR,
        acta_cdn,
        fechaACDN,
        entrevista,
        condiciones,
        referencias,
        nuevoExtra1,
        nuevoAnalitico,
        nuevoDniFrente,
        nuevoDniReverso,
        nuevoFoto4x4,
        nuevoAntecedentesPen,
        nuevoCertMat
        ])
    
         const actualizarTramite = async (url_analitico,
            url_dni_frente,
            url_dni_reverso,
            url_foto_4x4,
            url_antecedentes_pen,
            url_cert_mat,
            url_extra_1)=>{
            try{
               const resultado = await funcionActualizacionTramite({variables:{
                    id:data.ministroInfo._id,
                    usuario:usuario.nombre,
                    usuario_id:usuario.id_usuario,
                    analitico_validado: touched.analitico ? analitico : undefined,
                    entrevista_validada: touched.entrevista ? entrevista : undefined,
                    condiciones_validadas: touched.condiciones ? condiciones : undefined,
                    referencias_validadas: touched.referencias ? referencias : undefined,
                    acta_cdn:touched.acta_cdn ? acta_cdn ? Number(acta_cdn) : null : undefined,
                    acta_regional: touched.acta_regional ? acta_regional ? Number(acta_regional) : null : undefined,
                    fecha_acta_regional:touched.fecha_acta_regional ? analizarFecha(fechaAR) : undefined,
                    fecha_acta_cdn:touched.fecha_acta_cdn ? analizarFecha(fechaACDN) : undefined,
                    ...(url_analitico ? {analiticoURL:url_analitico} : {}),
                    ...(url_dni_frente ? {dni_frenteURL:url_dni_frente} : {}),
                    ...(url_dni_reverso ? {dni_reversoURL:url_dni_reverso} : {}),
                    ...(url_foto_4x4 ? {foto_4x4URL:url_foto_4x4} : {}),
                    ...(url_antecedentes_pen ? {antecedentes_penURL:url_antecedentes_pen} : {}),
                    ...(url_cert_mat ? {cert_matURL:url_cert_mat} : {}),
                    ...(url_extra_1 ? {extra_1URL:url_extra_1} : {})
                },
                refetchQueries:[FUNCION_BUSQUEDA_TRAMITE_GQL],
                onCompleted:(data)=>{
                    setProcesando(false);
                    setNuevoAnalitico(null);
                    setCambiarAnalitico(false);
                    setNuevoExtra1(null);
                    setCambiarExtra1(false);
                    setNuevoDniFrente(null);
                    setCambiarDniFrente(false);
                    setNuevoDniReverso(null);
                    setCambiarDniReverso(false);
                    setNuevoFoto4x4(null);
                    setCambiarFoto4x4(false);
                    setNuevoAntecedentesPen(null);
                    setCambiarAntecedentesPen(false);
                    setNuevoCertMat(null);
                    setCambiarCertMat(false);
                    setTouched({})
                }})
                Swal.fire({
                    text:resultado?.data?.updateNuevoMinistro,
                    icon: 'warning',
                    confirmButtonColor: '#3085d6',
                    }).then(()=>{
                        setTimeout(() => {
                            hacerFoco('tra-action')
                        }, 600);
                    })   
            }catch(err){
                if(err?.networkError?.result?.errors){
                    snackbar.showMessage(err?.networkError?.result?.errors?.reduce((acum,item)=>{return `${acum} ${item.message}`},''),'error')
                   }else{
                    snackbar.showMessage(err?.message || JSON.stringify(err) || 'Error al ejecutar la operación.','error')
                   }    
            }
        }

        
    const validarDatosLuegoActualizarTramite = ()=>{ /*NOTE - Esta función se crea porque necesitamos ejecutar pasos que no son standard 
        entonces hacemos la funcion customizada y por lo tanto tampoco usamos la funcion inicioActualizacionTramite de useTramites sino que la creamos y customizamos aqui. 
        Normalmente se usa el standard excepto los trámites que tienen casos muy específicos*/

        const validacion_inicial = validarDatosLuegoActualizar(); // ejecuto las validaciones standard

        if(!validacion_inicial){
            return 
        }

        setProcesando(true)
        setTimeout(async () => {
            
        try{
            let url_nuevo_extra_1,
            url_nuevo_analitico,
            url_dni_frente,
            url_dni_reverso,
            url_foto_4x4,
            url_antecedentes_pen,
            url_cert_mat;

            if(nuevoAnalitico){
                const analitico_subido = await subirAdjunto(nuevoAnalitico,
                    //cabecera.ministro.id,
                    data.ministroInfo.personales.nro_documento, // al ser 1 cred no va su id de ministro porque aún no existe en su lugar el nombre del archivo lleva su DNI
                    `analitico1cred`,
                    adjuntos)

                if(!analitico_subido[0])
                    {
                        setProcesando(false)
                        console.log(analitico_subido[1])
                        alert('Se produjo un error al subir el nuevo analítico')
                        return false
                }else{
                    url_nuevo_analitico = analitico_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_nuevo_analitico])
                }
        
            }

            if(nuevoDniFrente){
                const dni_frente_subido = await subirAdjunto(nuevoDniFrente,
                    //cabecera.ministro.id,
                    data.ministroInfo.personales.nro_documento, // al ser 1 cred no va su id de ministro porque aún no existe en su lugar el nombre del archivo lleva su DNI
                    `dni_frente1cred`,
                    adjuntos)

                if(!dni_frente_subido[0])
                    {
                        setProcesando(false)
                        console.log(dni_frente_subido[1])
                        alert('Se produjo un error al subir el nuevo dni frente')
                        return false
                }else{
                    url_dni_frente = dni_frente_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_dni_frente])
                }
        
            }

            if(nuevoDniReverso){
                const dni_reverso_subido = await subirAdjunto(nuevoDniReverso,
                    //cabecera.ministro.id,
                    data.ministroInfo.personales.nro_documento, // al ser 1 cred no va su id de ministro porque aún no existe en su lugar el nombre del archivo lleva su DNI
                    `dni_reverso1cred`,
                    adjuntos)

                if(!dni_reverso_subido[0])
                    {
                        setProcesando(false)
                        console.log(dni_reverso_subido[1])
                        alert('Se produjo un error al subir el nuevo dni reverso')
                        return false
                }else{
                    url_dni_reverso = dni_reverso_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_dni_reverso])
                }
        
            }

            if(nuevoFoto4x4){
                const foto_4x4_subido = await subirAdjunto(nuevoFoto4x4,
                    //cabecera.ministro.id,
                    data.ministroInfo.personales.nro_documento, // al ser 1 cred no va su id de ministro porque aún no existe en su lugar el nombre del archivo lleva su DNI
                    `foto_4x4cred`,
                    adjuntos)

                if(!foto_4x4_subido[0])
                    {
                        setProcesando(false)
                        console.log(foto_4x4_subido[1])
                        alert('Se produjo un error al subir la nueva foto 4x4')
                        return false
                }else{
                    url_foto_4x4 = foto_4x4_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_foto_4x4])
                }
        
            }

            if(nuevoAntecedentesPen){
                const antecedentes_pen_subido = await subirAdjunto(nuevoAntecedentesPen,
                    //cabecera.ministro.id,
                    data.ministroInfo.personales.nro_documento, // al ser 1 cred no va su id de ministro porque aún no existe en su lugar el nombre del archivo lleva su DNI
                    `antecedentes_pencred`,
                    adjuntos)

                if(!antecedentes_pen_subido[0])
                    {
                        setProcesando(false)
                        console.log(antecedentes_pen_subido[1])
                        alert('Se produjo un error al subir el nuevo archivo de antecedentes penales')
                        return false
                }else{
                    url_antecedentes_pen = antecedentes_pen_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_antecedentes_pen])
                }
        
            }

            if(nuevoCertMat){
                const cert_mat_subido = await subirAdjunto(nuevoCertMat,
                    //cabecera.ministro.id,
                    data.ministroInfo.personales.nro_documento, // al ser 1 cred no va su id de ministro porque aún no existe en su lugar el nombre del archivo lleva su DNI
                    `cert_matcred`,
                    adjuntos)

                if(!cert_mat_subido[0])
                    {
                        setProcesando(false)
                        console.log(cert_mat_subido[1])
                        alert('Se produjo un error al subir el nuevo certificado de matrimonio')
                        return false
                }else{
                    url_cert_mat = cert_mat_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_cert_mat])
                }
        
            }

            if(nuevoExtra1){
                const extra_1_subido = await subirAdjunto(nuevoExtra1,
                    //cabecera.ministro.id,
                    data.ministroInfo.personales.nro_documento, // al ser 1 cred no va su id de ministro porque aún no existe en su lugar el nombre del archivo lleva su DNI
                    `extra_1cred`,
                    adjuntos)

                if(!extra_1_subido[0])
                    {
                        setProcesando(false)
                        console.log(extra_1_subido[1])
                        alert('Se produjo un error al subir el nuevo archivo extra 1')
                        return false
                }else{
                    url_nuevo_extra_1 = extra_1_subido[1]?.acta;
                    setAdjuntos([...adjuntos,url_nuevo_extra_1])
                }                    
            }

            actualizarTramite(url_nuevo_analitico,
                url_dni_frente,
                url_dni_reverso,
                url_foto_4x4,
                url_antecedentes_pen,
                url_cert_mat,
                url_nuevo_extra_1)

        }catch(err){
            alert(JSON.stringify(err))
        }
        }, 1000);
    }

        const inicioActualizacionTramite = ()=>{ // creamos esta y no usamos el standard para llamar a validarDatosLuegoActualizarTramite y ejecutar acciones y validaciones custom

            Swal.fire({
                text:"Por favor confirme la modificación",
                showCancelButton:true,
                confirmButtonText:'Modificar',
                cancelButtonText:'Cancelar'
            }).then(
                resultado=>{
                    if (resultado.value){
                        validarDatosLuegoActualizarTramite();
                    }else{
                        console.log("Se canceló la operación de la solicitud")
                    }
                }
            )
        
        }
    
        const refrescar = ()=>{
            setRefrescando(true)
            setTimeout(() => {
                setRefrescando(false)
            }, 1000);
            refetch()
        }
    
       const IniciarCerrarEntrevista = ()=>{
    
            if (status_actual_tramite!=objestados.procRegion.nombre){
                
                snackbar.showMessage('Esta operación solo es posible si la solicitud se encuentra en estado "En proceso regional"','error')
    
                return
            }
    
            if (usuario.perfil!='region'){
                
                snackbar.showMessage('Esta operación solo es posible para el perfil de secretaría regional','error')
    
                return
            }
    
            Swal.fire({
                text:"Recuerde que al cerrar la entrevista ya no podrá ser modificada posteriormente.\n\nAsegúrese de haber revisado las respuestas y que no necesita hacer ninguna modificación \n\nPor favor confirme que desea cerrar la entrevista",
                showCancelButton:true,
                confirmButtonText:'Si, cerrar la entrevista',
                cancelButtonText:'Cancelar',
                customClass: {
                    container: 'custom-container1'
                  },
            }).then(
                resultado=>{
                    if (resultado.value){
                        cerrarEntrevista(datosDelTramite?.id_entrevista?._id)
                    }else{
                        console.log("Se canceló la operación del cierre de entrevista")
                    }
                }
            )
        }
    
        const cerrarEntrevista = async (id_entrevista)=>{
    
            if (status_actual_tramite!=objestados.procRegion.nombre){
                
                snackbar.showMessage('Esta operación solo es posible si la solicitud se encuentra en estado "En proceso regional"','error')
    
                return
            }
    
            if (usuario.perfil!='region'){
                
                snackbar.showMessage('Esta operación solo es posible para el perfil de secretaría regional','error')
    
                return
            }
    
            try{
                const {data} = await Axios.post(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/cre15/cerrar`,{id_entrevista})
                if(data?.resultado==true){
                    Swal.fire({
                        text:`Se cerró la entrevista`,
                        icon: 'success',
                        confirmButtonColor: '#3085d6'
                        }).then(r=>{
                            refetch()
                        })  
                }else{
                    console.log(data?.resultado?.mensaje)
                    Swal.fire({
                        text:`Se produjo un error al intentar cerrar la entrevista`,
                        icon: 'error',
                        confirmButtonColor: '#3085d6'
                        })  
                }
    
            }catch(err){
                console.log(err)
                alert('Se produjo un error al cerrar la entrevista')
            }
        }
    
        const camposTocadosPorElUsuario = ()=>{
    
            const touched = {};
        
            if(analitico!=datosDelTramite?.analitico_validado){
                Object.assign(touched,{analitico:true})
            }
    
            if(referencias!=datosDelTramite?.referencias_validadas){
                Object.assign(touched,{referencias:true})
            }
    
            if(entrevista!=datosDelTramite?.entrevista_validada){
                Object.assign(touched,{entrevista:true})
            }
    
            if(condiciones!=datosDelTramite?.condiciones_validadas){
                Object.assign(touched,{condiciones:true})
            }
    
            if(formatearTexto(acta_regional)!=formatearTexto(datosDelTramite?.acta_regional)){
                Object.assign(touched,{acta_regional:true})
            }
            if(formatearTexto(acta_cdn)!=formatearTexto(datosDelTramite?.acta_cdn)){
                Object.assign(touched,{acta_cdn:true})
            }
            if(fechasDifieren(datosDelTramite?.fecha_acta_regional,fechaAR)){
                Object.assign(touched,{fecha_acta_regional:true})
            }
            if(fechasDifieren(datosDelTramite?.fecha_acta_cdn,fechaACDN)){
                Object.assign(touched,{fecha_acta_cdn:true})
            }

            if(nuevoAnalitico){
                Object.assign(touched,{nuevoAnalitico:true})
            }

            if(nuevoExtra1){
                Object.assign(touched,{nuevoExtra1:true})
            }

            if(nuevoDniFrente){
                Object.assign(touched,{nuevoDniFrente:true})
            }

            if(nuevoDniReverso){
                Object.assign(touched,{nuevoDniReverso:true})
            }

            if(nuevoFoto4x4){
                Object.assign(touched,{nuevoFoto4x4:true})
            }

            if(nuevoAntecedentesPen){
                Object.assign(touched,{nuevoAntecedentesPen:true})
            }

            if(nuevoCertMat){
                Object.assign(touched,{nuevoCertMat:true})
            }
        
            return touched
        }
    
        const handleChangeAnaliticoCheck = (e)=>{
            setAnalitico(e.target.checked)
        }
    
        const handleChangeEntrevistaCheck = (e)=>{
            setEntrevista(e.target.checked)
        }
    
        const handleChangeReferenciasCheck = (e)=>{
            setReferencias(e.target.checked)
        }
    
        const handleChangeCondicionesCheck = (e)=>{
            setCondiciones(e.target.checked)
        }
    
        const accederEntrevista = ()=>{
    
            if(datosDelTramite?.id_entrevista?.activo!=false){
                if(usuario.perfil!='region'){
    
                    snackbar.showMessage('Esta operación solo es posible si la solicitud se encuentra cerrada o el usuario tiene perfil de secretaría regional"','error')
                    return
                }else{}{
                    if (status_actual_tramite!=objestados.procRegion.nombre){
                    
                        snackbar.showMessage('Esta operación solo es posible si la solicitud se encuentra en estado "En proceso regional"','error')
            
                        return
                    }
                }
            }
    
            window.open(`${process.env.REACT_APP_API_UAD_BASE}/documentos/cre15/cred/${usuario.usuario}/${datosDelTramite._id}/${usuario.id_usuario}/self`, "_blank")
    
        }
    
    
        const copiarLink = (tipo,link) =>{
            navigator.clipboard.writeText(link)
    
            switch(tipo){
                case 'pastor' : 
                    setCopia({...copia,pastor:true})
                    setTimeout(() => {
                        setCopia({...copia,pastor:false})
                    }, 1000);
                    return
    
                case 'anciano' : 
                    setCopia({...copia,anciano:true})
                    setTimeout(() => {
                        setCopia({...copia,anciano:false})
                    }, 1000);
                    return
    
                case 'conyuge' : 
                    setCopia({...copia,conyuge:true})
                    setTimeout(() => {
                        setCopia({...copia,conyuge:false})
                    }, 1000);
                    return                                
            }
        }

    return {
        error,
        loading,
        loading2,
        procesando,
        datosDelTramite,
        refrescando,
        status_actual_tramite,
        expanded,
        handleChange,
        traerUrl,
        copiarLink,
        copia,
        IniciarCerrarEntrevista,
        accederEntrevista,
        analitico,
        handleChangeAnaliticoCheck,
        referencias,
        condiciones,
        acta_regional,
        setActa_regional,
        fechaAR,
        handleChangeFechaAR,
        setActa_cdn,
        fechaACDN,
        handleChangeFechaACDN,
        inicioActualizacionTramite,
        actualizarTramite,
        touched,
        handleChangeReferenciasCheck,
        refrescar,
        entrevista,
        handleChangeEntrevistaCheck,
        handleChangeCondicionesCheck,
        acta_cdn,
        nuevoExtra1,setNuevoExtra1,
        nuevoAnalitico,setNuevoAnalitico,
        cambiarAnalitico,setCambiarAnalitico,
        cambiarExtra1,setCambiarExtra1,
        nuevoDniFrente,
        setNuevoDniFrente,
        cambiarDniFrente,
        setCambiarDniFrente,
        nuevoDniReverso,
        setNuevoDniReverso,
        cambiarDniReverso,
        setCambiarDniReverso,
        nuevoFoto4x4,
        setNuevoFoto4x4,
        cambiarFoto4x4,
        setCambiarFoto4x4,
        nuevoAntecedentesPen,
        setNuevoAntecedentesPen,
        cambiarAntecedentesPen,
        setCambiarAntecedentesPen,
        nuevoCertMat,
        setNuevoCertMat,
        cambiarCertMat,
        setCambiarCertMat,
        linkEntrevista
    }
}