import React, { useState, useEffect, useRef } from 'react';
import { Fecha } from '../../../componentes/Fecha'
import { TextField, Box } from "@material-ui/core";
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Option, Select, Typography } from '@material-tailwind/react';

export const Cabecera = ({ fechaDesde,
    fechaHasta,
    setFechaDesde,
    setFechaHasta,
    filtrarLista,
    usuario,
    regiones,
    handleChangeSelectRegion,
    regionSeleccionada  // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
}) => {

    const [textoNombre, setTextoNombre] = useState("");
    const { esCelular } = useContextoGlobal();

    useEffect(() => {

        const callback = (item) =>
            item.contribuyente.toUpperCase().includes(textoNombre.toUpperCase()) ||
            item.titular.toUpperCase().includes(textoNombre.toUpperCase())

        // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        filtrarLista(callback)

    }, [textoNombre])

    const handleChangeInputNombre = (e) => {

        setTextoNombre(e.target.value)

    }

    const limpiarNombre = () => {

        setTextoNombre("")

    }

    return <div className='flex f-col mt-4'>
        <div className={`flex ${esCelular ? 'f-col space-y-2' : 'f-row space-x-4'} justify-center flex-wrap abc`}>
            {usuario.sede_admin && <SeleccionadorXLabel titulo='Región'
                vector={regiones}
                onchange={handleChangeSelectRegion}
                claves={{ id: 'id_region', nombre: 'nombre' }}
                valor={regionSeleccionada}
                nombre='Seleccione una región'
            />
            }
            <div>
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Fecha Desde
                </Typography>
                <Fecha fecha={fechaDesde} setFecha={setFechaDesde} />
            </div>
            <div>
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Fecha Hasta
                </Typography>
                <Fecha fecha={fechaHasta} setFecha={setFechaHasta} />
            </div>
            <Box sx={{}}>
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Nombre
                </Typography>
                <TextField fullWidth id="texto-nombre" inputProps={{}} value={textoNombre} onChange={handleChangeInputNombre} variant="outlined" />
            </Box>
        </div>
    </div>
}


const SeleccionadorXLabel = ({ vector, onchange, valor, nombre, limpiar, claves, titulo, noDefault }) => {

    return <div className='select-ui-data'>
        <Typography
            variant="small"
            className="mb-1 font-medium "
        >
            {titulo}
        </Typography>
        <Select
            label="Seleccione una opción"
            value={valor}
            onChange={onchange}
            className='h-14'
        >
            {vector.map((item, index) => (
                <Option key={index} value={item.id_region}>
                    {item.nombre}
                </Option>
            ))}
        </Select>
    </div>
}

