import React from 'react';
import { uuidv4 } from '../Helpers/importacionesComunes';

export const SeleccionadorX = ({ vector, onchange, valor, nombre, noDefault, name, classancho, limpiar, claves, id }) => {
    if (!vector) {
        return null
    }

    //recordar que un objeto puedo leerlo de 2 formas como vector o como objeto
    // es lo mismo usuario['nombre'] que usuario.nombre
    // aprovecho esta característica para hacer un seleccionador genérico y le paso
    // el nombre de la clave y el texto como un string para referenciarlo en notación vector
    return (
        <div className="select-ui-data ">
            <select value={valor} name={name ? name : ''} onChange={onchange} className={'select-bg-ui !h-10'} id={id ? id : null}>
                {noDefault ? null : <option value="-1" key={uuidv4()}>{nombre}</option>}
                {claves && vector.map(item => <option value={item[claves.id]} key={uuidv4()}>{item[claves.nombre]}</option>)}
                {!claves && vector.map(item => <option value={item} key={item}>{item}</option>)}
            </select>
        </div>
    )

}   
