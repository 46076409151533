import React, {useContext,useState,useMemo} from 'react'
import {TextField, Box ,makeStyles ,Typography} from "@material-ui/core";
import {Dialogos} from '../../../componentes/MUI/Dialogos'
import { tramitesContext } from '../contexto/TramitesContext'
import {gql,useQuery} from '@apollo/client'
import {useContextoGlobal} from '../../../Context/contextoGlobal';
import moment from 'moment';
import {InicioTramite as Patrocinado} from '../../../tramites/patrocinado/vistas/InicioTramite'
import {InicioTramite as Anexo} from '../../../tramites/anexo/vistas/InicioTramite'
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import TaskIcon from '@mui/icons-material/Task';
import WarningIcon from '@mui/icons-material/PriorityHigh';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import FilterOnIcon from '@mui/icons-material/FilterAlt';
import FilterOffIcon from '@mui/icons-material/FilterAltOff';
import {hacerFoco,scrollTop} from '../../../Helpers/utilidades-globales'
import { Button, Card, CardBody, Input, Option, Select, Spinner } from '@material-tailwind/react';
import Main from '../../../componentes/Main';
import { ArrowPathIcon, ChevronUpDownIcon, HomeModernIcon, IdentificationIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';

const bkSelected = 'rgb(58 154 230 / 61%)'; 
const bkNotSelected = 'rgb(58 154 230)';

const objestados = {
  iniciado:{id:0,nombre:'Iniciado'},
  procRegion:{id:1,nombre:'En proceso regional'},
  procSede:{id:2,nombre:'En proceso sede'},
 // aprobado:{id:3,nombre:'Aprobado'},
 // rechazado:{id:4, nombre:'Rechazado'},
  auto:{id:5, nombre:'Auto'},
  tesoreria:{id:6, nombre:'En tesorería'},
  contaduria:{id:7, nombre:'En contaduría'},
  aprobado:{id:8,nombre:'Aprobado'}, //NOTE - cambiamos el id para que el aprobado o rechazado sigan siendo estados de últimas etapas
  rechazado:{id:9, nombre:'Rechazado'}
}

export const Listado = ({seleccionarTramite,filtros,refresh})=>{
  const {esCelular, usuario} = useContextoGlobal();
  const [solicitanteSeleccionado,setSolicitanteSeleccionado] = React.useState(null)
  const [filtros2,setFiltros2] = useState({region: usuario.perfil=='region' ? [usuario.id_region] : [] ,tipo:[],estado: usuario.id_usuario==16 ? ["En proceso sede"] : []})
  const [rotar,setRotar] = React.useState(null)
  const [orden,setOrden] = React.useState('fecha')

  const [callbackOrden,setCallbackOrden] = React.useState(null);

 const GET_LISTADOOLD = gql`
  query {
    Listado{
      listado{
      id,
      archivado,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre,
        id
      }
      status{
        texto
      }
    }}
  }
`;

const GET_LISTADO_sin_objeto_filtros = gql`
query Listado ($id_distrito:Int,$region:Int,$codigo_web:String,$estados:[String],$tipos:[String],$fechaI:String,$fechaF:String){
    listado(id_distrito:$id_distrito,region:$region,codigo_web:$codigo_web,
    estados:$estados,tipos:$tipos,fechaI:$fechaI,fechaF:$fechaF){
      id,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      fecha,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre
      }
      status{
        texto
      },
      codigo_web,
      respuesta{
        texto
      },
      observaciones{
        texto
      }}
    }
`
const comparacion = (item1,item2,rotar,orden)=>{
  let a,b;

  if(rotar !== null && rotar%2==0){
    a=item1;
    b=item2;
  }else{
    b=item1;
    a=item2;
  }

  switch (orden.toLowerCase()) {
    case 'ministro': 
        return a['ministro'].nombre.localeCompare(b['ministro'].nombre)
    case 'tipo':
      return a['tipo'].localeCompare(b['tipo'])
    case 'estado':
        console.log('pepepepepe',a['status'])
        return a['status'][a['status'].length-1].texto.localeCompare(b['status'][b['status'].length-1].texto)
      case 'fecha':
      case 'actualizacion':

      const campoFecha = orden=='fecha' ? 'createdAt' : 'updatedAt' ;

      let fa;
      let fb;
      const origen = 'mongodb' // 'sqlserver'

      if(origen=='sqlserver'){
        const dia_a = Number(a[campoFecha].substring(0,2));
        const mes_a  = Number(a[campoFecha].substring(3,5));
        const anio_a = Number(a[campoFecha].substring(6,10));
  
        fa = new Date(anio_a,mes_a,dia_a);
  
        const dia_b = Number(b[campoFecha].substring(0,2));
        const mes_b  = Number(b[campoFecha].substring(3,5));
        const anio_b = Number(b[campoFecha].substring(6,10));
  
        fb = new Date(anio_b,mes_b,dia_b);
      }else{
        /* cuando la fecha viene de mongodb viene en este formato... 1698066836857
        es una marca de tiempo en milisegundos desde el 1 de enero de 1970 (conocido como el "epoch" en Unix).
        */
        fa = Number(a[campoFecha]);
        fb = Number(b[campoFecha]); 
      }
     

      return fa-fb
     
    }

}
  
const ordenar = (campo)=>{
  if(campo == 'Ministro' || campo == 'Tipo' || campo == 'Estado' || campo == 'Fecha' || campo == 'Actualización'){
    setOrden(campo)
  }
  if(campo==orden){
    setRotar(prevRotar => (prevRotar === 1 ? 2 : 1));
  }else{
    setRotar(2) // para que se inicialice correctamente y rote cuando se haga click en el mismo campo, si lo dejo en null debo hacer click 2 veces
  }

}



const GET_LISTADO = gql`
query Listado ($filtros:FiltrosListadoType){
    listado(filtros:$filtros){
      _id,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      fecha,
      archivado,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre,id
      }
      status{
        texto
      },
      codigo_web,
      respuesta{
        texto
      },
      observaciones{
        texto
      }}
    }
`
const GET_OTROS = gql`
query Otros ($filtros:FiltrosListadoType){
    listado(filtros:$filtros){
      _id,
      archivado,
      descripcion,
      createdAt,
      updatedAt,
      tipo,
      region,
      fecha,
      distrito{
        nombre,
        id
      },
      ministro{
        nombre,id
      }
      status{
        texto
      },
      codigo_web,
      respuesta{
        texto
      },
      observaciones{
        texto
      }}
    }
`


/* atención podría pasar a una query de graphql un objeto como parametro pero para ello
hay que crear un type input con GraphQLInputObjectType
*/
/*const {loading,error,data,refetch} = useQuery(GET_LISTADO,
                                      {variables:{id_distrito:usuario.id_distrito,
                                        region:usuario.id_region,
                                        codigo_web:'R1AS-10461',
                                        estados:null,
                                        tipos:null,
                                        fechaI:null,
                                        fechaF:null
                                      },
    pollInterval: 15000
  })*/

  const {loading,error,data,refetch} = useQuery(GET_LISTADO,
    {variables:{filtros:{...filtros,perfil: usuario.rol_contabilidad ? 'contabilidad' : usuario.perfil }
},
onCompleted:()=>scrollTop(),
pollInterval: 60000
})


   
    const {dummy,datosOk} = useContext(tramitesContext)
    const [abrir,setAbrir] = React.useState(false)
    const [abrirNM,setAbrirNM] = React.useState(false)
    const [abrirOtros,setAbrirOtros] = React.useState(false)
    const [abrirIGForm,setAbrirIGForm] = React.useState(false)
    const [solicitantes,setSolicitantes] = React.useState([])

    const options = ['Manzana', 'Banana', 'Cereza', 'Damasco', 'Uva'];
    const [value, setValue] = React.useState(null);

    
    React.useEffect(()=>{
      refetch()
    },[refresh])

    React.useEffect(()=>{
      if(data?.listado){
          const solicitantes = new Set(data.listado.map(item=>item.ministro.nombre))
          setSolicitantes(Array.from(solicitantes))
      }
    },[data])

    React.useEffect(()=>{
      if(solicitanteSeleccionado){
        setTimeout(() => {
          hacerFoco("total-tr")
        }, 500);
      }
    },[solicitanteSeleccionado])
    
    
    const filterTramite = (tramite) => {
      return (
        (filtros2.estado.some(estado => tramite.status[tramite.status.length - 1].texto === estado) || filtros2.estado.length === 0) &&
        (filtros2.tipo.some(tipo => tramite.tipo === tipo) || filtros2.tipo.length === 0) &&
        (tramite.ministro.nombre === solicitanteSeleccionado || !solicitanteSeleccionado) &&
        (filtros2.region.some(region => tramite.region === region) || filtros2.region.length === 0)
      );
    };

    const sortedAndFilteredData = useMemo(() => {
      if (data?.listado === undefined) return [];
      return [...data.listado]
        .sort((a, b) => comparacion(a, b,rotar,orden))
        .filter(item => item.archivado !== true)
        .filter(filterTramite);
    }, [data, rotar, orden, filtros2, solicitanteSeleccionado, comparacion]);
    
    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const analizarcolumna = (e)=>{
      const cell = e.target;
      const columnIndex = cell.cellIndex;

      console.log(`Se hizo clic en la columna ${columnIndex}`);
  

    }
    const classes = useStyle();

    if(loading){
       return <Main center alinear>
                  <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                      <Spinner className="h-12 w-12" />
                      <span className="cargando font-semibold text-[15px]">Cargando trámites...</span>
                  </div>
              </Main>
    }

    if (!data || !(data?.listado) || data?.listado.length==0){
      return <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
        <h3 title={motivoNoHayData(data)}>No se encontraron trámites</h3>
        {/*<Button startIcon={<SyncIcon/>} variant='outlined' color='primary' onClick={()=>refetch()}>
                Refrescar
        </Button>*/}
      </Box>
  }

  const TABLE_HEAD_TRAMITES  = ["Tipo", "Ministro", "Descripcion", "Reg.", "Dist.", "Estado", "Código web", "Respuesta", "Obs.", "Creación", "Actualización"];

    return <div className='w-full flex f-col items-center pl-2 md:pl-6 md:pr-6'>
       
       {usuario.perfil=='region' && <>
            <div className={esCelular ? 'mb-4 grid grid-cols-1 space-y-2 mt-4' : 'mb-4 grid grid-cols-4 space-x-4 mt-4'}>
                <Button className='flex' onClick={()=>setAbrirNM(true)}>
                  <IdentificationIcon width={18} className='mr-2' />
                        Solicite una credencial
                </Button>
                <Button className='flex' onClick={()=>setAbrirIGForm(true)}>
                  <HomeModernIcon width={18} className='mr-2' />
                        Solicite una iglesia en formación
                </Button>
                <Button className='flex' id="refresh-list" onClick={()=>refetch()}>
                  <ArrowPathIcon width={18} className='mr-2' />
                    Refrescar el listado
                </Button>
                <Button className='flex' title='Consultar trámites archivados y solicitudes regionales' onClick={()=>setAbrirOtros(true)}>
                  <MagnifyingGlassIcon width={18} className='mr-2'/>
                    Buscar otros trámites
                </Button>
            </div>
       </>}

       {usuario.perfil!='region' && <>
          <div className={esCelular ? 'mb-4 grid grid-cols-1 space-y-2 mt-4' : 'mb-4 grid grid-cols-2 space-x-4 mt-4'}>
                <Button id="refresh-list" className='flex'  onClick={()=>refetch()}>
                  <ArrowPathIcon width={18} className='mr-2' />
                  Refrescar el listado
                </Button>
                <Button  className='flex' title='Consultar trámites archivados y solicitudes regionales'  onClick={()=>setAbrirOtros(true)}>
                  <MagnifyingGlassIcon width={18} className='mr-2'/>
                  Buscar otros trámites
                </Button>
            </div>
       </>}
     
        {data.listado!=undefined && <div>
          <Card className={`${esCelular ? 'overflow-scroll w-[22rem] ml-2' : 'w-full'} h-full p-2 mt-2 px-0 pt-0 pb-0`}>
            <CardBody>
              <table className="w-full table-auto text-center">
                  <thead>
                    <tr>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50' 
                          onClick={()=>resetearFiltros(setFiltros2,setSolicitanteSeleccionado,filtros2)}>
                        <p className='fw-600 text-center'>Total de trámites</p>
                        ({data?.listado.filter(i=>condicionRegion(filtros2,i)).length})
                      </th>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50'
                        onClick={()=>filtrarEstado('Iniciado',setFiltros2,filtros2)}>
                            <CellCabeceraResumen titulo="Iniciado" 
                                                  usuario={usuario} 
                                                  estado="Iniciado" 
                                                  data={data}
                                                  filtros={filtros2}/>
                      </th>
                      {!usuario.rol_contabilidad && <>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50'
                          onClick={()=>filtrarEstado('En proceso regional',setFiltros2,filtros2)}>
                          <CellCabeceraResumen titulo="En región" 
                                                  usuario={usuario} 
                                                  estado="En proceso regional" 
                                                  data={data}
                                                  filtros={filtros2}
                                                  />
                      </th>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50' 
                          onClick={()=>filtrarEstado('En proceso sede',setFiltros2,filtros2)}>
                          <CellCabeceraResumen titulo="En Sede" 
                                                  usuario={usuario} 
                                                  estado="En proceso sede" 
                                                  data={data}
                                                  filtros={filtros2}
                                                  />
                      </th>
                      </>}
                      { usuario.sede_admin && <>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50'
                          onClick={()=>filtrarEstado('En tesorería',setFiltros2,filtros2)}>
                          <CellCabeceraResumen titulo="En tesorería" 
                                                  usuario={usuario} 
                                                  estado="En tesorería" 
                                                  data={data}
                                                  filtros={filtros2}
                                                  />
                      </th>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50'
                        onClick={()=>filtrarEstado('En contaduría',setFiltros2,filtros2)}>
                          <CellCabeceraResumen titulo="En contaduría" 
                                                  usuario={usuario} 
                                                  estado="En contaduría" 
                                                  data={data}
                                                  filtros={filtros2}
                                                  />
                      </th>
                      </>}
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50'
                        onClick_NO_FILTRAR_APROBADOS={()=>filtrarEstado('Aprobado',setFiltros2,filtros2)}>
                          <CellCabeceraResumen titulo="Aprobados" 
                                                  usuario={usuario} 
                                                  estado="Aprobado" 
                                                  data={data}
                                                  filtros={filtros2}
                                                  />
                      </th>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50'
                        onClick={()=>filtrarEstado('Rechazado',setFiltros2,filtros2)}>
                            <CellCabeceraResumen titulo="Rechazados" 
                                                  usuario={usuario} 
                                                  estado="Rechazado" 
                                                  data={data}
                                                  filtros={filtros2}
                                                  />
                      </th>
                    </tr>
                  </thead>
              </table>
            </CardBody>
          </Card>
          
          
            {usuario.perfil!='region' && <div className='p-2 md:p-4'>
                  <p className='text-center'><b>Trámites por región</b> (Filtre haciendo clic)</p>
                  <div className='grid grid-cols-3 md:grid-cols-9 gap-2 justify-center w-full mb-4 mt-2 text-white' id="tr-regs">
                          <div 
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`}
                            style={{background: filtros2.region.some(i=>i==1) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(1,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 1</p> <p>({data.listado.filter(i=>i.region==1 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',1,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                          <div 
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`} 
                            style={{background: filtros2.region.some(i=>i==2) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(2,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 2</p> <p>({data.listado.filter(i=>i.region==2 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',2,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                          <div 
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`} 
                            style={{background: filtros2.region.some(i=>i==3) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(3,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 3</p> <p>({data.listado.filter(i=>i.region==3 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',3,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                          <div
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`} 
                            style={{background: filtros2.region.some(i=>i==4) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(4,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 4</p> <p>({data.listado.filter(i=>i.region==4 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',4,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                          <div 
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`} 
                            style={{background: filtros2.region.some(i=>i==5) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(5,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 5</p> <p>({data.listado.filter(i=>i.region==5 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',5,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                          <div
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`} 
                            style={{background: filtros2.region.some(i=>i==6) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(6,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 6</p> <p>({data.listado.filter(i=>i.region==6 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',6,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                          <div 
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`} 
                            style={{background: filtros2.region.some(i=>i==7) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(7,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 7</p> <p>({data.listado.filter(i=>i.region==7 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',7,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                          <div 
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`} 
                            style={{background: filtros2.region.some(i=>i==8) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(8,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 8</p> <p>({data.listado.filter(i=>i.region==8 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',9,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                          <div 
                            className={`flex flex-col items-center rounded-md cursor-pointer !border-none`} 
                            style={{background: filtros2.region.some(i=>i==9) ? bkNotSelected : bkSelected}} 
                            onClick={()=>filtrarRegion(9,setFiltros2,filtros2,setSolicitanteSeleccionado)}>
                              <p className='font-bold'>Region 9</p> <p>({data.listado.filter(i=>i.region==9 && condicion(filtros2,i,solicitanteSeleccionado)).length})</p>
                              {existeFiltro('region',9,filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          </div>
                  </div>

                  <div className='flex flex-col mt-6 mb-2 select-ui-data'>
                    <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="mb-1 font-medium "
                                >
                                    Busque y seleccione aquí a un ministro solicitante
                    </Typography>
                    <Select  
                      type="text" 
                      value={solicitanteSeleccionado}
                      icon={<MagnifyingGlassIcon className="h-5 w-5" />} 
                      className="w-full md:w-96" 
                      onChange={(event) => {
                        setSolicitanteSeleccionado(event);
                      }}
                      color='black'
                      options={solicitantes}
                      renderInput={(params) => <TextField {...params} variant="filled" />}
                    >
                      {solicitantes.map((tipo, index) => (
                                        <Option key={index} value={tipo}>
                                            {tipo}
                                        </Option>
                        ))}
                    </Select>
                  </div>
            </div>
          }
          {!usuario.rol_contabilidad &&  <Card className={`${esCelular ? 'overflow-scroll w-[22rem] ml-2' : 'w-full'} h-full p-2 mt-2 px-0 pt-0 pb-0`}>
            <table className="w-full table-auto text-center">
                <thead>
                  <tr>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50' onClick={()=>filtrarTipo('anexo',setFiltros2,filtros2)}>
                          <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Nuevo anexo ({data?.listado.filter(i=>i.tipo=='anexo' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','anexo',filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','anexo',filtros2) && <FilterOnIcon className='mt-0.5 ml-1' sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                              <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="anexo" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="anexo" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="anexo" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                filtros={filtros2}
                                                tipo="anexo" 
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="anexo" 
                                                filtros={filtros2}
                                                data={data}/>                                                                                                                                                                         
                          </tbody>
                        </table>
                      </th>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50' onClick={()=>filtrarTipo('ascenso',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Ascenso ({data?.listado.filter(i=>i.tipo=='ascenso' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','ascenso',filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','ascenso',filtros2) && <FilterOnIcon className='mt-0.5 ml-1' sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="ascenso" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="ascenso" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="ascenso" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                filtros={filtros2}
                                                tipo="ascenso" 
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                filtros={filtros2}
                                                tipo="ascenso" 
                                                data={data}/>
                            </tbody>
                        </table>
                      </th>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50' onClick={()=>filtrarTipo('igl_formacion',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Iglesia en formación ({data?.listado.filter(i=>i.tipo=='igl_formacion' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','igl_formacion',filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','igl_formacion',filtros2) && <FilterOnIcon className='mt-0.5 ml-1' sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="igl_formacion" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="igl_formacion" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="igl_formacion" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                filtros={filtros2}
                                                tipo="igl_formacion" 
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="igl_formacion" 
                                                filtros={filtros2}
                                                data={data}/>
                          </tbody>
                        </table>
                      </th>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50' onClick={()=>filtrarTipo('nuevo_ministro',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Primera credencial ({data?.listado.filter(i=>i.tipo=='nuevo_ministro' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','nuevo_ministro',filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','nuevo_ministro',filtros2) && <FilterOnIcon className='mt-0.5 ml-1' sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="nuevo_ministro" 
                                                filtros={filtros2}
                                                data={data}/>
                          </tbody>
                        </table>
                      </th>
                      <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50' onClick={()=>filtrarTipo('autonomia',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Autonomía ({data?.listado.filter(i=>i.tipo=='autonomia' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','autonomia',filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','autonomia',filtros2) && <FilterOnIcon className='mt-0.5 ml-1' sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En región" 
                                                usuario={usuario} 
                                                estado="En proceso regional"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En sede" 
                                                usuario={usuario} 
                                                estado="En proceso sede"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="autonomia" 
                                                filtros={filtros2}
                                                data={data}/>
                          </tbody>
                        </table>
                      </th>
                        
                      { usuario.sede_admin && <th className='cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50' onClick={()=>filtrarTipo('afiliacion',setFiltros2,filtros2)}>
                        <Box title='Filtre por este criterio' sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>Afiliación ({data?.listado.filter(i=>i.tipo=='afiliacion' && condicionRegion(filtros2,i)).length})</p>
                          {existeFiltro('tipo','afiliacion',filtros2) && <FilterOffIcon className='blink mt-0.5 ml-1' sx={{color:'red',fontSize:15}}/>}
                          {!existeFiltro('tipo','afiliacion',filtros2) && <FilterOnIcon className='mt-0.5 ml-1' sx={{color:'#2a87d0',fontSize:15}}/>}
                        </Box>
                        <table className='alinear'>
                          <tbody>                            
                          <CellCabeceraResumenDetalle titulo="Iniciado" 
                                                usuario={usuario} 
                                                estado="Iniciado"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/> 

                              <CellCabeceraResumenDetalle titulo="En tesorería" 
                                                usuario={usuario} 
                                                estado="En tesorería"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="En contaduría" 
                                                usuario={usuario} 
                                                estado="En contaduría"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/> 
                              <CellCabeceraResumenDetalle titulo="Aprobados" 
                                                usuario={usuario} 
                                                estado="Aprobado"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/>
                              <CellCabeceraResumenDetalle titulo="Rechazados" 
                                                usuario={usuario} 
                                                estado="Rechazado"
                                                tipo="afiliacion" 
                                                filtros={filtros2}
                                                data={data}/>
                          </tbody>
                      </table>
                    </th>}
                  </tr>
                </thead>
            </table>
          </Card>
          }
        </div>}


        <div id="total-tr" className='grid grid-cols-1 md:grid-cols-2 w-full justify-between mb-2 mt-4'>
          <div>
            <h3 className='font-bold mb-1'>Cantidad de trámites en la lista: {data.listado.filter(item=>item.archivado!=true).filter(tramite=>{return (filtros2.estado.some(estado=>tramite.status[tramite.status.length-1].texto==estado) || filtros2.estado.length==0)
                    && (filtros2.tipo.some(tipo=>tramite.tipo==tipo) || filtros2.tipo.length==0)
                    && (tramite.ministro.nombre==solicitanteSeleccionado || !solicitanteSeleccionado)
                    && (filtros2.region.some(region=>tramite.region==region) || filtros2.region.length==0)
                    }).length} {hayFiltros(filtros2,solicitanteSeleccionado,usuario.id_region) ? <span className='color-red fw-400 text-smaller'>(Hay filtros activos)</span>  : ''}
            </h3>
            {solicitanteSeleccionado && <h4 className='font-bold'>Solicitante: {solicitanteSeleccionado}</h4>}
            <p className='color-red text-small'>* Los trámites aprobados no se muestran en el listado</p>
          </div>
          
          {hayFiltros(filtros2,solicitanteSeleccionado,usuario.id_region) && <div className='flex md:justify-end'>
            <Button variant='text' className='text-red-400 hover:bg-red-100' onClick={()=>resetearFiltros(setFiltros2,setSolicitanteSeleccionado,filtros2)}>Eliminar filtros</Button>
            </div>}
        </div>

        {data?.listado.length> 0 && <Card className={`${esCelular? 'overflow-scroll w-[22rem]' : 'w-full'} h-full p-2 mt-2 px-0 pt-0 pb-0`}>
          <CardBody>
            <table className="w-full table-auto text-left">
                  <thead>
                      <tr>
                          {TABLE_HEAD_TRAMITES.map((head, index) => (
                              <th
                              key={head}
                              className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                            
                              >
                              <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                              >
                                  {head}{" "}
                                  <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" onClick={()=>ordenar(head)}/>
                              </Typography>
                              </th>
                          ))}
                      </tr>
                  </thead>
                  <tbody>
                      {sortedAndFilteredData.map((item, index) => {
                          const isLast = index === data.listado.length - 1;
                          const classes = isLast
                          ? "p-4"
                          : "p-4 border-b border-blue-gray-50";
          
                          return (
                              <>     
                              <tr key={item.id_iglesia} onClick={()=>seleccionarTramite(item)}>
                                  <td className={`${classes} cursor-pointer bg-blue-50 relative`}>
                                      <Typography
                                          variant="small"
                                          color="blue-gray"
                                          className="font-normal"
                                      >
                                          {mapearTipo(item.tipo)}
                                      </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                    {item.ministro.nombre}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {item.descripcion}
                                    </Typography>
                                  </td>
                                  <td className={`${classes}`}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {item.region}
                                    </Typography>
                                  </td>
                                  <td className={`${classes}`}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {item.distrito.id}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                      {mostrarEstado(item.status[item.status.length-1].texto)}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                      <span style={{textWrap:'nowrap'}}>{item.codigo_web}</span>
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {respuestaFormat(item)}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {observacionesFormat(item)}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                        {transformarFecha(Number(item.fecha))}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                    >
                                      {transformarFecha(Number(item.updatedAt))}
                                    </Typography>
                                  </td>
                              </tr>
                          </>
                          );
                      },
                      )}
                  </tbody>
              </table>
            </CardBody>
          </Card>
          
       
          }

        <Dialogos open={abrir} 
                    titulo='Un diálogo' 
                    closeHeader={true}
                    mensaje={!datosOk()[0] ? datosOk()[1] : null }
                    procesarCancelar = {()=>setAbrir(false)}
                   >
                   <h1>Cuerpo del diálogo</h1> 
        </Dialogos>
        <Dialogos open={abrirNM} 
                    titulo={`Solicitud de Primera Credencial (Región ${usuario.id_region})`} 
                    fullscreen={true}
                    closeHeader={true}
                    mensaje={!datosOk()[0] ? datosOk()[1] : null }
                    procesarCancelar = {()=>setAbrirNM(false)}
                   >
                   <Patrocinado finalizar={()=>setAbrirNM(false)}/> 
        </Dialogos>
        <Dialogos open={abrirIGForm} 
                    titulo={`Solicitud de Iglesia en Formación (Región ${usuario.id_region})`} 
                    fullscreen={true}
                    closeHeader={true}
                    mensaje={!datosOk()[0] ? datosOk()[1] : null }
                    procesarCancelar = {()=>setAbrirIGForm(false)}
                   >
                   <Anexo finalizar={()=>setAbrirIGForm(false)}/> 
        </Dialogos>
        <Dialogos open={abrirOtros} 
                    titulo={`Archivados y otros trámites`} 
                    fullscreen={false}
                    closeHeader={true}
                    procesarCancelar = {()=>setAbrirOtros(false)}
                   >
                    <OtrosTramites funcion={GET_OTROS} filtros={filtros} seleccionarTramite={seleccionarTramite}/>
        </Dialogos>
  </div>
}

 
const mapearTipo = (tipo)=>{
  switch(tipo){
    case 'anexo' : return 'Anexo'
    case 'autonomia' : return 'Autonomía'
    case 'afiliacion' : return 'Afiliación'
    case 'nuevoT1' : return 'Nuevo T1'
    case 'nuevo_patrocinado' : return 'Ministro presentado'
    case 'nuevo_ministro' : return 'Primera credencial'
    case 'ascenso' : return 'Ascenso'
    case 'igl_formacion' : return 'Igl. en formación'
    default : return 'Desconocido'
  }
}

const transformarFecha = (fecha)=>{
    const date = moment(fecha)
    var formattedDate = date.format("DD/MM/YYYY");

    return formattedDate
} 

const mostrarEstado = (status_actual)=>{

  switch(status_actual){
    case objestados.iniciado.nombre : return <div className='text-center'><HowToVoteIcon sx={{color:'#bd9651'}}/> <span className='text-xsmall fw-700 block'>Iniciado</span></div>
    case objestados.procRegion.nombre : return <div className='text-center'><FactCheckIcon sx={{color:'#6b97dd'}}/><span className='text-xsmall fw-700 block'>En región</span></div>
    case objestados.procSede.nombre : return <div className='text-center'><TaskIcon sx={{color:'#45c7aa'}}/><span className='text-xsmall fw-700 block'>En sede</span></div>
    case objestados.aprobado.nombre : return <div className='text-center'><ThumbUpOffAltIcon sx={{color:'#4cd34c'}}/><span className='text-xsmall fw-700 block'>Aprobado</span></div>
    case objestados.rechazado.nombre : return <div className='text-center'><ThumbDownOffAltIcon sx={{color:'#ff5656'}}/><span className='text-xsmall fw-700 block'>Rechazado</span></div>
    default : return <div className='text-center'>{status_actual}</div>
  }

}

const CellCabeceraResumen = ({usuario,titulo,estado,data,filtros})=>{
  if (!filtros) return null

  let listado = [];
  if(filtros.region.length>0){
    listado = data.listado.filter(i=>filtros.region.some(reg=>reg==i.region))
  }else{
    listado = data.listado;
  }

  const cant_tramites = listado.filter(i=>i.status[i.status.length-1].texto==estado).length;

  if(estado=='Aprobado'){// si es aprobado no filtramos
    return <>
        <Box sx={{display:'flex',justifyContent:'center'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
        </Box>
        ({cant_tramites})
      </>
  }

  if(cant_tramites==0){ 
    return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon title="Elimine el filtro por este criterio" className='blink ml-1 mt-0.5' sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon title="Filtre por este criterio" className='ml-1 mt-0.5' sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
        ({cant_tramites})
      </>
  }else{
    if((usuario.perfil=='region' && (estado=='Iniciado' || estado=='En proceso regional')) || (usuario.id_usuario==16 && estado=='En proceso sede')){
      return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon title="Elimine el filtro por este criterio" className='blink ml-1 mt-0.5' sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon title="Filtre por este criterio" className='ml-1 mt-0.5' sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
         <Box sx={{display:'flex',justifyContent:'center'}}>
            <p style={{color:'red',fontSize:'larger'}}>({cant_tramites})</p>
            <WarningIcon className='blink color-red'/>
          </Box>
       </>
     }else{
       return <>
        <Box title="Filtre por este criterio" sx={{display:'flex',justifyContent:'center',cursor:'pointer'}}>
                          <p className='fw-600 text-center'>{titulo}</p>
                          {existeFiltro('estado',estado,filtros) && <FilterOffIcon className='blink ml-1 mt-0.5' title="Elimine el filtro por este criterio" sx={{color:'red',fontSize: 15}}/>}
                          {!existeFiltro('estado',estado,filtros) && <FilterOnIcon className='ml-1 mt-0.5' title="Filtre por este criterio" sx={{color:'#2a87d0',fontSize: 15}}/>}
        </Box>
         <p>({cant_tramites})</p>
       </>
     }
  }
}

const CellCabeceraResumenDetalle = ({usuario,titulo,estado,data,tipo,filtros})=>{

  if (!filtros) return null

  let listado = [];
  if(filtros.region.length>0){
    listado = data.listado.filter(i=>filtros.region.some(reg=>reg==i.region))
  }else{
    listado = data.listado;
  }

  const cant_tramites = listado.filter(i=>i.tipo==tipo && i.status[i.status.length-1].texto==estado).length
  if(cant_tramites==0){
    return  <tr>
        <td>{titulo}</td>
        <td>{cant_tramites}</td>
    </tr>
  }else{
    if((usuario.perfil=='region' && (estado=='Iniciado' || estado=='En proceso regional')) || (usuario.id_usuario==16 && estado=='En proceso sede')){
      return  <tr>
      <td>{titulo}</td>
      <td style={{background:'tomato',color:'white',padding:'3px',fontSize:'larger',textAlign:'center'}}>{cant_tramites}</td>
    </tr>
     }else{
      return  <tr>
        <td>{titulo}</td>
        <td>{cant_tramites}</td>
      </tr>
     }
  }
}

const resetearFiltros = (setFiltros,setSolicitanteSeleccionado,filtros2)=>{
  setFiltros({...filtros2,tipo:[],estado:[],region:[]})
  setSolicitanteSeleccionado(null)
}

const filtrarEstado = (estado,setFiltros,filtros)=>{
  if(!estado){
      setFiltros({...filtros,estado:[]})
  }else{
      
    let estadoaux = [];

    const existeestado = filtros.estado.some(i=>i==estado)

    if(existeestado){
      estadoaux = [...filtros.estado.filter(i=>i!=estado)]
    }else{
      estadoaux = [...filtros.estado,estado]
    }
    /*NOTE inicio - 02/12/2024 a partir de ahora no reseteamos region*/
//    setFiltros({...filtros,estado:[...estadoaux],region:[]})
    setFiltros({...filtros,estado:[...estadoaux]})
    /*NOTE -  fin*/
  }
}

const filtrarRegion = (region,setFiltros,filtros,setSolicitanteSeleccionado)=>{

  if(!region){
      setFiltros({...filtros,region:[0]})
  }else{
      
    let regionaux = [];

    const existeregion = filtros.region.some(i=>i==region)

    if(existeregion){
      regionaux = [...filtros.region.filter(i=>i!=region)]
    }else{
      /*NOTE inicio - 02/12/2024 a partir de ahora vamos a permitir filtrar de a 1 región y no N regiones*/
//      regionaux = [...filtros.region,region]
      regionaux = [region]
      /*NOTE -  fin*/
    }

    /*NOTE inicio - 02/12/2024 a partir de ahora no recalculamos el resto de los parámetros*/
//    setFiltros({...filtros,region:[...regionaux],tipo:[],estado:[]})
    setFiltros({...filtros,region:[...regionaux]})
    setSolicitanteSeleccionado(null)
     /*NOTE -  fin*/

  }
}

const filtrarTipo= (tipo,setFiltros,filtros)=>{
  if(!tipo){
      setFiltros({...filtros,tipo:[]})
  }else{
      
    let tipoaux = [];

    const existetipo = filtros.tipo.some(i=>i==tipo)

    if(existetipo){
        tipoaux = [...filtros.tipo.filter(i=>i!=tipo)]
    }else{
      tipoaux = [...filtros.tipo,tipo]
    }
    /*NOTE inicio - 02/12/2024 a partir de ahora no reseteamos region*/
//    setFiltros({...filtros,tipo:[...tipoaux],region:[]})
    setFiltros({...filtros,tipo:[...tipoaux]})
    /*NOTE - fin */
  }
}

const existeFiltro = (tipo,parametro,filtros) =>{
  if(tipo=='tipo'){
    return filtros.tipo.some(i=>i==parametro)
  }else{
    if(tipo=='estado'){
      return filtros.estado.some(i=>i==parametro)
    }else{
      return filtros.region.some(i=>i==parametro)
    }
  }
}

const hayFiltros = (filtros,solicitanteSeleccionado,usuario_es_regional)=>{
  if(filtros.estado.length>0 || filtros.tipo.length>0 || (filtros.region.length>0 && !usuario_es_regional) || solicitanteSeleccionado){
      return true
  }else{
    return false
  }
}

const OtrosTramites = ({funcion,filtros,seleccionarTramite})=>{
  
  const {data:otrostramites,refetch:buscarotrostramites} = useQuery(funcion,
    {variables:{filtros:{...filtros,otros:true}
  },
  pollInterval: 60000
  })

  if(!otrostramites){
      return <Box sx={{display:'flex',justifyContent:'center'}}>
            No hay datos
      </Box>  
  }

  return <Box>
      <h3 className='mt-2'>Archivados</h3>
      {otrostramites.listado.filter(item=>item.archivado==true).map(item=><Box sx={{marginTop:'10px',borderBottom:'solid 1px gray',cursor:'pointer',position:'relative'}} onClick={()=>seleccionarTramite(item)}>
        <b>{mapearTipo(item.tipo)} </b>/ {item.ministro.nombre} / {item.codigo_web} / {item.descripcion} <span className='tipo-tram' style={{position:'absolute',right:'0',padding:'0'}}>{item.status[item.status.length-1].texto}</span>
      </Box>)}
      <h3 className='mt-4'>Presentación de ministros para 1ra credencial</h3>
      <p className='tipo-tram'>Iniciado por: Región</p>
      {otrostramites.listado.filter(item=>item.archivado!=true && item.tipo=="nuevo_patrocinado" && item.ministro.id==9534).map(item=><Box sx={{marginTop:'10px',borderBottom:'solid 1px gray',cursor:'pointer'}} onClick={()=>seleccionarTramite(item)}>
          <b>{mapearTipo(item.tipo)} </b>/ {item.ministro.nombre} / {item.codigo_web} / {item.descripcion}
      </Box>)}
      <h3 className='mt-4'>Presentación de ministros para 1ra credencial</h3>
      <p className='tipo-tram'>Iniciado por: Ministro principal</p>
      {otrostramites.listado.filter(item=>item.archivado!=true && item.tipo=="nuevo_patrocinado" && item.ministro.id!=9534).map(item=><Box sx={{marginTop:'10px',borderBottom:'solid 1px gray',cursor:'pointer'}} onClick={()=>seleccionarTramite(item)}>
          <b>{mapearTipo(item.tipo)} </b>/ {item.ministro.nombre} / {item.codigo_web} / {item.descripcion}
      </Box>)}
      <h3 className='mt-4'>Otros</h3>
      {otrostramites.listado.filter(item=>item.archivado!=true && item.tipo!="nuevo_patrocinado").map(item=><Box sx={{marginTop:'10px',borderBottom:'solid 1px gray',cursor:'pointer'}} onClick={()=>seleccionarTramite(item)}>
          <b>{mapearTipo(item.tipo)} </b>/ {item.ministro.nombre} / {item.codigo_web} / {item.descripcion}
      </Box>)}      
  </Box>
}

const observacionesFormat = (item)=>{

  if(item?.observaciones?.length == 0){
    return ''
  }

  const texto = item.observaciones[item?.observaciones?.length-1].texto;

  return texto.length>50 ? `${texto.substring(0, 50)}...` : texto

}

const respuestaFormat = (item)=>{

  if(item?.respuesta?.length == 0){
    return ''
  }

  const texto = item.respuesta[item?.respuesta?.length-1].texto;

  return texto.length>50 ? `${texto.substring(0, 50)}...` : texto

}

const condicion = (filtros2,tramite,solicitanteSeleccionado)=>{

  if (!filtros2 || !tramite) return true

  return (filtros2.tipo.some(tipo=>tramite.tipo==tipo) || filtros2.tipo.length==0)
  && (filtros2.estado.some(estado=>tramite.status[tramite.status.length-1].texto==estado) || filtros2.estado.length==0)
  && (tramite.ministro.nombre==solicitanteSeleccionado || !solicitanteSeleccionado)
  && (filtros2.region.some(region=>tramite.region==region) || filtros2.region.length==0)
}


const condicionRegion = (filtros,item)=>{
  if (filtros?.region==undefined || !item) return true

  return (filtros.region.length==0 || filtros.region.some(reg=>reg==item.region))
}

const motivoNoHayData = (data)=>{
  if(!data || !(data?.listado)){
      return 'No hubo retorno de datos posiblemente por un problema de conexión a la base mongodb'
  }else{
    return 'El listado de trámites está vacío'
  }
}