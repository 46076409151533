import React, { useState, useEffect, useRef } from 'react';
import { 
    Card, 
    Spinner, 
    Typography,
    Button
} from '@material-tailwind/react';
import { Main, Axios } from '../../../Helpers/importacionesComunes'
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { SucursalBancaria } from '../componentes/Sucursal';
import Swal from 'sweetalert2';
import { TablaDepositos } from '../componentes/TablaDepositos';

export default function Interdepositos({ match, history }) {
    const [registros, setRegistros] = useState([])
    const [registrosAmostrar, setRegistrosAmostrar] = useState([])
    const [cargandoRegistros, setCargandoRegistros] = useState(true);
    const { esCelular, usuario } = useContextoGlobal();
    const [hayFiltrosActivos, setHayFiltrosActivos] = useState(false)
    const [huboError, setHuboError] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [processedData, setProcessedData] = useState([]);
    const [open, setOpen] = React.useState(false);
    const filtrosRef = useRef()
    const [dataValues, setDataValues] = useState({
        id_cuenta_bancaria: 1,
        id_banco: 1
    });

    const [regiones, setRegiones] = useState([]);
    const [cuentas, setCuentas] = useState([]);

    useEffect(() => {
        const buscarRegiones = async () => {
            try {
                setCargandoRegistros(true)
                const resultado = await Axios.get('/api/tablasgenerales/regiones/*')
                setRegiones(resultado.data.filter(item => item.id_region > 0))
                setCargandoRegistros(false)
            } catch (err) {
                alert(err)
                setCargandoRegistros(false)
            }
        }
        if (usuario.sede_admin) {
            buscarRegiones()
        }
        buscarCuentasBancarias();
    }, [])

    useEffect(() => {
        buscarDatos({ conLoading: true })
    }, [usuario, dataValues]) // agregué usuario porque necesito que se dispare el evento cuando no sea vacío usuario, al principio es vacío por unos milisegundos...

    useEffect(() => {

        if (registrosAmostrar.length != registros.length) {
            setHayFiltrosActivos(true)
        } else {
            setHayFiltrosActivos(false)
        }

    }, [registrosAmostrar])

    const buscarCuentasBancarias = async () => {
        try {
            const resultado = await Axios.get('/api/tablasgenerales/banco/cuentas')
            console.log(resultado);
            setCuentas(resultado.data)
        } catch (err) {
            alert(err)
        }
    }

    const handleChangeSelectRegion = (e) => {
        handleDataChanges(e, 'id_region');
    }

    const handleDataChanges = (value, type) => {
        setDataValues({ ...dataValues, [type]: value });
    }

    const handleOpen = () => { 
        setOpen(!open);
    };

    const buscarDatos = async (params = {}) => {
        const { conLoading } = params // con loading se usa para la búsqueda inicial de registros
        // Cuando se modificó una iglesia queremos mantener el modal abierto y no mostramos el loading

        setHuboError(false)
        setRegistros([])
        conLoading && setCargandoRegistros(true)
        try {
            const params ={
                ...dataValues,
                id_region: usuario.sede_admin ? dataValues.id_region : usuario.id_region
            }
            const { data } = await Axios.post(`/api/tablasgenerales/interdepositos/lista`, params)
            setRegistros(data)
            setCargandoRegistros(false)
        } catch (err) {
            console.log(err)
            setHuboError(true)
            setCargandoRegistros(false)
        }
    }

    const filtrarLista = (filterCallback) => {

        let vectorRegistrosOriginalFiltrado;

        vectorRegistrosOriginalFiltrado = registros.filter(filterCallback)

        filtrosRef.current = filterCallback

        setRegistrosAmostrar(vectorRegistrosOriginalFiltrado)

    }
    const sendDepositos = async () => {
        if (processedData.length === 0) { 
            Swal.fire({
                icon: 'error',
                title: `<p>Por favor, cargue algún deposito antes de continuar</p>`,
                showCancelButton: true,
            });
            setOpen(true);
            return;
        }

        try {
            setIsProcessing(true);
            const data = processedData.map(item => {
                return {
                    ...item,
                    id_banco: dataValues.id_banco,
                    id_cuenta_bancaria: dataValues.id_cuenta_bancaria,
                    suc_origen: item.suc_origen || 0,
                    fecha: new Date(item.fecha)
                }
            });

            await Axios.post(`/api/tablasgenerales/interdepositos/crear`, data);
            Swal.fire({
                icon: 'success',
                title: `<p>Los depósitos se almacenaron con éxito</p>`,
            });
            handleOpen();
            setIsProcessing(false);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: `<p>Error al guarar los depositos</p>`
            });
            console.log(error);
            setOpen(true);
            setIsProcessing(false);
        }
    };

    if (huboError) {
        return <Main center>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <span>Se produjo un error al cargar los datos para esta vista</span>
            </div>
        </Main>
    }

    if (cargandoRegistros || !usuario) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
            </div>
        </Main>
    };

    return (
        <Card className='w-full lg:!m-6 xl:!m-6'>
            <div className="bg-blue text-whitexxx p-4 rounded ml-auto mr-auto mb-4 w-full">
                <Typography variant="h5" className="w-full mb-2 flex justify-center">
                    Cargar Depósitos
                </Typography>

                <SucursalBancaria
                    filtrarLista={filtrarLista}
                    hayFiltrosActivos={hayFiltrosActivos}
                    usuario={usuario}
                    regiones={regiones}
                    cuentas={cuentas}
                    handleDataChanges={handleDataChanges}
                    handleChangeSelectRegion={handleChangeSelectRegion}
                    dataValues={dataValues}
                    processedData={processedData}
                    setProcessedData={setProcessedData}
                />

                <div className='flex w-full justify-center items-center mt-10'>
                    <Button onClick={sendDepositos} variant="gradient" className="flex ml-2 items-center gap-3" disabled={isProcessing}>
                        Crear Deposito
                    </Button>   
                </div>

                {processedData.length > 0 && <TablaDepositos registrosAmostrar={processedData} />}
            </div>
        </Card>)
}