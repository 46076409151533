import React from 'react'
import Axios from 'axios'
import {useContextoGlobal} from '../../../Context/contextoGlobal'
import Swal from 'sweetalert2';
import moment from 'moment';
import {esMayor,fechaActual,esFechaMomentNula} from '../componentes/modulos/index.js'

const regex_solo_numeros = /^[0-9]+([.])?([0-9][0-9]?)?$/;

export const useTramites = ()=>{

    const {usuario,snackbar} = useContextoGlobal();

    const [dummy,setDummy] = React.useState([1,2,3])
    const [acta_regional,setActa_regional] = React.useState(null) 
    const [acta_cdn,setActa_cdn] = React.useState(null)
    const [procesando,setProcesando] = React.useState(false)
    const [fechaAR,setFechaAR]=React.useState(null)
    const [fechaACDN,setFechaACDN]=React.useState(null)
    const [touched,setTouched] = React.useState({})
    const [refrescando,setRefrescando] = React.useState(false)
    const [expanded, setExpanded] = React.useState(null);

    const datosOk = ()=>{
        return [true,null]
    }

    const handleChangeFechaAR =(fecha)=>{
        setFechaAR(fecha)
    }

    const handleChangeFechaACDN =(fecha)=>{
        setFechaACDN(fecha)
    }

    const handleChange = (panel) => (e, newValue) => {
        setExpanded(newValue ? panel : null);
    };

    const validarDatosLuegoActualizar = (funcionActualizacion)=>{

        const fecha_actual = fechaActual()
        const fechaInicial = moment().subtract(100, "years");

        if(fechaAR!=undefined && !esFechaMomentNula(fechaAR)){
            
            if(fechaAR?._isValid==false && fechaAR?._i=='Invalid Date'){
                //alert('La fecha de acta regional es inválida')
                snackbar.showMessage('La fecha de acta regional es inválida','error')
                return false
            }

            if(fechaAR?._isValid==false && fechaAR?._i=='Invalid Date'){
                //alert('La fecha de acta regional es inválida')
                snackbar.showMessage('La fecha de acta regional es inválida','error')
                return false
            }

            if(esMayor(fechaAR,fecha_actual)){
                snackbar.showMessage('La fecha de acta regional debe ser igual o anterior al día de la fecha','error')
                return false
            }

            if(!esMayor(fechaAR,fechaInicial)){
               snackbar.showMessage(`La fecha de acta regional no puede ser anterior al año ni superar la fecha actual`,'error')
               return false
            }

        }

        if(fechaACDN!=undefined && !esFechaMomentNula(fechaACDN)){

            if(fechaACDN?._isValid==false && fechaACDN?._i=='Invalid Date'){
                snackbar.showMessage('La fecha de acta CDN es inválida','error')
                return false
            }
            if(esMayor(fechaACDN,fecha_actual)){
                snackbar.showMessage('La fecha de acta CDN debe ser igual o anterior al día de la fecha','error')
                return false
            }

            const fechaInicial = fecha_actual.subtract(365, "days");

            if(!esMayor(fechaACDN,fechaInicial)){
               snackbar.showMessage(`La fecha de acta CDN no puede ser anterior al año ni superar la fecha actual`,'error')
               return false
            }
        }

        if(fechaACDN!=undefined && fechaAR!=undefined){
            if(!esMayor(fechaACDN,fechaAR)){
                snackbar.showMessage('La fecha de acta regional debe ser anterior a la fecha de acta CDN','error')
                return false
            }
        }

        if(funcionActualizacion){ // hay tramites que usan este método solo para validar los campos standard sin actualizar. La actualización se hace en el propio hook del trámite porque es custom
            setProcesando(true)
            setTimeout(() => {
                funcionActualizacion()
            }, 1000);
        }else{
            return true
        }
        
    }

    const inicioActualizacionTramite = (funcionActualizacion)=>{

        Swal.fire({
            text:"Por favor confirme la modificación",
            showCancelButton:true,
            confirmButtonText:'Modificar',
            cancelButtonText:'Cancelar'
        }).then(
            resultado=>{
                if (resultado.value){
                    validarDatosLuegoActualizar(funcionActualizacion);
                }else{
                    console.log("Se canceló la operación de la solicitud")
                }
            }
        )

    }

    const esPerfilContaduria = (usuario)=>{
        return usuario?.rol_contabilidad && usuario?.rol_contabilidad<51
    }

    const traerUrl = async (key)=>{
        try{
            const {data} = await Axios.get(`${process.env.REACT_APP_API_UAD_BASE}/api/tablasgenerales/pdf/${key}`)
            window.open(data.url)
        }catch(err){
            alert(err)
     }
    }

    return {dummy,
            datosOk,
            acta_regional,
            setActa_regional,
            acta_cdn,
            setActa_cdn,
            procesando,
            setProcesando,
            fechaAR,
            setFechaAR,
            fechaACDN,
            setFechaACDN,
            touched,
            setTouched,
            refrescando,
            setRefrescando,
            expanded,
            setExpanded,
            handleChangeFechaAR,
            handleChangeFechaACDN,
            handleChange,
            inicioActualizacionTramite,
            esPerfilContaduria,
            traerUrl,
            validarDatosLuegoActualizar
            }
}





