import React, {useContext,useEffect,useState} from 'react'
import {LinearProgress, CircularProgress, Box,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel, Typography, Divider} from "@material-ui/core";
import Axios from 'axios'
import Autocomplete from '@mui/material/Autocomplete';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CardGiftcard } from '@mui/icons-material';
import {Dialogos} from '../../../componentes/MUI/Dialogos'
//import {useEventos} from '../hooks/useEventos'
import Excel from '../../../componentes/Excel'
import { spawn } from 'child_process';
import { cuponesContext } from '../contexto/CuponesContext'
import {Cupon} from '../componentes/Cupon'
import {ActualizarCupon} from '../componentes/ActualizarCupon'
import { Button, Card, List, ListItem, Spinner } from '@material-tailwind/react';
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import Main from '../../../componentes/Main';

const columnas =   [
{label:'Nro',value:'id_evento_inscr'},
{label:'Nombre',value:'nombre'},
{label:'Documento',value:'nro_documento'},
{label:'Edad',value:'edad'},
{label:'Item pagado',value:'detalle'},
{label:'Provincia',value:'provincia'},
{label:'Email',value:'email'},
{label:'Tipo',value:'categoria'},
{label:'Rango',value:'rango'},
{label:'Región',value:'region'},
{label:'Fecha alta',value:'f_alta'},
{label:'Estado pago',value:'cod_interno'},
{label:'Número mercadopago',value:'mp_id'},
{label:'Código inscripción',value:'codigo_web'},
{label:'Fecha ingreso',value:'f_ingreso'},
{label:'Hora ingreso',value:'h_ingreso'},
{label:'Usuario ingreso',value:'usuario_ingreso'},

]

export const Listado = ()=>{

    const {cupones,cargando,refrescar,cargandoCupones,datosOk,reiniciar} = useContext(cuponesContext)
    const [crearNuevoCupon,setCrearNuevoCupon] = useState(false)
    const [cuponSeleccionado,setCuponSeleccionado] = useState(null)
    const { esCelular } = useContextoGlobal();

    useEffect(()=>{
      console.log('cambio')
        reiniciar()
        setCuponSeleccionado(null)
        setCrearNuevoCupon(false) // luego de crear un cupon cambia el estado de cargando y cierro el dialogo de nuevo cupon
    },[cupones])

    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    if(cargandoCupones){
       return <Main center>
                  <div className='flex flex-col justify-center items-center mt-20'>
                      <Spinner className="h-12 w-12" />
                      <span className="cargando font-semibold text-[15px]">Cargando...</span>
                    </div>
              </Main>
    }

    const TABLE_HEAD = ["Evento", "Item", "Personas", "Importe", "Nro. Cupón", "Fecha alta", "Estado", "Observaciones"];

    return <>{!esCelular ? <Card className='margin-auto lg:m-6 p-2 lg:p-10 xl:p-10 flex items-center flex-col mt-4'>
              <div className='flex space-x-4 mb-4'>
                  <Button onClick={refrescar} color='primary' variant='gradient'>Refrescar</Button>
                  <Button onClick={()=>setCrearNuevoCupon(true)} color='primary' variant='gradient'>Crear un cupón</Button>
              </div> 
              <Box sx={{display:'flex',border:'solid 1px gray',borderRadius:'5px'}}>
                  {/*<Box sx={{border:'solidx 1px gray',padding:'0.5rem',margin:'0.5rem'}}>
                      <Typography variant='body2'>Inscriptos: <b>{inscriptos.total}</b></Typography>
                      <Typography variant='body2'>UAD: <b>{inscriptos.uad}</b></Typography>
                      <Typography variant='body2'>Externos: <b>{inscriptos.externos}</b></Typography>
                      <Typography variant='body2'>Matrimonios: <b>{inscriptos.matrimonios}</b></Typography>
                      <Typography variant='body2'>Individuos: <b>{inscriptos.individuos}</b></Typography>
                      <Typography variant='body2'>Menores: <b>{inscriptos.menores}</b></Typography>
                      <Typography variant='body2'>Mayores: <b>{inscriptos.mayores}</b></Typography>
                  </Box>
                    <Detalle2 datos={inscriptos.regiones} regiones/>
                      <Detalle2 datos={inscriptos.rangos}/>*/}
              </Box>
            
              {(!cupones || cupones?.length==0) && <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',minHeight:'200px' }}> 
                  <Typography variant='h6'>
                      No se encontraron cupones cargados
                  </Typography>
              </Box>}

              {/*cupones.length > 0 && <Box sx={{marginTop:'0.5rem',marginBottom:'0.5rem'}}>
                                  <Excel  datos={cupones} 
                                  descripcion='Cupones'
                                  columnas={columnas}
                                  titulo='Cupones Convención' />
                              </Box>*/}


              {cupones.length > 0 && <>
                <table className="table-auto text-left">
                  <thead>
                      <tr>
                          {TABLE_HEAD.map((head) => (
                          <th
                              key={head}
                              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                              <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                              >
                              {head}
                              </Typography>
                          </th>
                          ))}
                      </tr>
                  </thead>
                  <tbody>
                    {cupones.map((row, index) => {
                       const isLast = index === cupones.length - 1;
                       const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                       return (
                        <><tr key={`lfe-${row.id_evento_cupon}`} className='cursor-pointer'
                           onClick={() => setCuponSeleccionado(row)}>
                           <td className={classes}>
                             <Typography
                               variant="small"
                               color="blue-gray"
                               className="font-normal"
                             >
                               {row.evento}
                             </Typography>
                           </td>
                           <td className={classes}>
                             <Typography
                               variant="small"
                               color="blue-gray"
                               className="font-normal"
                             >
                               {row.detalle}
                             </Typography>
                           </td>
                           <td className={classes}>
                             <Typography
                               variant="small"
                               color="blue-gray"
                               className="font-normal"
                             >
                               {row.cantidad_personas}
                             </Typography>
                           </td>
                           <td className={classes}>
                             <Typography
                               variant="small"
                               color="blue-gray"
                               className="font-normal"
                             >
                               {row.importe}
                             </Typography>
                           </td>
                           <td className={classes}>
                             <Typography
                               variant="small"
                               color="blue-gray"
                               className="font-normal"
                             >
                               {row.nro_cupon}
                             </Typography>
                           </td>
                           <td className={classes}>
                             <Typography
                               variant="small"
                               color="blue-gray"
                               className="font-normal"
                             >
                               {row.f_alta}
                             </Typography>
                           </td>
                           <td className={classes}>
                             <Typography
                               variant="small"
                               color="blue-gray"
                               className="font-normal"
                             >
                               {row.validado}
                             </Typography>
                           </td>
                           <td className={classes}>
                             <Typography
                               variant="small"
                               color="blue-gray"
                               className="font-normal"
                             >
                               {row.observaciones}
                             </Typography>
                           </td>
                         </tr>
                         <TableRow>
                             <TableCell colSpan="8" sx={{ lineHeight: '0', color: 'black' }} align="left">
                               <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                 <p>Creado por: {row.usuario} el : {row.f_alta} </p>
                                 <p>Validado por: {row.validador} el : {row.f_validado} </p>
                                 {row.utilizado && <p style={{ color: 'yellow', fontWeight: '600', cursor: 'none' }}>
                                   Cupón aplicado (Cod web qr {row?.utilizado})
                                 </p>}
                               </div>
                             </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </>}
                  <Dialogos open={crearNuevoCupon} 
                              titulo='Crear un nuevo cupón' 
                              procesarCancelar = {()=>setCrearNuevoCupon(false)}
                            >
                            <Cupon/> 
                  </Dialogos>
                  <Dialogos open={cuponSeleccionado?.id_evento_cupon} 
                              closeHeader
                              titulo={`Actualizar cupón ${cuponSeleccionado?.nro_cupon}`} 
                              procesarCancelar = {()=>setCuponSeleccionado(null)}
                            >
                            <ActualizarCupon cupon={cuponSeleccionado} refrescar={refrescar}/> 
                  </Dialogos>
          </Card> 
          : 
          <Card className='ml-5 mt-4'>
             <div className='flex justify-center items-center space-x-4 mb-4 mt-8'>
                  <Button onClick={refrescar} color='primary' variant='gradient'>Refrescar</Button>
                  <Button onClick={()=>setCrearNuevoCupon(true)} color='primary' variant='gradient'>Crear un cupón</Button>
              </div> 
              <Divider />
                
              {(!cupones || cupones?.length==0) && <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',minHeight:'200px' }}> 
                  <Typography variant='h6'>
                      No se encontraron cupones cargados
                  </Typography>
              </Box>}
            <List> 
                {cupones.map((item, index)=>
                <>
                <ListItem key={index} className="cursor-pointer" onClick={() => setCuponSeleccionado(item)}>
                    <div className='f-col space-y-2'>
                        <p><span className='font-bold'>Evento: </span>{item.evento}</p>
                        <p><span className='font-bold'>Item: </span>{item.descripcion}</p>
                        <p><span className='font-bold'>Personas: </span>{item.fecha}</p>
                        <p><span className='font-bold'>Importe: </span>{item.fecha}</p>
                        <p><span className='font-bold'>Nro.Cupón: </span>{item.fecha}</p>
                        <p><span className='font-bold'>Fecha alta: </span>{item.fecha}</p>
                        <p><span className='font-bold'>Fecha alta: </span>{item.fecha}</p>
                        <p><span className='font-bold'>Observaciones: </span>{item.fecha}</p>
                        <p>Creado por: {item.usuario} el : {item.f_alta} </p>
                        <p>Validado por: {item.validador} el : {item.f_validado} </p>
                          {item.utilizado && <p style={{ color: 'yellow', fontWeight: '600', cursor: 'none' }}>
                          Cupón aplicado (Cod web qr {item?.utilizado})
                         </p>}
                    </div>
                </ListItem>
                <Divider />
                </>
                )}
            </List>
            <Dialogos open={crearNuevoCupon} 
                              titulo='Crear un nuevo cupón' 
                              procesarCancelar = {()=>setCrearNuevoCupon(false)}
                            >
                            <Cupon/> 
                  </Dialogos>
                  <Dialogos open={cuponSeleccionado?.id_evento_cupon} 
                              closeHeader
                              titulo={`Actualizar cupón ${cuponSeleccionado?.nro_cupon}`} 
                              procesarCancelar = {()=>setCuponSeleccionado(null)}
                            >
                            <ActualizarCupon cupon={cuponSeleccionado} refrescar={refrescar}/> 
                  </Dialogos>
        </Card> 
        }
      </>
}


/*
const CrearCupon = ({crearCupon,cupon,})=>{
  const [fecha,setFecha] = useState(new moment())

  const handleChangeFechaNac = (fecha) => {
    console.log(fecha);
    setFecha(moment(fecha))
  };


  return <div>Crear un nuevo cupón
      <p>{fecha.format('YYYY-MM-DD HH:mm:ss')}</p>
      <button onClick={crearCupon}>Crear un cupón</button>
      <Fecha titulo='Fecha de nacimiento' fecha={fecha} setFecha={handleChangeFechaNac}/>      
  </div>
}
*/