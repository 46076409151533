import React, {useState, useEffect} from 'react';
import FormularioAlerta from '../abms/FormularioAlerta';
import {Main, Axios} from '../Helpers/importacionesComunes'
import { Spinner } from '@material-tailwind/react';

export default function Alerta({id_alerta,usuario,finAlerta}){

    const [alertas,setAlertas]=useState([]);
    const [alerta,setAlerta]=useState(null);
    const [alertaSeleccionado,setAlertaSeleccionado]=useState([]);
    const [abiertas,setAbiertas]=useState(true);
    const [propias,setPropias]=useState(false);
    const [buscando,setBuscando]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [contadorOperaciones,setContadorOperaciones] = useState(0)

    useEffect(()=>{
       
        let mounted = true;


        if (mounted){
            buscarAlerta()
        }
            
        return ()=> mounted = false
    },[])

    const buscarAlerta = async ()=>{

        setBuscando(true)

        try{
            const vector_promesas = await Promise.all([Axios.get(`/api/tablasgenerales/alerta/${id_alerta}`)])
     
                setAlerta(vector_promesas[0].data)
                setBuscando(false)

         }catch(err){
             setBuscando(false)
             setHuboError(true)
         }
     }

    if (huboError){
        return <Main center>
                        <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                            <span>Se produjo un error al cargar los datos para esta vista</span>
                        </div>
                </Main>
    }

    if (buscando){
        return <Main center alinear>
                    <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                        <Spinner className="h-12 w-12" />
                        <span className="cargando font-semibold text-[15px]">Cargando registro...</span>
                    </div>
                </Main>
    };

    if (!alerta){
        return null
    }

    return(
        <Main center> 
                <FormularioAlerta alerta={alerta} usuario={usuario} finAlerta={finAlerta}/>
        </Main>
    )
}



