import React from 'react';
import {TextField, CircularProgress, Box ,Grid,Typography} from "@material-ui/core";
import {Table,
        Fecha,
        Accordion,
        AccordionDetails,
        AccordionSummary,
        Loader,
        Loading,
        ExpandMoreIcon,
        objestados,
        regex_solo_numeros,
        usuario_habilitado_sede,
        deshabilitar} from './index.js'

import {useAutonomia} from '../../hooks/useAutonomia.js'
import Main from '../../../../componentes/Main.js';
import { Button, Spinner } from '@material-tailwind/react';

export const InfoAutonomia = ({id,cabecera,setData,usuario,snackbar,puedeEditarse,cambiarStatus})=>{

    const {refrescando,
        traerUrl,
        handleChange,
        expanded,
        datosDelTramite,
        inicioActualizacionTramite,
        actualizarTramite,
        touched,
        handleChangeFechaACDN,
        status_actual_tramite,
        fechaACDN,
        setActa_cdn,
        acta_cdn,
        handleChangeFechaAR,error,
        loading,loading2,procesando,
        fechaAR,setActa_regional,acta_regional} = useAutonomia(cabecera,id,setData,usuario,snackbar);

        if(error){
            return <p>{JSON.stringify(error)}</p>
        }
    
        if(loading || loading2 || procesando){
            return <Main center alinear>
                        <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                            <Spinner className="h-12 w-12" />
                            <span className="cargando font-semibold text-[15px]">Cargando...</span>
                        </div>
                    </Main>
         }

    if(!datosDelTramite){
       
        return <div>
               <Spinner className="h-12 w-12" />
             <p>Cargando solicitud</p>
        </div>
    }

    return <Box id="solicitud-proceso" sx={{marginRight:'auto',marginLeft:'auto',padding:'5px',borderRadius:'10px',border:'solid 1px gray'}}>
    <Loader open={refrescando}/>
     {status_actual_tramite==objestados.procRegion.nombre && usuario.perfil=='region' && <Accordion expanded={expanded === 'instrucciones'} onChange={handleChange('instrucciones')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="instrucciones-content"
        style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'rgb(228 228 231)'}}
        id="instrucciones-header"
        >
            <h3>Instrucciones para procesar una solicitud de autonomía</h3> 
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{textAlign:'left'}}>
                <InstruccionesAutonomia/>
            </Box>  
        </Grid>
    </Grid>
        </AccordionDetails>
    </Accordion>}
    <Accordion expanded={expanded === 'informacion'} onChange={handleChange('informacion')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="informacion-content"
        style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'rgb(228 228 231)'}}
        id="informacion-header"
        >
            <h3>Nombre de la iglesia y acta de autonomía TEST</h3> 
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{textAlign:'left'}}>
               <Table className='sol-anexo'>
                    <tbody className='text-sm'>
                        <tr>
                            <td>
                                Nombre
                            </td>
                            <td>
                                {datosDelTramite?.iglesia?.nombre || 'No se encontró el nombre de la iglesia'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {/*<Button startIcon={<AttachFileIcon/>} onClick={()=>traerUrl(data.ministroInfo.adjuntos.analitico)} variant="contained" color="primary" size='medium' title="Ver analítico">Ver analítico adjunto</Button>*/}
                                <p><span title='Visualice el acta de autonomía adjunto' className='cursor-pointer text-blue-400 fw-600' onClick={()=>traerUrl(datosDelTramite?.actaURL)}>Acta de autonomía</span></p>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Box>  
        </Grid>
    </Grid>
        </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'encargado'} onChange={handleChange('encargado')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="encargado-content"
        style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'rgb(228 228 231)'}}
        id="encargado-header"
        >
            <h3>Datos del encargado</h3> 
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{textAlign:'left'}}>
               <Table className='sol-anexo'>
                    <tbody className='text-sm'>
                        <tr>
                            <td>
                                Nombre
                            </td>
                            <td>
                                {datosDelTramite?.encargado.nombre}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Tiene credenciales
                            </td>
                            <td>
                                {datosDelTramite?.encargado.credenciales_si_no ? 'SI' : 'NO'}
                            </td>
                        </tr>
                        {datosDelTramite?.encargado.credenciales_si_no && <tr>
                            <td>
                                Rango
                            </td>
                            <td>
                                {datosDelTramite?.encargado.rango}
                            </td>
                        </tr>}  
                        <tr>
                            <td>
                                El encargado será sostenido por la iglesia
                            </td>
                            <td>
                                {datosDelTramite?.encargado.sostenido_iglesia_si_no ? 'SI' : 'NO'}
                            </td>
                        </tr>
                        {datosDelTramite?.encargado.sostenido_iglesia_si_no && <tr>
                            <td>
                                Porcentaje de tiempo del sostenimiento recibido
                            </td>
                            <td>
                                {datosDelTramite?.encargado.porcentaje_tiempo} %
                            </td>
                        </tr>}                                 
                    </tbody>
                </Table>
            </Box>  
        </Grid>
    </Grid>
        </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'infoGeneral'} onChange={handleChange('infoGeneral')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="infoGeneral-content"
        style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'rgb(228 228 231)'}}
        id="infoGeneral-header"
        >
            <h3>Información general del anexo</h3> 
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{textAlign:'left'}}>
               <Table className='sol-anexo'>
                    <tbody className='text-sm'>
                        <tr>
                            <td>
                                El anexo tiene una comisión
                            </td>
                            <td>
                                {datosDelTramite?.comision_si_no ? 'SI' : 'NO'}
                            </td>
                        </tr>
                        {datosDelTramite?.comision_si_no && <tr>
                            <td>
                                Comisión:
                            </td>
                            {datosDelTramite?.comision && <td>
                                <ul>
                                    {datosDelTramite?.comision.map(item=>{
                                    return <li className='text-small'><p>{item.nombre}</p><p className='text-xsmal color-red'>{item.cargo}</p></li>
                                    })}
                                </ul>
                                
                            </td>}
                            { (!(datosDelTramite?.comision) || datosDelTramite?.comision.length==0 ) && <td>
                                NO SE ENCONTRARON LOS DATOS DE LA COMISION
                            </td>}
                        </tr>}
                        <tr>
                            <td>
                                Condición del edificio
                            </td>
                            <td>
                                {datosDelTramite?.condicion_edificio}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Tiene libro de actas
                            </td>
                            <td>
                                {datosDelTramite?.libro_actas_si_no ? 'SI' : 'NO'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Tiene fichero de culto
                            </td>
                            <td>
                                {datosDelTramite?.fichero_culto_si_no ? 'SI' : 'NO'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Tiene certificado de afiliación
                            </td>
                            <td>
                                {datosDelTramite?.certificado_afiliacion_si_no ? 'SI' : 'NO'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Está enviando diezmos a la sede central
                            </td>
                            <td>
                                {datosDelTramite?.envia_diezmos_si_no ? 'SI' : 'NO'}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Observaciones
                            </td>
                            <td>
                                {datosDelTramite?.observaciones}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Box>  
        </Grid>
    </Grid>
        </AccordionDetails>
    </Accordion>
    <Accordion expanded={expanded === 'asistencia'} onChange={handleChange('asistencia')}>
        <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="asistencia-content"
        style={{borderBottom:'solid 1px gray',justifyContent:'center',alignItems:'center',background:'rgb(228 228 231)'}}
        id="asistencia-header"
        >
            <h3>Asistencia</h3> 
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{textAlign:'left'}}>
               <Table className='sol-anexo'>
                    <tbody className='text-sm'>
                        <tr>
                            <td>
                                Número de miembros registrados
                            </td>
                            <td>
                                {datosDelTramite?.miembros_registrados}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Asistencia promedio en el culto principal de la semana
                            </td>
                            <td>
                                {datosDelTramite?.promedio_semanal}
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Box>  
        </Grid>
    </Grid>
        </AccordionDetails>
    </Accordion>

    <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                value={acta_regional} 
                style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado') ? 'none' : ''}}
                disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                onChange={(e)=>{
                    if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                        setActa_regional(e.target.value)
                    }
                   }} label="Número de acta regional" variant="filled" />
                <Fecha  titulo='Fecha de acta regional' 
                        fecha={fechaAR} 
                        style={{marginTop:0}}
                        ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre],'encontrado')}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procRegion.nombre],'no_encontrado') || usuario.perfil!='region'}
                        setFecha={handleChangeFechaAR}/>  
                   
    </Box>

    <Box sx={{marginTop:'1rem',display:'flex',justifyContent:'space-evenly'}}>
                <TextField inputProps={{maxLength: 4}} id="dp-documento" 
                value={acta_cdn} 
                style={{display: deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado') ? 'none' : ''}}
                disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                onChange={(e)=>{
                    if(e.target.value === '' || regex_solo_numeros.test(e.target.value)){
                        setActa_cdn(e.target.value)
                    }
                   }} label="Número de acta CDN" variant="filled" />
                <Fecha titulo='Fecha acta CDN' 
                        title={JSON.stringify(fechaACDN)}
                        fecha={fechaACDN}
                        style={{marginTop:0}}
                        ocultar={deshabilitar(status_actual_tramite,[objestados.iniciado.nombre,objestados.procRegion.nombre],'encontrado')}
                        disabled={deshabilitar(status_actual_tramite,[objestados.procSede.nombre],'no_encontrado') || !usuario_habilitado_sede(usuario)}
                        setFecha={handleChangeFechaACDN}/> 
    </Box>
   
    <Box sx={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
        {Object.entries(touched).length > 0 && <Button id="tra-action" onClick={()=>inicioActualizacionTramite(actualizarTramite)}><span className=''>Grabar cambios</span></Button>}
        {!(Object.entries(touched).length > 0) && puedeEditarse && <Button id="tra-action" onClick={cambiarStatus}>Cambiar el estado</Button>}

    </Box>
</Box> 
}


const InstruccionesAutonomia = ()=>{
    return <div className='instrucciones'>
        <p> 1 - Definir y completar...</p>
    </div>
}


