import React, {useContext} from 'react'
import {TextField,DialogTitle,DialogContentText,DialogContent,DialogActions,Dialog} from "@material-ui/core";
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from '@material-tailwind/react';

export const Dialogos = ({open,
                          procesarResultado,
                          titulo,
                          children,
                          texto,
                          procesarCancelar,
                          error,
                          textoAceptar,
                          fullscreen,
                          mensaje,
                          closeHeader,
                          soloCheckCerrar,
                          maxWidth})=>{
  
    return     <div>
    <Dialog fullScreen={fullscreen || false} open={open} onClose={procesarResultado} maxWidth={maxWidth}>
      {titulo && <DialogTitle className='bg-blue-gray-100/60' >{titulo}
          {/*closeHeader && <Button style={{width:'100%',justifyContent:'center'}} title='Cerrar' onClick={procesarCancelar}>Cerrar <CloseSharpIcon/></Button>*/}
      </DialogTitle>}
      <DialogContent>
        <DialogContentText>
            {texto}
        </DialogContentText>
        {children}
        {error && <p style={{color:'red'}}>{error}</p>}
      </DialogContent>
      <DialogActions className='mt-4 mb-2'>
        <span style={{color:'red',maxWidth:'300px'}}>{mensaje}</span>
        {soloCheckCerrar && procesarCancelar && <Button variant="outlined" title='Cerrar' onClick={procesarCancelar}><CheckIcon/></Button>}
        {procesarCancelar && !soloCheckCerrar && <Button variant="outlined" className='bg-black/80 text-white' onClick={procesarCancelar}>Cancelar</Button>}
        {procesarResultado && <Button onClick={procesarResultado}>{textoAceptar ? textoAceptar : 'Aceptar'}</Button>}
      </DialogActions>
    </Dialog>
  </div>
}