import React, { useState, useEffect } from 'react';
import { Axios, Main, Loading, FontAwesomeIcon, Ventana } from '../../../Helpers/importacionesComunes'
import { BalancesYdiezmos } from '../../iglesias/componentes/BalancesYdiezmos';
import { faTimesCircle, faCheckCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import AbmIglesia from '../../../abms/Abm-iglesia'
import { useContextoGlobal } from '../../../Context/contextoGlobal'
import { Spinner, Typography } from '@material-tailwind/react';
import { EyeDropperIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';

export const IglesiasMinistro = ({ ministro }) => {
    const [iglesiaSeleccionada, setIglesiaSeleccionada] = useState(null)
    const [iglesiasObrero, setIglesiasObrero] = useState([])
    const [buscandoIglesiasObrero, setBuscandoIglesiasObrero] = useState(false)
    const [huboError, setHuboError] = useState(false)
    const { usuario } = useContextoGlobal()

    useEffect(() => {
        buscarIglesiasPorObrero()
    }, [])


    const buscarIglesiasPorObrero = async () => {

        setBuscandoIglesiasObrero(true)

        const url = `/api/tablasgenerales/iglesiasobrero/${ministro.id_obrero}`
        try {
            const { data } = await Axios.get(url)
            setIglesiasObrero(data)
            setBuscandoIglesiasObrero(false)
            return data
        } catch (err) {
            console.log('Error al buscar las iglesias de un ministro')
            setBuscandoIglesiasObrero(false)
            setHuboError(true)
            return null
        }
    }

    if (huboError) {
        return <Main center>
            <div className='flex flex-col justify-center items-center h-[calc(50vh-5rem)]'>
                <span>Se produjo un error al cargar los datos para esta vista</span>
            </div>
        </Main>
    }

    if (buscandoIglesiasObrero) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(50vh-5rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Buscando iglesias...</span>
            </div>
        </Main>
    }

    return <div className="FormAbmContainer flex f-col mt-2">
        {iglesiaSeleccionada && <Ventana cerrar={() => setIglesiaSeleccionada(null)}>
            <AbmIglesia usuario={usuario} id_iglesia={iglesiaSeleccionada} finalizarAltaOcopia={() => setIglesiaSeleccionada(null)} esVisualizacion={true} />
        </Ventana>}

        <Typography
            variant="h5"
            className="font-normal flex justify-center font-bold mt-4 text-blue-500"
        >
            {ministro.nombre_obrero}
        </Typography>

        {iglesiasObrero.length > 0 && <div className="flex flex-col text-large">
            <Typography
                variant="h5"
                className="font-normal flex justify-center mt-4"
            >
                Iglesias autónomas a cargo:
            </Typography>

            {iglesiasObrero.map(item => <div className="ig-min">
                <span title="Visualizar la iglesia" onClick={() => setIglesiaSeleccionada(item.id_iglesia)} className="cursor-pointer text-blue-400 font-normal !flex !justify-center mb-2">
                    {item.nombre}
                    <EyeIcon title="Visualizar la iglesia" className="ml-2" width={20} />
                </span>
                <CondicionIglesia iglesia={item} />
                <BalancesYdiezmos id_iglesia={item.id_iglesia} />
            </div>)}
        </div>}
        {iglesiasObrero.length == 0 && !buscandoIglesiasObrero && <div className="flex f-col text-large">
            <div className="ig-min"><span>{`No tiene iglesias autónomas a cargo`}</span></div>
        </div>}
    </div>
}

function CondicionIglesia({ iglesia }) {
    return <div className="flex !justify-center f-row jfc-fe text-smaller mt-4 border-bottom-dotted-gray">
        <div className="ml-4"><span className='font-bold'>Balances:</span>{iglesia.estado_balances == 1 && <span><FontAwesomeIcon className="mr-2 ml-2 color-green" icon={faCheckCircle} />Al día</span>}
            {iglesia.estado_balances == 0 && <span><FontAwesomeIcon className="mr-2 ml-2 color-red" icon={faTimesCircle} />Adeuda</span>}
        </div>
        <div className="ml-4"><span className='font-bold'>Diezmos:</span> {iglesia.detalle_diezmos == 1 && <span><FontAwesomeIcon className="mr-2 ml-2 color-green" icon={faCheckCircle} />Al día</span>}
            {iglesia.detalle_diezmos == 0 && <span><FontAwesomeIcon className="mr-2 ml-2 color-red" icon={faTimesCircle} />Adeuda</span>}
        </div>
    </div>
}