import React, { useState, useEffect } from 'react';
import Main from '../componentes/Main';
import Axios from 'axios';
import { Grid } from "@material-ui/core";
import { Spinner, Card, Typography, Input, IconButton, Tooltip, Dialog, DialogHeader, DialogBody, DialogFooter, Button, Select, Option, Tabs, TabsHeader, TabsBody, Tab, TabPanel, Textarea } from '@material-tailwind/react';
import { MagnifyingGlassIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Swal from 'sweetalert2';
import ExportToExcelCajasRubros from '../componentes/exportExcelCajasRubros';
import { PiePagina, Paginacion, usePaginacion } from '../Helpers/importacionesComunes'

const IProviderModel = {
    nombre: '',
    direccion: '',
    telefono: '',
    email: '',
    cuit: '',
    pagina_web: '',
    banco: '',
    cuenta: '',
    cbu: '',
    observaciones: '',
    contacto: ''
}


export default function Proveedores({ usuario }) {

    const [proveedoresList, setProveedoresList] = useState([])
    const [nameSearch, setNameSearch] = useState('')
    const [proveedorId, setProveedorId,] = useState(0)
    const [buscandoDatos, setBuscandoDatos] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [providerData, setProviderData] = useState(IProviderModel);
    const { configuracion, paginar, inicializar: inicializarPaginacion } = usePaginacion()

    useEffect(() => {
        obtenerArchivos()
    }, [])

    useEffect(() => {
        setProveedoresList([...proveedoresList])
        inicializarPaginacion(null, proveedoresList)
    }, [proveedoresList])


    useEffect(() => {
        if (nameSearch.trim() === '') {
            setProveedoresList(proveedoresList);
        } else {
            const listaFiltrada = proveedoresList.filter(item =>
                item.nombre.toLowerCase().includes(nameSearch.toLowerCase())
            );
            setProveedoresList(listaFiltrada.length > 0 ? listaFiltrada : proveedoresList);
        }
    }, [nameSearch, proveedoresList]);


    const obtenerArchivos = async () => {
        setBuscandoDatos(true);
        try {
            const resultList = await Promise.all([Axios.get(`/api/tablasgenerales/proveedores`)])
            setProveedoresList(resultList[0].data)
            setBuscandoDatos(false);
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const handleOpen = () => setOpen(!open);

    const verProveedor = async (e) => {
        try {
            const proveedorIdResponse = await Promise.all([Axios.get(`/api/tablasgenerales/proveedor/${e.id_proveedor}`)])
            const proveedorIdSeleccionada = proveedorIdResponse[0].data.recordsets[0][0];

            setProveedorId(proveedorIdSeleccionada.id_proveedor);

            let data = {
                nombre: proveedorIdSeleccionada.nombre,
                direccion: proveedorIdSeleccionada.direccion,
                telefono: proveedorIdSeleccionada.telefono,
                email: proveedorIdSeleccionada.email,
                cuit: proveedorIdSeleccionada.cuit,
                pagina_web: proveedorIdSeleccionada.pagina_web,
                banco: proveedorIdSeleccionada.banco,
                cuenta: proveedorIdSeleccionada.cuenta,
                cbu: proveedorIdSeleccionada.cbu,
                observaciones: proveedorIdSeleccionada.observaciones,
                contacto: proveedorIdSeleccionada.contacto
            }
            setProviderData(data)

            handleOpen();
        } catch (error) {
            console.log(error)
            setBuscandoDatos(false);
        }
    }

    const editarProveedor = async () => {
        console.log(providerData);
        
        try {
            await Promise.all([Axios.put(`/api/tablasgenerales/proveedor/${proveedorId}`, providerData)])
            Swal.fire({
                icon: 'alert',
                title: `<p>Los datos han sido actualizados con éxito</p>`,
            })
            handleOpen();
            obtenerArchivos();
        } catch (error) {
            Swal.fire({
                icon: 'alert',
                title: `<p>Error al actualizar los Datos</p>`,
                showCancelButton: true,
            })
            console.log(error)
            setOpen(true);
        }
    }

    const borrarProveedor = async (e) => {
        Swal.fire({
            title: "Esta seguro que desea Eliminarlo?",
            text: "Este cambio es irreversible!",
            type: "warning",
            confirmButtonText: 'Si, eliminar!',
            cancelButtonText: "No, cancelar!",
            showCancelButton: true,
            // allowOutsideClick: false
        }).then(
            async resultado => {
                if (resultado.value) {
                    try {
                        await Promise.all([Axios.delete(`/api/tablasgenerales/proveedor/${e.id_proveedor}`)])
                        obtenerArchivos();
                    } catch (error) {
                        console.log(error)
                        setBuscandoDatos(false);
                    }
                }
            }
        )
    }

    const limpiarBusqueda = async () => {
        obtenerArchivos();
        setNameSearch('')
    }


    if (buscandoDatos) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando archivos...</span>
            </div>
        </Main>
    }

    if (proveedoresList.length == 0) {
        return <Grid container justifyContent='center' alignItems='center' style={{ minHeight: '400px', textAlign: 'center' }} >
            <Grid item>
                <Typography variant="h5">
                    No se encontraron archivos
                </Typography>
            </Grid>
        </Grid>
    }

    return <div className='w-full flex flex-col justify-center items-center lg:!m-6 xl:!m-6'>
        <Typography variant="h4" className="flex ">
            Proveedores
        </Typography>
        <Card className='w-full justify-center items-center flex p-5 justify-left mt-4'>
            <div className='w-full lg:w-96 xl:w-96'>
                <Input
                    type="text"
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                    label="Buscar nombre de Proveedor"
                    value={nameSearch}
                    onChange={(e) => { setNameSearch(e.target.value) }}
                    color='black'
                />
            </div>
            {proveedoresList.length > 0 && <div className='mt-8 w-full'>
                <div className="flex xl:!justify-end">
                {nameSearch && <Button variant='text' className='text-red-400 bg-red-100 hover:bg-red-200' onClick={limpiarBusqueda}>Limpiar Busqueda</Button>}
                    <ExportToExcelCajasRubros data={proveedoresList} fileName="Listado de Proveedores" isProvider={true}/>
                </div>
                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none"
                                >
                                    Se han encontrado {proveedoresList.length} Proveedores
                                </Typography>
                {proveedoresList.length > 0 &&
                    <PiePagina>
                        <div className="">
                            <Paginacion configuracion={configuracion} paginar={paginar} />
                        </div>
                    </PiePagina>}
                <table className="text-left w-full mt-2">
                    <thead>
                        <tr>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Nombre
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Teléfono
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Email
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Cuit
                                </Typography>
                            </th>
                            <th
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal leading-none opacity-70"
                                >
                                    Acciones
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {proveedoresList.filter((item, index) => {
                                return index >= configuracion.iIni && index <= configuracion.iFin
                            }).map((item, index) => {
                            const isLast = index === proveedoresList.length - 1;
                            const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";

                            return (
                                <tr key={index}>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.nombre}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.telefono}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.email}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                        >
                                            {item.cuit}
                                        </Typography>
                                    </td>
                                    <td className={classes}>
                                        <div className="flex items-center gap-2">
                                            <Tooltip content="Ver Detalles">
                                                <IconButton variant="text" size="sm" onClick={() => verProveedor(item)}>
                                                    <PencilIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip content="Eliminar">
                                                <IconButton variant="text" size="sm" onClick={() => borrarProveedor(item)}>
                                                    <TrashIcon className="h-4 w-4 text-gray-900" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {proveedoresList.length > 0 &&
                    <PiePagina>
                        <div className="">
                            <Paginacion configuracion={configuracion} paginar={paginar} />
                        </div>
                    </PiePagina>}
            </div>}
        </Card>
        <Dialog open={open} handler={handleOpen}>
            <DialogHeader>Unión de las Asambleas de Dios</DialogHeader>
            <DialogBody className="xl:p-10">

                <div className="flex flex-col w-full mt-4">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Razón Social
                    </Typography>
                    <Input
                        onChange={(e) => {
                            setProviderData({...providerData, nombre: e.target.value})
                        }}
                        value={providerData.nombre}
                        type='text'
                        id='name'
                        name='name'
                        color='black'
                        className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>

                <Tabs value="Contacto" className="max-w-full mt-6">
                    <TabsHeader
                        className="bg-transparent"
                        indicatorProps={{
                        className: "bg-gray-900/10 shadow-none !text-gray-900",
                        }}
                    >
                        <Tab value={"Contacto"}>
                            Contacto
                        </Tab>
                        <Tab value={"Bancarios"}>
                            Bancarios
                        </Tab>
                        <Tab value={"Observaciones"}>
                            Observaciones
                        </Tab>

                    </TabsHeader>
                    <TabsBody>

                        <TabPanel value={"Contacto"}>
                            <div className="flex flex-col w-full mt-4">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="mb-1 font-medium "
                                >
                                    Nombre
                                </Typography>
                                <Input
                                    onChange={(e) => {
                                        setProviderData({...providerData, nombre: e.target.value})
                                    }}
                                    value={providerData.nombre}
                                    type='text'
                                    id='name'
                                    name='name'
                                    color='black'
                                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                    labelProps={{
                                        className: "before:content-none after:content-none",
                                    }}
                                />
                            </div>
                            <div className='grid grid-cols-3 gap-4'>
                                <div class="...">
                                    <div className="flex flex-col w-full mt-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-1 font-medium "
                                        >
                                            CUIT
                                        </Typography>
                                        <Input
                                            onChange={(e) => {
                                                setProviderData({...providerData, cuit: e.target.value})
                                            }}
                                            value={providerData.cuit}
                                            type='text'
                                            id='name'
                                            name='name'
                                            color='black'
                                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="col-span-2 ...">
                                    <div className="flex flex-col w-full mt-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-1 font-medium "
                                        >
                                            Página web
                                        </Typography>
                                        <Input
                                            onChange={(e) => {
                                                setProviderData({...providerData, pagina_web: e.target.value})
                                            }}
                                            value={providerData.pagina_web}
                                            type='text'
                                            id='name'
                                            name='name'
                                            color='black'
                                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='grid grid-cols-3 gap-4'>
                                <div class="...">
                                    <div className="flex flex-col w-full mt-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-1 font-medium "
                                        >
                                            Teléfono
                                        </Typography>
                                        <Input
                                            onChange={(e) => {
                                                setProviderData({...providerData, telefono: e.target.value})
                                            }}
                                            value={providerData.telefono}
                                            type='text'
                                            id='name'
                                            name='name'
                                            color='black'
                                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="col-span-2 ...">
                                    <div className="flex flex-col w-full mt-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-1 font-medium "
                                        >
                                            Email
                                        </Typography>
                                        <Input
                                            onChange={(e) => {
                                                setProviderData({...providerData, email: e.target.value})
                                            }}
                                            value={providerData.email}
                                            type='text'
                                            id='name'
                                            name='name'
                                            color='black'
                                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                        <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="mb-1 font-medium "
                                        >
                                            Contacto
                                        </Typography>
                                        <Input
                                            onChange={(e) => {
                                                setProviderData({...providerData, contacto: e.target.value})
                                            }}
                                            value={providerData.contacto}
                                            type='text'
                                            id='name'
                                            name='name'
                                            color='black'
                                            className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                            labelProps={{
                                                className: "before:content-none after:content-none",
                                            }}
                                        />
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="mb-1 font-medium "
                                >
                                    Dirección
                                </Typography>
                                <Input
                                    onChange={(e) => {
                                        setProviderData({...providerData, direccion: e.target.value})
                                    }}
                                    value={providerData.direccion}
                                    type='text'
                                    id='name'
                                    name='name'
                                    color='black'
                                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                    labelProps={{
                                        className: "before:content-none after:content-none",
                                    }}
                                />
                            </div>
                        </TabPanel>

                        <TabPanel value={"Bancarios"}>
                            <div className="flex flex-col w-full mt-4">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="mb-1 font-medium "
                                >
                                    Banco y sucursal
                                </Typography>
                                <Input
                                    onChange={(e) => {
                                        setProviderData({...providerData, banco: e.target.value})
                                    }}
                                    value={providerData.banco}
                                    type='text'
                                    id='name'
                                    name='name'
                                    color='black'
                                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                    labelProps={{
                                        className: "before:content-none after:content-none",
                                    }}
                                />
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="mb-1 font-medium "
                                >
                                    Número y tipo de Cuenta
                                </Typography>
                                <Input
                                    onChange={(e) => {
                                        setProviderData({...providerData, cuenta: e.target.value})
                                    }}
                                    value={providerData.cuenta}
                                    type='text'
                                    id='name'
                                    name='name'
                                    color='black'
                                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                    labelProps={{
                                        className: "before:content-none after:content-none",
                                    }}
                                />
                            </div>
                            <div className="flex flex-col w-full mt-4">
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="mb-1 font-medium "
                                >
                                    CBU
                                </Typography>
                                <Input
                                    onChange={(e) => {
                                        setProviderData({...providerData, cbu: e.target.value})
                                    }}
                                    value={providerData.cbu}
                                    type='text'
                                    id='name'
                                    name='name'
                                    color='black'
                                    className="!border-t-blue-gray-200 focus:!border-t-gray-900"
                                    labelProps={{
                                        className: "before:content-none after:content-none",
                                    }}
                                />
                            </div>
                        </TabPanel>

                        <TabPanel value={"Observaciones"}>
                            <Textarea 
                            label="Observaciones"
                            onChange={(e) => {
                                setProviderData({...providerData, observaciones: e.target.value})
                            }}
                            value={providerData.observaciones} 
                            />
                        </TabPanel>

                    </TabsBody>
                </Tabs>
            </DialogBody>
            <DialogFooter className="space-x-2">
                <Button variant="gradient" color="blue" onClick={editarProveedor}>
                    Guardar
                </Button>
                <Button variant="text" color="blue-gray" onClick={handleOpen}>
                    Cancelar
                </Button>
            </DialogFooter>
        </Dialog>
    </div>
}

