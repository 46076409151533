import React, { useEffect, useState } from 'react';
import { usePaginacion, Paginacion, FontAwesomeIcon, PiePagina, Ventana } from '../../../Helpers/importacionesComunes'
import { } from '@fortawesome/free-regular-svg-icons';
import { faSearchDollar, faWindowClose, faEdit, faSort } from '@fortawesome/free-solid-svg-icons';
import IngresosIgl from '../../../componentes/IngresosIgl';
import { BalancesYdiezmos } from '../componentes/BalancesYdiezmos'
import AbmIglesia from '../../../abms/Abm-iglesia'
import { ListadoCelular } from '../componentes/ListadoCelular'
import ExportToExcelGeneric from '../../../componentes/exportExcelGeneric';

import { useContextoGlobal } from '../../../Context/contextoGlobal'
import { Card, CardBody, Typography } from '@material-tailwind/react';
import { ChevronUpDownIcon, CurrencyDollarIcon, PencilSquareIcon } from '@heroicons/react/24/solid';

export const Tabla = ({ registrosAmostrar,
    setIglesiaSeleccionadaIngresos,
    iglesiaSeleccionadaIngresos,
    finalizarIngresosIgl,
    reinicializar,
    resetearLista,
    setIglesiaSeleccionada,
    iglesiaSeleccionada,
    setIsIglesiaSeleccionado
}) => {

    const { configuracion, paginar, inicializar: inicializarPaginacion } = usePaginacion()
    // Hago una copia de los datos que se reciben para manejar el ordenamiento
    const [copiaRegistrosaMostrar, setCopiaRegistrosaMostrar] = useState([...registrosAmostrar])
    const [orden, setOrden] = useState(null)
    const [nuevoCampo, setNuevoCampo] = useState(true)
    const [contadorOrden, setContadorOrden] = useState(0)
    const { usuario, esCelular } = useContextoGlobal()


    useEffect(() => {
        setCopiaRegistrosaMostrar([...registrosAmostrar])
        inicializarPaginacion(null, registrosAmostrar)
    }, [registrosAmostrar])


    useEffect(() => {// el ordenamiento se maneja con las propiedades orden que almacena el campo por el cual ordenar
        // y nuevoCampo que es un flag que indica si es otro campo o el mismo a la inversa
        // cada vez que se cambia el orden en click de la cabecera se cambia contadorOrden como un trigger 

        ordenarDatos()
    }, [contadorOrden])

    const ordenarDatos = () => {
        setCopiaRegistrosaMostrar([...registrosAmostrar.sort((a, b) => { return comparacion(a, b) })
        ])
    }

    const funcionOrden = (nombre_campo) => {

        if (orden == nombre_campo) {
            setNuevoCampo(false)
        } else {
            setNuevoCampo(true)
        }

        setOrden(nombre_campo)
        setContadorOrden(contadorOrden + 1)

    }

    const comparacion = (a, b) => {

        switch (orden) {
            case null: return 0
            case 'id_region':
            case 'id_iglesia_madre':
            case 'edad':
            case 'id_distrito':

                if (nuevoCampo == true) {
                    return a[orden] - b[orden]
                } else {
                    if (contadorOrden % 2 == 0) {
                        return b[orden] - a[orden]
                    } else {
                        return a[orden] - b[orden]
                    }
                }
            case 'ult_ascenso':
            case 'f_solicitud':

                const dia_a = Number(a[orden].substring(0, 2));
                const mes_a = Number(a[orden].substring(3, 5));
                const anio_a = Number(a[orden].substring(6, 10));

                const fa = new Date(anio_a, mes_a, dia_a);

                const dia_b = Number(b[orden].substring(0, 2));
                const mes_b = Number(b[orden].substring(3, 5));
                const anio_b = Number(b[orden].substring(6, 10));

                const fb = new Date(anio_b, mes_b, dia_b);

                if (nuevoCampo == true) {
                    return fa - fb
                } else {
                    if (contadorOrden % 2 == 0) {
                        return fb - fa
                    } else {
                        return fa - fb
                    }
                }
            default:
                if (nuevoCampo == true) {
                    return a[orden].localeCompare(b[orden])
                } else {
                    if (contadorOrden % 2 == 0) {
                        return b[orden].localeCompare(a[orden])
                    } else {
                        return a[orden].localeCompare(b[orden])
                    }
                }
        }
    }

    if (esCelular) {
        return <>
            <ListadoCelular datos={copiaRegistrosaMostrar} funcionSeleccion={setIglesiaSeleccionada} setIsIglesiaSeleccionado={setIsIglesiaSeleccionado} />
        </>
    }

    return <>
        <Card className="h-full md:w-[62rem] 2xl:w-full">
            
            {copiaRegistrosaMostrar.length > 0 &&
                <div className="flex items-center justify-between pr-3">
                    <Paginacion configuracion={configuracion} paginar={paginar} />
                    <ExportToExcelGeneric data={copiaRegistrosaMostrar} fileName="Listado de Iglesias" isProvider={true}/>
                </div>
            }
            <CardBody className="overflow-x-auto px-0 pt-0 pb-0">
                <table className="table-auto text-left w-full">
                    <thead>
                        <tr>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Nombre' sort orden={orden === 'nombre_igl'} callback={() => funcionOrden('nombre_igl')} />
                            </th>
                            {usuario.perfil == 'region' && <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50">
                                <ThTitle title='Distrito' sort orden={orden === 'id_distrito'} callback={() => funcionOrden('id_distrito')} />
                            </th>}
                            {usuario.perfil == 'sede' && <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50">
                                <ThTitle title='Región' sort orden={orden === 'id_region'} callback={() => funcionOrden('id_region')} />
                            </th>}
                            {usuario.perfil == 'sede' && <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50">
                                <ThTitle title='Distrito' sort orden={orden === 'id_distrito'} callback={() => funcionOrden('id_distrito')} />
                            </th>}
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Pastor' sort orden={orden === 'pastor'} callback={() => funcionOrden('pastor')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Encargado' sort orden={orden === 'encargado'} callback={() => funcionOrden('encargado')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Domicilio' sort orden={orden === 'domicilio'} callback={() => funcionOrden('domicilio')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Provincia' sort orden={orden === 'provincia'} callback={() => funcionOrden('provincia')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Localidad' sort orden={orden === 'localidad'} callback={() => funcionOrden('localidad')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Teléfono' sort orden={orden === 'telefono'} callback={() => funcionOrden('telefono')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Tipo' sort orden={orden === 'tipo_iglesia'} callback={() => funcionOrden('tipo_iglesia')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-v transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Trámite' sort orden={orden === 'id_iglesia_madre'} callback={() => funcionOrden('id_iglesia_madre')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <ThTitle title='Balance' sort orden={orden === 'balance'} callback={() => funcionOrden('balance')} />
                            </th>
                            <th className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-2 transition-colors hover:bg-blue-gray-50"
                            >
                                <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="flex justify-between gap-2 font-normal leading-none opacity-70"
                                >
                                    Acciones
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {copiaRegistrosaMostrar
                            .filter((item, index) => {
                                return index >= configuracion.iIni && index <= configuracion.iFin
                            })
                            .map(
                                (item, index) => {
                                    const isLast = index === copiaRegistrosaMostrar.length - 1;
                                    const classes = isLast
                                        ? "p-2"
                                        : "p-2 border-b border-blue-gray-50";

                                    return (
                                        <>
                                            <tr key={item.id_iglesia}>
                                                <td className={`${classes} cursor-pointer bg-blue-50 relative`} onClick={() => { setIglesiaSeleccionada(item.id_iglesia); setIsIglesiaSeleccionado(true) }}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.nombre_igl}
                                                    </Typography>
                                                </td>
                                                {usuario.perfil == 'region' && <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.id_distrito}
                                                    </Typography>
                                                </td>}
                                                {usuario.perfil == 'sede' && <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.id_region}
                                                    </Typography>
                                                </td>}
                                                {usuario.perfil == 'sede' && <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.id_distrito}
                                                    </Typography>
                                                </td>}
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.pastor}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.encargado}
                                                    </Typography>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.domicilio}
                                                    </Typography>
                                                </td>
                                                <td className={`${classes}`}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.provincia}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.localidad}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.telefono}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.tipo_iglesia}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.id_iglesia_madre ? 'WEB' : ''}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <Typography
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal"
                                                    >
                                                        {item.balance}
                                                    </Typography>
                                                </td>
                                                <td className={classes}>
                                                    <div className="flex items-center justify-center">
                                                        <span onClick={() => { setIglesiaSeleccionadaIngresos(item.id_iglesia) }}
                                                            title={`Ver los ingresos del último año`} className="filas-lista-nw cursor-copy" >
                                                            <CurrencyDollarIcon className="h-6 w-6 cursor-pointer" icon={faSearchDollar} />
                                                        </span>
                                                        <span onClick={() => { setIglesiaSeleccionada(item.id_iglesia); setIsIglesiaSeleccionado(true) }}
                                                            title={`Editar la iglesia`} className="filas-lista-nw cursor-copy" >
                                                            <PencilSquareIcon className="cursor-pointer h-6 w-6" icon={faEdit} />
                                                        </span>
                                                    </div>
                                                </td>
                                            </tr>
                                            {iglesiaSeleccionadaIngresos && iglesiaSeleccionadaIngresos == item.id_iglesia &&
                                                <tr>
                                                    <FilaConcidional finalizarIngresosIgl={finalizarIngresosIgl} iglesia={item} />
                                                </tr>
                                            }
                                        </>
                                    );
                                },
                            )}
                    </tbody>
                </table>

                {copiaRegistrosaMostrar.length > 0 &&
                    <PiePagina>
                        <div className="items-center">
                            <Paginacion configuracion={configuracion} paginar={paginar} />
                        </div>
                    </PiePagina>}
            </CardBody>
        </Card>
    </>
}

const FilaConcidional = ({ finalizarIngresosIgl, iglesia }) => {
    return <td colSpan="11" className='text-center'>
        <div className='flex justify-center'>
            <div className="flex gap-6 f-row justify-center">
                <BalancesYdiezmos id_iglesia={iglesia.id_iglesia} />
                <IngresosIgl id_iglesia={iglesia.id_iglesia} finalizar={finalizarIngresosIgl} />
            </div>
            <span onClick={finalizarIngresosIgl}
                title={`Cerrar`} >
                <FontAwesomeIcon className="cursor-pointer text-red-300 !mt-4 !ml-4" icon={faWindowClose} />
            </span>
        </div>
    </td>
}

const ThTitle = ({ title, sort, orden, callback }) => {
    return <div className='flex f-col justify-center items-center'>
        <Typography
            variant="small"
            color="blue-gray"
            className="flex justify-between gap-2 font-normal leading-none opacity-70"
        >
            {title}{" "}
            {sort &&
                <ChevronUpDownIcon strokeWidth={2} className={`cursor-pointer ${orden ? 'text-blue-500' : ''} h-4 w-4`} icon={faSort} onClick={callback} />
            }
        </Typography>
    </div>
}