import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Main, Axios, Loading, uuidv4, usePaginacion, Paginacion } from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-regular-svg-icons';
import { faSyncAlt, faFilePdf, faWindowClose, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import GoBack from '../componentes/GoBack';
import { CSVLink, CSVDownload } from "react-csv";
import ImpresionPDF from '../componentes/ImpresionPDF';
import { useContextoGlobal } from '../Context/contextoGlobal';
import { formatoMoneda, hacerfocoEnPrimerInput, seleccionarTextoInput } from '../Helpers/utilidades-globales';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import { formatearComparacion, formatearMontoMoneda } from '../Helpers/utilidades-globales';
import { obtenerFechaPrimerDiaMesSiguiente, obtenerFechaDiamenosN, obtenerFechaUltimoDiaMes } from '../Helpers/fechas';
import { imprimir as imprimirEgresos } from '../impresiones/egresos';
import { imprimir as imprimirEcronologicos } from '../impresiones/ecronologicos';
import { imprimir as imprimirResumen } from '../impresiones/resumenegresos';
import { imprimir as imprimirHistorialAportes } from '../impresiones/historialaportes';
import { imprimir as imprimirMesesDiezmados } from '../impresiones/mesesdiezmados';
import { Loader } from '../componentes/Loader';
import Logo from '../componentes/Logo';
import { Button, Card, CardBody, Input, Spinner, Typography } from '@material-tailwind/react';
import { ArrowDownTrayIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import ExportToExcel from './exportToExcel';
const moment = require('moment');

/*import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;*/

const anchoPaginacion = 200;

const claves_fondo = [
    {
        nombre: '',
        estiloDiv: 'flex f-col cursor-pointer',
        estiloSpan: 'mt-2 p-2 fw-700 bg-steelblue text-white',
        callbackFiltro: ['total'],
        etiquetasFiltro: ['Total'],
        botonDesplegar: true,
        desplegar: false
    },
    {
        nombre: 'fondo',
        estiloDiv: 'flex f-col cursor-pointer ml-2',
        estiloSpan: 'mt-2 p-2 fw-700',
        callbackFiltro: ['fondo'],
        etiquetasFiltro: ['Fondo'],
        botonDesplegar: true,
        desplegar: false
    },
    {
        nombre: 'año', estiloDiv: 'flex f-col ml-4 cursor-pointer bg-lgray',
        estiloSpan: 'mt-2 fw-700',
        callbackFiltro: ['año', 'fondo'],
        etiquetasFiltro: ['Año', 'Fondo'],
        botonDesplegar: true,
        desplegar: false
    },
    {
        nombre: 'mes',
        estiloDiv: 'flex f-col ml-6 cursor-pointer',
        estiloSpan: 'mt-2 fw-700',
        callbackFiltro: ['mes', 'año', 'fondo'],
        etiquetasFiltro: ['Mes', 'Año', 'Fondo'],
        botonDesplegar: false,
        desplegar: false
    }
]

const claves_proveedor = [
    {
        nombre: '',
        estiloDiv: 'flex f-col cursor-pointer',
        estiloSpan: 'mt-2 p-2 fw-700 bg-steelblue text-white',
        callbackFiltro: ['total'],
        etiquetasFiltro: ['Total'],
        botonDesplegar: true,
        desplegar: false
    },
    {
        nombre: 'proveedor',
        estiloDiv: 'flex f-col cursor-pointer ml-2',
        estiloSpan: 'mt-2 p-2 fw-700',
        callbackFiltro: ['proveedor'],
        etiquetasFiltro: ['Proveedor'],
        botonDesplegar: true,
        desplegar: false
    },
    {
        nombre: 'año', estiloDiv: 'flex f-col ml-4 cursor-pointer bg-lgray',
        estiloSpan: 'mt-2 fw-700',
        callbackFiltro: ['año', 'proveedor'],
        etiquetasFiltro: ['Año', 'Proveedor'],
        botonDesplegar: true,
        desplegar: false
    },
    {
        nombre: 'mes',
        estiloDiv: 'flex f-col ml-6 cursor-pointer',
        estiloSpan: 'mt-2 fw-700',
        callbackFiltro: ['mes', 'año', 'proveedor'],
        etiquetasFiltro: ['Mes', 'Año', 'Proveedor'],
        botonDesplegar: false,
        desplegar: false
    }
]

const claves_caja = [
    {
        nombre: '',
        estiloDiv: 'flex f-col cursor-pointer',
        estiloSpan: 'mt-2 p-2 fw-700 bg-steelblue text-white',
        callbackFiltro: ['total'],
        etiquetasFiltro: ['Total'],
        botonDesplegar: true,
        desplegar: true
    },
    {
        nombre: 'caja',
        estiloDiv: 'flex f-col cursor-pointer ml-2',
        estiloSpan: 'mt-2 p-2 fw-700',
        callbackFiltro: ['caja'],
        etiquetasFiltro: ['Caja'],
        botonDesplegar: true,
        desplegar: false
    },
    {
        nombre: 'año', estiloDiv: 'flex f-col ml-4 cursor-pointer bg-lgray',
        estiloSpan: 'mt-2 fw-700',
        callbackFiltro: ['año', 'caja'],
        etiquetasFiltro: ['Año', 'Caja'],
        botonDesplegar: true,
        desplegar: false
    },
    {
        nombre: 'mes',
        estiloDiv: 'flex f-col ml-6 cursor-pointer',
        estiloSpan: 'mt-2 fw-700',
        callbackFiltro: ['mes', 'año', 'caja'],
        etiquetasFiltro: ['Mes', 'Año', 'Caja'],
        botonDesplegar: false,
        desplegar: false
    }
]

const claves_rubro = [
    {
        nombre: '',
        estiloDiv: 'flex f-col cursor-pointer',
        estiloSpan: 'mt-2 p-2 fw-700 bg-steelblue text-white',
        callbackFiltro: ['total'],
        etiquetasFiltro: ['Total'],
        botonDesplegar: true,
        desplegar: true,
        ordenar: false
    },
    {
        nombre: 'rubro',
        estiloDiv: 'flex f-col cursor-pointer ml-2',
        estiloSpan: 'mt-2 p-2 fw-700 bg-tomato text-white',
        callbackFiltro: ['rubro'],
        etiquetasFiltro: ['Rubro'],
        botonDesplegar: true,
        desplegar: false,
        ordenar: true
    },
    {
        nombre: 'año', estiloDiv: 'flex f-col ml-4 cursor-pointer bg-lgray',
        estiloSpan: 'mt-2 fw-700',
        callbackFiltro: ['año', 'rubro'],
        etiquetasFiltro: ['Año', 'Rubro'],
        botonDesplegar: true,
        desplegar: false,
        ordenar: false
    },
    {
        nombre: 'mes',
        estiloDiv: 'flex f-col ml-6 cursor-pointer',
        estiloSpan: 'mt-2 fw-700',
        callbackFiltro: ['mes', 'año', 'rubro'],
        etiquetasFiltro: ['Mes', 'Año', 'Rubro'],
        botonDesplegar: false,
        desplegar: false,
        ordenar: false
    }
]

export default function ReportesEgresosIntegrados({ parametros,
    descripcion,
    claseshead,
    bodyTablaCustomizado,
    api,
    finalizarReporte,
    titulo,
    titulos,
    columnas,
    filtro,
    callbackCSV,
    callbackPDF,
    arrayPDF,
    orientacionPDF,
    clasesbody,
    excel,
    mostrarResumenLateral,
    isHistorial
}) {
    // Si el reporte requiere que la tabla tenga características particulares se manda ese body en bodyTablaCustomizado que es una función que se ubica dentro de un vector.map
    const [cargandoRegistros, setCargandoRegistros] = useState(false);
    const [huboError, setHuboError] = useState(false);
    const [datos, setDatos] = useState([]);
    const [registrosAmostrar, setRegistrosAmostrar] = useState([]);
    const [textoBusqueda, setTextoBusqueda] = useState('');
    const { usuario, esCelular } = useContextoGlobal();
    const [contadorConsulta, setContadorConsulta] = useState(0);
    const { configuracion, paginar, inicializar } = usePaginacion();
    const [callbackFilter, setCallbackFilter] = useState(() => (item) => item)

    registerLocale('es', es)

    useEffect(() => {
        buscarDatos()
    }, [])

    useEffect(() => {
        setRegistrosAmostrar(datos)
    }, [datos])

    /*useEffect(()=>{
         const aux = [...datos]
         setRegistrosAmostrar(aux.filter(item=>
             formatearComparacion(item.rubro).includes(formatearComparacion(textoBusqueda)) ||
             formatearComparacion(item.codigo_rubro).includes(formatearComparacion(textoBusqueda))))
     },[textoBusqueda])
 */
    useEffect(() => {
        if (datos.length == 0) return

        const aux = [...datos]

        setRegistrosAmostrar(aux.filter(item =>
            formatearComparacion(item[filtro.campo1]).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item[filtro.campo2]).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item[filtro.campo3]).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item[filtro.campo4]).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item[filtro.campo5]).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item[filtro.campo6]).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item[filtro.campo7]).includes(formatearComparacion(textoBusqueda)) ||
            formatearComparacion(item[filtro.campo8]).includes(formatearComparacion(textoBusqueda)))
            .filter(callbackFilter))

    }, [textoBusqueda, callbackFilter])

    useEffect(() => {
        inicializar(anchoPaginacion, registrosAmostrar)
    }, [registrosAmostrar])

    const buscarDatos = async () => {

        try {
            setContadorConsulta(contadorConsulta + 1)
            setCargandoRegistros(true)
            //            const {data} = await Axios.get(`api/reportes/resumenegresos/4/${parametros.cant_meses_desde}/${parametros.cant_meses_hasta}/${parametros.promedio_desde}/${parametros.promedio_hasta}/${parametros.total_desde}/${parametros.total_hasta}/${convertirMesAñoAfechaDesde(parametros.fechaDesde)}/${convertirMesAñoAfechaHasta(parametros.fechaHasta)}`,
            const { data } = await Axios.get(api)
            isHistorial ? setDatos(data.sort((a, b) => { // ordenar historial segun fecha
                // Ordenar por periodo_año de mayor a menor
                if (b.periodo_año !== a.periodo_año) {
                    return b.periodo_año - a.periodo_año;
                }
                // Si los años son iguales, ordenar por periodo_mes de mayor a menor
                return b.periodo_mes - a.periodo_mes;
            })) : setDatos(data);
            setCargandoRegistros(false)
        } catch (err) {
            setCargandoRegistros(false)
            setHuboError(true)
            console.log('Error al ejecutar el reporte')
        }
    }

    if (huboError) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <p>Se produjo un error al cargar los datos para esta vista</p>
            </div>
        </Main>
    }

    if (cargandoRegistros) {
        return <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
            </div>
        </Main>
    };

    return (
        <Main center>
            <div className="flex f-row justify-left items-center pl-6">
                <GoBack titulo="" callback={finalizarReporte} />
                <p className="text-large">{titulo}</p>
            </div>
            <div className="flex f-col p-6">
                {(registrosAmostrar.length > 0 || (registrosAmostrar.length == 0 && textoBusqueda != "")) && <Cabecera
                    datos={registrosAmostrar}
                    callbackCSV={callbackCSV}
                    configuracion={configuracion}
                    texto={textoBusqueda}
                    setTexto={setTextoBusqueda}
                    titulo={titulo}
                    callbackPDF={callbackPDF}
                    arrayPDF={arrayPDF}
                    orientacionPDF={orientacionPDF}
                    descripcion={descripcion}
                    parametros={parametros}
                    excel={excel}
                    esCelular={esCelular}
                    isHistorial={isHistorial}
                />}
                {registrosAmostrar.length == 0 && <p className="color-red">No se encontraron registros</p>}
                {registrosAmostrar.length > 0 && <Tabla registrosAmostrar={registrosAmostrar}
                    configuracion={configuracion}
                    titulos={titulos}
                    bodyTablaCustomizado={bodyTablaCustomizado}
                    columnas={columnas}
                    parametros={parametros}
                    clasesbody={clasesbody}
                    claseshead={claseshead}
                    datos={datos}
                    paginar={paginar}
                    setCallbackFilter={setCallbackFilter}
                    mostrarResumenLateral={mostrarResumenLateral}
                    esCelular={esCelular}
                    isHistorial={isHistorial} />}
            </div>
        </Main>
    )
}


function Cabecera({ datos, texto, setTexto, configuracion, callbackCSV, titulo, callbackPDF, arrayPDF, orientacionPDF, descripcion, parametros, excel, esCelular, isHistorial }) {

    const [iniciarImprimirPDF, setIniciarImprimirPDF] = useState(false)
    const [totalFiltros, setTotalfiltros] = useState(0)


    useEffect(() => {

        const check = datos.filter(item => item.monto_numerico > 0).length > 0 // Comprobamos si el array de datos trae un campo monto_numerico

        if (check) {
            const totalSinFormato = datos.reduce((ac, item) => {
                return ac + Number(item.monto_numerico)
            }, 0)
            const total = formatearMontoMoneda(totalSinFormato)

            setTotalfiltros(total)
        } else {
            setTotalfiltros(null)
        }
    }, [datos])

    const datosCSV = datos.map(callbackCSV)

    const cerrarImpresionPDF = () => {
        setIniciarImprimirPDF(false)
    }

    const iniciarImpresionPDF = () => {
        if (iniciarImprimirPDF) {
            setIniciarImprimirPDF(false)
        } else {
            setIniciarImprimirPDF(true)
        }
    }

    const convertirFormato = (numero) => {
        let numeroConPunto = numero.replace(/,/g, '.');
        let numeroEnNuevoFormato = numeroConPunto.replace(/\.(?=[^.]*$)/, ',');
        return numeroEnNuevoFormato;
    }

    const totalAportes = () => {
        let totalAportes = 0;
        datos.map(item => {
            totalAportes = (totalAportes + item.monto);
        })
        return convertirFormato(totalAportes.toString());
    }

    const totalPromedioMeses = () => {
        let montoTotal = totalAportes();
        let fechaInicio = moment(parametros.fechaDesde);
        let fechaFin = moment(parametros.fechaHasta);

        // Calcular la cantidad exacta de meses entre las dos fechas
        let cantidadMeses = fechaFin.diff(fechaInicio, 'months', true);

        // Calcular el promedio del monto por mes
        let promedioPorMes = montoTotal / cantidadMeses;

        return promedioPorMes.toFixed();
    }

    return <div>
        {isHistorial && <Card className={`${esCelular ? '' : 'grid grid-cols-2 p-5'}`}>
            <div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Ministro:
                    </Typography>
                    <span className='mt-2 ml-2'>{!parametros.id_obrero || parametros.id_obrero == '-1' ? 'Todas' : datos[0].obrero}</span>
                </div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Iglesia:
                    </Typography>
                    <span className='mt-2 ml-2'>{!parametros.id_iglesia || parametros.id_iglesia == '-1' ? 'Todas' : datos[0].iglesia}</span>
                </div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Dirección:
                    </Typography>
                    <span className='mt-2 ml-2'>{!parametros.id_iglesia || parametros.id_iglesia == '-1' ? 'Todas' : datos[0].locacion}</span>
                </div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Región:
                    </Typography>
                    <span className='mt-2 ml-2'>{!parametros.id_region || parametros.id_region == '-1' ? 'Todas' : datos[0].region_obr}</span>
                </div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Distrito:
                    </Typography>
                    <span className='mt-2 ml-2'>{!parametros.id_distrito || parametros.id_distrito == '-1' ? 'Todas' : datos[0].distrito_igl}</span>
                </div>
            </div>
            <div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Promedio de meses aportados:
                    </Typography>
                    <span className='mt-2 ml-2'>{totalPromedioMeses()}</span>
                </div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Total de aportes en el período:
                    </Typography>
                    <span className='mt-2 ml-2'>{totalAportes()}</span>
                </div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Cantidad de meses aportados:
                    </Typography>
                    <span className='mt-2 ml-2'>{`Desde: ${moment(parametros.fechaDesde).format('MMM DD YYYY')} - Hasta: ${moment(parametros.fechaHasta).format('MMM DD YYYY')}`}</span>
                </div>
                <div className='flex text-left ml-1'>
                    <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mt-2"
                    >
                        Encargado:
                    </Typography>
                    <span className='mt-2 ml-2'>{!parametros.id_iglesia || parametros.id_iglesia == '-1' ? 'Todas' : datos[0].encargado}</span>
                </div>
            </div>
        </Card>}

        <div className={`${esCelular ? 'flex mt-2' : 'flex justify-end mt-4'}`}>
            <TextoInput onchange={(e) => setTexto(e.target.value)} placeHolder="Filtrar más..." texto={texto} limpiarTexto={() => setTexto("")} />
            {datos.length > 0 && <div>
                <Button variant="gradient" className="flex ml-2 items-center gap-3" onClick={iniciarImpresionPDF}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={2}
                        stroke="currentColor"
                        className="h-4 w-4/"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                        />
                    </svg>
                    Exportar PDF
                </Button>
                <div className="relative inline">
                    {iniciarImprimirPDF && <ImpresionPDF registrosAmostrar={datos} cerrar={cerrarImpresionPDF} impresion={parametros.tipo == 'cronologico' ? imprimirEcronologicos : parametros.tipo == 'historialaportes' ? imprimirHistorialAportes : parametros.tipo == 'mesesdiezmados' ? imprimirMesesDiezmados : imprimirResumen} callbackPDF={callbackPDF} arrayPDF={arrayPDF} orientacion={orientacionPDF} tituloDefault={descripcion} parametros={parametros} sinParametros />}
                </div>
                {/*<Excel datos={datos} descripcion={descripcion} columnas={excel} titulo={titulo} />*/}
            </div>}
            <ExportToExcel data={datos} fileName="Reporte de Historial de Aportante" parametros={parametros} />
        </div>
    </div>
}

function Tabla({ parametros,
    registrosAmostrar,
    configuracion,
    columnas,
    titulos,
    bodyTablaCustomizado,
    datos,
    clasesbody,
    paginar,
    setCallbackFilter,
    mostrarResumenLateral,
    esCelular,
    isHistorial
}) {

    const h1detalle = useRef(null)

    let TABLE_HEAD = [
        titulos.col1 || null,
        titulos.col2 || null,
        titulos.col3 || null,
        titulos.col4 || null,
        titulos.col5 || null,
        titulos.col6 || null,
        titulos.col7 || null,
        titulos.col8 || null,
    ];

    const NEW_TABLE_HEAD = TABLE_HEAD.slice(0, Object.values(titulos).length);

    return <Card className={`${esCelular ? 'overflow-scroll w-[22rem]' : 'w-full'} h-full p-2 mt-2 px-0 pt-0 pb-0`}>
        <CardBody>
            {/* {parametros.tipo=='cronologico' && mostrarResumenLateral && <div>
                        <div className='flex spaxe-x-2'>
                            <Resumen claves={claves_rubro} datos={datos} funcionFiltro = {setCallbackFilter} h1detalle={h1detalle} titulo='Rubros'/>
                        </div>
                    </div>    
                    }
                    {mostrarResumenLateral && <>
                    <div>
                        <div className='flex spaxe-x-2'>
                            <Resumen claves={claves_caja} datos={datos} funcionFiltro = {setCallbackFilter} h1detalle={h1detalle} titulo='Cajas'/>
                            <Resumen claves={claves_fondo} datos={datos} funcionFiltro = {setCallbackFilter} h1detalle={h1detalle} titulo='Fondos'/>
                            <Resumen claves={claves_proveedor} datos={datos} funcionFiltro = {setCallbackFilter} h1detalle={h1detalle} titulo='Proveedores'/>
                        </div>
                    </div>
                    </>}  */}
            <div className='flex f-col hidden-bg-tomato'>
                <p className='text-small' ref={h1detalle}></p>
                <table className="table-auto text-left" style={{ tableLayout: 'fixed' }}>
                    <thead>
                        <tr>
                            {NEW_TABLE_HEAD.map((head, index) => (
                                <th
                                    key={head}
                                    className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                                >
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                                    >
                                        {head}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {registrosAmostrar
                            .map((item, index) => {
                                return { ...item, indice: index + 1 }
                            })
                            .filter((item, index) => {
                                return index >= configuracion.iIni && index <= configuracion.iFin
                            })
                            //            .map(item=><p>hola</p>)
                            .map(bodyTablaCustomizado ? bodyTablaCustomizado : bodyTablaStandard(columnas, clasesbody, isHistorial))
                        }
                    </tbody>
                </table>
                <Paginacion configuracion={configuracion} paginar={paginar} />
            </div>
        </CardBody>
    </Card>
}

function TextoInput({ onchange, texto, limpiarTexto, nombre, placeHolder }) {

    return <div className="">
        <div>
            <Input
                type="text"
                label={placeHolder}
                onChange={onchange}
                value={texto}
                color='black'
            />
        </div>
    </div>
}

function bodyTablaStandard(columnas, clasesbody, isHistorial) {
    // devuelve una función que se ejecuta dentro del vector.map
    return item => {
        return (
            <tr className="space-x-2" key={uuidv4()}>
                {columnas.col1 && <td classNamex={clasesbody.col1}>
                    {item[columnas.col1]} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                </td>}
                {columnas.col2 && <td classNamex={clasesbody.col2}>{item[columnas.col2]}</td>}
                {columnas.col3 && <td classNamex={clasesbody.col3}>{item[columnas.col3]}</td>}
                {columnas.col4 && <td className={!isHistorial ? 'text-right' : ''} classNamex={clasesbody.col4}>{item[columnas.col4]}</td>}
                {columnas.col5 && <td className={!isHistorial ? 'text-right' : 'text-right'} classNamex={clasesbody.col5}>{item[columnas.col5]}</td>}
                {columnas.col6 && <td className={!isHistorial ? 'text-right' : ''} classNamex={clasesbody.col6}>{item[columnas.col6]}</td>}
                {columnas.col7 && <td classNamex={clasesbody.col7}>{item[columnas.col7]}</td>}
                {columnas.col8 && <td classNamex={clasesbody.col8}>{item[columnas.col8]}</td>}
            </tr>
        )
    }
}

function bodyTablaStandardold(columnas, clasesbody) {
    // devuelve una función que se ejecuta dentro del vector.map
    return item => {
        return (
            <tr className="border-bottom-solid" key={uuidv4()}>
                <td className={clasesbody.col1}>
                    {item[columnas.col1]} {/*Recordar que puedo referenciar a un objeto como objeto.campo o objeto['nombre_campo']*/}
                </td>
                <td className={clasesbody.col2}>{item[columnas.col2]}</td>
                <td className={clasesbody.col3}>{item[columnas.col3]}</td>
                <td className={clasesbody.col4}>{item[columnas.col4]}</td>
                <td className={clasesbody.col5}>{item[columnas.col5]}</td>
                <td className={clasesbody.col6}>{item[columnas.col6]}</td>
                <td className={clasesbody.col7}>{item[columnas.col7]}</td>
                <td className={clasesbody.col8}>{item[columnas.col8]}</td>
            </tr>
        )
    }
}

function Excel({ datos, descripcion, columnas, titulo }) {

    const copia = [agregarFila(datos, descripcion), ...datos]

    return '' /*<ExcelFile filename={titulo} element={
        <span className="cursor-pointer botonNc ml-6" >
            <FontAwesomeIcon className="color-tomato" icon={faFileExcel}/> Exportar a Excel
        </span> 
    }>
        <ExcelSheet data={copia} name="Egresos">
            {columnas.map(item=>{
                 return <ExcelColumn label={item.label} value={item.value}/>
            })}
        </ExcelSheet>
</ExcelFile>*/
}


function agregarFila(datos, descripcion) {
    return {
        ...datos[0],
        rubro: descripcion,
        mes_largo: null,
        año: null,
        egreso_numerico: null,
        codigo_rubro: null
    }
}

const Resumen = ({ datos, funcionFiltro, h1detalle, titulo, claves }) => {

    // return <div className='flex f-col' style={{height:'',marginTop:'-10rem',overflow:'scroll'}}>
    return <DetallesEnNiveles funcionFiltro={funcionFiltro}
        referencia={h1detalle}
        datos={datos}
        clave={claves[0]}
        claves={claves}
        titulo={titulo}
        index={0}
        complementoTitulo='' />
    //   </div>  
}

const DetallesEnNiveles = ({ datos, clave, claves, index, complementoTitulo, funcionFiltro, referencia, titulo }) => {

    const [mostrar, setMostrar] = useState([])

    const [vectorValoresDeLaClave, setVectorValoresDeLaClave] = useState([])
    const [flag, setFlag] = useState(false)

    //el vector datos trae un conjunto de registros en donde buscar la clave e identificar los
    // N valores diferentes para la misma clave. Para ello usamos un set 

    // Creamos un set que va a almacenar las diferentes valores 1 sola vez
    const clavesset = new Set()
    const clavesMap = new Map()

    datos.forEach(item => {
        clavesset.add(item[clave.nombre])

        clave.callbackFiltro.forEach(claveFiltro => {
            clavesMap.set(claveFiltro, item[claveFiltro])
        })
    })

    useEffect(() => {
        const vector_de_valores = Array.from(clavesset)

        if (clave.desplegar) {
            setMostrar([...vector_de_valores])
        }
        // Convertimos el set en un array para manipularlo
        setVectorValoresDeLaClave(vector_de_valores)
        //const vectorValoresMap = JSON.stringify(Object.fromEntries(clavesMap))

    }, [])

    useEffect(() => {
        if (vectorValoresDeLaClave.length > 0) {
            setFlag(true)
        }
    }, [vectorValoresDeLaClave])


    const agregarItem = (valor) => {
        setMostrar([...mostrar, valor])
    }

    const eliminarItem = (valor) => {
        const delta = mostrar.filter(item => item != valor)
        setMostrar(delta)
    }

    const gestionarFiltro = (valor) => {

        const detalle = `${clave.etiquetasFiltro[0]} ${valor ? valor : ''} ${clave.callbackFiltro[1] ? `${clave.etiquetasFiltro[1]} ${clavesMap.get(clave.callbackFiltro[1])}` : ''} ${clave.callbackFiltro[2] ? `${clave.etiquetasFiltro[2]} ${clavesMap.get(clave.callbackFiltro[2])}` : ''}`
        referencia.current.textContent = detalle

        const callback = () => (item) => {
            if (clave.callbackFiltro[0] == 'total') {
                return item
            }
            return item[clave.callbackFiltro[0]] == valor && (item[clave.callbackFiltro[1]] == clavesMap.get(clave.callbackFiltro[1]) || !(clave.callbackFiltro[1]))
                && (item[clave.callbackFiltro[2]] == clavesMap.get(clave.callbackFiltro[2]) || !(clave.callbackFiltro[2]))
        }

        // alert(JSON.stringify(Object.fromEntries(clavesMap)))
        funcionFiltro(callback)
    }


    // Vamos a volver a llamar recursivamente a este mismo componente hasta que no haya más claves en el vector claves
    // Para ello eliminamos el primer elemento para pasar las claves pendientes al componennte
    const proximasClaves = claves.slice(1)

    if (!flag) {
        return null
    }

    return <div className='flex f-col det-niv'>
        <p className='text-small fw-700'>{titulo}</p>
        {vectorValoresDeLaClave.sort((a, b) => clave.ordenar ? a.localeCompare(b) : null).map(valor => <>
            <div >
                <div className={clave.estiloDiv} onClick={() => gestionarFiltro(valor)}>

                    <div className='flex'>
                        <span className={clave.estiloSpan}>
                            {!clave.nombre ? 'Total' : clave.nombre == 'mes' ? `${mesString(valor)} ${complementoTitulo}` : `${valor} ${complementoTitulo}`}
                        </span>
                        {clave.botonDesplegar && <>
                            {!mostrar.some(item => valor == item) && <button title='Expandir' onClick={() => agregarItem(valor)}><span className='text-large'>+</span></button>}
                            {mostrar.some(item => valor == item) && <button title='Contraer' onClick={() => eliminarItem(valor)}><span className='text-large'>-</span></button>}
                        </>}
                    </div>

                    <span>
                        {
                            formatearMontoMoneda(datos.filter(item => item[clave.nombre] == valor).reduce((ac, item) => {
                                return ac + item.monto_numerico
                            }, 0))
                        }
                    </span>
                </div>

                {proximasClaves.length > 0 && mostrar.some(item => valor == item) && <DetallesEnNiveles index={index + 1}
                    datos={datos.filter(item => item[clave.nombre] == valor)}
                    clave={proximasClaves[0]}
                    claves={proximasClaves}
                    complementoTitulo=''
                    referencia={referencia}
                    funcionFiltro={funcionFiltro} />}
            </div>
        </>)}
    </div>
}

const mesString = (mes) => {
    var meses = [{ id: 1, mes: 'Enero' },
    { id: 2, mes: 'Febrero' },
    { id: 3, mes: 'Marzo' },
    { id: 4, mes: 'Abril' },
    { id: 5, mes: 'Mayo' },
    { id: 6, mes: 'Junio' },
    { id: 7, mes: 'Julio' },
    { id: 8, mes: 'Agosto' },
    { id: 9, mes: 'Septiembre' },
    { id: 10, mes: 'Octubre' },
    { id: 11, mes: 'Noviembre' },
    { id: 12, mes: 'Diciembre' }];

    return meses.filter(item => item.id == mes).map(item => item.mes)
}
