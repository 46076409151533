import React, {useContext,useEffect,useState} from 'react'
import {TextField, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import {Listado} from '../componentes/Listado'
import {CuponesContextProvider} from '../contexto/CuponesContext'
import Axios from 'axios';
import { Typography } from '@material-tailwind/react';

export const Cupones = ()=>{
    
    return <CuponesContextProvider>
                <Typography variant="h4" color="black" className="flex justify-center items-center">
                        Cupones
                </Typography> 
                <Box> 
                        <Listado/>
                </Box>   
            </CuponesContextProvider> 
}

const Ejemplo = ()=>{

const [datos,setDatos]=useState([])

useEffect(()=>{
    traerDatos()
},[])

const traerDatos = async ()=>{
    try{
        const {data} = await Axios.get('http://localhost:3020/api/tablasgenerales/provincias')
        setDatos(data)
    }catch(err){
        alert(err)
    }
}

    if(datos.length>0){
        return <div>
            {JSON.stringify(datos)}
        </div>
    }
    return <h1>ejemplo</h1>
}

