import React, { useState, useEffect, useRef } from 'react';
import { Axios, Main, Loading, FontAwesomeIcon } from '../../../Helpers/importacionesComunes'
import { faTrash, faPlusSquare, faFilePdf, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import ImpresionPDF from '../../../componentes/ImpresionPDF';
import { SeleccionadorX } from '../../../componentes/SeleccionadorX'
import { TextoInput } from '../../../componentes/TextoInput'
import { useTablasGenerales } from '../hooks/useTablasGenerales'
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Button, Input, Option, Select, Typography } from '@material-tailwind/react';

const estadosBalanceCustom = [{ id: '-1', nombre: 'Todos' }, { id: 'Si', nombre: 'Presentado' }, { id: 'No', nombre: 'No presentado' }]

export const Cabecera = ({ setCrearIglesia,
    toggle,
    periodosFiscales,
    periodoSeleccionado,
    handleChangeSelectPeriodos,
    setPeriodoSeleccionado,
    hayFiltrosActivos,
    filtrarLista, // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
    resetearLista // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
}) => {

    const { usuario, esCelular } = useContextoGlobal()
    const [textoNombre, setTextoNombre] = useState("");
    const [textoLocalidad, setTextoLocalidad] = useState("");
    const [textoPastor, setTextoPastor] = useState("");
    const [textoEncargado, setTextoEncargado] = useState("");
    const [estadoBalanceSeleccion, setEstadoBalanceSeleccion] = useState('-1')
    const [tipoIglesiaSeleccion, setTipoIglesiaSeleccion] = useState('-1')
    const [regionSeleccionada, setRegionSeleccionada] = useState(usuario?.id_region || '-1')
    const { regiones, tiposIglesias, rangos, distritos } = useTablasGenerales()
    const [rangoPastorSeleccion, setRangoPastorSeleccion] = useState('-1')
    const [distritoSeleccionado, setDistritoSeleccionado] = useState('-1')
    const [distritosRegion, setDistritosRegion] = useState([])

    useEffect(() => {

        const callback = (item) =>
            item.nombre_igl.toUpperCase().includes(textoNombre.toUpperCase())
            && (item.localidad.toUpperCase().includes(textoLocalidad.toUpperCase()) || item.provincia.toUpperCase().includes(textoLocalidad.toUpperCase()))
            && item.pastor.toUpperCase().includes(textoPastor.toUpperCase())
            && item.encargado.toUpperCase().includes(textoEncargado.toUpperCase())
            && ((item.tipo_iglesia == tipoIglesiaSeleccion && tipoIglesiaSeleccion != '-1') ||
                tipoIglesiaSeleccion == '-1')
            && ((item.id_region == regionSeleccionada && regionSeleccionada != '-1') ||
                regionSeleccionada == '-1')
            && ((item.id_distrito == distritoSeleccionado && distritoSeleccionado != '-1') ||
                distritoSeleccionado == '-1')
            && ((item.rangoPastor == rangoPastorSeleccion && rangoPastorSeleccion != '-1') ||
                rangoPastorSeleccion == '-1')
            && ((item.balance == estadoBalanceSeleccion && estadoBalanceSeleccion != '-1') ||
                estadoBalanceSeleccion == '-1')

        // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        filtrarLista(callback)

    }, [textoEncargado,
        textoLocalidad,
        textoNombre,
        textoPastor,
        tipoIglesiaSeleccion,
        estadoBalanceSeleccion,
        regionSeleccionada,
        distritoSeleccionado,
        rangoPastorSeleccion])

    useEffect(() => {
        if (regionSeleccionada == -1) { // si es perfil sede usa distritosRegion en el select, si es perfil distrito o region usa distrito en el select que ya viene filtrado del hook
            setDistritoSeleccionado('-1')
            setDistritosRegion([])
        } else {
            setDistritosRegion(distritos.filter(item => item.id_distrito > 0).filter(item => item.id_region == regionSeleccionada))
        }
    }, [regionSeleccionada])

    useEffect(() => {
        if (distritosRegion.length > 0) {
            //  setDistritoSeleccionado(distritosRegion[0].id_distrito)
            setDistritoSeleccionado('-1')
        }
    }, [distritosRegion])

    const handleChangeInputNombre = (e) => {
        setTextoNombre(e.target.value)
    }

    const handleChangeInputLocalidad = (e) => {
        setTextoLocalidad(e.target.value)
    }

    const handleChangeInputPastor = (e) => {
        setTextoPastor(e.target.value)
    }

    const handleChangeInputEncargado = (e) => {
        setTextoEncargado(e.target.value)
    }

    const handleChangeSelectEstadoBalance = (e) => {
        setEstadoBalanceSeleccion(e.target.value)
    }

    const handleChangeSelectRegion = (e) => {
        setRegionSeleccionada(e.target.value)
    }

    const handleChangeSelectTipoIgl = (e) => {
        setTipoIglesiaSeleccion(e.target.value)
    }

    const handleChangeSelectRango = (e) => {
        setRangoPastorSeleccion(e.target.value)
    }

    const limpiarNombre = () => {

        setTextoNombre("")

    }


    const limpiarPastor = () => {

        setTextoPastor("")

    }

    const limpiarEncargado = () => {

        setTextoEncargado("")

    }

    const limpiarLocalidad = () => {

        setTextoLocalidad("")

    }

    const limpiarTipoIglesia = () => {

        setTipoIglesiaSeleccion(-1)

    }

    const limpiarRegion = () => {

        setRegionSeleccionada(-1)

    }

    const limpiarDistrito = () => {

        setDistritoSeleccionado(-1)

    }

    const limpiarEstadoBalance = () => {

        setEstadoBalanceSeleccion(-1)

    }

    const limpiarRangos = () => {
        setRangoPastorSeleccion(-1)
    }

    const handleChangeSelectDistrito = (e) => {
        setDistritoSeleccionado(e.target.value)
    }

    const limpiarFiltros = () => {
        setTextoNombre("")
        setTextoLocalidad("")
        setTextoEncargado("")
        setTextoPastor("")
        setTipoIglesiaSeleccion(-1)
        setEstadoBalanceSeleccion(-1)
        setRegionSeleccionada(-1)
        setRangoPastorSeleccion(-1)
        setDistritoSeleccionado(-1)
        setPeriodoSeleccionado(periodosFiscales[0].id_año_fiscal)
        resetearLista()
    }

    return <div className='flex f-col mb-6'>
        <div className={`flex ${esCelular ? 'f-col space-y-2' : 'grid grid-cols-5 gap-4'} justify-center flex-wrap abc mb-2 select-ui-data`}>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Nombre Iglesia
                </Typography>
                <Input
                    onChange={handleChangeInputNombre}
                    value={textoNombre}
                    name="texto-nombre"
                    color='black'
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Pastor
                </Typography>
                <Input
                    onChange={handleChangeInputPastor}
                    name="texto-pastor"
                    color='black'
                    value={textoPastor}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Encargado
                </Typography>
                <Input
                    onChange={handleChangeInputEncargado}
                    name="texto-encargado"
                    color='black'
                    value={textoEncargado}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Ubicación
                </Typography>
                <Input
                    onChange={handleChangeInputLocalidad}
                    name="texto-localidad"
                    color='black'
                    value={textoLocalidad}
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                        className: "before:content-none after:content-none",
                    }}
                />
            </div>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Tipo de iglesia
                </Typography>
                <SeleccionadorXLabel titulo='Tipo de iglesia' vector={tiposIglesias} onchange={handleChangeSelectTipoIgl}
                    valor={tipoIglesiaSeleccion} nombre='Todos' limpiar={limpiarTipoIglesia} />
            </div>
        </div>

        <div className={`flex ${esCelular ? 'f-col space-y-2' : 'grid grid-cols-5 gap-4'} justify-center flex-wrap abc mb-2 select-ui-data`}>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Estado del balance
                </Typography>
                <SeleccionadorXLabel titulo='Estado del balance' vector={estadosBalanceCustom} onchange={handleChangeSelectEstadoBalance}
                    valor={estadoBalanceSeleccion} nombre='Todos' limpiar={limpiarEstadoBalance} noDefault claves={{ id: 'id', nombre: 'nombre' }} />
            </div>
            {usuario.perfil == 'sede' &&
                <div className="flex flex-col">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Región
                    </Typography>
                    <SeleccionadorXLabel titulo='Región' vector={regiones} onchange={handleChangeSelectRegion} claves={{ id: 'id_region', nombre: 'nombre' }}
                        valor={regionSeleccionada} nombre='Todas' noDefault={!!usuario.id_region} limpiar={usuario.id_region ? false : limpiarRegion} />
                </div>
            }
            {usuario.perfil != 'distrito' &&
                <div className="flex flex-col">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Distrito
                    </Typography>
                    <SeleccionadorXLabel titulo='Distrito' vector={usuario.id_region ? distritos : distritosRegion} onchange={handleChangeSelectDistrito} claves={{ id: 'id_distrito', nombre: 'nombre' }}
                        valor={distritoSeleccionado} nombre='Todos' NO_VA_noDefault={!!usuario.id_region} limpiar={usuario.id_region ? false : limpiarDistrito} />
                </div>
            }
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-1 font-medium"
                >
                    Año fiscal
                </Typography>
                <SeleccionadorXLabel titulo=' Año fiscal' vector={periodosFiscales} onchange={handleChangeSelectPeriodos} claves={{ id: 'id_año_fiscal', nombre: 'nombre_corto' }}
                    valor={periodoSeleccionado} nombre='Todos' noDefault />
            </div>
            <div className="flex flex-col">
                <Typography
                    variant="small"
                    color="blue-gray"
                    className="mb-1 font-medium "
                >
                    Rango del Pastor
                </Typography>
                <SeleccionadorXLabel titulo='Rango del pastor' vector={rangos} onchange={handleChangeSelectRango}
                    valor={rangoPastorSeleccion} nombre='Todos' limpiar={limpiarRangos} />
            </div>
        </div>
        <div className="mt-2 flex md:justify-end">
            <Button variant='text' className='text-red-400 bg-red-100 hover:bg-red-200' onClick={limpiarFiltros}>Limpiar Filtros</Button>
        </div>
    </div>
}


const SeleccionadorXLabel = ({ vector, onchange, valor, nombre, limpiar, claves, titulo, noDefault }) => {

    return <div className=''>
        <SeleccionadorX vector={vector}
            onchange={onchange}
            valor={valor}
            nombre={nombre}
            claves={claves}
            limpiar={limpiar}
            noDefault={noDefault ? true : false}
        />
    </div>
}