import React, {useContext,useEffect,useState} from 'react'
import {TextField, Box ,InputAdornment,Grid,InputLabel,MenuItem,FormControl,Radio,FormLabel} from "@material-ui/core";
import { cuponesContext } from '../contexto/CuponesContext'
import {Fecha} from '../../../componentes/MUI/Fecha'
import Divider from '@mui/material/Divider';
import {Alert} from '@mui/material'
import { Button, Input, Option, Select, Textarea, Typography } from '@material-tailwind/react';

export const Cupon = ()=>{
    const {cupon,handleChangeFechaFin,crearCupon,
        handleChangeObservaciones,handleChangeDocumento,handleChangeEmail,handleChangeCantidad,
        handleChangeImporte,formatearMontoBlur,formatearMontoFoco,importeFormateado,
        handleChangeEventoValor,reiniciar,handleChangeEvento,eventos,eventosValor,datosOk
    } = useContext(cuponesContext)

    const [precio,setPrecio] = useState(null)

    useEffect(()=>{ 
        reiniciar()
    },[])

    useEffect(()=>{
            
            if(cupon?.id_evento_valor>0){
                const item = eventosValor.filter(item=>item.id_evento_valor==cupon.id_evento_valor)
                setPrecio(item[0].valor)
            }

    },[cupon.id_evento_valor])
//    return <Box sx={{display:'flex',alignSelf:'start',flexDirection:'column',alignItems:'left',marginTop:'1rem', marginBottom:'1rem' }}> 
          return <Box>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
                    <div className="flex flex-col select-ui-data">
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-1 font-medium "
                        >
                            Evento
                        </Typography>
                        <Select 
                            id="sl-evento"
                            label="Seleccione un evento" 
                            value={cupon.id_evento}	
                            onChange={handleChangeEvento}
                        >
                            {eventos.map((item, index) => (
                                <Option key={item.id_evento} value={item.id_evento}>
                                    {item.titulo}
                                </Option>
                            ))}
                        </Select>
                    </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                <div className="flex flex-col select-ui-data">
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-1 font-medium "
                        >
                            Item del evento {precio? `$ ${precio}` : ''}
                        </Typography>
                        <Select 
                            id="sl-eventovalor"
                            label="Seleccione un evento" 
                            value={cupon.id_evento_valor}	
                            onChange={handleChangeEventoValor}
                        >
                            {eventosValor.map((item, index) => (
                                <Option key={item.id_evento_valor} value={item.id_evento_valor}>
                                    {item.detalle}
                                </Option>
                            ))}
                        </Select>
                </div>
            </Grid>
          </Grid>  
          {/*<Grid container>
                <Grid item xs={12} sm={6}>
                    <Box sx={{marginTop:'0.5rem'}}>
                        <Fecha disabled titulo='Fecha de expiración' fecha={cupon.fecha_fin} setFecha={handleChangeFechaFin}/>      
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box sx={{alignItems:'center',color:'red',height:'100%',display:'flex',justifyContent:'center'}}>
                        <Typography variant='caption' >El cupón expira en 10 días</Typography>
                    </Box>
                </Grid>
          </Grid>*/}
          <Grid container>
                <div className="w-full mt-2">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        E-mail del solicitante
                    </Typography>
                    <Input
                        id="con-email" 
                        type="email"
                        maxLength={200}
                        onChange={handleChangeEmail} 
                        value={cupon.email} 
                        name="texto-localidad" 
                        color='black'
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
          </Grid>
          <Grid container>
                <div className="w-full mt-2">
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Nro. de documento del solicitante
                    </Typography>
                    <Input
                        id="dp-documento" 
                        type="text"
                        maxLength={9}
                        onChange={handleChangeDocumento} 
                        value={cupon.nro_documento} 
                        name="texto-localidad" 
                        color='black'
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                </div>
          </Grid>
          <Grid container>
                <div className="flex f-col mt-3 w-full">
                    <Textarea 
                        id="con-observaciones" 
                        maxLength="500"
                        color="gray"
                        rows={cupon.observaciones.trim().length > 70 ? 3 : 2}
                        label="Motivo del cupón" 
                        value={cupon.observaciones} 
                        onChange={handleChangeObservaciones} 
                    />
                </div>
          </Grid>          
          <Grid container>
                <Grid item xs={12}>
                    <Box sx={{marginTop:'1rem',marginRight:'auto',marginLeft:'auto', maxWidth:'280px',border:'solid 1px black',borderRadius:'5px',padding:'0.5rem'}}>
                        <div className="flex flex-col select-ui-data">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Cantidad de personas
                            </Typography>
                            <Select 
                                id="sl-cantidad"
                                label="Cantidad de personas"
                                onChange={handleChangeCantidad}
                            >
                                {vectorNumeros().map((item, index) => (
                                    <Option key={index} value={item}>
                                        {item}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                        <Divider sx={{marginTop:'0.5rem',marginBottom:'0.5rem'}}/>

                        <div className="w-full mt-2">
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 font-medium "
                            >
                                Importe total: {importeFormateado}
                            </Typography>
                            <Input
                                id="dp-monto"  
                                type="email"
                                maxLength={25}
                                value={cupon.importe} 
                                InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                color='black'
                                onBlur={formatearMontoBlur}
                                onFocus={formatearMontoFoco}
                                onChange={handleChangeImporte}
                                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                                labelProps={{
                                    className: "before:content-none after:content-none",
                                }}
                            />
                        </div>
                    </Box>
                </Grid>
          </Grid> 
          {datosOk()[0] && <Box sx={{marginTop:'2rem',display:'flex',justifyContent:'center'}}>  
               <Button onClick={crearCupon} variant="gradient" size='lg'>
                    Crear el cupón
               </Button>    
          </Box>}
          {!datosOk()[0] && <Box sx={{marginRight:'auto',marginLeft:'auto',marginTop:'2rem',display:'flex',justifyContent:'center',maxWidth:'300px'}}> 
                <Alert variant="outlined" severity="error">
                                {datosOk()[1]}
                </Alert>
          </Box>}
  </Box>   
}

const vectorNumeros = ()=>{
    let array = []

    for(let i=0;i<100;i++){
        array.push(i+1)
    }

    return array
}

