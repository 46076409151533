import React, { useState,useRef } from 'react';
import {Main} from '../Helpers/importacionesComunes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import {
    IdentificationIcon,
    LockClosedIcon
} from "@heroicons/react/24/solid";
import {
    Card,
    Input,
    Button,
    Typography
} from "@material-tailwind/react";
import Logo from '../componentes/Logo';

export default function Login({login, mostrarError, error}){

    // la interface del usuario depende del estado, en este caso consiste solo de un objeto usuario. Cada vez que cambia el estado se vuelve a renderizar la interface
const [datosLogin,setdatosLogin] = useState({username:'',password:''})    

const [verPassword,setVerPassword] = useState('password')

    const handleInputChange = (e)=>{  // defino una función que va a escuchar los cambios que ocurren en los inputs. Agrego el listener con onChange
        setdatosLogin({...datosLogin,[e.target.name]:e.target.value})
    }

    async function handleSubmit(e) {
        e.preventDefault();
    
        try {
          await login(datosLogin.username, datosLogin.password);
        } catch (err) {
            mostrarError(err);
        }
      }


    return ( // envuelvo un contenido X con un componente Main 
    <Main center>  
        <div className="flex justify-center mt-20">
            <Typography variant="h4" color="blue-gray">
                Sistema Administrativo - UAD
            </Typography>
        </div>
        <div className="h-screen mt-10">
            <Card className="FormContainer border-[#EBEBEB] border-[1px] login !w-[670px] shadow-lg bg-white">
                <div className='flex justify-center'>
                    <Logo/>
                </div>
                <p className="FormContainer__info mt-8">Login integrado</p>
                <form onSubmit={handleSubmit}>
                    {/* Tengo conectado el input email con el estado usuario.email a través del atributo value y del evento onChange */}
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 font-medium "
                    >
                        Usuario
                    </Typography>
                    <Input
                        onChange={handleInputChange} 
                        icon={
                            <IdentificationIcon className="absolute left-0 h-4 w-4 text-blue-gray-300" />
                        }
                        name="username" 
                        color='black'
                        placeholder="Usuario" 
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }}
                    />
                    <div className='relative'>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-1 font-medium mt-3"
                        >
                            Contraseña
                        </Typography>
                        <Input
                            type={verPassword} 
                            onChange={handleInputChange} 
                            color='black' 
                            name="password" 
                            placeholder="Contraseña" 
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }}
                        />
                        <FontAwesomeIcon 
                            icon={faEye}
                            color='gray' 
                            className='absolute right-[12px] top-[36px] cursor-pointer'
                            onClick={()=> verPassword == 'password' ? setVerPassword(''):setVerPassword('password')}
                        />
                    </div>
                    <Button className="Form__submit mt-6 mb-[-20px] bg-[#111] shadow-none hover:shadow-none hover:opacity-90" type="submit">Ingresar</Button>
                </form>
                {<span className="error_formulario mt-10 text-center">{error}</span>}
            </Card>
        </div>
    </Main>
    )
}