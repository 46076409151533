import React, {useContext,useEffect,useState} from 'react'
import {Button, Grid, Box ,Switch,FormGroup,FormControlLabel,Select,makeStyles ,InputLabel,MenuItem,FormControl,RadioGroup,Radio,FormLabel} from "@material-ui/core";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteIcon from '@mui/icons-material/Delete';

export const Acciones = ({status_actual_tramite,usuario,iniciarGrabar,eliminar,objestados,cabecera})=>{
  
    const useStyle = makeStyles({
        select: {
            background: 'white',
            color:'black'
        }
      });

    const classes = useStyle();

    if (cabecera.tipo=='afiliacion'){
        return <div> 
                    {status_actual_tramite=='Iniciado' 
                        && (usuario.perfil=="sede" && usuario?.rol_contabilidad && usuario?.rol_contabilidad > 50) 
                        &&   <div className='mt-2 mb-10 grid grid-cols-1 md:grid-cols-2 gap-2'>

                        <Button 
                            title="Como primer paso debe ingresar el trámite a la tesorería para que se tome en cuenta para su procesamiento" 
                            startIcon={<ArrowRightAltIcon/>} 
                            onClick={()=>iniciarGrabar({status:objestados.tesoreria.nombre})}>
                                        Ingresar a tesorería
                        </Button>
                        <Button 
                            title='Puede eliminar un trámite cuando el solicitante lo requiera a la secretaría siempre que se encuentre en estado Iniciado' 
                            startIcon={<DeleteIcon/>} 
                            onClick={()=>eliminar({id:cabecera._id,usuario:usuario.nombre,id_usuario:usuario.id_usuario,observaciones:'Eliminado desde módulo administrativo'})}>
                                            Eliminar
                        </Button>
                    </div>}
                 </div>
    }
    return <div> 
                {status_actual_tramite=='Iniciado' && (usuario.perfil=="region" || (usuario.perfil=="sede" && usuario.rol_sede>95)) && 
                <div className='mt-2 mb-10 grid grid-cols-1 md:grid-cols-2 gap-2'>
                    {cabecera.tipo!="nuevo_patrocinado" && 
                    <Button 
                        title="Como primer paso debe ingresar el trámite a la secretaría para que se tome en cuenta para su procesamiento" 
                        startIcon={<ArrowRightAltIcon/>} 
                        variant="contained"  
                        onClick={()=>iniciarGrabar({status:objestados.procRegion.nombre})}>
                                    Ingresar a secretaría regional 
                    </Button>}
                    <Button 
                        title='Puede eliminar un trámite cuando el solicitante lo requiera a la secretaría siempre que se encuentre en estado Iniciado' 
                        startIcon={<DeleteIcon/>} 
                        variant="contained"
                        onClick={()=>eliminar({id:cabecera._id,usuario:usuario.nombre,id_usuario:usuario.id_usuario,observaciones:'Eliminado desde módulo administrativo'})}>
                                        Eliminar
                    </Button>
                </div>}
           </div>
            
}

