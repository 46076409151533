import React, { useState, useEffect, useRef } from 'react';
import { Fecha } from '../../../componentes/Fecha'
import { esMayor, fechasEstaIncluida } from '../../../Helpers/fechas'
import { TextField, Box } from "@material-ui/core";
import { useContextoGlobal } from '../../../Context/contextoGlobal';
import { Option, Select, Typography } from '@material-tailwind/react';

export const Cabecera = ({ setCrearIglesia,
    toggle,
    filtrarLista,
    usuario,
    regiones,
    cuentas,
    handleDataChanges,
    dataValues,
    handleChangeSelectRegion,
}) => {

    const [textoSucursal, setTextoSucursal] = useState("");
    const [textoConcepto, setTextoConcepto] = useState("");
    const { esCelular } = useContextoGlobal();
    const estados = [{ 
        id_region: 'Libre', nombre: 'Libre' 
    }, 
    { 
        id_region: 'Asignado', nombre: 'Asignado' 
    },
    { 
        id_region: 'Fraccionado', nombre: 'Fraccionado' 
    },
    { 
        id_region: 'Anonimo', nombre: 'Anónimo' 
    }]

    useEffect(() => {

        const callback = (item) =>
            item.desc_sucursal.toUpperCase().includes(textoSucursal.toUpperCase())
            && item.concepto.toUpperCase().includes(textoConcepto.toUpperCase())
            && fechasEstaIncluida(dataValues.fecha_desde, dataValues.fecha_hasta, item.fecha)

        // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes

        if (esMayor(dataValues.fecha_desde, dataValues.fecha_hasta)) {
            alert('El orden de las fechas es incorrecto')
        } else {
            filtrarLista(callback)
        }

    }, [textoSucursal,
        textoConcepto, dataValues.fecha_desde, dataValues.fecha_hasta])
    
    const handleChangeInputSucursal = (e) => {

        setTextoSucursal(e.target.value)

    }

    const handleChangeInputConcepto = (e) => {

        setTextoConcepto(e.target.value)

    }

    const limpiarSucursal = () => {

        setTextoSucursal("")

    }

    const handleStatusChange = (e) => {
        console.log(e);
        handleDataChanges(e, 'estado')
    }

    const handleCuentaChange = (e) => {
        console.log(e);
        handleDataChanges(e, 'id_cuenta_bancaria')
    }

    return <div className='flex f-col mt-4'>
        <div className={`flex ${esCelular ? 'f-col space-y-2' : 'f-row space-x-4'} justify-center flex-wrap abc`}>
            {usuario.sede_admin && <SeleccionadorXLabel titulo='Región'
                vector={regiones}
                onchange={handleChangeSelectRegion}
                claves={{ id: 'id_region', nombre: 'nombre' }}
                valor={dataValues.id_region}
                nombre='Seleccione una región'
            />
            }
            <div>
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Fecha Desde
                </Typography>
                <Fecha fecha={dataValues.fecha_desde} setFecha={(e) => handleDataChanges(e, 'fecha_desde')} />
            </div>
            <div>
                <Typography
                    variant="small"
                    className="mb-1 font-medium "
                >
                    Fecha Hasta
                </Typography>
                <Fecha fecha={dataValues.fecha_desde} setFecha={(e) => handleDataChanges(e, 'fecha_hasta')} />
            </div>
            
            <SeleccionadorXLabel titulo='Cuenta Bancaria'
                vector={cuentas.map(item => ({ id_region: item.id_cuenta_bancaria, nombre: item.descripcion }))}
                onchange={handleCuentaChange}
                claves={{ id: 'id_region', nombre: 'nombre' }}
                valor={dataValues.id_cuenta_bancaria}
                nombre='Seleccione una cuenta'
            />

            <SeleccionadorXLabel titulo='Estado'
                vector={estados}
                onchange={handleStatusChange}
                claves={{ id: 'id_region', nombre: 'nombre' }}
                valor={dataValues.estado}
                nombre='Seleccione un estado'
            />
            {/*
                <Box sx={{}}>
                    <Typography
                        variant="small"
                        className="mb-1 font-medium "
                    >
                        Sucursal
                    </Typography>
                    <TextField fullWidth id="texto-sucursal" inputProps={{}} value={textoSucursal} onChange={handleChangeInputSucursal} variant="outlined" />
                </Box>
                <Box sx={{}}>
                    <Typography
                        variant="small"
                        className="mb-1 font-medium "
                    >
                        Concepto
                    </Typography>
                    <TextField fullWidth id="texto-concepto" inputProps={{}} value={textoConcepto} onChange={handleChangeInputConcepto} variant="outlined" />
                </Box>
            */}
        </div>
    </div>
}

const SeleccionadorXLabel = ({ vector, onchange, valor, nombre, limpiar, claves, titulo, noDefault }) => {

    return <div className='select-ui-data'>
        <Typography
            variant="small"
            className="mb-1 font-medium "
        >
            {titulo}
        </Typography>
        <Select
            label="Seleccione una opción"
            value={valor}
            onChange={onchange}
            className='h-14'
        >
            {vector.map((item, index) => (
                <Option key={index} value={item.id_region}>
                    {item.nombre}
                </Option>
            ))}
        </Select>
    </div>
}




