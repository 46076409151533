import React, { useState, useEffect,useRef } from 'react';
import {Main,Loading,Axios} from '../../../Helpers/importacionesComunes'
import {useContextoGlobal} from '../../../Context/contextoGlobal';
import {Tabla} from '../componentes/Tabla'
import {Cabecera} from '../componentes/Cabecera'
import {
    Spinner, Typography, 
} from "@material-tailwind/react";
import AbmObrero from '../../../abms/Abm-obrero';

export default function Ministros({match,history}){
    const [registros,setRegistros] = useState([])
    const [registrosAmostrar,setRegistrosAmostrar]=useState([])
    const [cargandoRegistros,setCargandoRegistros] = useState(true);
    const {esCelular, usuario} = useContextoGlobal();
    const [hayFiltrosActivos,setHayFiltrosActivos]=useState(false)
    const [huboError,setHuboError]=useState(false)
    const [periodosFiscales,setPeriodosFiscales]=useState([])
    const [periodoSeleccionado,setPeriodoSeleccionado]=useState(0)
    const [obreroSeleccionado,setObreroSeleccionado] = useState(null)
    const [isObreroSeleccionado,setIsObreroSeleccionado]=useState(false)
    const filtrosRef = useRef()

useEffect(()=>{

    if (usuario){
        buscarDatos({conLoading:true}) 
    } 
            
},[usuario]) // agregué usuario porque necesito que se dispare el evento cuando no sea vacío usuario, al principio es vacío por unos milisegundos...
    
   useEffect(()=>{

        if (registrosAmostrar.length != registros.length){
            setHayFiltrosActivos(true)
        }else{
            setHayFiltrosActivos(false)
        }

    },[registrosAmostrar])

const buscarDatos = async (params={})=>{
    
    const {conLoading} = params // con loading se usa para la búsqueda inicial de datos
                                // Cuando se modificó un ministro queremos mantener el modal abierto y no mostramos el loading

    let url = buscarApi(usuario);
    setHuboError(false)
    setRegistros([]) 
    conLoading &&  setCargandoRegistros(true)
    try{          
        const {data} = await Axios.get(`${url}`)
        if(usuario.perfil=='distrito'){
            setRegistros(data.filter(item=>item.id_distrito==usuario.id_distrito))
        }else{
            setRegistros(data)
        }
        conLoading && setCargandoRegistros(false)
    }catch(err){
        console.log(err)    
        setHuboError(true)
        conLoading && setCargandoRegistros(false)
    }
}

function finalizarAltaOcopiaMinistro (alta,id){
    buscarDatos()
}

function finalizarObreroSeleccionado (){
    setIsObreroSeleccionado(false)
}

const resetearLista = (params={})=>{
    const {respetarFiltros} = params

    if(respetarFiltros && filtrosRef.current){

        const filtros = filtrosRef.current;
        const datosFiltrados = registros.filter(filtros)
        setRegistrosAmostrar(datosFiltrados)

    }else{
        filtrosRef.current = {}
        setRegistrosAmostrar(registros)
    }
   
}

const filtrarLista=(filterCallback)=>{

    let vectorRegistrosOriginalfiltrado;

    vectorRegistrosOriginalfiltrado = registros.filter(filterCallback) 

    filtrosRef.current = filterCallback

    setRegistrosAmostrar(vectorRegistrosOriginalfiltrado)

}

if (huboError){
    return <Main center>
                <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                    <span>Se produjo un error al cargar los datos para esta vista</span>
                </div>
            </Main>
}

if (cargandoRegistros || registros.length==0 || !usuario){
    return (
        <Main center alinear>
            <div className='flex flex-col justify-center items-center h-[calc(100vh-10rem)]'>
                <Spinner className="h-12 w-12" />
                <span className="cargando font-semibold text-[15px]">Cargando registros...</span>
            </div>
        </Main>
    )
};

return(
    <div className='flex'>
            {isObreroSeleccionado && <div className="p-4 rounded ml-auto mr-auto">
                                    <AbmObrero id_obrero={obreroSeleccionado ? obreroSeleccionado : null} 
                                        esModal={true}
                                        usuario={usuario} 
                                        finalizarAltaOcopia ={finalizarAltaOcopiaMinistro}
                                        finalizarObreroSeleccionado = {finalizarObreroSeleccionado}
                                    />
                                </div>
            } 
       {!isObreroSeleccionado && <div className="bg-blue lg:m-6 xl:m-6 items-center flex flex-col justify-center">
            <Typography variant="h5" className="w-full mb-2 flex justify-center lg:hidden xl:hidden">
                Ministros
            </Typography> 
             <div className='mb-2'>
                <Cabecera
                        periodosFiscales = {periodosFiscales}
                        periodoSeleccionado = {periodoSeleccionado}
                        filtrarLista = {filtrarLista}   // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                        hayFiltrosActivos = {hayFiltrosActivos}  
                        resetearLista = {resetearLista} // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                        tablasDelListado = {tablasDelListado(registros)}      
                    />
                </div>

            <Typography
                variant="small"
                color="blue-gray"
                className="flex justify-between mb-3 text-lg text-[#F63E3E] font-medium"
            >
                {registrosAmostrar.length.toLocaleString()} registros encontrados
            </Typography>

            {registrosAmostrar.length ? <Tabla  
                registrosAmostrar ={registrosAmostrar} 
                reinicializar = {finalizarAltaOcopiaMinistro}
                resetearLista = {resetearLista} // el listado se filtra y se resetea en el comp padre porque los datos se comparten con otros componentes
                obreroSeleccionado = {obreroSeleccionado}
                setObreroSeleccionado = {setObreroSeleccionado}
                setIsObreroSeleccionado = {setIsObreroSeleccionado}
            /> : null}
        </div>}
     </div>)
    }

function buscarApi(usuario,periodoFiscal){
    return usuario.sede_admin ? `/api/tablasgenerales/obreros/*` : `/api/tablasgenerales/obreros/${usuario.id_region}`
}


const tablasDelListado = (listado)=>{

    const ministerios = ministeriosDeLosObreros(listado)

    const estados_credenciales_ministros = estadosCredencialDeLosObreros(listado)

    return {ministerios:ministerios, estadosCredenciales:estados_credenciales_ministros}
}

const ministeriosDeLosObreros = (listado)=>{
   

        const ministerios = listado.map(item=>item.ministerio.replace(/\[/g, " ").replace(/\]/g, " ")).sort().filter((item,index,vector)=>
        item != vector[index-1])

        const ministerios_filtrados = ministerios.toString().split(" ").sort().filter((item,index,vector)=>
        item != vector[index-1] && item!="" && item!=",").map(item=>`[${item}]`)

        return ministerios_filtrados
}

const estadosCredencialDeLosObreros = (listado)=>{

    return listado.map(item=>item.tieneCredencial).sort().filter((item,index,vector)=>
    item != vector[index-1] )
}
